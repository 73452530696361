<template>
  <div class="pt-7">
    <div class="pb-5 px-2">
      <p class="mb-5" v-html="$t('messageInsuranceProductOverview')"></p>
    </div>

    <v-row v-if="!$vuetify.breakpoint.smAndDown" justify="center" class="px-2 insurance-plan">
      <v-col v-for="(item, index) in currentProviders" :key="item.name + index" cols="12" sm="12" md="6" lg="6">
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <img :src="item.providerDetails.logo" height="65" alt="" class="provider-logo" />
              <template v-slot:actions>
                <v-icon color="primary"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <h3 class="insurance-plan-name">{{ item.providerDetails.name }} &ndash; {{ item.name }}</h3>
              <div
                v-for="benefit in getBenefits(item.planBenefits)"
                class="d-flex align-items-start insurance-plan-benefit"
              >
                <v-icon size="16" color="#37B59D">
                  {{ icons.mdiCheck }}
                </v-icon>
                <p class="ml-4">
                  {{ benefit }}
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="insurance-plan-links-lg">
                <div class="insurance-plan-link d-flex align-items-center">
                  <v-icon size="24" color="primary">
                    {{ icons.mdiFileDownload }}
                  </v-icon>
                  <a
                    v-if="item.planDocument.tariffSheets"
                    :href="getDocumentUrl(item.planDocument.tariffSheets[0])"
                    target="_blank"
                    class="ml-4"
                  >
                    {{ $t('buttonProductDataSheetDownload') }}
                  </a>
                </div>
                <div v-if="item.planDocument.factSheets.length" class="insurance-plan-link d-flex align-items-center">
                  <v-icon size="24" color="primary">
                    {{ icons.mdiFileDownload }}
                  </v-icon>
                  <a :href="getDocumentUrl(item.planDocument.factSheets[0])" target="_blank" class="ml-4">
                    {{ $t('buttonProductFactSheetDownload') }}
                  </a>
                </div>
              </div>
              <template v-slot:actions>
                <v-icon color="primary"> </v-icon>
              </template>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown" justify="center" class="px-2 insurance-plan insurance-plan-m">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="(item, index) in currentProviders" :key="item.name + index">
            <v-expansion-panel-header>
              <img :src="item.providerDetails.logo" height="65" alt="" class="provider-logo" />
              <template v-slot:actions>
                <v-icon color="primary"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <h3 class="insurance-plan-name">
                {{ item.providerDetails.name }}
              </h3>
              <div
                v-for="benefit in getBenefits(item.planBenefits)"
                class="d-flex align-items-start insurance-plan-benefit"
              >
                <v-icon size="16" color="#37B59D">
                  {{ icons.mdiCheck }}
                </v-icon>
                <p class="ml-4">
                  {{ benefit }}
                </p>
              </div>
              <div class="insurance-plan-links">
                <div class="insurance-plan-link d-flex align-items-center">
                  <v-icon size="24" color="primary">
                    {{ icons.mdiFileDownload }}
                  </v-icon>
                  <a
                    v-if="item.planDocument.tariffSheets"
                    :href="getDocumentUrl(item.planDocument.tariffSheets[0])"
                    target="_blank"
                    class="ml-4"
                  >
                    {{ $t('buttonProductDataSheetDownload') }}
                  </a>
                </div>
                <div v-if="item.planDocument.factSheets.length" class="insurance-plan-link d-flex align-items-center">
                  <v-icon size="24" color="primary">
                    {{ icons.mdiFileDownload }}
                  </v-icon>
                  <a :href="getDocumentUrl(item.planDocument.factSheets[0])" target="_blank" class="ml-4">
                    {{ $t('buttonProductFactSheetDownload') }}
                  </a>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, watch, computed, getCurrentInstance } from '@vue/composition-api';
import { mdiFilePdfBox, mdiDownload, mdiCheck, mdiFileDownload } from '@mdi/js';
import { config } from '../../../../../appConfig';

export default {
  components: {},
  setup() {
    const vm = getCurrentInstance().proxy;
    const totalTabs = ref(3);
    const currentTab = ref(0);
    const assetsUrl = ref('');

    watch(totalTabs, (newValue) => {
      currentTab.value = newValue - 1;
    });

    assetsUrl.value = config.variables.ASSETS_BASE_URL;

    const currentLang = computed(() => vm.$store.state.app.currentLang);

    const getProviderLogoUrl = (logo) => {
      return `${logo}`;
    };

    const getDocumentUrl = (files) => {
      return currentLang.value === 'en' ? `${files.en.file}` : `${files.de.file}`;
    };

    const getBenefits = (benefits) => {
      if (!benefits.en || !benefits.de) return [];
      return currentLang.value === 'en' ? benefits.en : benefits.de;
    };

    return {
      getProviderLogoUrl,
      getDocumentUrl,
      getBenefits,
      currentLang,
      assetsUrl,
      totalTabs,
      currentTab,
      icons: {
        mdiFilePdfBox,
        mdiDownload,
        mdiCheck,
        mdiFileDownload,
      },
    };
  },
  props: {
    currentProviders: [],
  },

  mounted() {},
  computed: {},
};
</script>

<style lang="scss">
.provider {
  &-logo {
    height: 65px;
    max-width: 250px;
    object-fit: contain;
    object-position: left;
  }
}
.v-image--custom {
  background: #fafafa;
}
.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

.insurance-plan {
  &-m {
    .v-expansion-panel {
      border-radius: 0 !important;
    }
  }
  &-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #505a68;
    margin-bottom: 32px;
    margin-top: 15px;
    margin-left: 19px;
  }
  &-benefit {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
    padding-left: 19px;
    padding-right: 19px;
  }
  &-links {
    border-top: 1px solid #f3f3f3;
    padding: 6px 16px;
  }
  &-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #2830ce;
    margin: 12px 0;
  }

  .v-expansion-panel-header--active {
    border-bottom: 1px solid #f3f3f3 !important;
  }

  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
</style>
