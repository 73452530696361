<template>
  <div>
    <!-- <vimeo-player
      class="mt-10 v-card--no-border"
      :videoDE="'https://player.vimeo.com/video/460233316?h=3f9902c785'"
      :videoEN="'https://player.vimeo.com/video/460230592?h=29e5e9d48d'"
    ></vimeo-player> -->
    <v-card class="v-card--no-border py-11">
      <h2 class="font-weight-bold text-center">
        {{ $t('headlineFunctionality') }}
      </h2>
    </v-card>

    <v-row class="d-flex align-items-center">
      <div class="scenario mb-12 col-12">
        <div class="scenario-img scenario-img--a"><ScenarioAGraphic :currentLang="currentLang" /></div>
        <div class="scenario-text mb-5 scenario-text">
          <h3>{{ $t('subHeadlineFunctionality1') }}</h3>
          <p class="">
            {{
              $t('paragraphFunctionality1', {
                COMPANY: companyName,
              })
            }}
          </p>
        </div>
      </div>
    </v-row>

    <v-row class="d-flex align-items-center">
      <div class="scenario scenario--b mb-12 col-12">
        <div class="scenario-text scenario-text--b mb-5 ml-8">
          <h3>{{ $t('subHeadlineFunctionality2') }}</h3>
          <p
            class=""
            v-html="
              $t('paragraphFunctionality2', {
                COMPANY: '<br>companyName</strong>',
              })
            "
          ></p>
        </div>
        <div class="scenario-img--b"><ScenarioBGraphic :currentLang="currentLang" /></div>
      </div>
    </v-row>

    <v-row class="d-flex align-items-center">
      <div class="scenario col-12">
        <v-img
          :max-width="$vuetify.breakpoint.mdAndUp ? '353px' : '324px'"
          width="100%"
          class="mb-5 scenario-img scenario-img--c"
          :src="imgPath('scenario-c-new')"
        ></v-img>
        <div class="scenario-text mb-5">
          <h3>{{ $t('subHeadlineFunctionality3') }}</h3>
          <p class="">
            {{
              $t('paragraphFunctionality3', {
                COMPANY: companyName,
              })
            }}
          </p>
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import { getCurrentInstance, computed, toRef } from '@vue/composition-api';
import VimeoPlayer from '@/components/vimeo-player/VimeoPlayer';
import ScenarioAGraphic from '@/components/graphics/ScenarioA.vue';
import ScenarioBGraphic from '@/components/graphics/ScenarioB.vue';

export default {
  name: 'GeneralBavInfo',
  components: { VimeoPlayer, ScenarioAGraphic, ScenarioBGraphic },
  props: ['companyName'],
  setup() {
    const vm = getCurrentInstance().proxy;

    const currentLang = computed(() => vm.$store.state.app.currentLang);

    const imgPath = (filename) => {
      return require(`@/assets/static/images/${filename}${currentLang.value === 'en' ? '_EN' : ''}.svg`);
    };

    return {
      imgPath,
      currentLang,
    };
  },
};
</script>

<style scoped lang="scss">
.scenario {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;

    color: #2830ce;
  }
  &--b {
    padding-right: 95px;
  }
  &-img {
    // height: 339px;
    // width: 226px;

    &--a {
      max-width: 336px;
      width: 100%;
      margin-right: 132px;
    }
    &--b {
      margin-right: 0;
      margin-left: 46px;
      // width: 263px;
      // height: 336px;
      max-width: 350px;
      width: 100%;
    }
    &--c {
      // width: 252px;
      // height: 393px;
      margin-right: 130px;
    }
  }
  &-text {
    max-width: 347px;
  }
}

@media screen and (max-width: 960px) {
  .scenario {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 25px !important;
    margin-top: 8px;
    &--b {
      padding-right: 5px;
    }
    &-img {
      // width: 124px;
      // height: 186px;
      margin-right: 44px;
      margin-top: 33px;

      &--b {
        margin-right: 0;
        margin-left: 15px;
        // width: 143.95px;
        // height: 183.91px;
      }
      &--c {
        // width: 119.25px;
        // height: 193.24px;
        margin-right: 48px;
      }
    }
    &-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      max-width: 158.07px;
    }
  }
}
</style>
