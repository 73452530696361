<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1097.4 977.4"
    style="enable-background: new 0 0 1097.4 977.4"
    xml:space="preserve"
  >
    <g id="Layer_2">
      <path
        style="fill: #6edec8"
        d="M801.4,429.7H576c-7.3,0-13.3-5.9-13.3-13.3v-76.3c0-7.3,5.9-13.3,13.3-13.3h225.4
		c7.3,0,13.3,5.9,13.3,13.3v76.3C814.6,423.7,808.7,429.7,801.4,429.7z"
      />
      <g>
        <path
          style="fill: #6edec8"
          d="M801.4,317.9H576c-7.3,0-13.3-5.9-13.3-13.3v-76.3c0-7.3,5.9-13.3,13.3-13.3h225.4
			c7.3,0,13.3,5.9,13.3,13.3v76.3C814.6,312,808.7,317.9,801.4,317.9z"
        />
      </g>
      <g>
        <text transform="matrix(1 0 0 1 650.6741 251.3552)">
          <tspan x="0" y="0" style="fill: #039181; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 20px">
            {{ formatCurrency(socialContribution) }}
          </tspan>
          <tspan x="-81.7" y="20.4" style="fill: #039181; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 17px">
            {{ $t('exampleCalcsTaxSavingsLabel1') }}
          </tspan>
          <tspan x="-0.5" y="40.8" style="fill: #039181; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 17px">
            {{ $t('exampleCalcsTaxSavingsLabel2') }}
          </tspan>
        </text>
      </g>
      <path
        style="fill: #6edec8"
        d="M801.4,206.2H576c-7.3,0-13.3-5.9-13.3-13.3v-76.3c0-7.3,5.9-13.3,13.3-13.3h225.4
		c7.3,0,13.3,5.9,13.3,13.3v76.3C814.6,200.2,808.7,206.2,801.4,206.2z"
      />
      <g>
        <text transform="matrix(1 0 0 1 651.0242 149.8054)">
          <tspan x="0" y="0" style="fill: #039181; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 20px">
            {{ formatCurrency(employerSubsidyCurrency) }}
          </tspan>
          <tspan x="-48.6" y="20.4" style="fill: #039181; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 17px">
            {{ $t('exampleCalcsEmployerSubsidyLabel') }}
          </tspan>
        </text>
      </g>
      <g>
        <path
          style="fill: #6bbdff"
          d="M238.7,914.2H14.9c-7.7,0-14-6.3-14-14v-450c0-7.7,6.3-14,14-14h223.9c7.7,0,14,6.3,14,14v450
			C252.7,907.9,246.5,914.2,238.7,914.2z"
        />
      </g>
      <path
        style="fill: #1895f9"
        d="M505.5,914.2H281.7c-7.7,0-14-6.3-14-14V696c0-7.7,6.3-14,14-14h223.9c7.7,0,14,6.3,14,14v204.2
		C519.5,907.9,513.2,914.2,505.5,914.2z"
      />
      <path
        style="fill: #f9b60a"
        d="M505.5,675.8H281.7c-7.7,0-14-6.3-14-14v-88.5c0-7.7,6.3-14,14-14h223.9c7.7,0,14,6.3,14,14v88.5
		C519.5,669.5,513.2,675.8,505.5,675.8z"
      />
      <path
        style="fill: #f9b60a"
        d="M505.5,552.7H281.7c-7.7,0-14-6.3-14-14v-88.5c0-7.7,6.3-14,14-14h223.9c7.7,0,14,6.3,14,14v88.5
		C519.5,546.5,513.2,552.7,505.5,552.7z"
      />
      <g>
        <path
          style="fill: #6bbdff"
          d="M800.6,914.2H576.8c-7.7,0-14-6.3-14-14v-450c0-7.7,6.3-14,14-14h223.9c7.7,0,14,6.3,14,14v450
			C814.6,907.9,808.4,914.2,800.6,914.2z"
        />
      </g>
      <path
        style="fill: #1895f9"
        d="M1083.4,914.2H859.6c-7.7,0-14-6.3-14-14V696c0-7.7,6.3-14,14-14h223.9c7.7,0,14,6.3,14,14v204.2
		C1097.4,907.9,1091.1,914.2,1083.4,914.2z"
      />
      <path
        style="fill: #6edec8"
        d="M1083.4,429.7H859.6c-7.7,0-14-6.3-14-14V30.3c0-7.7,6.3-14,14-14h223.9c7.7,0,14,6.3,14,14v385.4
		C1097.4,423.4,1091.1,429.7,1083.4,429.7z"
      />
      <path
        style="fill: #f9b60a"
        d="M1088.4,649.6H854.6c-5,0-9-4-9-9v-51.4c0-5,4-9,9-9h233.9c5,0,9,4,9,9v51.4
		C1097.4,645.6,1093.4,649.6,1088.4,649.6z"
      />
      <path
        style="fill: #f9b60a"
        d="M1088.4,572.2H854.6c-5,0-9-4-9-9v-51.4c0-5,4-9,9-9h233.9c5,0,9,4,9,9v51.4
		C1097.4,568.1,1093.4,572.2,1088.4,572.2z"
      />
      <path
        style="fill: #2930ce"
        d="M1083.4,736.6H859.6c-7.7,0-14-6.3-14-14v-51c0-7.7,6.3-14,14-14h223.9c7.7,0,14,6.3,14,14v51
		C1097.4,730.3,1091.1,736.6,1083.4,736.6z"
      />
      <path
        style="fill: #6edec8"
        d="M1089.4,494.6H853.6c-4.4,0-8-3.6-8-8v-27.8c0-4.4,3.6-8,8-8h235.9c4.4,0,8,3.6,8,8v27.8
		C1097.4,491,1093.8,494.6,1089.4,494.6z"
      />
      <text transform="matrix(1 0 0 1 327.0417 798.0858)">
        <tspan x="0" y="0" style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 28.5266px">
          {{ formatCurrency(netWageBefore) }}
        </tspan>
        <tspan x="2.7" y="27.1" style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 22.6124px">
          {{ $t('exampleCalcsNetWageLabel') }}
        </tspan>
      </text>
      <text transform="matrix(1 0 0 1 905.2163 798.0857)">
        <tspan x="0" y="0" style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 28.5266px">
          {{ formatCurrency(netWageAfter) }}
        </tspan>
        <tspan x="2.4" y="27.1" style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 22.6124px">
          {{ $t('exampleCalcsNetWageLabel') }}
        </tspan>
      </text>
      <text transform="matrix(1 0 0 1 864.9241 468.5679)">
        <tspan x="0" y="0" style="fill: #039181; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 15px">
          {{ $t('exampleCalcsTaxSavingsLabel1') }}
        </tspan>
        <tspan x="72.6" y="18" style="fill: #039181; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 15px">
          {{ $t('exampleCalcsTaxSavingsLabel2') }}
        </tspan>
      </text>
      <text transform="matrix(0.975 0 0 1 927.2651 694.5552)">
        <tspan x="0" y="0" style="fill: #6bbdff; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 23.192px">
          {{ formatCurrency(personalContribution) }}
        </tspan>
        <tspan x="-30.6" y="21.6" style="fill: #6bbdff; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 18.0048px">
          {{ $t('exampleCalcsOwnContributionLabel') }}
        </tspan>
      </text>
      <text transform="matrix(1 0 0 1 649.5945 373.3045)">
        <tspan x="0" y="0" style="fill: #039181; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 20px">
          {{ formatCurrency(personalContribution) }}
        </tspan>
        <tspan x="-32.6" y="20.4" style="fill: #039181; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 17px">
          {{ $t('exampleCalcsOwnContributionLabel') }}
        </tspan>
      </text>
      <text transform="matrix(1 0 0 1 51.2824 675.189)">
        <tspan x="0" y="0" style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 28.5266px">
          {{ formatCurrency(grossWage, { minimumFractionDigits: 2 }) }}
        </tspan>
        <tspan x="7.8" y="27.1" style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 22.6124px">
          {{ $t('exampleCalcsGrossWageLabel') }}
        </tspan>
      </text>
      <text transform="matrix(1 0 0 1 613.1951 675.189)">
        <tspan x="0" y="0" style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 28.5266px">
          {{ formatCurrency(grossWage, { minimumFractionDigits: 2 }) }}
        </tspan>
        <tspan x="7.8" y="27.1" style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 22.6124px">
          {{ $t('exampleCalcsGrossWageLabel') }}
        </tspan>
      </text>
      <path
        style="fill: #37b59d"
        d="M835.9,155.5l-8.9,8.9c-0.4,0.4-1.1,0.4-1.6,0l-1-1c-0.4-0.4-0.4-1.1,0-1.6l7.1-7.1l-7.1-7.1
		c-0.4-0.4-0.4-1.1,0-1.6l1-1c0.4-0.4,1.1-0.4,1.6,0l8.9,8.9C836.3,154.4,836.3,155.1,835.9,155.5z"
      />
      <path
        style="fill: #6edec8"
        d="M801.4,94.4H576c-7.3,0-13.3-5.9-13.3-13.3V29.5c0-7.3,5.9-13.3,13.3-13.3h225.4
		c7.3,0,13.3,5.9,13.3,13.3v51.6C814.6,88.4,808.7,94.4,801.4,94.4z"
      />
      <path
        style="fill: #37b59d"
        d="M835.9,56.1l-8.9,8.9c-0.4,0.4-1.1,0.4-1.6,0l-1-1c-0.4-0.4-0.4-1.1,0-1.6l7.1-7.1l-7.1-7.1
		c-0.4-0.4-0.4-1.1,0-1.6l1-1c0.4-0.4,1.1-0.4,1.6,0l8.9,8.9C836.3,55,836.3,55.7,835.9,56.1z"
      />
      <path
        style="fill: #37b59d"
        d="M835.9,267.3l-8.9,8.9c-0.4,0.4-1.1,0.4-1.6,0l-1-1c-0.4-0.4-0.4-1.1,0-1.6l7.1-7.1l-7.1-7.1
		c-0.4-0.4-0.4-1.1,0-1.6l1-1c0.4-0.4,1.1-0.4,1.6,0l8.9,8.9C836.3,266.2,836.3,266.9,835.9,267.3z"
      />
      <path
        style="fill: #37b59d"
        d="M835.9,379l-8.9,8.9c-0.4,0.4-1.1,0.4-1.6,0l-1-1c-0.4-0.4-0.4-1.1,0-1.6l7.1-7.1l-7.1-7.1
		c-0.4-0.4-0.4-1.1,0-1.6l1-1c0.4-0.4,1.1-0.4,1.6,0l8.9,8.9C836.3,377.9,836.3,378.6,835.9,379z"
      />
      <path
        style="fill: #37b59d"
        d="M978.4,434.4l8.9,8.9c0.4,0.4,0.4,1.1,0,1.6l-1,1c-0.4,0.4-1.1,0.4-1.6,0l-7.1-7.1l-7.1,7.1
		c-0.4,0.4-1.1,0.4-1.6,0l-1-1c-0.4-0.4-0.4-1.1,0-1.6l8.9-8.9C977.2,434,977.9,434,978.4,434.4z"
      />
      <text transform="matrix(1 0 0 1 907.7593 281.4005)">
        <tspan x="0" y="0" style="fill: #039181; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 32.9154px">
          {{ formatCurrency(totalContribution) }}
        </tspan>
        <tspan x="-10.2" y="25" style="fill: #039181; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 20.8464px">
          {{ $t('exampleCalcsTotalContrLabel') }}
        </tspan>
      </text>
      <path
        style="fill: #37b59d"
        d="M1017.1,152.4L975.4,135c-1.2-0.5-2.6-0.8-3.9-0.8c-1.4,0-2.7,0.3-3.9,0.8l-41.7,17.4
		c-3.9,1.7-6.5,5.4-6.5,9.6c0,43.2,24.9,72.9,48.2,82.7c1.2,0.5,2.6,0.8,3.9,0.8c1.4,0,2.7-0.3,3.9-0.8
		c23.3-9.8,48.2-39.5,48.2-82.7C1023.6,157.8,1021,153.9,1017.1,152.4z M971.5,237h-0.3c-23.3-11.6-42.8-38.7-43.5-76.4l43.7-18.2
		h0.3l43.7,18.2C1014.4,198.3,994.9,225.5,971.5,237z"
      />
      <path
        style="fill: #6edec8"
        d="M971.3,142.5l-43.7,18.2c0.9,37.7,20.4,64.8,43.7,76.4h0.3c23.3-11.6,42.9-38.9,43.7-76.4
		L971.3,142.5L971.3,142.5z"
      />
      <g>
        <path
          style="fill: #ffffff"
          d="M947,191.5L947,191.5v2.4h-4.6v-22.7h4.8v5c0,1.4-0.1,2.8-0.1,3.7h0.1c1-1.7,2.9-2.8,4.9-2.7
			c4.2,0,7,3.6,7,8.5c0,5.4-2.7,8.6-6.9,8.6C949.9,194.6,947.9,193.4,947,191.5z M954,185.8c0-2.9-1.1-4.9-3.4-4.9
			c-2.3,0-3.6,2.1-3.6,4.9c0,2.7,1.4,4.8,3.7,4.8S954,188.7,954,185.8L954,185.8z"
        />
        <path
          style="fill: #ffffff"
          d="M968.1,171.3h5.4l8.1,22.7h-5.4l-1.4-4.3h-8.2l-1.4,4.3H960L968.1,171.3z M967.8,185.9h5.8
			l-1.4-4.2c-0.6-2-1.1-4-1.5-6l0,0c0,0-0.8,3.8-1.5,6L967.8,185.9z"
        />
        <path
          style="fill: #ffffff"
          d="M979.9,171.3h5.4l3.3,10.9c0.7,2.5,1.6,6.7,1.6,6.7l0,0c0,0,0.8-4.2,1.5-6.7l3.3-10.9h5.2
			l-7.5,22.7h-5.4L979.9,171.3z"
        />
      </g>
      <text transform="matrix(1 0 0 1 338.8127 614.5932)">
        <tspan x="0" y="0" style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 22.6124px">
          - {{ formatCurrency(socialDeductionsBefore) }}
        </tspan>
        <tspan x="-24" y="21.1" style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 17.5548px">
          {{ $t('exampleCalcsSocialLabel') }}
        </tspan>
      </text>
      <text transform="matrix(1 0 0 1 344.4658 491.55)">
        <tspan x="0" y="0" style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 22.6124px">
          - {{ formatCurrency(taxDeductionsBefore) }}
        </tspan>
        <tspan x="21" y="21.1" style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 17.5548px">
          {{ $t('exampleCalcsTaxLabel') }}
        </tspan>
      </text>
      <text transform="matrix(1 0 0 1 916.1973 611.976)">
        <tspan x="0" y="0" style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 22.6124px">
          - {{ formatCurrency(socialDeductionsAfter) }}
        </tspan>
        <tspan x="-24" y="21.1" style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 17.5548px">
          {{ $t('exampleCalcsSocialLabel') }}
        </tspan>
      </text>
      <text transform="matrix(1 0 0 1 924.1787 534.5293)">
        <tspan x="0" y="0" style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 22.6124px">
          - {{ formatCurrency(taxDeductionsAfter) }}
        </tspan>
        <tspan x="19.2" y="21.1" style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 17.5548px">
          {{ $t('exampleCalcsTaxLabel') }}
        </tspan>
      </text>
      <g>
        <circle style="fill: #37b59d" cx="688.7" cy="210.6" r="13.9" />
        <path style="fill: #ffffff" d="M689.9,217.4h-2.3v-5.6h-5.6v-2.3h5.6v-5.6h2.3v5.6h5.7v2.3h-5.6V217.4z" />
      </g>
      <g>
        <circle style="fill: #37b59d" cx="688.7" cy="98.8" r="13.9" />
        <path style="fill: #ffffff" d="M689.9,105.6h-2.3v-5.6h-5.6v-2.3h5.6v-5.6h2.3v5.6h5.7v2.3h-5.6V105.6z" />
      </g>
      <g>
        <circle style="fill: #37b59d" cx="688.7" cy="322.4" r="13.9" />
        <path style="fill: #ffffff" d="M689.9,329.3h-2.3v-5.6h-5.6v-2.3h5.6v-5.6h2.3v5.6h5.7v2.3h-5.6V329.3z" />
      </g>

      <text
        transform="matrix(1 0 0 1 155.7915 951.8844)"
        style="fill: #6bbdff; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 36.1992px"
      >
        {{ $t('exampleCalcsWithoutBAVLabel') }}
      </text>

      <text
        transform="matrix(1 0 0 1 755.4031 951.8844)"
        style="fill: #37b59d; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 36.1992px"
      >
        {{ $t('exampleCalcsWithBAVLabel') }}
      </text>
      <g>
        <text transform="matrix(1 0 0 1 657.2644 52.3219)">
          <tspan x="0" y="0" style="fill: #039181; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 20px">
            {{ formatCurrency(employerContribution) }}
          </tspan>
          <tspan x="-52" y="20.4" style="fill: #039181; font-family: 'NHaasGroteskTXPro-65Md'; font-size: 17px">
            {{ $t('exampleCalcEmployersContributionLabel2') }}
          </tspan>
        </text>
      </g>
    </g>
    <g id="Layer_3" style="display: none"></g>
    <g id="Layer_4"></g>
  </svg>
</template>

<script>
export default {
  props: {
    grossWage: {
      type: Number,
    },
    taxDeductionsBefore: {
      type: Number,
    },
    taxDeductionsAfter: {
      type: Number,
    },
    socialDeductionsBefore: {
      type: Number,
    },
    socialDeductionsAfter: {
      type: Number,
    },
    personalContribution: {
      type: Number,
    },
    socialContribution: {
      type: Number,
    },
    employerContribution: {
      type: Number,
    },
    totalContribution: {
      type: Number,
    },
    netWageBefore: {
      type: Number,
    },
    netWageAfter: {
      type: Number,
    },
    employerSubsidy: {
      type: Number,
    },
    employerSubsidyCurrency: {
      type: Number,
    },
    currentLang: {
      type: String,
    },
  },
  methods: {
    getFormatter(opts) {
      return new Intl.NumberFormat('DE', opts);
    },

    formatNumber(amount, opts = {}) {
      const defaults = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

      return this.getFormatter({ ...defaults, ...opts }).format(amount);
    },

    formatCurrency(amount, opts = {}) {
      const defaults = {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

      return this.getFormatter({ ...defaults, ...opts }).format(amount);
    },

    formatPercent(amount, opts = {}) {
      const defaults = {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };

      return this.getFormatter({ ...defaults, ...opts }).format(amount);
    },
  },
};
</script>

<style scoped lang="scss"></style>
