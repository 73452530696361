<template>
  <svg
    id="Layer_2"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 372.3 340.9"
    style="enable-background: new 0 0 372.3 340.9"
    xml:space="preserve"
    class="scenario-b"
  >
    <font horiz-adv-x="1000">
      <font-face
        font-family="NHaasGroteskTXPro-65Md"
        units-per-em="1000"
        underline-position="-100"
        underline-thickness="50"
      />
      <missing-glyph horiz-adv-x="500" />
      <glyph
        unicode="A"
        horiz-adv-x="718"
        d="M14,0l140,0l60,177l282,0l59,-177l148,0l-271,744l-148,0M307,451C331,522 356,630 356,630l2,0C358,630 376,539 405,451l57,-172l-214,0z"
      />
      <glyph
        unicode="&#xC6;"
        horiz-adv-x="1029"
        d="M972,744l-694,0l-261,-744l138,0l61,179l244,0l0,-179l524,0l0,124l-384,0l0,206l326,0l0,111l-326,0l0,185l372,0M368,626l92,0l0,-345l-209,0z"
      />
      <glyph
        unicode="&#x1FC;"
        horiz-adv-x="1029"
        d="M548,785l102,0l100,128l-154,0M972,744l-694,0l-261,-744l138,0l61,179l244,0l0,-179l524,0l0,124l-384,0l0,206l326,0l0,111l-326,0l0,185l372,0M368,626l92,0l0,-345l-209,0z"
      />
      <glyph
        unicode="&#xC1;"
        horiz-adv-x="718"
        d="M14,0l140,0l60,177l282,0l59,-177l148,0l-271,744l-148,0M307,451C331,522 356,630 356,630l2,0C358,630 376,539 405,451l57,-172l-214,0M301,785l102,0l100,128l-154,0z"
      />
      <glyph
        unicode="&#x102;"
        horiz-adv-x="718"
        d="M14,0l140,0l60,177l282,0l59,-177l148,0l-271,744l-148,0M307,451C331,522 356,630 356,630l2,0C358,630 376,539 405,451l57,-172l-214,0M360,779C448,779 504,839 507,915l-77,0C423,886 400,863 359,863C316,863 295,885 288,915l-79,0C213,834 275,779 360,779z"
      />
      <glyph
        unicode="&#xC2;"
        horiz-adv-x="718"
        d="M14,0l140,0l60,177l282,0l59,-177l148,0l-271,744l-148,0M307,451C331,522 356,630 356,630l2,0C358,630 376,539 405,451l57,-172l-214,0M188,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph
        unicode="&#xC4;"
        horiz-adv-x="718"
        d="M14,0l140,0l60,177l282,0l59,-177l148,0l-271,744l-148,0M307,451C331,522 356,630 356,630l2,0C358,630 376,539 405,451l57,-172l-214,0M409,785l125,0l0,122l-125,0M184,785l125,0l0,122l-125,0z"
      />
      <glyph
        unicode="&#xC0;"
        horiz-adv-x="718"
        d="M14,0l140,0l60,177l282,0l59,-177l148,0l-271,744l-148,0M307,451C331,522 356,630 356,630l2,0C358,630 376,539 405,451l57,-172l-214,0M306,785l101,0l-47,128l-154,0z"
      />
      <glyph
        unicode="&#x100;"
        horiz-adv-x="718"
        d="M14,0l140,0l60,177l282,0l59,-177l148,0l-271,744l-148,0M307,451C331,522 356,630 356,630l2,0C358,630 376,539 405,451l57,-172l-214,0M209,792l299,0l0,94l-299,0z"
      />
      <glyph
        unicode="&#x104;"
        horiz-adv-x="718"
        d="M637,-187C669,-187 690,-183 703,-178l0,72l-3,0C691,-109 679,-111 665,-111C642,-111 628,-102 628,-80C628,-54 654,-20 702,3l-270,741l-148,0l-270,-744l140,0l60,177l281,0l60,-177l44,0C563,-17 520,-50 520,-101C520,-153 559,-187 637,-187M307,451C331,522 356,630 356,630l2,0C358,630 376,539 405,451l57,-172l-213,0z"
      />
      <glyph
        unicode="&#xC5;"
        horiz-adv-x="718"
        d="M14,0l140,0l60,177l282,0l59,-177l148,0l-271,744l-148,0M360,777C419,777 468,823 468,883C468,941 419,988 360,988C299,988 250,941 250,883C250,823 299,777 360,777M307,451C331,522 356,630 356,630l2,0C358,630 376,539 405,451l57,-172l-214,0M360,827C327,827 305,851 305,883C305,914 327,939 360,939C391,939 413,914 413,883C413,851 391,827 360,827z"
      />
      <glyph
        unicode="&#xC3;"
        horiz-adv-x="718"
        d="M14,0l140,0l60,177l282,0l59,-177l148,0l-271,744l-148,0M307,451C331,522 356,630 356,630l2,0C358,630 376,539 405,451l57,-172l-214,0M428,786C489,786 521,834 523,909l-76,0C444,886 436,872 416,872C380,872 351,911 291,911C236,911 195,868 194,786l75,0C271,810 285,826 301,826C330,826 371,786 428,786z"
      />
      <glyph
        unicode="B"
        horiz-adv-x="701"
        d="M70,0l348,0C498,0 560,22 603,62C645,98 669,150 669,216C669,310 610,374 517,396l0,3C597,423 639,474 639,551C639,608 619,653 582,687C543,725 484,744 407,744l-337,0M207,437l0,196l187,0C462,633 504,597 504,536C504,472 459,437 387,437M207,113l0,221l195,0C476,334 530,297 530,221C530,189 519,163 500,145C480,125 448,113 406,113z"
      />
      <glyph
        unicode="C"
        horiz-adv-x="752"
        d="M398,-16C498,-16 574,12 628,66C680,116 715,191 718,265l-139,0C571,175 510,101 399,101C266,101 181,212 181,370C181,525 256,641 398,641C496,641 559,585 572,505l138,0C701,583 665,650 605,697C554,736 484,758 399,758C178,758 40,591 40,370C40,147 183,-16 398,-16z"
      />
      <glyph
        unicode="&#x106;"
        horiz-adv-x="752"
        d="M398,-16C498,-16 574,12 628,66C680,116 715,191 718,265l-139,0C571,175 510,101 399,101C266,101 181,212 181,370C181,525 256,641 398,641C496,641 559,585 572,505l138,0C701,583 665,650 605,697C554,736 484,758 399,758C178,758 40,591 40,370C40,147 183,-16 398,-16M330,785l102,0l100,128l-154,0z"
      />
      <glyph
        unicode="&#x10C;"
        horiz-adv-x="752"
        d="M398,-16C498,-16 574,12 628,66C680,116 715,191 718,265l-139,0C571,175 510,101 399,101C266,101 181,212 181,370C181,525 256,641 398,641C496,641 559,585 572,505l138,0C701,583 665,650 605,697C554,736 484,758 399,758C178,758 40,591 40,370C40,147 183,-16 398,-16M556,908l-117,0l-52,-59l-2,0l-50,59l-117,0l104,-123l129,0z"
      />
      <glyph
        unicode="&#xC7;"
        horiz-adv-x="752"
        d="M359,-209C430,-209 480,-179 480,-128C480,-69 422,-50 372,-57l0,2l45,40C508,-12 577,15 628,66C680,116 715,191 718,265l-139,0C571,175 510,101 399,101C266,101 181,212 181,370C181,525 256,641 398,641C496,641 559,585 572,505l138,0C701,583 665,650 605,697C554,736 484,758 399,758C178,758 40,591 40,370C40,161 165,5 357,-14l-58,-62l17,-26C327,-98 338,-95 354,-95C376,-95 392,-104 392,-125C392,-151 368,-159 337,-159C311,-159 286,-153 265,-143l-18,-36C273,-196 311,-209 359,-209z"
      />
      <glyph
        unicode="&#x108;"
        horiz-adv-x="752"
        d="M398,-16C498,-16 574,12 628,66C680,116 715,191 718,265l-139,0C571,175 510,101 399,101C266,101 181,212 181,370C181,525 256,641 398,641C496,641 559,585 572,505l138,0C701,583 665,650 605,697C554,736 484,758 399,758C178,758 40,591 40,370C40,147 183,-16 398,-16M216,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph
        unicode="&#x10A;"
        horiz-adv-x="752"
        d="M322,785l130,0l0,122l-130,0M398,-16C498,-16 574,12 628,66C680,116 715,191 718,265l-139,0C571,175 510,101 399,101C266,101 181,212 181,370C181,525 256,641 398,641C496,641 559,585 572,505l138,0C701,583 665,650 605,697C554,736 484,758 399,758C178,758 40,591 40,370C40,147 183,-16 398,-16z"
      />
      <glyph
        unicode="D"
        horiz-adv-x="741"
        d="M71,0l285,0C581,0 702,146 702,368C702,594 579,744 364,744l-293,0M210,118l0,508l141,0C497,626 560,523 560,368C560,212 494,118 347,118z"
      />
      <glyph
        unicode="&#x10E;"
        horiz-adv-x="741"
        d="M512,908l-117,0l-52,-59l-2,0l-50,59l-117,0l104,-123l129,0M71,0l285,0C581,0 702,146 702,368C702,594 579,744 364,744l-293,0M210,118l0,508l141,0C497,626 560,523 560,368C560,212 494,118 347,118z"
      />
      <glyph
        unicode="&#x110;"
        horiz-adv-x="747"
        d="M11,320l66,0l0,-320l285,0C589,0 709,146 709,368C709,594 587,744 371,744l-294,0l0,-313l-66,0M216,118l0,202l189,0l0,111l-189,0l0,195l142,0C504,626 567,523 567,368C567,212 501,118 354,118z"
      />
      <glyph
        unicode="&#x2206;"
        horiz-adv-x="716"
        d="M28,0l661,0l0,101l-253,643l-157,0l-251,-643M303,463C336,551 354,634 354,634l2,0C356,634 373,551 406,463l135,-354l-373,0z"
      />
      <glyph
        unicode="E"
        horiz-adv-x="668"
        d="M70,0l553,0l0,121l-415,0l0,210l359,0l0,111l-359,0l0,184l406,0l0,118l-544,0z"
      />
      <glyph
        unicode="&#xC9;"
        horiz-adv-x="668"
        d="M70,0l553,0l0,121l-415,0l0,210l359,0l0,111l-359,0l0,184l406,0l0,118l-544,0M295,785l102,0l100,128l-154,0z"
      />
      <glyph
        unicode="&#x114;"
        horiz-adv-x="668"
        d="M70,0l553,0l0,121l-415,0l0,210l359,0l0,111l-359,0l0,184l406,0l0,118l-544,0M354,779C442,779 498,839 501,915l-77,0C417,886 394,863 353,863C310,863 289,885 282,915l-79,0C207,834 269,779 354,779z"
      />
      <glyph
        unicode="&#x11A;"
        horiz-adv-x="668"
        d="M70,0l553,0l0,121l-415,0l0,210l359,0l0,111l-359,0l0,184l406,0l0,118l-544,0M522,908l-117,0l-52,-59l-2,0l-50,59l-117,0l104,-123l129,0z"
      />
      <glyph
        unicode="&#xCA;"
        horiz-adv-x="668"
        d="M70,0l553,0l0,121l-415,0l0,210l359,0l0,111l-359,0l0,184l406,0l0,118l-544,0M181,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph
        unicode="&#xCB;"
        horiz-adv-x="668"
        d="M70,0l553,0l0,121l-415,0l0,210l359,0l0,111l-359,0l0,184l406,0l0,118l-544,0M178,785l125,0l0,122l-125,0M403,785l125,0l0,122l-125,0z"
      />
      <glyph
        unicode="&#x116;"
        horiz-adv-x="668"
        d="M70,0l553,0l0,121l-415,0l0,210l359,0l0,111l-359,0l0,184l406,0l0,118l-544,0M288,785l130,0l0,122l-130,0z"
      />
      <glyph
        unicode="&#xC8;"
        horiz-adv-x="668"
        d="M70,0l553,0l0,121l-415,0l0,210l359,0l0,111l-359,0l0,184l406,0l0,118l-544,0M300,785l101,0l-47,128l-154,0z"
      />
      <glyph
        unicode="&#x112;"
        horiz-adv-x="668"
        d="M70,0l553,0l0,121l-415,0l0,210l359,0l0,111l-359,0l0,184l406,0l0,118l-544,0M203,792l299,0l0,94l-299,0z"
      />
      <glyph
        unicode="&#x14A;"
        horiz-adv-x="747"
        d="M439,-72C420,-72 404,-70 393,-68l-5,0l0,-111C407,-183 428,-186 456,-186C543,-186 598,-161 634,-115C665,-76 678,-28 678,33l0,711l-131,0l0,-373C547,297 555,181 555,181l-3,0C552,181 499,289 459,354l-247,390l-142,0l0,-744l131,0l0,372C201,446 194,562 194,562l2,0C196,562 248,454 290,388l250,-392C531,-49 497,-72 439,-72z"
      />
      <glyph
        unicode="&#x118;"
        horiz-adv-x="668"
        d="M70,0l406,0C439,-19 402,-52 402,-101C402,-153 441,-187 519,-187C551,-187 571,-183 584,-178l0,72l-2,0C573,-109 561,-111 547,-111C524,-111 510,-102 510,-80C510,-55 534,-25 565,0l58,0l0,121l-415,0l0,210l359,0l0,111l-359,0l0,184l406,0l0,118l-544,0z"
      />
      <glyph
        unicode="&#xD0;"
        horiz-adv-x="747"
        d="M11,320l66,0l0,-320l285,0C589,0 709,146 709,368C709,594 587,744 371,744l-294,0l0,-313l-66,0M216,118l0,202l189,0l0,111l-189,0l0,195l142,0C504,626 567,523 567,368C567,212 501,118 354,118z"
      />
      <glyph
        unicode="&#x20AC;"
        horiz-adv-x="673"
        d="M29,391l51,0C79,379 79,367 79,355C79,341 79,328 80,315l-51,0l0,-62l59,0C121,86 234,-16 385,-16C543,-16 638,94 645,238l-125,0C511,155 467,93 384,93C299,93 245,149 225,253l182,0l0,62l-190,0C216,328 216,341 216,355C216,367 216,379 217,391l190,0l0,62l-183,0C242,556 292,621 377,621C452,621 494,574 508,500l129,0C623,632 531,728 380,728C228,728 118,618 88,453l-59,0z"
      />
      <glyph unicode="F" horiz-adv-x="632" d="M70,0l138,0l0,319l325,0l0,115l-325,0l0,191l390,0l0,119l-528,0z" />
      <glyph
        unicode="G"
        horiz-adv-x="783"
        d="M636,0l93,0l0,396l-313,0l0,-108l180,0l0,-9C595,184 529,99 404,99C270,99 182,210 182,369C182,523 253,642 404,642C503,642 557,590 577,521l140,0C690,661 584,758 402,758C296,758 214,723 154,664C81,594 41,489 41,369C41,255 75,158 139,89C200,23 287,-17 392,-17C485,-17 565,21 613,102l4,0z"
      />
      <glyph
        unicode="&#x11E;"
        horiz-adv-x="783"
        d="M636,0l93,0l0,396l-313,0l0,-108l180,0l0,-9C595,184 529,99 404,99C270,99 182,210 182,369C182,523 253,642 404,642C503,642 557,590 577,521l140,0C690,661 584,758 402,758C296,758 214,723 154,664C81,594 41,489 41,369C41,255 75,158 139,89C200,23 287,-17 392,-17C485,-17 565,21 613,102l4,0M395,779C483,779 539,839 542,915l-77,0C458,886 435,863 394,863C351,863 330,885 323,915l-79,0C248,834 310,779 395,779z"
      />
      <glyph
        unicode="&#x11C;"
        horiz-adv-x="783"
        d="M636,0l93,0l0,396l-313,0l0,-108l180,0l0,-9C595,184 529,99 404,99C270,99 182,210 182,369C182,523 253,642 404,642C503,642 557,590 577,521l140,0C690,661 584,758 402,758C296,758 214,723 154,664C81,594 41,489 41,369C41,255 75,158 139,89C200,23 287,-17 392,-17C485,-17 565,21 613,102l4,0M222,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph
        unicode="&#x122;"
        horiz-adv-x="783"
        d="M636,0l93,0l0,396l-313,0l0,-108l180,0l0,-9C595,184 529,99 404,99C270,99 182,210 182,369C182,523 253,642 404,642C503,642 557,590 577,521l140,0C690,661 584,758 402,758C296,758 214,723 154,664C81,594 41,489 41,369C41,255 75,158 139,89C200,23 287,-17 392,-17C485,-17 565,21 613,102l4,0M460,-51l-131,0l0,-100l61,0C390,-183 366,-199 327,-206l0,-49C404,-250 460,-213 460,-131z"
      />
      <glyph
        unicode="&#x120;"
        horiz-adv-x="783"
        d="M636,0l93,0l0,396l-313,0l0,-108l180,0l0,-9C595,184 529,99 404,99C270,99 182,210 182,369C182,523 253,642 404,642C503,642 557,590 577,521l140,0C690,661 584,758 402,758C296,758 214,723 154,664C81,594 41,489 41,369C41,255 75,158 139,89C200,23 287,-17 392,-17C485,-17 565,21 613,102l4,0M329,785l130,0l0,122l-130,0z"
      />
      <glyph
        unicode="H"
        horiz-adv-x="745"
        d="M70,0l138,0l0,330l329,0l0,-330l139,0l0,744l-139,0l0,-299l-329,0l0,299l-138,0z"
      />
      <glyph
        unicode="&#x126;"
        horiz-adv-x="745"
        d="M2,539l68,0l0,-539l138,0l0,330l329,0l0,-330l139,0l0,539l67,0l0,83l-67,0l0,122l-139,0l0,-122l-329,0l0,122l-138,0l0,-122l-68,0M208,539l329,0l0,-97l-329,0z"
      />
      <glyph
        unicode="&#x124;"
        horiz-adv-x="745"
        d="M70,0l138,0l0,330l329,0l0,-330l139,0l0,744l-139,0l0,-299l-329,0l0,299l-138,0M201,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph unicode="I" horiz-adv-x="280" d="M70,0l139,0l0,744l-139,0z" />
      <glyph
        unicode="&#x132;"
        horiz-adv-x="818"
        d="M70,0l139,0l0,744l-139,0M525,-14C607,-14 669,13 707,57C741,94 757,144 757,205l0,539l-139,0l0,-534C618,136 584,100 520,100C451,100 415,133 415,231l0,34l-130,0l0,-38C285,70 369,-14 525,-14z"
      />
      <glyph unicode="&#xCD;" horiz-adv-x="280" d="M70,0l139,0l0,744l-139,0M83,785l102,0l100,128l-154,0z" />
      <glyph
        unicode="&#x12C;"
        horiz-adv-x="280"
        d="M70,0l139,0l0,744l-139,0M142,779C230,779 286,839 289,915l-77,0C205,886 182,863 141,863C98,863 77,885 70,915l-79,0C-5,834 57,779 142,779z"
      />
      <glyph
        unicode="&#xCE;"
        horiz-adv-x="280"
        d="M70,0l139,0l0,744l-139,0M-31,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph
        unicode="&#xCF;"
        horiz-adv-x="280"
        d="M70,0l139,0l0,744l-139,0M180,785l119,0l0,122l-119,0M-20,785l120,0l0,122l-120,0z"
      />
      <glyph unicode="&#x130;" horiz-adv-x="280" d="M70,0l139,0l0,744l-139,0M76,785l130,0l0,122l-130,0z" />
      <glyph unicode="&#xCC;" horiz-adv-x="280" d="M70,0l139,0l0,744l-139,0M88,785l101,0l-47,128l-154,0z" />
      <glyph unicode="&#x12A;" horiz-adv-x="280" d="M70,0l139,0l0,744l-139,0M6,792l268,0l0,94l-268,0z" />
      <glyph
        unicode="&#x12E;"
        horiz-adv-x="280"
        d="M70,0l42,0C77,-18 36,-52 36,-101C36,-153 75,-187 153,-187C186,-187 206,-183 219,-178l0,72l-3,0C207,-109 195,-111 182,-111C158,-111 144,-102 144,-80C144,-54 169,-20 209,1l0,743l-139,0z"
      />
      <glyph
        unicode="&#x128;"
        horiz-adv-x="280"
        d="M70,0l139,0l0,744l-139,0M210,786C271,786 303,834 305,909l-76,0C226,886 218,872 198,872C162,872 133,911 73,911C18,911 -23,868 -24,786l75,0C53,810 67,826 83,826C112,826 153,786 210,786z"
      />
      <glyph
        unicode="J"
        horiz-adv-x="560"
        d="M268,-14C350,-14 412,13 450,57C484,94 500,144 500,205l0,539l-139,0l0,-534C361,136 327,100 263,100C194,100 158,133 158,231l0,34l-130,0l0,-38C28,70 112,-14 268,-14z"
      />
      <glyph
        unicode="&#x134;"
        horiz-adv-x="560"
        d="M268,-14C350,-14 412,13 450,57C484,94 500,144 500,205l0,539l-139,0l0,-534C361,136 327,100 263,100C194,100 158,133 158,231l0,34l-130,0l0,-38C28,70 112,-14 268,-14M256,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph
        unicode="K"
        horiz-adv-x="713"
        d="M70,0l138,0l0,268l88,89l250,-357l162,0l-313,446l292,298l-168,0l-316,-330C205,453 208,513 208,572l0,172l-138,0z"
      />
      <glyph
        unicode="&#x136;"
        horiz-adv-x="713"
        d="M70,0l138,0l0,268l88,89l250,-357l162,0l-313,446l292,298l-168,0l-316,-330C205,453 208,513 208,572l0,172l-138,0M425,-51l-131,0l0,-100l61,0C355,-183 331,-199 292,-206l0,-49C369,-250 425,-213 425,-131z"
      />
      <glyph unicode="L" horiz-adv-x="600" d="M70,0l500,0l0,121l-362,0l0,623l-138,0z" />
      <glyph
        unicode="&#x139;"
        horiz-adv-x="600"
        d="M81,785l102,0l100,128l-154,0M70,0l500,0l0,121l-362,0l0,623l-138,0z"
      />
      <glyph
        unicode="&#x13D;"
        horiz-adv-x="600"
        d="M70,0l500,0l0,121l-362,0l0,623l-138,0M466,744l-121,0l0,-111l53,0C397,599 381,579 344,574l0,-47C416,531 466,567 466,653z"
      />
      <glyph
        unicode="&#x13B;"
        horiz-adv-x="600"
        d="M388,-51l-131,0l0,-100l61,0C318,-183 294,-199 255,-206l0,-49C332,-250 388,-213 388,-131M70,0l500,0l0,121l-362,0l0,623l-138,0z"
      />
      <glyph
        unicode="&#x13F;"
        horiz-adv-x="600"
        d="M70,0l500,0l0,121l-362,0l0,623l-138,0M383,295l129,0l0,129l-129,0z"
      />
      <glyph
        unicode="&#x141;"
        horiz-adv-x="603"
        d="M5,201l68,36l0,-237l501,0l0,121l-363,0l0,189l230,122l0,101l-230,-122l0,333l-138,0l0,-406l-68,-36z"
      />
      <glyph
        unicode="M"
        horiz-adv-x="883"
        d="M70,0l124,0l0,388C194,469 185,620 185,620l2,0C187,620 218,483 242,409l142,-409l112,0l141,409C661,483 692,620 692,620l2,0C694,620 685,469 685,388l0,-388l129,0l0,744l-187,0l-120,-354C479,302 443,140 443,140l-3,0C440,140 404,303 375,390l-122,354l-183,0z"
      />
      <glyph
        unicode="N"
        horiz-adv-x="747"
        d="M70,0l131,0l0,372C201,446 194,562 194,562l2,0C196,562 249,454 290,388l246,-388l142,0l0,744l-131,0l0,-373C547,297 555,181 555,181l-3,0C552,181 499,289 459,354l-247,390l-142,0z"
      />
      <glyph
        unicode="&#x143;"
        horiz-adv-x="747"
        d="M324,785l102,0l100,128l-154,0M70,0l131,0l0,372C201,446 194,562 194,562l2,0C196,562 249,454 290,388l246,-388l142,0l0,744l-131,0l0,-373C547,297 555,181 555,181l-3,0C552,181 499,289 459,354l-247,390l-142,0z"
      />
      <glyph
        unicode="&#x147;"
        horiz-adv-x="747"
        d="M551,908l-117,0l-52,-59l-2,0l-50,59l-117,0l104,-123l129,0M70,0l131,0l0,372C201,446 194,562 194,562l2,0C196,562 249,454 290,388l246,-388l142,0l0,744l-131,0l0,-373C547,297 555,181 555,181l-3,0C552,181 499,289 459,354l-247,390l-142,0z"
      />
      <glyph
        unicode="&#x145;"
        horiz-adv-x="747"
        d="M439,-51l-131,0l0,-100l61,0C369,-183 345,-199 306,-206l0,-49C383,-250 439,-213 439,-131M70,0l131,0l0,372C201,446 194,562 194,562l2,0C196,562 249,454 290,388l246,-388l142,0l0,744l-131,0l0,-373C547,297 555,181 555,181l-3,0C552,181 499,289 459,354l-247,390l-142,0z"
      />
      <glyph
        unicode="&#xD1;"
        horiz-adv-x="747"
        d="M451,786C512,786 544,834 546,909l-76,0C467,886 459,872 439,872C403,872 374,911 314,911C259,911 218,868 217,786l75,0C294,810 308,826 324,826C353,826 394,786 451,786M70,0l131,0l0,372C201,446 194,562 194,562l2,0C196,562 249,454 290,388l246,-388l142,0l0,744l-131,0l0,-373C547,297 555,181 555,181l-3,0C552,181 499,289 459,354l-247,390l-142,0z"
      />
      <glyph
        unicode="O"
        horiz-adv-x="812"
        d="M406,-17C631,-17 772,148 772,370C772,593 631,758 406,758C181,758 40,593 40,370C40,148 181,-17 406,-17M407,100C259,100 181,216 181,370C181,525 259,644 407,644C554,644 630,525 630,370C630,216 554,100 407,100z"
      />
      <glyph
        unicode="&#x152;"
        horiz-adv-x="1135"
        d="M388,-17C473,-17 533,15 575,70l0,-70l515,0l0,124l-388,0l0,206l329,0l0,111l-329,0l0,185l376,0l0,118l-503,0l0,-76C533,724 472,758 388,758C184,758 40,593 40,370C40,148 182,-17 388,-17M394,99C257,99 181,216 181,370C181,525 258,644 394,644C496,644 569,567 569,448l0,-162C569,171 496,99 394,99z"
      />
      <glyph
        unicode="&#xD3;"
        horiz-adv-x="812"
        d="M348,785l102,0l100,128l-154,0M406,-17C631,-17 772,148 772,370C772,593 631,758 406,758C181,758 40,593 40,370C40,148 181,-17 406,-17M407,100C259,100 181,216 181,370C181,525 259,644 407,644C554,644 630,525 630,370C630,216 554,100 407,100z"
      />
      <glyph
        unicode="&#x14E;"
        horiz-adv-x="812"
        d="M407,779C495,779 551,839 554,915l-77,0C470,886 447,863 406,863C363,863 342,885 335,915l-79,0C260,834 322,779 407,779M406,-17C631,-17 772,148 772,370C772,593 631,758 406,758C181,758 40,593 40,370C40,148 181,-17 406,-17M407,100C259,100 181,216 181,370C181,525 259,644 407,644C554,644 630,525 630,370C630,216 554,100 407,100z"
      />
      <glyph
        unicode="&#xD4;"
        horiz-adv-x="812"
        d="M406,-17C631,-17 772,148 772,370C772,593 631,758 406,758C181,758 40,593 40,370C40,148 181,-17 406,-17M407,100C259,100 181,216 181,370C181,525 259,644 407,644C554,644 630,525 630,370C630,216 554,100 407,100M234,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph
        unicode="&#xD6;"
        horiz-adv-x="812"
        d="M456,785l125,0l0,122l-125,0M231,785l125,0l0,122l-125,0M406,-17C631,-17 772,148 772,370C772,593 631,758 406,758C181,758 40,593 40,370C40,148 181,-17 406,-17M407,100C259,100 181,216 181,370C181,525 259,644 407,644C554,644 630,525 630,370C630,216 554,100 407,100z"
      />
      <glyph
        unicode="&#xD2;"
        horiz-adv-x="812"
        d="M353,785l101,0l-47,128l-154,0M406,-17C631,-17 772,148 772,370C772,593 631,758 406,758C181,758 40,593 40,370C40,148 181,-17 406,-17M407,100C259,100 181,216 181,370C181,525 259,644 407,644C554,644 630,525 630,370C630,216 554,100 407,100z"
      />
      <glyph
        unicode="&#x150;"
        horiz-adv-x="812"
        d="M406,-17C631,-17 772,148 772,370C772,593 631,758 406,758C181,758 40,593 40,370C40,148 181,-17 406,-17M407,100C259,100 181,216 181,370C181,525 259,644 407,644C554,644 630,525 630,370C630,216 554,100 407,100M445,785l86,0l75,128l-128,0M271,785l88,0l75,128l-129,0z"
      />
      <glyph
        unicode="&#x14C;"
        horiz-adv-x="812"
        d="M256,792l299,0l0,94l-299,0M406,-17C631,-17 772,148 772,370C772,593 631,758 406,758C181,758 40,593 40,370C40,148 181,-17 406,-17M407,100C259,100 181,216 181,370C181,525 259,644 407,644C554,644 630,525 630,370C630,216 554,100 407,100z"
      />
      <glyph
        unicode="&#x2126;"
        horiz-adv-x="775"
        d="M43,0l306,0l0,104C229,124 177,243 177,386C177,544 256,644 388,644C519,644 598,544 598,386C598,243 547,123 426,104l0,-104l306,0l0,109l-126,0l0,2C692,166 739,260 739,391C739,609 595,759 388,759C180,759 37,609 37,391C37,260 82,166 168,111l0,-2l-125,0z"
      />
      <glyph
        unicode="&#xD8;"
        horiz-adv-x="812"
        d="M77,-42l94,104C232,12 311,-17 406,-17C631,-17 772,148 772,370C772,472 742,561 689,629l92,101l-52,45l-88,-97C580,729 500,758 406,758C181,758 40,593 40,370C40,268 69,178 123,111l-97,-107M181,370C181,525 259,644 407,644C471,644 521,623 557,586l-342,-375C192,255 181,309 181,370M407,100C343,100 293,120 256,155l341,374C619,484 630,430 630,370C630,216 554,100 407,100z"
      />
      <glyph
        unicode="&#x1FE;"
        horiz-adv-x="812"
        d="M77,-42l94,104C232,12 311,-17 406,-17C631,-17 772,148 772,370C772,472 742,561 689,629l92,101l-52,45l-88,-97C580,729 500,758 406,758C181,758 40,593 40,370C40,268 69,178 123,111l-97,-107M181,370C181,525 259,644 407,644C471,644 521,623 557,586l-342,-375C192,255 181,309 181,370M407,100C343,100 293,120 256,155l341,374C619,484 630,430 630,370C630,216 554,100 407,100M346,785l102,0l100,128l-154,0z"
      />
      <glyph
        unicode="&#xD5;"
        horiz-adv-x="812"
        d="M475,786C536,786 568,834 570,909l-76,0C491,886 483,872 463,872C427,872 398,911 338,911C283,911 242,868 241,786l75,0C318,810 332,826 348,826C377,826 418,786 475,786M406,-17C631,-17 772,148 772,370C772,593 631,758 406,758C181,758 40,593 40,370C40,148 181,-17 406,-17M407,100C259,100 181,216 181,370C181,525 259,644 407,644C554,644 630,525 630,370C630,216 554,100 407,100z"
      />
      <glyph
        unicode="P"
        horiz-adv-x="668"
        d="M70,0l140,0l0,280l188,0C555,280 644,371 644,511C644,577 621,631 581,674C540,719 476,744 392,744l-322,0M210,625l168,0C459,625 505,584 505,509C505,437 461,391 376,391l-166,0z"
      />
      <glyph
        unicode="Q"
        horiz-adv-x="811"
        d="M706,-64l64,71l-98,85C735,161 771,258 771,370C771,593 631,758 406,758C180,758 40,593 40,370C40,148 180,-17 406,-17C479,-17 543,0 596,31M406,97C258,97 181,216 181,370C181,525 258,644 406,644C553,644 629,525 629,370C629,292 611,225 575,176l-105,92l-63,-72l92,-81C472,103 441,97 406,97z"
      />
      <glyph
        unicode="R"
        horiz-adv-x="708"
        d="M70,0l138,0l0,296l159,0C456,296 497,258 505,170C513,70 513,12 532,0l140,0l0,7C652,17 651,78 639,187C631,279 602,324 523,348l0,3C620,382 660,446 660,535C660,665 560,744 421,744l-351,0M208,631l185,0C482,631 521,589 521,515C521,446 475,402 388,402l-180,0z"
      />
      <glyph
        unicode="&#x154;"
        horiz-adv-x="708"
        d="M70,0l138,0l0,296l159,0C456,296 497,258 505,170C513,70 513,12 532,0l140,0l0,7C652,17 651,78 639,187C631,279 602,324 523,348l0,3C620,382 660,446 660,535C660,665 560,744 421,744l-351,0M208,631l185,0C482,631 521,589 521,515C521,446 475,402 388,402l-180,0M290,785l102,0l100,128l-154,0z"
      />
      <glyph
        unicode="&#x158;"
        horiz-adv-x="708"
        d="M70,0l138,0l0,296l159,0C456,296 497,258 505,170C513,70 513,12 532,0l140,0l0,7C652,17 651,78 639,187C631,279 602,324 523,348l0,3C620,382 660,446 660,535C660,665 560,744 421,744l-351,0M208,631l185,0C482,631 521,589 521,515C521,446 475,402 388,402l-180,0M517,908l-117,0l-52,-59l-2,0l-50,59l-117,0l104,-123l129,0z"
      />
      <glyph
        unicode="&#x156;"
        horiz-adv-x="708"
        d="M70,0l138,0l0,296l159,0C456,296 497,258 505,170C513,70 513,12 532,0l140,0l0,7C652,17 651,78 639,187C631,279 602,324 523,348l0,3C620,382 660,446 660,535C660,665 560,744 421,744l-351,0M208,631l185,0C482,631 521,589 521,515C521,446 475,402 388,402l-180,0M425,-51l-131,0l0,-100l61,0C355,-183 331,-199 292,-206l0,-49C369,-250 425,-213 425,-131z"
      />
      <glyph
        unicode="S"
        horiz-adv-x="682"
        d="M353,-17C518,-17 642,66 642,214C642,375 515,416 377,446C262,470 190,488 190,557C190,616 245,651 328,651C416,651 471,610 482,537l135,0C603,674 503,757 330,757C171,757 55,686 55,545C55,396 171,359 310,328C428,301 502,283 502,203C502,129 435,94 354,94C240,94 177,142 167,238l-139,0C34,86 149,-17 353,-17z"
      />
      <glyph
        unicode="&#x15A;"
        horiz-adv-x="682"
        d="M353,-17C518,-17 642,66 642,214C642,375 515,416 377,446C262,470 190,488 190,557C190,616 245,651 328,651C416,651 471,610 482,537l135,0C603,674 503,757 330,757C171,757 55,686 55,545C55,396 171,359 310,328C428,301 502,283 502,203C502,129 435,94 354,94C240,94 177,142 167,238l-139,0C34,86 149,-17 353,-17M276,785l102,0l100,128l-154,0z"
      />
      <glyph
        unicode="&#x160;"
        horiz-adv-x="682"
        d="M353,-17C518,-17 642,66 642,214C642,375 515,416 377,446C262,470 190,488 190,557C190,616 245,651 328,651C416,651 471,610 482,537l135,0C603,674 503,757 330,757C171,757 55,686 55,545C55,396 171,359 310,328C428,301 502,283 502,203C502,129 435,94 354,94C240,94 177,142 167,238l-139,0C34,86 149,-17 353,-17M497,908l-117,0l-52,-59l-2,0l-50,59l-117,0l104,-123l129,0z"
      />
      <glyph
        unicode="&#x15E;"
        horiz-adv-x="682"
        d="M306,-209C377,-209 427,-179 427,-128C427,-69 369,-50 319,-57l0,2l43,38C523,-14 642,69 642,214C642,375 515,416 377,446C262,470 190,488 190,557C190,616 245,651 328,651C416,651 471,610 482,537l135,0C603,674 503,757 330,757C171,757 55,686 55,545C55,396 171,359 310,328C428,301 502,283 502,203C502,129 435,94 354,94C240,94 177,142 167,238l-139,0C33,98 130,0 304,-15l-58,-61l17,-26C274,-98 285,-95 301,-95C323,-95 339,-104 339,-125C339,-151 315,-159 284,-159C258,-159 233,-153 212,-143l-18,-36C220,-196 258,-209 306,-209z"
      />
      <glyph
        unicode="&#x15C;"
        horiz-adv-x="682"
        d="M353,-17C518,-17 642,66 642,214C642,375 515,416 377,446C262,470 190,488 190,557C190,616 245,651 328,651C416,651 471,610 482,537l135,0C603,674 503,757 330,757C171,757 55,686 55,545C55,396 171,359 310,328C428,301 502,283 502,203C502,129 435,94 354,94C240,94 177,142 167,238l-139,0C34,86 149,-17 353,-17M161,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph
        unicode="&#x218;"
        horiz-adv-x="682"
        d="M401,-51l-131,0l0,-100l61,0C331,-183 307,-199 268,-206l0,-49C345,-250 401,-213 401,-131M353,-17C518,-17 642,66 642,214C642,375 515,416 377,446C262,470 190,488 190,557C190,616 245,651 328,651C416,651 471,610 482,537l135,0C603,674 503,757 330,757C171,757 55,686 55,545C55,396 171,359 310,328C428,301 502,283 502,203C502,129 435,94 354,94C240,94 177,142 167,238l-139,0C34,86 149,-17 353,-17z"
      />
      <glyph unicode="T" horiz-adv-x="661" d="M29,625l232,0l0,-625l139,0l0,625l233,0l0,119l-604,0z" />
      <glyph
        unicode="&#x166;"
        horiz-adv-x="661"
        d="M29,625l232,0l0,-196l-156,0l0,-91l156,0l0,-338l139,0l0,338l156,0l0,91l-156,0l0,196l233,0l0,119l-604,0z"
      />
      <glyph
        unicode="&#x164;"
        horiz-adv-x="661"
        d="M29,625l232,0l0,-625l139,0l0,625l233,0l0,119l-604,0M500,908l-117,0l-52,-59l-2,0l-50,59l-117,0l104,-123l129,0z"
      />
      <glyph
        unicode="&#x162;"
        horiz-adv-x="661"
        d="M29,625l232,0l0,-625l139,0l0,625l233,0l0,119l-604,0M397,-51l-131,0l0,-100l61,0C327,-183 303,-199 264,-206l0,-49C341,-250 397,-213 397,-131z"
      />
      <glyph
        unicode="&#xDE;"
        horiz-adv-x="668"
        d="M70,0l140,0l0,162l188,0C555,162 644,254 644,393C644,459 621,514 581,555C540,601 476,627 392,627l-182,0l0,117l-140,0M378,507C459,507 505,466 505,391C505,320 461,273 376,273l-166,0l0,234z"
      />
      <glyph
        unicode="U"
        horiz-adv-x="715"
        d="M358,-13C541,-13 656,75 656,251l0,493l-139,0l0,-489C517,155 460,102 359,102C253,102 198,153 198,253l0,491l-138,0l0,-493C60,74 173,-13 358,-13z"
      />
      <glyph
        unicode="&#xDA;"
        horiz-adv-x="715"
        d="M303,785l102,0l100,128l-154,0M358,-13C541,-13 656,75 656,251l0,493l-139,0l0,-489C517,155 460,102 359,102C253,102 198,153 198,253l0,491l-138,0l0,-493C60,74 173,-13 358,-13z"
      />
      <glyph
        unicode="&#x16C;"
        horiz-adv-x="715"
        d="M359,779C447,779 503,839 506,915l-77,0C422,886 399,863 358,863C315,863 294,885 287,915l-79,0C212,834 274,779 359,779M358,-13C541,-13 656,75 656,251l0,493l-139,0l0,-489C517,155 460,102 359,102C253,102 198,153 198,253l0,491l-138,0l0,-493C60,74 173,-13 358,-13z"
      />
      <glyph
        unicode="&#xDB;"
        horiz-adv-x="715"
        d="M358,-13C541,-13 656,75 656,251l0,493l-139,0l0,-489C517,155 460,102 359,102C253,102 198,153 198,253l0,491l-138,0l0,-493C60,74 173,-13 358,-13M186,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph
        unicode="&#xDC;"
        horiz-adv-x="715"
        d="M407,785l125,0l0,122l-125,0M182,785l125,0l0,122l-125,0M358,-13C541,-13 656,75 656,251l0,493l-139,0l0,-489C517,155 460,102 359,102C253,102 198,153 198,253l0,491l-138,0l0,-493C60,74 173,-13 358,-13z"
      />
      <glyph
        unicode="&#xD9;"
        horiz-adv-x="715"
        d="M309,785l101,0l-47,128l-154,0M358,-13C541,-13 656,75 656,251l0,493l-139,0l0,-489C517,155 460,102 359,102C253,102 198,153 198,253l0,491l-138,0l0,-493C60,74 173,-13 358,-13z"
      />
      <glyph
        unicode="&#x170;"
        horiz-adv-x="715"
        d="M358,-13C541,-13 656,75 656,251l0,493l-139,0l0,-489C517,155 460,102 359,102C253,102 198,153 198,253l0,491l-138,0l0,-493C60,74 173,-13 358,-13M397,785l86,0l75,128l-128,0M223,785l88,0l75,128l-129,0z"
      />
      <glyph
        unicode="&#x16A;"
        horiz-adv-x="715"
        d="M208,792l299,0l0,94l-299,0M358,-13C541,-13 656,75 656,251l0,493l-139,0l0,-489C517,155 460,102 359,102C253,102 198,153 198,253l0,491l-138,0l0,-493C60,74 173,-13 358,-13z"
      />
      <glyph
        unicode="&#x172;"
        horiz-adv-x="715"
        d="M402,-187C434,-187 454,-183 467,-178l0,72l-2,0C456,-109 444,-111 430,-111C407,-111 393,-102 393,-80C393,-38 442,-11 492,7C595,42 656,123 656,251l0,493l-139,0l0,-489C517,155 460,102 359,102C253,102 198,153 198,253l0,491l-138,0l0,-493C60,79 166,-7 342,-12C312,-31 285,-60 285,-101C285,-153 323,-187 402,-187z"
      />
      <glyph
        unicode="&#x16E;"
        horiz-adv-x="715"
        d="M358,-13C541,-13 656,75 656,251l0,493l-139,0l0,-489C517,155 460,102 359,102C253,102 198,153 198,253l0,491l-138,0l0,-493C60,74 173,-13 358,-13M358,743C417,743 466,789 466,849C466,907 417,954 358,954C297,954 248,907 248,849C248,789 297,743 358,743M358,793C325,793 303,817 303,849C303,880 325,905 358,905C389,905 411,880 411,849C411,817 389,793 358,793z"
      />
      <glyph
        unicode="&#x168;"
        horiz-adv-x="715"
        d="M427,786C488,786 520,834 522,909l-76,0C443,886 435,872 415,872C379,872 350,911 290,911C235,911 194,868 193,786l75,0C270,810 284,826 300,826C329,826 370,786 427,786M358,-13C541,-13 656,75 656,251l0,493l-139,0l0,-489C517,155 460,102 359,102C253,102 198,153 198,253l0,491l-138,0l0,-493C60,74 173,-13 358,-13z"
      />
      <glyph
        unicode="V"
        horiz-adv-x="678"
        d="M264,0l148,0l253,744l-142,0l-133,-422C369,254 343,131 343,131l-2,0C341,131 315,253 294,322l-135,422l-146,0z"
      />
      <glyph
        unicode="W"
        horiz-adv-x="983"
        d="M211,0l138,0l100,389C467,468 487,612 487,612l2,0C489,612 508,468 528,389l95,-389l135,0l206,744l-142,0l-88,-359C711,294 691,141 691,141l-2,0C689,141 667,299 646,389l-90,355l-136,0l-91,-356C308,299 284,141 284,141l-2,0C282,141 265,293 243,384l-88,360l-142,0z"
      />
      <glyph
        unicode="&#x1E82;"
        horiz-adv-x="983"
        d="M431,785l102,0l100,128l-154,0M211,0l138,0l100,389C467,468 487,612 487,612l2,0C489,612 508,468 528,389l95,-389l135,0l206,744l-142,0l-88,-359C711,294 691,141 691,141l-2,0C689,141 667,299 646,389l-90,355l-136,0l-91,-356C308,299 284,141 284,141l-2,0C282,141 265,293 243,384l-88,360l-142,0z"
      />
      <glyph
        unicode="&#x174;"
        horiz-adv-x="983"
        d="M211,0l138,0l100,389C467,468 487,612 487,612l2,0C489,612 508,468 528,389l95,-389l135,0l206,744l-142,0l-88,-359C711,294 691,141 691,141l-2,0C689,141 667,299 646,389l-90,355l-136,0l-91,-356C308,299 284,141 284,141l-2,0C282,141 265,293 243,384l-88,360l-142,0M317,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph
        unicode="&#x1E84;"
        horiz-adv-x="983"
        d="M538,785l125,0l0,122l-125,0M313,785l125,0l0,122l-125,0M211,0l138,0l100,389C467,468 487,612 487,612l2,0C489,612 508,468 528,389l95,-389l135,0l206,744l-142,0l-88,-359C711,294 691,141 691,141l-2,0C689,141 667,299 646,389l-90,355l-136,0l-91,-356C308,299 284,141 284,141l-2,0C282,141 265,293 243,384l-88,360l-142,0z"
      />
      <glyph
        unicode="&#x1E80;"
        horiz-adv-x="983"
        d="M436,785l101,0l-47,128l-154,0M211,0l138,0l100,389C467,468 487,612 487,612l2,0C489,612 508,468 528,389l95,-389l135,0l206,744l-142,0l-88,-359C711,294 691,141 691,141l-2,0C689,141 667,299 646,389l-90,355l-136,0l-91,-356C308,299 284,141 284,141l-2,0C282,141 265,293 243,384l-88,360l-142,0z"
      />
      <glyph
        unicode="X"
        horiz-adv-x="701"
        d="M19,0l157,0l98,160C307,214 349,299 349,299l2,0C351,299 390,214 422,160l95,-160l164,0l-249,386l233,358l-152,0l-84,-137C396,554 355,470 355,470l-2,0C353,470 313,554 282,607l-84,137l-159,0l233,-358z"
      />
      <glyph
        unicode="Y"
        horiz-adv-x="692"
        d="M275,0l139,0l0,292l262,452l-147,0l-106,-188C390,495 348,397 348,397l-2,0C346,397 305,496 272,556l-105,188l-153,0l261,-450z"
      />
      <glyph
        unicode="&#xDD;"
        horiz-adv-x="692"
        d="M275,0l139,0l0,292l262,452l-147,0l-106,-188C390,495 348,397 348,397l-2,0C346,397 305,496 272,556l-105,188l-153,0l261,-450M289,785l102,0l100,128l-154,0z"
      />
      <glyph
        unicode="&#x176;"
        horiz-adv-x="692"
        d="M275,0l139,0l0,292l262,452l-147,0l-106,-188C390,495 348,397 348,397l-2,0C346,397 305,496 272,556l-105,188l-153,0l261,-450M175,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph
        unicode="&#x178;"
        horiz-adv-x="692"
        d="M275,0l139,0l0,292l262,452l-147,0l-106,-188C390,495 348,397 348,397l-2,0C346,397 305,496 272,556l-105,188l-153,0l261,-450M171,785l125,0l0,122l-125,0M396,785l125,0l0,122l-125,0z"
      />
      <glyph
        unicode="&#x1EF2;"
        horiz-adv-x="692"
        d="M275,0l139,0l0,292l262,452l-147,0l-106,-188C390,495 348,397 348,397l-2,0C346,397 305,496 272,556l-105,188l-153,0l261,-450M294,785l101,0l-47,128l-154,0z"
      />
      <glyph
        unicode="Z"
        horiz-adv-x="679"
        d="M37,0l599,0l0,120l-288,0C290,120 195,115 195,115l0,3C195,118 270,194 313,246l308,389l0,109l-557,0l0,-117l251,0C370,627 461,631 461,631l0,-3C461,628 392,560 353,512l-316,-394z"
      />
      <glyph
        unicode="&#x179;"
        horiz-adv-x="679"
        d="M37,0l599,0l0,120l-288,0C290,120 195,115 195,115l0,3C195,118 270,194 313,246l308,389l0,109l-557,0l0,-117l251,0C370,627 461,631 461,631l0,-3C461,628 392,560 353,512l-316,-394M283,785l102,0l100,128l-154,0z"
      />
      <glyph
        unicode="&#x17D;"
        horiz-adv-x="679"
        d="M510,908l-117,0l-52,-59l-2,0l-50,59l-117,0l104,-123l129,0M37,0l599,0l0,120l-288,0C290,120 195,115 195,115l0,3C195,118 270,194 313,246l308,389l0,109l-557,0l0,-117l251,0C370,627 461,631 461,631l0,-3C461,628 392,560 353,512l-316,-394z"
      />
      <glyph
        unicode="&#x17B;"
        horiz-adv-x="679"
        d="M276,785l130,0l0,122l-130,0M37,0l599,0l0,120l-288,0C290,120 195,115 195,115l0,3C195,118 270,194 313,246l308,389l0,109l-557,0l0,-117l251,0C370,627 461,631 461,631l0,-3C461,628 392,560 353,512l-316,-394z"
      />
      <glyph
        unicode="a"
        horiz-adv-x="566"
        d="M404,0l123,0l0,9C515,17 507,40 507,84l0,281C507,436 481,487 431,517C396,539 349,548 288,548C136,548 64,465 60,374l120,0C183,427 218,460 289,460C353,460 385,429 385,383C385,335 341,321 253,310C126,295 34,266 34,145C34,41 112,-13 216,-13C301,-13 349,22 384,69l2,0C388,33 395,12 404,0M253,74C204,74 161,91 161,148C161,207 206,223 284,238C323,246 363,257 388,273l0,-79C388,134 340,74 253,74z"
      />
      <glyph
        unicode="&#xE1;"
        horiz-adv-x="566"
        d="M404,0l123,0l0,9C515,17 507,40 507,84l0,281C507,436 481,487 431,517C396,539 349,548 288,548C136,548 64,465 60,374l120,0C183,427 218,460 289,460C353,460 385,429 385,383C385,335 341,321 253,310C126,295 34,266 34,145C34,41 112,-13 216,-13C301,-13 349,22 384,69l2,0C388,33 395,12 404,0M253,74C204,74 161,91 161,148C161,207 206,223 284,238C323,246 363,257 388,273l0,-79C388,134 340,74 253,74M233,618l98,0l105,154l-150,0z"
      />
      <glyph
        unicode="&#x103;"
        horiz-adv-x="566"
        d="M404,0l123,0l0,9C515,17 507,40 507,84l0,281C507,436 481,487 431,517C396,539 349,548 288,548C136,548 64,465 60,374l120,0C183,427 218,460 289,460C353,460 385,429 385,383C385,335 341,321 253,310C126,295 34,266 34,145C34,41 112,-13 216,-13C301,-13 349,22 384,69l2,0C388,33 395,12 404,0M253,74C204,74 161,91 161,148C161,207 206,223 284,238C323,246 363,257 388,273l0,-79C388,134 340,74 253,74M288,613C375,613 433,672 436,755l-77,0C354,720 328,696 287,696C244,696 220,718 215,755l-79,0C140,667 204,613 288,613z"
      />
      <glyph
        unicode="&#xE2;"
        horiz-adv-x="566"
        d="M404,0l123,0l0,9C515,17 507,40 507,84l0,281C507,436 481,487 431,517C396,539 349,548 288,548C136,548 64,465 60,374l120,0C183,427 218,460 289,460C353,460 385,429 385,383C385,335 341,321 253,310C126,295 34,266 34,145C34,41 112,-13 216,-13C301,-13 349,22 384,69l2,0C388,33 395,12 404,0M253,74C204,74 161,91 161,148C161,207 206,223 284,238C323,246 363,257 388,273l0,-79C388,134 340,74 253,74M117,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0z"
      />
      <glyph unicode="&#xB4;" horiz-adv-x="500" d="M190,618l98,0l105,154l-150,0z" />
      <glyph
        unicode="&#xE4;"
        horiz-adv-x="566"
        d="M404,0l123,0l0,9C515,17 507,40 507,84l0,281C507,436 481,487 431,517C396,539 349,548 288,548C136,548 64,465 60,374l120,0C183,427 218,460 289,460C353,460 385,429 385,383C385,335 341,321 253,310C126,295 34,266 34,145C34,41 112,-13 216,-13C301,-13 349,22 384,69l2,0C388,33 395,12 404,0M253,74C204,74 161,91 161,148C161,207 206,223 284,238C323,246 363,257 388,273l0,-79C388,134 340,74 253,74M336,621l122,0l0,123l-122,0M114,621l122,0l0,123l-122,0z"
      />
      <glyph
        unicode="&#xE6;"
        horiz-adv-x="926"
        d="M234,-14C328,-14 392,18 445,95C484,27 551,-16 652,-16C779,-16 863,53 887,151l-124,0C747,108 710,81 653,81C563,81 518,143 510,235l385,0C896,333 874,418 821,474C777,522 720,548 643,548C567,548 510,519 470,472C438,524 376,548 290,548C138,548 66,465 62,374l119,0C185,427 220,460 291,460C355,460 387,429 387,382C387,335 342,321 255,310C128,295 36,266 36,145C36,41 112,-14 234,-14M254,74C206,74 163,91 163,148C163,207 208,223 285,238C325,246 365,257 390,272l0,-78C390,134 342,74 254,74M642,456C713,456 760,403 764,315l-253,0C522,399 562,456 642,456z"
      />
      <glyph
        unicode="&#x1FD;"
        horiz-adv-x="926"
        d="M234,-14C328,-14 392,18 445,95C484,27 551,-16 652,-16C779,-16 863,53 887,151l-124,0C747,108 710,81 653,81C563,81 518,143 510,235l385,0C896,333 874,418 821,474C777,522 720,548 643,548C567,548 510,519 470,472C438,524 376,548 290,548C138,548 66,465 62,374l119,0C185,427 220,460 291,460C355,460 387,429 387,382C387,335 342,321 255,310C128,295 36,266 36,145C36,41 112,-14 234,-14M254,74C206,74 163,91 163,148C163,207 208,223 285,238C325,246 365,257 390,272l0,-78C390,134 342,74 254,74M642,456C713,456 760,403 764,315l-253,0C522,399 562,456 642,456M423,618l98,0l105,154l-150,0z"
      />
      <glyph
        unicode="&#x2113;"
        horiz-adv-x="417"
        d="M76,273C60,266 35,258 17,252l0,-79C34,177 59,185 76,191l0,-37C76,33 140,-5 238,-5C278,-5 317,4 341,15l0,101l-3,0C317,108 298,100 266,100C218,100 198,118 198,172l0,71C310,306 384,403 384,581C384,707 330,752 236,752C144,752 76,707 76,597M243,388C229,364 212,346 198,333l0,267C198,647 218,664 245,664C272,664 290,647 290,584C290,492 271,430 243,388z"
      />
      <glyph
        unicode="&#xE0;"
        horiz-adv-x="566"
        d="M404,0l123,0l0,9C515,17 507,40 507,84l0,281C507,436 481,487 431,517C396,539 349,548 288,548C136,548 64,465 60,374l120,0C183,427 218,460 289,460C353,460 385,429 385,383C385,335 341,321 253,310C126,295 34,266 34,145C34,41 112,-13 216,-13C301,-13 349,22 384,69l2,0C388,33 395,12 404,0M253,74C204,74 161,91 161,148C161,207 206,223 284,238C323,246 363,257 388,273l0,-79C388,134 340,74 253,74M243,618l98,0l-53,154l-151,0z"
      />
      <glyph
        unicode="&#x101;"
        horiz-adv-x="566"
        d="M404,0l123,0l0,9C515,17 507,40 507,84l0,281C507,436 481,487 431,517C396,539 349,548 288,548C136,548 64,465 60,374l120,0C183,427 218,460 289,460C353,460 385,429 385,383C385,335 341,321 253,310C126,295 34,266 34,145C34,41 112,-13 216,-13C301,-13 349,22 384,69l2,0C388,33 395,12 404,0M253,74C204,74 161,91 161,148C161,207 206,223 284,238C323,246 363,257 388,273l0,-79C388,134 340,74 253,74M138,636l294,0l0,94l-294,0z"
      />
      <glyph
        unicode="&amp;"
        horiz-adv-x="694"
        d="M288,-14C366,-14 434,16 488,60l13,-17l34,-43l155,0l-123,154C607,217 629,291 633,368l-111,0C518,323 509,280 493,241C461,283 394,369 382,386l-12,14C445,441 510,492 510,585C510,693 426,753 323,753C212,753 134,682 134,586C134,525 158,474 202,423l2,-3C110,370 35,313 35,198C35,69 142,-14 288,-14M291,92C223,92 166,136 166,200C166,262 202,307 263,345l160,-204C386,110 342,92 291,92M252,588C252,629 282,659 324,659C365,659 393,629 393,585C393,540 370,503 315,470l-6,8C274,514 252,547 252,588z"
      />
      <glyph
        unicode="&#x105;"
        horiz-adv-x="572"
        d="M467,-187C500,-187 520,-183 533,-178l0,72l-3,0C521,-109 509,-111 496,-111C472,-111 458,-102 458,-80C458,-50 487,-16 529,0l0,9C517,17 509,40 509,84l0,281C509,436 483,487 433,517C398,539 351,548 290,548C138,548 66,465 62,374l119,0C185,427 220,460 291,460C355,460 387,429 387,383C387,335 342,321 255,310C128,295 36,266 36,145C36,41 114,-13 218,-13C303,-13 351,22 386,69l1,0C390,34 397,12 405,2l22,0C391,-18 351,-51 351,-101C351,-153 389,-187 467,-187M254,74C206,74 163,91 163,148C163,207 208,223 285,238C325,246 365,257 390,273l0,-79C390,134 342,74 254,74z"
      />
      <glyph
        unicode="&#x2248;"
        horiz-adv-x="604"
        d="M426,357C509,357 548,410 549,502l-89,0C458,474 446,459 414,459C364,459 261,498 180,498C108,498 56,451 55,350l88,0C146,380 159,395 187,395C242,395 346,357 426,357M426,155C509,155 548,208 549,300l-89,0C458,272 446,257 414,257C364,257 261,296 180,296C108,296 56,250 55,148l88,0C146,178 159,193 187,193C242,193 346,155 426,155z"
      />
      <glyph
        unicode="&#xE5;"
        horiz-adv-x="566"
        d="M404,0l123,0l0,9C515,17 507,40 507,84l0,281C507,436 481,487 431,517C396,539 349,548 288,548C136,548 64,465 60,374l120,0C183,427 218,460 289,460C353,460 385,429 385,383C385,335 341,321 253,310C126,295 34,266 34,145C34,41 112,-13 216,-13C301,-13 349,22 384,69l2,0C388,33 395,12 404,0M287,597C346,597 395,643 395,703C395,761 346,809 287,809C226,809 177,761 177,703C177,643 226,597 287,597M253,74C204,74 161,91 161,148C161,207 206,223 284,238C323,246 363,257 388,273l0,-79C388,134 340,74 253,74M287,647C254,647 232,671 232,703C232,735 254,760 287,760C318,760 340,735 340,703C340,671 318,647 287,647z"
      />
      <glyph unicode="^" horiz-adv-x="497" d="M17,441l123,0l108,189l2,0l106,-189l124,0l-175,303l-113,0z" />
      <glyph
        unicode="~"
        horiz-adv-x="453"
        d="M308,268C380,268 415,327 416,418l-87,0C328,389 318,374 293,374C274,374 258,383 234,396C210,408 185,419 152,419C88,419 41,367 40,267l87,0C129,296 140,312 162,312C181,312 198,302 228,289C252,279 278,268 308,268z"
      />
      <glyph
        unicode="*"
        horiz-adv-x="443"
        d="M147,398l76,110l74,-110l62,44l-75,104l115,38l-26,69l-111,-37l0,128l-80,0l0,-128l-112,37l-28,-69l117,-38l-74,-104z"
      />
      <glyph
        unicode="@"
        horiz-adv-x="942"
        d="M424,97C485,97 532,126 561,180l2,0C579,125 630,95 699,95C814,95 902,183 902,353C902,607 706,743 488,743C260,743 41,582 41,295C41,49 218,-113 463,-113C541,-113 612,-100 681,-67l-28,69C594,-22 530,-36 461,-36C248,-36 131,104 131,295C131,528 293,664 487,664C679,664 816,561 816,352C816,214 757,173 706,173C669,173 654,198 660,248l33,270l-112,0l-7,-64l-2,0C553,500 515,528 457,528C343,528 260,430 260,286C260,172 324,97 424,97M453,183C400,183 377,227 377,288C377,375 411,441 478,441C532,441 560,401 560,338C560,248 522,183 453,183z"
      />
      <glyph
        unicode="&#xE3;"
        horiz-adv-x="566"
        d="M404,0l123,0l0,9C515,17 507,40 507,84l0,281C507,436 481,487 431,517C396,539 349,548 288,548C136,548 64,465 60,374l120,0C183,427 218,460 289,460C353,460 385,429 385,383C385,335 341,321 253,310C126,295 34,266 34,145C34,41 112,-13 216,-13C301,-13 349,22 384,69l2,0C388,33 395,12 404,0M253,74C204,74 161,91 161,148C161,207 206,223 284,238C323,246 363,257 388,273l0,-79C388,134 340,74 253,74M356,621C417,621 449,669 451,744l-75,0C373,722 365,706 345,706C309,706 279,746 219,746C164,746 123,704 122,621l74,0C198,646 212,661 229,661C258,661 299,621 356,621z"
      />
      <glyph
        unicode="b"
        horiz-adv-x="608"
        d="M348,-17C490,-17 578,92 578,266C578,436 486,548 349,548C273,548 219,511 184,455l-3,0C183,483 185,526 185,568l0,176l-127,0l0,-744l123,0l0,77l3,0C216,14 277,-17 348,-17M321,87C229,87 180,162 180,266C180,368 224,449 321,449C406,449 449,372 449,267C449,160 406,87 321,87z"
      />
      <glyph unicode="\" horiz-adv-x="453" d="M474,-134l-346,918l-120,0l346,-918z" />
      <glyph unicode="|" horiz-adv-x="246" d="M78,-134l89,0l0,918l-89,0z" />
      <glyph
        unicode="{"
        horiz-adv-x="384"
        d="M322,-134l29,0l0,101l-25,0C269,-33 245,-15 245,51l0,169C245,281 222,311 172,327l0,3C222,344 245,376 245,437l0,164C245,669 269,684 326,684l25,0l0,100l-29,0C189,784 121,743 121,602l0,-151C121,402 97,379 31,377l0,-98C97,277 121,254 121,205l0,-154C121,-90 189,-134 322,-134z"
      />
      <glyph
        unicode="}"
        horiz-adv-x="384"
        d="M42,-134l23,0C199,-134 266,-91 266,50l0,154C266,252 289,275 352,278l0,97C288,377 266,401 266,449l0,151C266,741 199,784 65,784l-23,0l0,-100l19,0C118,684 142,667 142,600l0,-165C142,374 165,343 216,328l0,-3C165,309 142,279 142,218l0,-168C142,-17 118,-33 61,-33l-19,0z"
      />
      <glyph unicode="[" horiz-adv-x="394" d="M78,-134l273,0l0,101l-149,0l0,717l149,0l0,100l-273,0z" />
      <glyph unicode="]" horiz-adv-x="394" d="M42,-134l273,0l0,918l-273,0l0,-100l148,0l0,-717l-148,0z" />
      <glyph
        unicode="&#x2D8;"
        horiz-adv-x="500"
        d="M271,613C358,613 416,672 419,755l-77,0C337,720 311,696 270,696C227,696 203,718 198,755l-79,0C123,667 187,613 271,613z"
      />
      <glyph unicode="&#xA6;" horiz-adv-x="246" d="M78,386l89,0l0,398l-89,0M78,-134l89,0l0,415l-89,0z" />
      <glyph
        unicode="&#x2022;"
        horiz-adv-x="371"
        d="M186,196C262,196 325,258 325,335C325,412 262,476 186,476C108,476 46,412 46,335C46,258 108,196 186,196z"
      />
      <glyph
        unicode="c"
        horiz-adv-x="569"
        d="M302,-16C445,-16 536,70 544,194l-124,0C413,124 371,83 301,83C208,83 160,161 160,266C160,374 208,451 301,451C365,451 405,414 417,356l124,0C522,471 436,548 300,548C136,548 31,425 31,266C31,108 137,-16 302,-16z"
      />
      <glyph
        unicode="&#x107;"
        horiz-adv-x="569"
        d="M241,618l98,0l105,154l-150,0M302,-16C445,-16 536,70 544,194l-124,0C413,124 371,83 301,83C208,83 160,161 160,266C160,374 208,451 301,451C365,451 405,414 417,356l124,0C522,471 436,548 300,548C136,548 31,425 31,266C31,108 137,-16 302,-16z"
      />
      <glyph unicode="&#x2C7;" horiz-adv-x="500" d="M417,755l-116,0l-51,-77l-2,0l-49,77l-116,0l102,-137l129,0z" />
      <glyph
        unicode="&#x10D;"
        horiz-adv-x="569"
        d="M461,755l-116,0l-51,-77l-2,0l-49,77l-116,0l102,-137l129,0M302,-16C445,-16 536,70 544,194l-124,0C413,124 371,83 301,83C208,83 160,161 160,266C160,374 208,451 301,451C365,451 405,414 417,356l124,0C522,471 436,548 300,548C136,548 31,425 31,266C31,108 137,-16 302,-16z"
      />
      <glyph
        unicode="&#xE7;"
        horiz-adv-x="569"
        d="M265,-209C336,-209 386,-179 386,-128C386,-69 328,-50 278,-57l0,2l45,40C454,-7 536,76 544,194l-124,0C413,124 371,83 301,83C208,83 160,161 160,266C160,374 208,451 301,451C365,451 405,414 417,356l124,0C522,471 436,548 300,548C136,548 31,425 31,266C31,120 121,4 264,-13l-59,-63l17,-26C233,-98 244,-95 260,-95C282,-95 298,-104 298,-125C298,-151 274,-159 243,-159C217,-159 192,-153 171,-143l-18,-36C179,-196 217,-209 265,-209z"
      />
      <glyph
        unicode="&#x109;"
        horiz-adv-x="569"
        d="M125,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0M302,-16C445,-16 536,70 544,194l-124,0C413,124 371,83 301,83C208,83 160,161 160,266C160,374 208,451 301,451C365,451 405,414 417,356l124,0C522,471 436,548 300,548C136,548 31,425 31,266C31,108 137,-16 302,-16z"
      />
      <glyph
        unicode="&#x10B;"
        horiz-adv-x="569"
        d="M302,-16C445,-16 536,70 544,194l-124,0C413,124 371,83 301,83C208,83 160,161 160,266C160,374 208,451 301,451C365,451 405,414 417,356l124,0C522,471 436,548 300,548C136,548 31,425 31,266C31,108 137,-16 302,-16M229,621l130,0l0,123l-130,0z"
      />
      <glyph
        unicode="&#xB8;"
        horiz-adv-x="500"
        d="M227,-209C298,-209 348,-179 348,-128C348,-69 290,-50 240,-57l0,2l63,55l-64,0l-72,-76l17,-26C195,-98 206,-95 222,-95C244,-95 260,-104 260,-125C260,-151 236,-159 205,-159C179,-159 154,-153 133,-143l-18,-36C141,-196 179,-209 227,-209z"
      />
      <glyph
        unicode="&#xA2;"
        horiz-adv-x="571"
        d="M269,-124l61,0l0,109C457,-5 538,77 545,195l-121,0C418,134 384,94 330,84l0,365C379,440 411,404 421,353l123,0C527,461 450,537 330,547l0,88l-61,0l0,-88C122,532 31,414 31,266C31,119 121,2 269,-14M158,266C158,360 196,432 269,448l0,-363C196,102 158,173 158,266z"
      />
      <glyph unicode="&#x2C6;" horiz-adv-x="500" d="M83,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0z" />
      <glyph unicode=":" horiz-adv-x="243" d="M52,319l139,0l0,138l-139,0M52,0l139,0l0,139l-139,0z" />
      <glyph
        unicode=","
        horiz-adv-x="236"
        d="M50,-169C132,-160 188,-108 188,1l0,138l-139,0l0,-139l65,0l0,-6C114,-67 88,-96 50,-107z"
      />
      <glyph
        unicode="&#xF6C3;"
        horiz-adv-x="500"
        d="M317,-51l-131,0l0,-100l61,0C247,-183 223,-199 184,-206l0,-49C261,-250 317,-213 317,-131z"
      />
      <glyph
        unicode="&#xA9;"
        horiz-adv-x="865"
        d="M433,-18C647,-18 815,147 815,369C815,592 647,757 433,757C219,757 51,592 51,369C51,147 219,-18 433,-18M433,28C246,28 105,174 105,369C105,565 246,711 433,711C619,711 759,565 759,369C759,174 619,28 433,28M438,145C500,145 542,163 576,194C607,222 625,265 627,309l-99,0C523,267 493,231 439,231C371,231 329,289 329,371C329,451 365,514 437,514C488,514 517,483 523,446l101,0C619,493 597,535 559,562C529,586 487,600 438,600C308,600 224,502 224,371C224,240 311,145 438,145z"
      />
      <glyph
        unicode="&#xA4;"
        horiz-adv-x="524"
        d="M262,227C194,227 154,281 154,357C154,433 194,488 262,488C329,488 367,433 367,357C367,281 329,227 262,227M82,107l69,70C182,157 218,147 262,147C304,147 342,157 372,177l69,-70l64,62l-70,70C456,272 468,314 468,357C468,400 457,441 435,475l70,70l-64,61l-68,-70C342,556 304,568 262,568C218,568 181,556 150,536l-68,70l-63,-61l68,-70C66,441 54,401 54,357C54,313 66,272 87,238l-68,-69z"
      />
      <glyph
        unicode="d"
        horiz-adv-x="610"
        d="M263,-17C336,-17 394,16 427,78l2,0l0,-78l123,0l0,744l-127,0l0,-176C425,528 427,486 429,457l-2,0C392,513 337,548 263,548C127,548 31,436 31,266C31,92 122,-17 263,-17M289,87C203,87 160,160 160,267C160,372 203,449 289,449C383,449 430,373 430,266C430,161 383,87 289,87z"
      />
      <glyph
        unicode="&#x2020;"
        horiz-adv-x="611"
        d="M44,435l194,0l0,-564l133,0l0,564l195,0l0,107l-195,0l0,202l-133,0l0,-202l-194,0z"
      />
      <glyph
        unicode="&#x2021;"
        horiz-adv-x="611"
        d="M44,435l194,0l0,-255l-194,0l0,-108l194,0l0,-201l133,0l0,201l195,0l0,108l-195,0l0,255l195,0l0,107l-195,0l0,202l-133,0l0,-202l-194,0z"
      />
      <glyph
        unicode="&#x10F;"
        horiz-adv-x="610"
        d="M263,-17C336,-17 394,16 427,78l2,0l0,-78l123,0l0,744l-127,0l0,-176C425,528 427,486 429,457l-2,0C392,513 337,548 263,548C127,548 31,436 31,266C31,92 122,-17 263,-17M289,87C203,87 160,160 160,267C160,372 203,449 289,449C383,449 430,373 430,266C430,161 383,87 289,87M714,744l-116,0l0,-93l53,0C650,622 634,605 597,600l0,-47C669,557 714,590 714,671z"
      />
      <glyph
        unicode="&#x111;"
        horiz-adv-x="610"
        d="M617,673l-65,0l0,71l-127,0l0,-71l-144,0l0,-71l144,0l0,-34C425,528 427,486 429,457l-2,0C392,513 337,548 263,548C127,548 31,436 31,266C31,92 122,-17 263,-17C336,-17 394,16 427,78l2,0l0,-78l123,0l0,602l65,0M289,87C203,87 160,160 160,267C160,372 203,449 289,449C383,449 430,373 430,266C430,161 383,87 289,87z"
      />
      <glyph
        unicode="&#xB0;"
        horiz-adv-x="412"
        d="M206,410C296,410 371,479 371,568C371,657 296,728 206,728C117,728 42,657 42,568C42,479 117,410 206,410M206,479C155,479 123,518 123,568C123,618 155,659 206,659C257,659 289,618 289,568C289,518 257,479 206,479z"
      />
      <glyph unicode="&#xA8;" horiz-adv-x="500" d="M300,621l122,0l0,123l-122,0M78,621l122,0l0,123l-122,0z" />
      <glyph
        unicode="&#xF7;"
        horiz-adv-x="602"
        d="M232,472l139,0l0,130l-139,0M61,290l480,0l0,102l-480,0M232,80l139,0l0,129l-139,0z"
      />
      <glyph
        unicode="$"
        horiz-adv-x="652"
        d="M299,-117l62,0l0,110C504,0 612,76 612,207C612,351 501,389 361,420l0,202C425,612 462,578 470,524l126,0C585,640 504,710 361,721l0,78l-62,0l0,-77C158,716 56,646 56,523C56,381 161,348 297,316C297,316 298,315 299,315l0,-219C212,106 170,152 161,227l-131,0C35,102 122,5 299,-7M481,200C481,139 427,103 361,95l0,205C439,279 481,258 481,200M185,529C185,583 229,618 299,624l0,-190C221,455 185,478 185,529z"
      />
      <glyph unicode="&#x2D9;" horiz-adv-x="500" d="M185,621l130,0l0,123l-130,0z" />
      <glyph unicode="&#x131;" horiz-adv-x="242" d="M58,0l127,0l0,534l-127,0z" />
      <glyph
        unicode="&#xF6BE;"
        horiz-adv-x="243"
        d="M42,-176C84,-176 120,-167 147,-143C170,-122 185,-89 185,-28l0,562l-127,0l0,-557C58,-61 41,-74 8,-76C-2,-77 -13,-76 -25,-76l-5,0l0,-93C-11,-173 17,-176 42,-176z"
      />
      <glyph
        unicode="e"
        horiz-adv-x="587"
        d="M303,-16C434,-16 520,55 546,151l-124,0C405,107 365,81 304,81C215,81 166,143 157,235l399,0C557,334 532,418 480,473C435,521 374,548 296,548C138,548 31,425 31,266C31,108 133,-16 303,-16M296,456C373,456 420,403 425,315l-266,0C170,399 213,456 296,456z"
      />
      <glyph
        unicode="&#xE9;"
        horiz-adv-x="587"
        d="M303,-16C434,-16 520,55 546,151l-124,0C405,107 365,81 304,81C215,81 166,143 157,235l399,0C557,334 532,418 480,473C435,521 374,548 296,548C138,548 31,425 31,266C31,108 133,-16 303,-16M296,456C373,456 420,403 425,315l-266,0C170,399 213,456 296,456M244,618l98,0l105,154l-150,0z"
      />
      <glyph
        unicode="&#x115;"
        horiz-adv-x="587"
        d="M303,-16C434,-16 520,55 546,151l-124,0C405,107 365,81 304,81C215,81 166,143 157,235l399,0C557,334 532,418 480,473C435,521 374,548 296,548C138,548 31,425 31,266C31,108 133,-16 303,-16M296,456C373,456 420,403 425,315l-266,0C170,399 213,456 296,456M298,613C385,613 443,672 446,755l-77,0C364,720 338,696 297,696C254,696 230,718 225,755l-79,0C150,667 214,613 298,613z"
      />
      <glyph
        unicode="&#x11B;"
        horiz-adv-x="587"
        d="M303,-16C434,-16 520,55 546,151l-124,0C405,107 365,81 304,81C215,81 166,143 157,235l399,0C557,334 532,418 480,473C435,521 374,548 296,548C138,548 31,425 31,266C31,108 133,-16 303,-16M296,456C373,456 420,403 425,315l-266,0C170,399 213,456 296,456M464,755l-116,0l-51,-77l-2,0l-49,77l-116,0l102,-137l129,0z"
      />
      <glyph
        unicode="&#xEA;"
        horiz-adv-x="587"
        d="M303,-16C434,-16 520,55 546,151l-124,0C405,107 365,81 304,81C215,81 166,143 157,235l399,0C557,334 532,418 480,473C435,521 374,548 296,548C138,548 31,425 31,266C31,108 133,-16 303,-16M296,456C373,456 420,403 425,315l-266,0C170,399 213,456 296,456M128,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0z"
      />
      <glyph
        unicode="&#xEB;"
        horiz-adv-x="587"
        d="M303,-16C434,-16 520,55 546,151l-124,0C405,107 365,81 304,81C215,81 166,143 157,235l399,0C557,334 532,418 480,473C435,521 374,548 296,548C138,548 31,425 31,266C31,108 133,-16 303,-16M296,456C373,456 420,403 425,315l-266,0C170,399 213,456 296,456M125,621l122,0l0,123l-122,0M347,621l122,0l0,123l-122,0z"
      />
      <glyph
        unicode="&#x117;"
        horiz-adv-x="587"
        d="M303,-16C434,-16 520,55 546,151l-124,0C405,107 365,81 304,81C215,81 166,143 157,235l399,0C557,334 532,418 480,473C435,521 374,548 296,548C138,548 31,425 31,266C31,108 133,-16 303,-16M296,456C373,456 420,403 425,315l-266,0C170,399 213,456 296,456M232,621l130,0l0,123l-130,0z"
      />
      <glyph
        unicode="&#xE8;"
        horiz-adv-x="587"
        d="M303,-16C434,-16 520,55 546,151l-124,0C405,107 365,81 304,81C215,81 166,143 157,235l399,0C557,334 532,418 480,473C435,521 374,548 296,548C138,548 31,425 31,266C31,108 133,-16 303,-16M296,456C373,456 420,403 425,315l-266,0C170,399 213,456 296,456M254,618l98,0l-53,154l-151,0z"
      />
      <glyph
        unicode="8"
        horiz-adv-x="638"
        d="M321,-17C475,-17 603,56 603,206C603,295 549,363 456,387l0,3C531,415 570,469 570,538C570,655 468,728 319,728C171,728 68,652 68,536C68,466 107,413 183,388l0,-3C92,359 35,295 35,203C35,56 167,-17 321,-17M319,429C240,429 193,468 193,530C193,592 240,632 319,632C398,632 444,592 444,530C444,468 398,429 319,429M321,85C226,85 169,132 169,209C169,288 226,338 320,338C414,338 470,288 470,209C470,133 412,85 321,85z"
      />
      <glyph
        unicode="&#x2088;"
        horiz-adv-x="442"
        d="M222,-171C333,-171 415,-121 415,-27C415,28 381,70 320,88l0,3C369,109 393,142 393,185C393,260 324,307 221,307C120,307 50,258 50,182C50,139 74,108 124,89l0,-3C64,67 28,27 28,-30C28,-121 112,-171 222,-171M221,118C175,118 150,141 150,178C150,213 175,237 221,237C269,237 294,213 294,178C294,141 269,118 221,118M221,-95C168,-95 134,-68 134,-24C134,21 168,51 222,51C277,51 309,21 309,-24C309,-67 276,-95 221,-95z"
      />
      <glyph
        unicode="&#x2078;"
        horiz-adv-x="442"
        d="M222,276C333,276 415,326 415,420C415,475 381,517 320,535l0,3C369,556 393,589 393,632C393,707 324,754 221,754C120,754 50,705 50,629C50,586 74,555 124,536l0,-3C64,514 28,474 28,417C28,326 112,276 222,276M221,565C175,565 150,588 150,625C150,660 175,684 221,684C269,684 294,660 294,625C294,588 269,565 221,565M221,352C168,352 134,379 134,423C134,468 168,498 222,498C277,498 309,468 309,423C309,380 276,352 221,352z"
      />
      <glyph
        unicode="&#x2026;"
        horiz-adv-x="769"
        d="M49,0l139,0l0,139l-139,0M315,0l140,0l0,139l-140,0M582,0l139,0l0,139l-139,0z"
      />
      <glyph
        unicode="&#x113;"
        horiz-adv-x="587"
        d="M303,-16C434,-16 520,55 546,151l-124,0C405,107 365,81 304,81C215,81 166,143 157,235l399,0C557,334 532,418 480,473C435,521 374,548 296,548C138,548 31,425 31,266C31,108 133,-16 303,-16M296,456C373,456 420,403 425,315l-266,0C170,399 213,456 296,456M149,636l294,0l0,94l-294,0z"
      />
      <glyph unicode="&#x2014;" horiz-adv-x="1000" d="M200,210l600,0l0,109l-600,0z" />
      <glyph unicode="&#x2013;" horiz-adv-x="500" d="M0,210l500,0l0,109l-500,0z" />
      <glyph
        unicode="&#x14B;"
        horiz-adv-x="589"
        d="M408,-23C408,-61 391,-74 358,-76C348,-77 337,-76 325,-76l-5,0l0,-93C339,-173 367,-176 392,-176C434,-176 470,-167 497,-143C520,-122 535,-89 535,-28l0,390C535,477 466,548 351,548C276,548 220,515 185,456l-3,0l0,78l-124,0l0,-534l127,0l0,313C185,389 235,443 306,443C371,443 408,404 408,341z"
      />
      <glyph
        unicode="&#x119;"
        horiz-adv-x="587"
        d="M377,-187C410,-187 430,-183 443,-178l0,72l-3,0C431,-109 419,-111 406,-111C382,-111 368,-102 368,-80C368,-42 417,-6 462,29C493,55 530,90 546,151l-124,0C405,107 365,81 304,81C215,81 166,144 157,235l399,0C557,334 532,418 480,473C435,521 374,548 296,548C138,548 31,425 31,266C31,108 133,-16 303,-16C305,-16 314,-16 317,-15C288,-33 261,-62 261,-101C261,-153 299,-187 377,-187M296,456C373,456 420,403 425,315l-266,0C170,398 213,456 296,456z"
      />
      <glyph unicode="=" horiz-adv-x="602" d="M62,375l480,0l0,99l-480,0M62,174l480,0l0,99l-480,0z" />
      <glyph
        unicode="&#x212E;"
        horiz-adv-x="720"
        d="M679,335C680,462 653,563 571,631C522,673 454,697 373,697C179,697 40,551 40,343C40,135 164,-13 378,-13C545,-13 645,80 675,207l-55,0C584,102 512,38 378,38C300,38 244,59 202,93l0,242M373,650C435,650 486,632 520,607l0,-226l-318,0l0,212C241,626 301,650 373,650z"
      />
      <glyph
        unicode="&#xF0;"
        horiz-adv-x="607"
        d="M182,571l143,44C362,577 394,533 416,483l-3,0C377,526 326,541 275,541C126,541 31,424 31,266C31,109 138,-16 304,-16C488,-16 575,121 575,302C575,455 513,570 441,651l81,25l-24,55l-105,-32C373,716 353,731 334,744l-157,0l0,-7C207,715 241,690 274,662l-115,-35M303,80C209,80 160,158 160,266C160,374 208,451 301,451C395,451 445,375 445,268C445,159 396,80 303,80z"
      />
      <glyph unicode="!" horiz-adv-x="277" d="M105,239l67,0l33,297l0,208l-134,0l0,-208M69,0l140,0l0,139l-140,0z" />
      <glyph
        unicode="&#xA1;"
        horiz-adv-x="277"
        d="M172,373l-67,0l-33,-297l0,-208l134,0l0,208M209,612l-140,0l0,-139l140,0z"
      />
      <glyph
        unicode="f"
        horiz-adv-x="374"
        d="M25,446l82,0l0,-446l127,0l0,446l110,0l0,88l-110,0l0,51C234,643 261,652 345,648l0,95C325,745 305,746 288,746C227,747 179,736 148,709C120,684 107,647 107,593l0,-59l-82,0z"
      />
      <glyph
        unicode="&#xFB00;"
        horiz-adv-x="688"
        d="M421,0l127,0l0,446l110,0l0,88l-110,0l0,51C548,643 575,652 659,648l0,95C600,750 541,746 507,734C449,714 421,671 421,593l0,-59l-187,0l0,46C234,638 260,647 343,643l0,96C285,744 220,740 188,728C128,705 107,666 107,588l0,-54l-82,0l0,-88l82,0l0,-446l127,0l0,446l187,0z"
      />
      <glyph
        unicode="&#xFB03;"
        horiz-adv-x="919"
        d="M735,0l126,0l0,534l-126,0M733,621l130,0l0,123l-130,0M421,0l127,0l0,446l110,0l0,88l-110,0l0,51C548,643 575,652 659,648l0,95C600,750 541,746 507,734C449,714 421,671 421,593l0,-59l-187,0l0,46C234,638 260,647 343,643l0,96C285,744 220,740 188,728C128,705 107,666 107,588l0,-54l-82,0l0,-88l82,0l0,-446l127,0l0,446l187,0z"
      />
      <glyph
        unicode="&#xFB04;"
        horiz-adv-x="922"
        d="M735,0l127,0l0,744l-127,0M421,0l127,0l0,446l110,0l0,88l-110,0l0,51C548,635 568,649 629,649C638,649 648,648 659,648l0,95C639,745 620,746 601,746C477,746 421,704 421,593l0,-59l-187,0l0,46C234,630 254,644 313,644C322,644 332,643 343,643l0,96C327,740 309,741 293,741C149,741 107,696 107,588l0,-54l-82,0l0,-88l82,0l0,-446l127,0l0,446l187,0z"
      />
      <glyph
        unicode="&#xF001;"
        horiz-adv-x="605"
        d="M25,446l82,0l0,-446l127,0l0,446l110,0l0,88l-110,0l0,51C234,643 261,652 345,648l0,95C325,745 305,746 288,746C232,746 186,736 157,712C123,689 107,651 107,593l0,-59l-82,0M421,0l126,0l0,534l-126,0M419,621l130,0l0,123l-130,0z"
      />
      <glyph
        unicode="&#xF002;"
        horiz-adv-x="608"
        d="M25,446l82,0l0,-446l127,0l0,446l110,0l0,88l-110,0l0,51C234,643 261,652 345,648l0,95C325,745 306,746 288,746C232,747 186,736 157,712C123,689 107,651 107,593l0,-59l-82,0M421,0l127,0l0,744l-127,0z"
      />
      <glyph
        unicode="&#xFB01;"
        horiz-adv-x="605"
        d="M25,446l82,0l0,-446l127,0l0,446l110,0l0,88l-110,0l0,51C234,643 261,652 345,648l0,95C325,745 305,746 288,746C232,746 186,736 157,712C123,689 107,651 107,593l0,-59l-82,0M421,0l126,0l0,534l-126,0M419,621l130,0l0,123l-130,0z"
      />
      <glyph
        unicode="5"
        horiz-adv-x="611"
        d="M303,-16C460,-16 575,82 575,236C575,389 461,481 336,481C264,481 217,459 180,420l-2,0l39,183l314,0l0,109l-408,0l-76,-389l125,0C196,353 240,379 304,379C380,379 441,326 441,237C441,151 384,93 299,93C217,93 170,135 157,197l-130,0C37,71 147,-16 303,-16z"
      />
      <glyph
        unicode="&#x2085;"
        horiz-adv-x="420"
        d="M207,-171C314,-171 391,-105 391,-6C391,91 320,148 233,148C189,148 157,134 136,111l-2,0l19,104l209,0l0,82l-279,0l-47,-255l92,0C143,60 171,74 205,74C252,74 287,46 287,-7C287,-56 253,-90 205,-90C159,-90 129,-67 120,-34l-99,0C31,-116 104,-171 207,-171z"
      />
      <glyph
        unicode="&#x2075;"
        horiz-adv-x="420"
        d="M207,276C314,276 391,342 391,441C391,538 320,595 233,595C189,595 157,581 136,558l-2,0l19,104l209,0l0,82l-279,0l-47,-255l92,0C143,507 171,521 205,521C252,521 287,493 287,440C287,391 253,357 205,357C159,357 129,380 120,413l-99,0C31,331 104,276 207,276z"
      />
      <glyph
        unicode="&#x215D;"
        horiz-adv-x="983"
        d="M210,276C317,276 394,342 394,441C394,538 323,595 236,595C192,595 160,581 139,558l-2,0l19,104l209,0l0,82l-279,0l-47,-255l92,0C146,507 174,521 208,521C255,521 290,493 290,440C290,391 256,357 208,357C162,357 132,380 123,413l-99,0C34,331 107,276 210,276M110,-130l95,0l638,874l-96,0M759,-121C870,-121 952,-71 952,23C952,78 918,120 857,138l0,3C906,159 930,192 930,235C930,310 861,357 758,357C657,357 587,308 587,232C587,189 611,158 661,139l0,-3C601,117 565,77 565,20C565,-71 649,-121 759,-121M758,168C712,168 687,191 687,228C687,263 712,287 758,287C806,287 831,263 831,228C831,191 806,168 758,168M758,-45C705,-45 671,-18 671,26C671,71 705,101 759,101C814,101 846,71 846,26C846,-17 813,-45 758,-45z"
      />
      <glyph
        unicode="&#xFB02;"
        horiz-adv-x="608"
        d="M25,446l82,0l0,-446l127,0l0,446l110,0l0,88l-110,0l0,51C234,643 261,652 345,648l0,95C325,745 306,746 288,746C232,747 186,736 157,712C123,689 107,651 107,593l0,-59l-82,0M421,0l127,0l0,744l-127,0z"
      />
      <glyph
        unicode="&#x192;"
        horiz-adv-x="433"
        d="M38,341l93,0l-51,-352C72,-65 43,-69 -5,-66l0,-90C64,-165 125,-162 163,-127C188,-104 201,-69 209,-22l51,363l127,0l0,87l-114,0l20,141C301,624 335,633 409,627l0,95C327,732 256,727 213,688C187,664 173,629 166,584l-23,-156l-105,0z"
      />
      <glyph
        unicode="4"
        horiz-adv-x="640"
        d="M387,0l128,0l0,167l110,0l0,99l-110,0l0,454l-120,0l-347,-436l0,-117l339,0M142,261l0,2C142,263 193,317 222,352l102,129C353,518 390,577 390,577l2,0C392,577 387,510 387,470l0,-204l-141,0C206,266 142,261 142,261z"
      />
      <glyph
        unicode="&#x2084;"
        horiz-adv-x="446"
        d="M253,-160l105,0l0,100l69,0l0,76l-69,0l0,286l-104,0l-223,-270l0,-92l222,0M99,11l0,2C99,13 135,45 156,70l55,68C231,161 258,203 258,203l2,0C260,203 253,156 253,127l0,-111l-79,0C145,16 99,11 99,11z"
      />
      <glyph
        unicode="&#x2074;"
        horiz-adv-x="446"
        d="M253,287l105,0l0,100l69,0l0,76l-69,0l0,286l-104,0l-223,-270l0,-92l222,0M99,458l0,2C99,460 135,492 156,517l55,68C231,608 258,650 258,650l2,0C260,650 253,603 253,574l0,-111l-79,0C145,463 99,458 99,458z"
      />
      <glyph unicode="&#x2044;" horiz-adv-x="253" d="M-276,-130l95,0l638,874l-96,0z" />
      <glyph
        unicode="g"
        horiz-adv-x="605"
        d="M295,-186C370,-186 435,-169 479,-132C521,-95 546,-40 546,35l0,499l-123,0l0,-69l-2,0C389,522 338,548 263,548C135,548 37,446 37,286C37,127 140,29 270,29C341,29 388,58 421,111l3,0C423,93 422,66 422,37l0,-13C422,-53 378,-93 293,-93C223,-93 182,-71 173,-27l-122,0C64,-128 152,-186 295,-186M293,127C210,127 163,190 163,288C163,386 209,449 292,449C383,449 427,385 427,290C427,197 390,127 293,127z"
      />
      <glyph
        unicode="&#x11F;"
        horiz-adv-x="605"
        d="M295,-186C370,-186 435,-169 479,-132C521,-95 546,-40 546,35l0,499l-123,0l0,-69l-2,0C389,522 338,548 263,548C135,548 37,446 37,286C37,127 140,29 270,29C341,29 388,58 421,111l3,0C423,93 422,66 422,37l0,-13C422,-53 378,-93 293,-93C223,-93 182,-71 173,-27l-122,0C64,-128 152,-186 295,-186M293,127C210,127 163,190 163,288C163,386 209,449 292,449C383,449 427,385 427,290C427,197 390,127 293,127M300,613C387,613 445,672 448,755l-77,0C366,720 340,696 299,696C256,696 232,718 227,755l-79,0C152,667 216,613 300,613z"
      />
      <glyph
        unicode="&#x11D;"
        horiz-adv-x="605"
        d="M295,-186C370,-186 435,-169 479,-132C521,-95 546,-40 546,35l0,499l-123,0l0,-69l-2,0C389,522 338,548 263,548C135,548 37,446 37,286C37,127 140,29 270,29C341,29 388,58 421,111l3,0C423,93 422,66 422,37l0,-13C422,-53 378,-93 293,-93C223,-93 182,-71 173,-27l-122,0C64,-128 152,-186 295,-186M293,127C210,127 163,190 163,288C163,386 209,449 292,449C383,449 427,385 427,290C427,197 390,127 293,127M130,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0z"
      />
      <glyph
        unicode="&#x123;"
        horiz-adv-x="605"
        d="M295,-186C370,-186 435,-169 479,-132C521,-95 546,-40 546,35l0,499l-123,0l0,-69l-2,0C389,522 338,548 263,548C135,548 37,446 37,286C37,127 140,29 270,29C341,29 388,58 421,111l3,0C423,93 422,66 422,37l0,-13C422,-53 378,-93 293,-93C223,-93 182,-71 173,-27l-122,0C64,-128 152,-186 295,-186M293,127C210,127 163,190 163,288C163,386 209,449 292,449C383,449 427,385 427,290C427,197 390,127 293,127M232,621l131,0l0,99l-61,0C302,752 326,769 365,775l0,49C288,820 232,782 232,700z"
      />
      <glyph
        unicode="&#x121;"
        horiz-adv-x="605"
        d="M295,-186C370,-186 435,-169 479,-132C521,-95 546,-40 546,35l0,499l-123,0l0,-69l-2,0C389,522 338,548 263,548C135,548 37,446 37,286C37,127 140,29 270,29C341,29 388,58 421,111l3,0C423,93 422,66 422,37l0,-13C422,-53 378,-93 293,-93C223,-93 182,-71 173,-27l-122,0C64,-128 152,-186 295,-186M293,127C210,127 163,190 163,288C163,386 209,449 292,449C383,449 427,385 427,290C427,197 390,127 293,127M234,621l130,0l0,123l-130,0z"
      />
      <glyph
        unicode="&#xDF;"
        horiz-adv-x="579"
        d="M313,-10C452,-10 548,76 548,227C548,330 494,397 399,421l0,3C478,449 513,498 513,569C513,681 422,755 293,755C147,755 58,674 58,550l0,-550l127,0l0,545C185,614 225,656 293,656C356,656 393,617 393,560C393,494 345,456 275,456l-19,0l0,-81l25,0C374,375 422,322 422,233C422,128 356,78 255,93l-3,0l0,-96C268,-7 291,-10 313,-10z"
      />
      <glyph unicode="`" horiz-adv-x="538" d="M231,618l98,0l-53,154l-151,0z" />
      <glyph
        unicode="&gt;"
        horiz-adv-x="544"
        d="M62,562l0,-116l198,-77C314,349 399,324 399,324l0,-2C399,322 315,296 260,274l-198,-79l0,-112l425,180l0,119z"
      />
      <glyph
        unicode="&#x2265;"
        horiz-adv-x="544"
        d="M487,98l-425,0l0,-98l425,0M487,289l0,126l-425,155l0,-110l201,-67C317,376 404,354 404,354l0,-2C404,352 317,329 263,311l-201,-68l0,-109z"
      />
      <glyph
        unicode="&#xAB;"
        horiz-adv-x="513"
        d="M445,60l0,116l-98,94l0,2l98,94l0,119l-160,-151l0,-123M227,60l0,116l-97,94l0,2l97,94l0,119l-159,-151l0,-123z"
      />
      <glyph
        unicode="&#xBB;"
        horiz-adv-x="513"
        d="M68,485l0,-117l98,-94l0,-2l-98,-94l0,-118l159,150l0,123M285,485l0,-117l98,-94l0,-2l-98,-94l0,-118l160,150l0,123z"
      />
      <glyph unicode="&#x2039;" horiz-adv-x="311" d="M242,51l0,123l-108,96l0,2l108,97l0,123l-174,-157l0,-126z" />
      <glyph unicode="&#x203A;" horiz-adv-x="311" d="M68,492l0,-122l109,-97l0,-2l-109,-97l0,-123l174,158l0,125z" />
      <glyph
        unicode="h"
        horiz-adv-x="589"
        d="M185,544l0,200l-127,0l0,-744l127,0l0,313C185,389 235,443 306,443C371,443 408,404 408,341l0,-341l127,0l0,362C535,477 466,548 351,548C276,548 222,514 186,457l-3,0C184,488 185,529 185,544z"
      />
      <glyph
        unicode="&#x127;"
        horiz-adv-x="589"
        d="M-7,602l65,0l0,-602l127,0l0,313C185,389 235,443 306,443C371,443 408,404 408,341l0,-341l127,0l0,362C535,477 466,548 351,548C276,548 222,514 186,457l-3,0C184,488 185,529 185,544l0,58l144,0l0,71l-144,0l0,71l-127,0l0,-71l-65,0z"
      />
      <glyph
        unicode="&#x125;"
        horiz-adv-x="589"
        d="M185,544l0,200l-127,0l0,-744l127,0l0,313C185,389 235,443 306,443C371,443 408,404 408,341l0,-341l127,0l0,362C535,477 466,548 351,548C276,548 222,514 186,457l-3,0C184,488 185,529 185,544M125,785l117,0l52,59l2,0l50,-59l117,0l-104,123l-129,0z"
      />
      <glyph unicode="&#x2DD;" horiz-adv-x="500" d="M281,618l84,0l82,154l-125,0M110,618l85,0l83,154l-126,0z" />
      <glyph unicode="-" horiz-adv-x="368" d="M44,218l280,0l0,112l-280,0z" />
      <glyph unicode="i" horiz-adv-x="242" d="M56,621l130,0l0,123l-130,0M58,0l127,0l0,534l-127,0z" />
      <glyph unicode="&#xED;" horiz-adv-x="242" d="M69,618l98,0l105,154l-150,0M58,0l127,0l0,534l-127,0z" />
      <glyph
        unicode="&#x12D;"
        horiz-adv-x="242"
        d="M58,0l127,0l0,534l-127,0M123,613C210,613 268,672 271,755l-77,0C189,720 163,696 122,696C79,696 55,718 50,755l-79,0C-25,667 39,613 123,613z"
      />
      <glyph
        unicode="&#xEE;"
        horiz-adv-x="242"
        d="M-47,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0M58,0l127,0l0,534l-127,0z"
      />
      <glyph
        unicode="&#xEF;"
        horiz-adv-x="242"
        d="M58,0l127,0l0,534l-127,0M151,621l111,0l0,123l-111,0M-20,621l111,0l0,123l-111,0z"
      />
      <glyph unicode="&#xEC;" horiz-adv-x="242" d="M58,0l127,0l0,534l-127,0M79,618l98,0l-53,154l-151,0z" />
      <glyph
        unicode="&#x133;"
        horiz-adv-x="485"
        d="M284,-176C326,-176 362,-167 389,-143C412,-122 427,-89 427,-28l0,562l-127,0l0,-557C300,-61 283,-74 250,-76C240,-77 229,-76 217,-76l-5,0l0,-93C231,-173 259,-176 284,-176M58,0l127,0l0,534l-127,0M298,621l130,0l0,123l-130,0M56,621l130,0l0,123l-130,0z"
      />
      <glyph unicode="&#x12B;" horiz-adv-x="242" d="M58,0l127,0l0,534l-127,0M4,636l234,0l0,94l-234,0z" />
      <glyph
        unicode="&#x221E;"
        horiz-adv-x="747"
        d="M194,131C279,131 332,183 377,249C420,183 471,131 552,131C649,131 715,205 715,317C715,429 649,502 552,502C471,502 420,450 377,386C332,450 279,502 194,502C89,502 31,417 31,317C31,217 87,131 194,131M202,405C248,405 282,363 309,316C282,271 248,228 202,228C154,228 134,268 134,317C134,366 154,405 202,405M544,228C501,228 470,271 442,316C470,363 501,405 544,405C593,405 614,366 614,317C614,268 593,228 544,228z"
      />
      <glyph
        unicode="&#x222B;"
        horiz-adv-x="370"
        d="M117,-15C117,-63 94,-81 49,-81C26,-81 10,-77 -2,-75l-3,0l0,-100C19,-181 49,-185 81,-185C184,-185 244,-137 244,-25l0,612C244,635 267,653 314,653C336,653 353,650 363,647l3,0l0,99C341,753 311,756 281,756C178,756 117,710 117,596z"
      />
      <glyph
        unicode="&#x12F;"
        horiz-adv-x="242"
        d="M58,0l32,0C55,-18 15,-51 15,-101C15,-153 54,-187 131,-187C163,-187 183,-183 196,-178l0,72l-2,0C185,-109 173,-111 159,-111C136,-111 122,-102 122,-80C122,-55 146,-21 185,1l0,533l-127,0M56,621l130,0l0,123l-130,0z"
      />
      <glyph
        unicode="&#x129;"
        horiz-adv-x="242"
        d="M58,0l127,0l0,534l-127,0M191,621C252,621 284,669 286,744l-75,0C208,722 200,706 180,706C144,706 114,746 54,746C-1,746 -42,704 -43,621l74,0C33,646 47,661 64,661C93,661 134,621 191,621z"
      />
      <glyph
        unicode="j"
        horiz-adv-x="243"
        d="M42,-176C84,-176 120,-167 147,-143C170,-122 185,-89 185,-28l0,562l-127,0l0,-557C58,-61 41,-74 8,-76C-2,-77 -13,-76 -25,-76l-5,0l0,-93C-11,-173 17,-176 42,-176M56,621l130,0l0,123l-130,0z"
      />
      <glyph
        unicode="&#x135;"
        horiz-adv-x="243"
        d="M42,-176C84,-176 120,-167 147,-143C170,-122 185,-89 185,-28l0,562l-127,0l0,-557C58,-61 41,-74 8,-76C-2,-77 -13,-76 -25,-76l-5,0l0,-93C-11,-173 17,-176 42,-176M-48,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0z"
      />
      <glyph
        unicode="k"
        horiz-adv-x="568"
        d="M407,0l148,0l-219,339l196,195l-151,0l-201,-209C181,356 184,405 184,456l0,288l-126,0l0,-744l126,0l0,193l63,63z"
      />
      <glyph
        unicode="&#x137;"
        horiz-adv-x="568"
        d="M407,0l148,0l-219,339l196,195l-151,0l-201,-209C181,356 184,405 184,456l0,288l-126,0l0,-744l126,0l0,193l63,63M350,-51l-131,0l0,-100l61,0C280,-183 256,-199 217,-206l0,-49C294,-250 350,-213 350,-131z"
      />
      <glyph
        unicode="&#x138;"
        horiz-adv-x="568"
        d="M407,0l148,0l-219,339l196,195l-151,0l-201,-209C181,356 184,405 184,456l0,78l-126,0l0,-534l126,0l0,193l63,63z"
      />
      <glyph unicode="l" horiz-adv-x="247" d="M60,0l128,0l0,744l-128,0z" />
      <glyph unicode="&#x13A;" horiz-adv-x="247" d="M67,785l102,0l100,128l-154,0M60,0l128,0l0,744l-128,0z" />
      <glyph
        unicode="&#x13E;"
        horiz-adv-x="247"
        d="M350,744l-116,0l0,-93l53,0C286,622 270,605 233,600l0,-47C305,557 350,590 350,671M60,0l128,0l0,744l-128,0z"
      />
      <glyph
        unicode="&#x13C;"
        horiz-adv-x="247"
        d="M191,-51l-131,0l0,-100l61,0C121,-183 97,-199 58,-206l0,-49C135,-250 191,-213 191,-131M60,0l128,0l0,744l-128,0z"
      />
      <glyph unicode="&#x140;" horiz-adv-x="377" d="M60,0l128,0l0,744l-128,0M248,295l129,0l0,129l-129,0z" />
      <glyph
        unicode="&lt;"
        horiz-adv-x="544"
        d="M482,83l0,116l-197,77C231,296 146,321 146,321l0,2C146,323 230,349 285,371l197,78l0,113l-425,-180l0,-119z"
      />
      <glyph
        unicode="&#x2264;"
        horiz-adv-x="544"
        d="M57,0l425,0l0,98l-425,0M482,134l0,111l-200,67C228,329 140,350 140,350l0,2C140,352 227,376 282,394l200,67l0,109l-425,-155l0,-126z"
      />
      <glyph unicode="&#xAC;" horiz-adv-x="555" d="M58,337l317,0l0,-171l109,0l0,274l-426,0z" />
      <glyph
        unicode="&#x25CA;"
        horiz-adv-x="610"
        d="M374,744l-139,0l-209,-372l210,-372l139,0l209,371M161,374l89,165C287,610 302,653 302,653l2,0C304,653 320,610 357,539l90,-171l-88,-165C321,133 307,89 307,89l-2,0C305,89 289,133 252,203z"
      />
      <glyph
        unicode="&#x142;"
        horiz-adv-x="293"
        d="M9,219l73,55l0,-274l128,0l0,371l82,62l0,101l-82,-62l0,272l-128,0l0,-369l-73,-55z"
      />
      <glyph
        unicode="m"
        horiz-adv-x="883"
        d="M58,0l127,0l0,316C185,391 237,443 296,443C349,443 380,406 380,345l0,-345l127,0l0,316C507,391 553,443 615,443C667,443 702,406 702,345l0,-345l127,0l0,362C829,477 765,548 656,548C590,548 530,517 488,448l-2,0C462,514 411,548 344,548C271,548 218,513 184,453l-3,0l0,81l-123,0z"
      />
      <glyph unicode="&#xAF;" horiz-adv-x="500" d="M104,636l294,0l0,94l-294,0z" />
      <glyph unicode="&#x2212;" horiz-adv-x="604" d="M58,290l488,0l0,101l-488,0z" />
      <glyph
        unicode="&#xB5;"
        horiz-adv-x="587"
        d="M58,-179l127,0l0,93C185,-32 182,14 182,14l2,0C199,-2 221,-10 255,-10C325,-10 372,25 404,76l2,0l0,-76l123,0l0,534l-126,0l0,-313C403,147 357,92 285,92C221,92 185,129 185,192l0,342l-127,0z"
      />
      <glyph
        unicode="&#xD7;"
        horiz-adv-x="603"
        d="M137,104l164,165l164,-165l71,70l-165,165l165,165l-71,70l-164,-165l-163,165l-70,-70l164,-165l-165,-166z"
      />
      <glyph
        unicode="n"
        horiz-adv-x="589"
        d="M182,534l-124,0l0,-534l127,0l0,313C185,389 235,443 306,443C371,443 408,404 408,341l0,-341l127,0l0,362C535,477 466,548 351,548C276,548 220,515 185,456l-3,0z"
      />
      <glyph
        unicode="&#x144;"
        horiz-adv-x="589"
        d="M182,534l-124,0l0,-534l127,0l0,313C185,389 235,443 306,443C371,443 408,404 408,341l0,-341l127,0l0,362C535,477 466,548 351,548C276,548 220,515 185,456l-3,0M246,618l98,0l105,154l-150,0z"
      />
      <glyph
        unicode="&#x149;"
        horiz-adv-x="589"
        d="M182,534l-124,0l0,-534l127,0l0,313C185,389 235,443 306,443C371,443 408,404 408,341l0,-341l127,0l0,362C535,477 466,548 351,548C276,548 220,515 185,456l-3,0M81,744l-116,0l0,-93l53,0C17,622 1,605 -36,600l0,-47C36,557 81,590 81,671z"
      />
      <glyph
        unicode="&#x148;"
        horiz-adv-x="589"
        d="M182,534l-124,0l0,-534l127,0l0,313C185,389 235,443 306,443C371,443 408,404 408,341l0,-341l127,0l0,362C535,477 466,548 351,548C276,548 220,515 185,456l-3,0M466,755l-116,0l-51,-77l-2,0l-49,77l-116,0l102,-137l129,0z"
      />
      <glyph
        unicode="&#x146;"
        horiz-adv-x="589"
        d="M182,534l-124,0l0,-534l127,0l0,313C185,389 235,443 306,443C371,443 408,404 408,341l0,-341l127,0l0,362C535,477 466,548 351,548C276,548 220,515 185,456l-3,0M365,-51l-131,0l0,-100l61,0C295,-183 271,-199 232,-206l0,-49C309,-250 365,-213 365,-131z"
      />
      <glyph
        unicode="9"
        horiz-adv-x="641"
        d="M313,-17C396,-17 461,9 507,57C571,121 597,226 597,357C597,504 568,604 494,665C448,704 385,728 308,728C153,728 40,622 40,480C40,337 151,233 289,233C368,233 427,268 471,325l2,0C469,237 454,175 424,137C397,100 360,83 310,83C234,83 194,128 185,173l-129,0C69,68 166,-17 313,-17M310,334C225,334 164,396 164,481C164,566 224,627 311,627C395,627 460,567 460,481C460,394 400,334 310,334z"
      />
      <glyph
        unicode="&#x2089;"
        horiz-adv-x="443"
        d="M217,-171C276,-171 321,-152 354,-118C393,-76 410,-8 410,68C410,161 391,226 342,265C309,291 267,307 215,307C109,307 32,241 32,145C32,50 106,-15 197,-15C245,-15 280,6 305,38l2,0C305,-11 295,-44 278,-65C264,-85 242,-95 214,-95C171,-95 150,-70 143,-46l-101,0C53,-118 120,-171 217,-171M217,62C167,62 132,97 132,146C132,194 167,230 217,230C266,230 301,194 301,146C301,97 268,62 217,62z"
      />
      <glyph
        unicode="&#x2079;"
        horiz-adv-x="443"
        d="M217,276C276,276 321,295 354,329C393,371 410,439 410,515C410,608 391,673 342,712C309,738 267,754 215,754C109,754 32,688 32,592C32,497 106,432 197,432C245,432 280,454 305,485l2,0C305,436 295,403 278,382C264,362 242,352 214,352C171,352 150,377 143,401l-101,0C53,329 120,276 217,276M217,509C167,509 132,544 132,593C132,641 167,677 217,677C266,677 301,641 301,593C301,544 268,509 217,509z"
      />
      <glyph
        unicode="&#x2260;"
        horiz-adv-x="602"
        d="M62,377l223,0l-54,-106l-169,0l0,-97l120,0l-57,-113l88,0l57,113l272,0l0,97l-223,0l54,106l169,0l0,97l-120,0l58,113l-88,0l-58,-113l-272,0z"
      />
      <glyph
        unicode="&#xF1;"
        horiz-adv-x="589"
        d="M182,534l-124,0l0,-534l127,0l0,313C185,389 235,443 306,443C371,443 408,404 408,341l0,-341l127,0l0,362C535,477 466,548 351,548C276,548 220,515 185,456l-3,0M368,621C429,621 461,669 463,744l-75,0C385,722 377,706 357,706C321,706 291,746 231,746C176,746 135,704 134,621l74,0C210,646 224,661 241,661C270,661 311,621 368,621z"
      />
      <glyph
        unicode="#"
        horiz-adv-x="631"
        d="M73,430l102,0l-21,-136l-100,0l-17,-101l102,0l-29,-193l88,0l29,193l125,0l-29,-193l88,0l29,193l100,0l17,101l-102,0l21,136l101,0l17,100l-103,0l28,183l-87,0l-28,-183l-126,0l28,183l-88,0l-28,-183l-100,0M263,430l126,0l-21,-136l-126,0z"
      />
      <glyph
        unicode="o"
        horiz-adv-x="606"
        d="M304,-16C470,-16 575,108 575,266C575,425 470,548 304,548C138,548 31,425 31,266C31,108 138,-16 304,-16M303,80C209,80 160,159 160,266C160,374 209,454 303,454C396,454 446,374 446,266C446,159 396,80 303,80z"
      />
      <glyph
        unicode="&#xF3;"
        horiz-adv-x="606"
        d="M250,618l98,0l105,154l-150,0M304,-16C470,-16 575,108 575,266C575,425 470,548 304,548C138,548 31,425 31,266C31,108 138,-16 304,-16M303,80C209,80 160,159 160,266C160,374 209,454 303,454C396,454 446,374 446,266C446,159 396,80 303,80z"
      />
      <glyph
        unicode="&#x14F;"
        horiz-adv-x="606"
        d="M304,-16C470,-16 575,108 575,266C575,425 470,548 304,548C138,548 31,425 31,266C31,108 138,-16 304,-16M303,80C209,80 160,159 160,266C160,374 209,454 303,454C396,454 446,374 446,266C446,159 396,80 303,80M304,613C391,613 449,672 452,755l-77,0C370,720 344,696 303,696C260,696 236,718 231,755l-79,0C156,667 220,613 304,613z"
      />
      <glyph
        unicode="&#xF4;"
        horiz-adv-x="606"
        d="M134,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0M304,-16C470,-16 575,108 575,266C575,425 470,548 304,548C138,548 31,425 31,266C31,108 138,-16 304,-16M303,80C209,80 160,159 160,266C160,374 209,454 303,454C396,454 446,374 446,266C446,159 396,80 303,80z"
      />
      <glyph
        unicode="&#xF6;"
        horiz-adv-x="606"
        d="M353,621l122,0l0,123l-122,0M131,621l122,0l0,123l-122,0M304,-16C470,-16 575,108 575,266C575,425 470,548 304,548C138,548 31,425 31,266C31,108 138,-16 304,-16M303,80C209,80 160,159 160,266C160,374 209,454 303,454C396,454 446,374 446,266C446,159 396,80 303,80z"
      />
      <glyph
        unicode="&#x153;"
        horiz-adv-x="972"
        d="M696,-16C823,-16 906,55 931,151l-123,0C790,107 757,81 697,81C614,81 564,143 555,235l387,0C942,334 918,418 866,474C824,522 765,548 690,548C603,548 536,506 496,440C456,506 388,548 295,548C133,548 31,425 31,266C31,108 133,-16 295,-16C388,-16 455,26 495,91C536,25 604,-16 696,-16M296,80C204,80 160,159 160,266C160,374 204,454 296,454C386,454 433,374 433,266C433,159 386,80 296,80M690,456C763,456 805,403 810,315l-253,0C568,399 609,456 690,456z"
      />
      <glyph
        unicode="&#x2DB;"
        horiz-adv-x="500"
        d="M232,-187C265,-187 285,-183 298,-178l0,72l-3,0C286,-109 274,-111 261,-111C237,-111 223,-102 223,-80C223,-55 247,-25 279,0l-84,0C159,-17 115,-50 115,-101C115,-153 154,-187 232,-187z"
      />
      <glyph
        unicode="&#xF2;"
        horiz-adv-x="606"
        d="M304,-16C470,-16 575,108 575,266C575,425 470,548 304,548C138,548 31,425 31,266C31,108 138,-16 304,-16M303,80C209,80 160,159 160,266C160,374 209,454 303,454C396,454 446,374 446,266C446,159 396,80 303,80M260,618l98,0l-53,154l-151,0z"
      />
      <glyph
        unicode="&#x151;"
        horiz-adv-x="606"
        d="M343,618l84,0l82,154l-125,0M172,618l85,0l83,154l-126,0M304,-16C470,-16 575,108 575,266C575,425 470,548 304,548C138,548 31,425 31,266C31,108 138,-16 304,-16M303,80C209,80 160,159 160,266C160,374 209,454 303,454C396,454 446,374 446,266C446,159 396,80 303,80z"
      />
      <glyph
        unicode="&#x14D;"
        horiz-adv-x="606"
        d="M155,636l294,0l0,94l-294,0M304,-16C470,-16 575,108 575,266C575,425 470,548 304,548C138,548 31,425 31,266C31,108 138,-16 304,-16M303,80C209,80 160,159 160,266C160,374 209,454 303,454C396,454 446,374 446,266C446,159 396,80 303,80z"
      />
      <glyph unicode="1" horiz-adv-x="417" d="M202,0l132,0l0,718l-103,0C215,617 135,578 37,575l0,-88l165,0z" />
      <glyph unicode="&#x2081;" horiz-adv-x="314" d="M143,-160l106,0l0,461l-87,0C151,238 101,211 29,209l0,-71l114,0z" />
      <glyph
        unicode="&#x215B;"
        horiz-adv-x="929"
        d="M146,287l106,0l0,462l-87,0C154,685 104,658 32,656l0,-71l114,0M45,-130l95,0l638,874l-96,0M706,-121C817,-121 899,-71 899,23C899,78 865,120 804,138l0,3C853,159 877,192 877,235C877,310 808,357 705,357C604,357 534,308 534,232C534,189 558,158 608,139l0,-3C548,117 512,77 512,20C512,-71 596,-121 706,-121M705,168C659,168 634,191 634,228C634,263 659,287 705,287C753,287 778,263 778,228C778,191 753,168 705,168M705,-45C652,-45 618,-18 618,26C618,71 652,101 706,101C761,101 793,71 793,26C793,-17 760,-45 705,-45z"
      />
      <glyph
        unicode="&#xBD;"
        horiz-adv-x="920"
        d="M528,-110l356,0l0,84l-223,0C683,8 744,37 788,68C839,103 884,137 884,212C884,291 821,356 716,356C607,356 536,293 536,188l100,0C636,240 665,274 712,274C755,274 780,248 780,208C780,151 719,129 649,80C588,38 530,-14 528,-110M146,287l106,0l0,462l-87,0C154,685 104,658 32,656l0,-71l114,0M52,-130l95,0l638,874l-96,0z"
      />
      <glyph
        unicode="&#xBC;"
        horiz-adv-x="874"
        d="M678,-110l105,0l0,100l69,0l0,76l-69,0l0,286l-104,0l-223,-270l0,-92l222,0M56,-130l95,0l638,874l-96,0M146,287l106,0l0,462l-87,0C154,685 104,658 32,656l0,-71l114,0M524,61l0,2C524,63 560,95 581,120l55,68C656,211 683,253 683,253l2,0C685,253 678,206 678,177l0,-111l-79,0C570,66 524,61 524,61z"
      />
      <glyph unicode="&#xB9;" horiz-adv-x="314" d="M143,287l106,0l0,462l-87,0C151,685 101,658 29,656l0,-71l114,0z" />
      <glyph
        unicode="&#x2153;"
        horiz-adv-x="937"
        d="M146,287l106,0l0,462l-87,0C154,685 104,658 32,656l0,-71l114,0M51,-130l95,0l638,874l-96,0M724,-121C823,-121 904,-74 904,22C904,83 871,119 807,135l0,1C858,153 888,181 888,229C888,309 819,357 722,357C611,357 546,293 543,211l99,0C644,249 668,282 719,282C759,282 787,260 787,224C787,186 758,166 708,166l-25,0l0,-73l28,0C767,93 798,73 798,28C798,-17 768,-41 718,-41C658,-41 632,-6 629,38l-100,0C530,-53 600,-121 724,-121z"
      />
      <glyph
        unicode="&#xAA;"
        horiz-adv-x="532"
        d="M368,245l115,0l0,7C473,259 466,281 466,317l0,250C466,628 443,672 402,698C369,719 326,728 270,728C136,728 70,655 64,574l110,0C180,619 208,648 272,648C328,648 353,624 353,583C353,542 319,531 239,522C127,510 43,481 43,374C43,284 116,235 207,235C281,235 324,268 351,307l1,0C354,275 360,256 368,245M238,314C196,314 159,329 159,378C159,427 193,443 263,454C298,460 334,469 355,481l0,-64C355,364 314,314 238,314z"
      />
      <glyph
        unicode="&#xBA;"
        horiz-adv-x="553"
        d="M277,232C424,232 516,340 516,479C516,619 424,728 277,728C130,728 37,619 37,479C37,340 130,232 277,232M277,318C196,318 157,386 157,479C157,573 196,642 277,642C356,642 396,573 396,479C396,386 356,318 277,318z"
      />
      <glyph
        unicode="&#xF8;"
        horiz-adv-x="606"
        d="M62,-44l74,81C181,4 238,-16 304,-16C470,-16 575,108 575,266C575,338 553,403 514,452l72,79l-43,38l-70,-77C429,527 371,548 304,548C138,548 31,425 31,266C31,193 54,127 95,77l-76,-83M158,266C158,374 209,455 303,455C345,455 378,440 401,414l-226,-248C164,194 158,228 158,266M303,80C263,80 231,93 208,116l224,246C442,334 447,301 447,266C447,159 396,80 303,80z"
      />
      <glyph
        unicode="&#x1FF;"
        horiz-adv-x="606"
        d="M62,-44l74,81C181,4 238,-16 304,-16C470,-16 575,108 575,266C575,338 553,403 514,452l72,79l-43,38l-70,-77C429,527 371,548 304,548C138,548 31,425 31,266C31,193 54,127 95,77l-76,-83M158,266C158,374 209,455 303,455C345,455 378,440 401,414l-226,-248C164,194 158,228 158,266M303,80C263,80 231,93 208,116l224,246C442,334 447,301 447,266C447,159 396,80 303,80M250,618l98,0l105,154l-150,0z"
      />
      <glyph
        unicode="&#xF5;"
        horiz-adv-x="606"
        d="M304,-16C470,-16 575,108 575,266C575,425 470,548 304,548C138,548 31,425 31,266C31,108 138,-16 304,-16M303,80C209,80 160,159 160,266C160,374 209,454 303,454C396,454 446,374 446,266C446,159 396,80 303,80M373,621C434,621 466,669 468,744l-75,0C390,722 382,706 362,706C326,706 296,746 236,746C181,746 140,704 139,621l74,0C215,646 229,661 246,661C275,661 316,621 373,621z"
      />
      <glyph
        unicode="p"
        horiz-adv-x="611"
        d="M58,-179l127,0l0,143C185,14 183,48 181,68l3,0C217,14 275,-17 346,-17C490,-17 580,94 580,266C580,436 489,548 350,548C275,548 222,516 184,455l-3,0l0,79l-123,0M322,84C224,84 180,162 180,263C180,364 225,445 321,445C408,445 451,369 451,263C451,158 406,84 322,84z"
      />
      <glyph
        unicode="&#xB6;"
        horiz-adv-x="589"
        d="M278,358l0,-498l80,0l0,794l81,0l0,-794l81,0l0,884l-272,0C181,744 129,727 94,692C58,656 37,608 37,550C37,434 113,358 241,358z"
      />
      <glyph
        unicode="("
        horiz-adv-x="353"
        d="M230,-134l104,0C258,-4 206,141 206,327C206,515 260,665 334,784l-104,0C143,678 70,516 70,327C70,136 141,-17 230,-134z"
      />
      <glyph
        unicode=")"
        horiz-adv-x="353"
        d="M19,-134l105,0C213,-17 284,136 284,327C284,516 211,678 124,784l-105,0C95,665 149,515 149,327C149,141 96,-4 19,-134z"
      />
      <glyph
        unicode="&#x2202;"
        horiz-adv-x="606"
        d="M429,432C399,468 349,484 297,484C144,484 38,367 38,211C38,83 128,-16 280,-16C474,-16 562,135 562,312C562,564 451,723 172,751l0,-108C324,619 405,558 429,432M282,79C205,79 166,133 166,212C166,318 223,391 311,391C388,391 428,341 428,264C428,173 377,79 282,79z"
      />
      <glyph
        unicode="%"
        horiz-adv-x="915"
        d="M213,314C318,314 391,395 391,521C391,649 317,728 213,728C110,728 34,647 34,521C34,395 109,314 213,314M217,0l84,0l397,712l-85,0M701,-17C807,-17 881,65 881,191C881,318 806,398 701,398C599,398 522,317 522,191C522,65 597,-17 701,-17M213,391C162,391 138,446 138,521C138,598 162,653 213,653C264,653 290,596 290,521C290,445 265,391 213,391M702,61C651,61 626,116 626,191C626,267 651,323 702,323C751,323 778,266 778,191C778,115 754,61 702,61z"
      />
      <glyph unicode="." horiz-adv-x="236" d="M49,0l139,0l0,139l-139,0z" />
      <glyph unicode="&#xB7;" horiz-adv-x="246" d="M53,258l140,0l0,140l-140,0z" />
      <glyph
        unicode="&#x2030;"
        horiz-adv-x="1318"
        d="M213,0l84,0l396,712l-84,0M213,314C318,314 391,395 391,521C391,649 317,728 213,728C110,728 34,647 34,521C34,395 109,314 213,314M693,-17C799,-17 872,65 872,191C872,318 798,398 693,398C591,398 514,317 514,191C514,65 589,-17 693,-17M213,391C162,391 138,446 138,521C138,598 162,653 213,653C264,653 290,596 290,521C290,445 265,391 213,391M693,61C642,61 618,116 618,191C618,267 642,323 693,323C744,323 769,266 769,191C769,115 745,61 693,61M1106,-17C1211,-17 1285,65 1285,191C1285,318 1210,398 1106,398C1003,398 926,317 926,191C926,65 1000,-17 1106,-17M1106,61C1054,61 1030,116 1030,191C1030,267 1054,323 1106,323C1156,323 1182,266 1182,191C1182,115 1158,61 1106,61z"
      />
      <glyph
        unicode="&#x3C0;"
        horiz-adv-x="675"
        d="M25,447l83,0l0,-447l127,0l0,447l190,0l0,-328C425,26 481,-6 560,-6C589,-6 615,-2 634,3l0,98l-4,0C621,99 609,96 596,96C565,96 551,107 551,141l0,306l91,0l0,87l-617,0z"
      />
      <glyph
        unicode="+"
        horiz-adv-x="604"
        d="M58,290l190,0l0,-200l108,0l0,200l190,0l0,101l-190,0l0,200l-108,0l0,-200l-190,0z"
      />
      <glyph
        unicode="&#xB1;"
        horiz-adv-x="604"
        d="M58,342l190,0l0,-171l108,0l0,171l190,0l0,100l-190,0l0,170l-108,0l0,-170l-190,0M58,0l488,0l0,98l-488,0z"
      />
      <glyph unicode="&#x220F;" horiz-adv-x="757" d="M75,744l0,-848l139,0l0,729l328,0l0,-729l140,0l0,848z" />
      <glyph
        unicode="q"
        horiz-adv-x="611"
        d="M427,-179l126,0l0,713l-123,0l0,-77l-3,0C392,517 339,548 264,548C126,548 33,436 33,266C33,94 123,-17 267,-17C339,-17 392,14 428,68l2,0C429,42 427,-4 427,-36M290,84C206,84 161,158 161,263C161,369 203,445 291,445C386,445 432,368 432,263C432,160 388,84 290,84z"
      />
      <glyph
        unicode="?"
        horiz-adv-x="565"
        d="M196,0l139,0l0,139l-139,0M209,239l115,0C325,275 330,296 347,317C366,340 399,361 448,400C491,435 531,482 531,560C531,675 438,757 289,757C116,757 27,645 23,517l127,0C152,593 201,649 285,649C354,649 397,611 397,557C397,496 345,467 299,428C234,372 210,330 209,239z"
      />
      <glyph
        unicode="&#xBF;"
        horiz-adv-x="565"
        d="M369,612l-140,0l0,-139l140,0M355,373l-115,0C240,337 234,316 217,295C198,272 166,251 117,212C73,177 34,130 34,52C34,-63 127,-145 276,-145C449,-145 537,-33 541,95l-126,0C412,19 364,-37 280,-37C211,-37 167,1 167,55C167,116 220,145 265,184C330,240 355,282 355,373z"
      />
      <glyph unicode='"' horiz-adv-x="405" d="M242,476l111,0l0,268l-111,0M52,476l113,0l0,268l-113,0z" />
      <glyph
        unicode="&#x201E;"
        horiz-adv-x="408"
        d="M171,128l-125,0l0,-128l57,0l0,-7C103,-60 76,-87 44,-97l0,-54C110,-144 171,-99 171,4M362,128l-124,0l0,-128l57,0l0,-7C295,-60 267,-87 235,-97l0,-54C302,-144 362,-99 362,4z"
      />
      <glyph
        unicode="&#x201C;"
        horiz-adv-x="408"
        d="M238,468l124,0l0,128l-57,0l0,7C305,655 332,683 364,692l0,55C298,739 238,696 238,592M46,468l125,0l0,128l-58,0l0,7C113,655 141,683 173,692l0,55C106,739 46,696 46,592z"
      />
      <glyph
        unicode="&#x201D;"
        horiz-adv-x="408"
        d="M171,744l-125,0l0,-128l57,0l0,-7C103,556 76,529 44,519l0,-54C110,472 171,516 171,620M362,744l-124,0l0,-128l57,0l0,-7C295,556 267,529 235,519l0,-54C302,472 362,516 362,620z"
      />
      <glyph
        unicode="&#x2018;"
        horiz-adv-x="216"
        d="M46,468l125,0l0,128l-58,0l0,7C113,655 141,683 173,692l0,55C106,739 46,696 46,592z"
      />
      <glyph
        unicode="&#x2019;"
        horiz-adv-x="216"
        d="M171,744l-125,0l0,-128l57,0l0,-7C103,556 76,529 44,519l0,-54C110,472 171,516 171,620z"
      />
      <glyph
        unicode="&#x201A;"
        horiz-adv-x="216"
        d="M171,128l-125,0l0,-128l57,0l0,-7C103,-60 76,-87 44,-97l0,-54C110,-144 171,-99 171,4z"
      />
      <glyph unicode="'" horiz-adv-x="217" d="M52,476l113,0l0,268l-113,0z" />
      <glyph
        unicode="r"
        horiz-adv-x="396"
        d="M181,534l-123,0l0,-534l127,0l0,268C185,371 248,428 332,427C345,427 355,426 367,423l4,0l0,114C362,541 352,542 337,542C269,542 218,507 184,438l-3,0z"
      />
      <glyph
        unicode="&#x155;"
        horiz-adv-x="396"
        d="M181,534l-123,0l0,-534l127,0l0,268C185,371 248,428 332,427C345,427 355,426 367,423l4,0l0,114C362,541 352,542 337,542C269,542 218,507 184,438l-3,0M169,618l98,0l105,154l-150,0z"
      />
      <glyph
        unicode="&#x221A;"
        horiz-adv-x="664"
        d="M23,224l81,0l135,-328l143,0l273,848l-131,0l-175,-571C326,99 308,19 308,19l-2,0C306,19 284,99 256,173l-63,163l-170,0z"
      />
      <glyph
        unicode="&#x159;"
        horiz-adv-x="396"
        d="M181,534l-123,0l0,-534l127,0l0,268C185,371 248,428 332,427C345,427 355,426 367,423l4,0l0,114C362,541 352,542 337,542C269,542 218,507 184,438l-3,0M393,755l-116,0l-51,-77l-2,0l-49,77l-116,0l102,-137l129,0z"
      />
      <glyph
        unicode="&#x157;"
        horiz-adv-x="396"
        d="M181,534l-123,0l0,-534l127,0l0,268C185,371 248,428 332,427C345,427 355,426 367,423l4,0l0,114C362,541 352,542 337,542C269,542 218,507 184,438l-3,0M187,-51l-131,0l0,-100l61,0C117,-183 93,-199 54,-206l0,-49C131,-250 187,-213 187,-131z"
      />
      <glyph
        unicode="&#xAE;"
        horiz-adv-x="657"
        d="M329,172C490,172 617,297 617,464C617,633 490,757 329,757C168,757 40,633 40,464C40,297 168,172 329,172M329,213C191,213 89,320 89,464C89,609 191,716 329,716C467,716 569,609 569,464C569,320 467,213 329,213M212,315l72,0l0,116l49,0C365,431 382,415 384,386C388,353 388,319 397,315l70,0l0,6C456,325 457,362 452,398C448,434 437,448 402,457l0,2C445,471 462,497 462,532C462,587 415,619 357,619l-145,0M284,562l60,0C376,562 391,547 391,524C391,499 375,485 343,485l-59,0z"
      />
      <glyph
        unicode="&#x2DA;"
        horiz-adv-x="500"
        d="M251,597C310,597 359,643 359,703C359,761 310,809 251,809C190,809 141,761 141,703C141,643 190,597 251,597M251,647C218,647 196,671 196,703C196,735 218,760 251,760C282,760 304,735 304,703C304,671 282,647 251,647z"
      />
      <glyph
        unicode="s"
        horiz-adv-x="531"
        d="M272,-17C404,-17 500,42 500,154C500,284 400,308 299,327C218,342 163,351 163,398C163,435 194,462 253,462C316,462 350,437 359,391l121,0C467,484 396,548 254,548C129,548 43,490 43,389C43,268 138,245 238,225C322,209 376,199 376,146C376,103 343,73 273,73C205,73 156,100 146,164l-122,0C32,56 120,-17 272,-17z"
      />
      <glyph
        unicode="&#x15B;"
        horiz-adv-x="531"
        d="M207,618l98,0l105,154l-150,0M272,-17C404,-17 500,42 500,154C500,284 400,308 299,327C218,342 163,351 163,398C163,435 194,462 253,462C316,462 350,437 359,391l121,0C467,484 396,548 254,548C129,548 43,490 43,389C43,268 138,245 238,225C322,209 376,199 376,146C376,103 343,73 273,73C205,73 156,100 146,164l-122,0C32,56 120,-17 272,-17z"
      />
      <glyph
        unicode="&#x161;"
        horiz-adv-x="531"
        d="M426,755l-116,0l-51,-77l-2,0l-49,77l-116,0l102,-137l129,0M272,-17C404,-17 500,42 500,154C500,284 400,308 299,327C218,342 163,351 163,398C163,435 194,462 253,462C316,462 350,437 359,391l121,0C467,484 396,548 254,548C129,548 43,490 43,389C43,268 138,245 238,225C322,209 376,199 376,146C376,103 343,73 273,73C205,73 156,100 146,164l-122,0C32,56 120,-17 272,-17z"
      />
      <glyph
        unicode="&#x15F;"
        horiz-adv-x="531"
        d="M232,-209C303,-209 353,-179 353,-128C353,-69 295,-50 245,-57l0,2l44,39C412,-11 500,47 500,154C500,284 400,308 299,327C218,342 163,351 163,398C163,435 194,462 253,462C316,462 350,437 359,391l121,0C467,484 396,548 254,548C129,548 43,490 43,389C43,268 138,245 238,225C322,209 376,199 376,146C376,103 343,73 273,73C205,73 156,100 146,164l-122,0C31,66 104,-2 229,-15l-57,-61l17,-26C200,-98 211,-95 227,-95C249,-95 265,-104 265,-125C265,-151 241,-159 210,-159C184,-159 159,-153 138,-143l-18,-36C146,-196 184,-209 232,-209z"
      />
      <glyph
        unicode="&#x15D;"
        horiz-adv-x="531"
        d="M92,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0M272,-17C404,-17 500,42 500,154C500,284 400,308 299,327C218,342 163,351 163,398C163,435 194,462 253,462C316,462 350,437 359,391l121,0C467,484 396,548 254,548C129,548 43,490 43,389C43,268 138,245 238,225C322,209 376,199 376,146C376,103 343,73 273,73C205,73 156,100 146,164l-122,0C32,56 120,-17 272,-17z"
      />
      <glyph
        unicode="&#x219;"
        horiz-adv-x="531"
        d="M327,-51l-131,0l0,-100l61,0C257,-183 233,-199 194,-206l0,-49C271,-250 327,-213 327,-131M272,-17C404,-17 500,42 500,154C500,284 400,308 299,327C218,342 163,351 163,398C163,435 194,462 253,462C316,462 350,437 359,391l121,0C467,484 396,548 254,548C129,548 43,490 43,389C43,268 138,245 238,225C322,209 376,199 376,146C376,103 343,73 273,73C205,73 156,100 146,164l-122,0C32,56 120,-17 272,-17z"
      />
      <glyph
        unicode="&#xA7;"
        horiz-adv-x="646"
        d="M332,-190C463,-190 548,-126 548,-20C548,31 522,68 486,98C560,117 609,160 609,243C609,352 508,401 395,455C291,503 237,525 237,572C237,609 266,636 327,636C394,636 425,600 430,552l117,0C540,662 460,736 325,736C198,736 112,670 112,571C112,522 132,492 165,463C88,443 39,395 39,321C39,220 116,172 241,112C362,53 422,33 422,-20C422,-59 390,-89 330,-89C254,-89 215,-46 212,8l-121,0C95,-101 183,-190 332,-190M335,353C440,301 487,277 487,228C487,192 464,165 418,152C382,172 335,195 299,214C192,270 157,290 157,336C157,370 182,397 226,407C255,391 294,372 335,353z"
      />
      <glyph
        unicode=";"
        horiz-adv-x="243"
        d="M52,319l139,0l0,138l-139,0M53,-169C135,-160 191,-108 191,1l0,138l-139,0l0,-139l65,0l0,-6C117,-67 91,-96 53,-107z"
      />
      <glyph
        unicode="7"
        horiz-adv-x="566"
        d="M129,0l139,0C279,264 407,482 543,609l0,103l-513,0l0,-113l232,0C340,599 414,604 414,604l0,-2C276,485 142,254 129,0z"
      />
      <glyph
        unicode="&#x2087;"
        horiz-adv-x="388"
        d="M207,-160C213,4 285,136 368,216l0,81l-344,0l0,-88l137,0C235,209 271,214 271,214l0,-3C179,131 99,0 94,-160z"
      />
      <glyph
        unicode="&#x2077;"
        horiz-adv-x="388"
        d="M207,287C213,451 285,583 368,664l0,80l-344,0l0,-88l137,0C235,656 271,661 271,661l0,-3C179,578 99,447 94,287z"
      />
      <glyph
        unicode="&#x215E;"
        horiz-adv-x="945"
        d="M721,-121C832,-121 914,-71 914,23C914,78 880,120 819,138l0,3C868,159 892,192 892,235C892,310 823,357 720,357C619,357 549,308 549,232C549,189 573,158 623,139l0,-3C563,117 527,77 527,20C527,-71 611,-121 721,-121M44,-130l95,0l638,874l-96,0M210,287C216,451 288,583 371,664l0,80l-344,0l0,-88l137,0C238,656 274,661 274,661l0,-3C182,578 102,447 97,287M720,-45C667,-45 633,-18 633,26C633,71 667,101 721,101C776,101 808,71 808,26C808,-17 775,-45 720,-45M720,168C674,168 649,191 649,228C649,263 674,287 720,287C768,287 793,263 793,228C793,191 768,168 720,168z"
      />
      <glyph
        unicode="6"
        horiz-adv-x="640"
        d="M339,-17C488,-17 602,90 601,233C600,374 490,479 356,479C274,479 215,445 172,379l-2,0C176,540 227,629 340,629C410,629 445,585 453,544l128,0C564,641 484,728 338,728C241,728 172,694 122,632C69,565 44,469 45,356C45,229 71,134 134,67C181,15 249,-17 339,-17M335,84C250,84 184,145 184,231C184,318 245,377 334,377C415,377 477,317 477,230C477,145 416,84 335,84z"
      />
      <glyph
        unicode="&#x2086;"
        horiz-adv-x="444"
        d="M234,-171C338,-171 414,-104 413,-8C413,86 339,151 251,151C201,151 163,132 138,96l-2,0C141,184 174,233 234,233C275,233 293,208 298,186l103,0C387,257 330,308 235,308C168,308 120,285 86,244C50,201 34,140 34,69C34,-12 53,-75 94,-116C127,-150 173,-171 234,-171M230,-92C181,-92 145,-57 145,-9C145,40 179,74 230,74C278,74 312,39 312,-9C312,-57 278,-92 230,-92z"
      />
      <glyph
        unicode="&#x2076;"
        horiz-adv-x="444"
        d="M234,277C338,277 414,343 413,439C413,533 339,598 251,598C201,598 163,579 138,543l-2,0C141,631 174,680 234,680C275,680 293,655 298,633l103,0C387,704 330,755 235,755C168,755 120,732 86,691C50,648 34,587 34,516C34,435 53,373 94,331C127,297 173,277 234,277M230,355C181,355 145,390 145,438C145,487 179,521 230,521C278,521 312,486 312,438C312,390 278,355 230,355z"
      />
      <glyph unicode="/" horiz-adv-x="453" d="M-21,-134l120,0l347,918l-120,0z" />
      <glyph unicode=" " horiz-adv-x="253" />
      <glyph
        unicode="&#xA3;"
        horiz-adv-x="612"
        d="M44,307l87,0C137,288 141,269 141,248C141,178 96,133 36,92l68,-98C135,16 169,39 210,39C292,39 328,-14 426,-14C491,-14 548,13 590,50l-62,99C503,125 468,103 426,103C368,103 342,136 269,136C234,136 210,129 171,109l-2,3C233,162 262,207 262,268C262,281 261,294 259,307l122,0l0,81l-145,0C220,429 203,468 203,512C203,579 244,622 321,622C394,622 448,582 449,484l128,0C576,572 547,631 499,671C453,710 391,727 321,727C158,727 65,636 65,515C65,465 81,426 98,388l-54,0z"
      />
      <glyph
        unicode="&#x2211;"
        horiz-adv-x="671"
        d="M626,-104l0,117l-423,0l0,2l260,289l0,55l-253,271l0,2l406,0l0,112l-566,0l0,-116l274,-298l0,-2l-274,-310l0,-122z"
      />
      <glyph
        unicode="t"
        horiz-adv-x="371"
        d="M26,447l82,0l0,-327C108,26 167,-5 253,-5C287,-5 316,-1 337,3l0,99l-6,0C321,100 303,98 290,98C254,98 233,109 233,148l0,299l107,0l0,87l-107,0l0,170l-125,0l0,-170l-82,0z"
      />
      <glyph
        unicode="&#x167;"
        horiz-adv-x="371"
        d="M26,447l82,0l0,-88l-82,0l0,-80l82,0l0,-159C108,26 167,-5 253,-5C287,-5 316,-1 337,3l0,99l-6,0C321,100 303,98 290,98C254,98 233,109 233,148l0,131l107,0l0,80l-107,0l0,88l107,0l0,87l-107,0l0,170l-125,0l0,-170l-82,0z"
      />
      <glyph
        unicode="&#x165;"
        horiz-adv-x="371"
        d="M26,447l82,0l0,-327C108,26 167,-5 253,-5C287,-5 316,-1 337,3l0,99l-6,0C321,100 303,98 290,98C254,98 233,109 233,148l0,299l107,0l0,87l-107,0l0,170l-125,0l0,-170l-82,0M404,744l-116,0l0,-93l53,0C340,622 324,605 287,600l0,-47C359,557 404,590 404,671z"
      />
      <glyph
        unicode="&#xFE;"
        horiz-adv-x="610"
        d="M348,-17C490,-17 580,94 580,266C580,436 489,548 350,548C275,548 219,511 184,455l-3,0C183,483 185,526 185,568l0,176l-127,0l0,-919l127,0l0,139C185,14 183,48 181,68l3,0C217,14 277,-17 348,-17M322,84C224,84 180,162 180,263C180,364 225,445 321,445C408,445 451,369 451,263C451,158 406,84 322,84z"
      />
      <glyph
        unicode="3"
        horiz-adv-x="625"
        d="M319,-17C466,-17 588,57 588,202C588,296 539,356 445,382l0,2C517,408 565,453 565,530C565,654 458,728 315,728C147,728 50,630 48,498l125,0C175,569 222,628 312,628C383,628 435,589 435,525C435,460 386,422 297,422l-41,0l0,-95l44,0C398,327 454,288 454,212C454,131 399,88 314,88C210,88 156,147 152,233l-126,0C27,156 57,90 111,46C163,5 232,-17 319,-17z"
      />
      <glyph
        unicode="&#x2083;"
        horiz-adv-x="425"
        d="M216,-171C315,-171 396,-124 396,-28C396,33 363,69 299,85l0,1C350,103 380,131 380,179C380,259 311,307 214,307C103,307 38,243 35,161l99,0C136,199 160,232 211,232C251,232 279,210 279,174C279,136 250,116 200,116l-25,0l0,-73l28,0C259,43 290,23 290,-22C290,-67 260,-91 210,-91C150,-91 124,-56 121,-12l-100,0C22,-103 92,-171 216,-171z"
      />
      <glyph
        unicode="&#x215C;"
        horiz-adv-x="999"
        d="M219,276C318,276 399,323 399,419C399,480 366,516 302,532l0,1C353,550 383,578 383,626C383,706 314,754 217,754C106,754 41,690 38,608l99,0C139,646 163,679 214,679C254,679 282,657 282,621C282,583 253,563 203,563l-25,0l0,-73l28,0C262,490 293,470 293,425C293,380 263,356 213,356C153,356 127,391 124,435l-100,0C25,344 95,276 219,276M124,-130l95,0l638,874l-96,0M775,-121C886,-121 968,-71 968,23C968,78 934,120 873,138l0,3C922,159 946,192 946,235C946,310 877,357 774,357C673,357 603,308 603,232C603,189 627,158 677,139l0,-3C617,117 581,77 581,20C581,-71 665,-121 775,-121M774,168C728,168 703,191 703,228C703,263 728,287 774,287C822,287 847,263 847,228C847,191 822,168 774,168M774,-45C721,-45 687,-18 687,26C687,71 721,101 775,101C830,101 862,71 862,26C862,-17 829,-45 774,-45z"
      />
      <glyph
        unicode="&#xBE;"
        horiz-adv-x="962"
        d="M766,-110l105,0l0,100l69,0l0,76l-69,0l0,286l-104,0l-223,-270l0,-92l222,0M219,276C318,276 399,323 399,419C399,480 366,516 302,532l0,1C353,550 383,578 383,626C383,706 314,754 217,754C106,754 41,690 38,608l99,0C139,646 163,679 214,679C254,679 282,657 282,621C282,583 253,563 203,563l-25,0l0,-73l28,0C262,490 293,470 293,425C293,380 263,356 213,356C153,356 127,391 124,435l-100,0C25,344 95,276 219,276M140,-130l95,0l638,874l-96,0M612,61l0,2C612,63 648,95 669,120l55,68C744,211 771,253 771,253l2,0C773,253 766,206 766,177l0,-111l-79,0C658,66 612,61 612,61z"
      />
      <glyph
        unicode="&#xB3;"
        horiz-adv-x="425"
        d="M216,276C315,276 396,323 396,419C396,480 363,516 299,532l0,1C350,550 380,578 380,626C380,706 311,754 214,754C103,754 38,690 35,608l99,0C136,646 160,679 211,679C251,679 279,657 279,621C279,583 250,563 200,563l-25,0l0,-73l28,0C259,490 290,470 290,425C290,380 260,356 210,356C150,356 124,391 121,435l-100,0C22,344 92,276 216,276z"
      />
      <glyph
        unicode="&#x2DC;"
        horiz-adv-x="500"
        d="M321,621C382,621 414,669 416,744l-75,0C338,722 330,706 310,706C274,706 244,746 184,746C129,746 88,704 87,621l74,0C163,646 177,661 194,661C223,661 264,621 321,621z"
      />
      <glyph
        unicode="&#x2122;"
        horiz-adv-x="920"
        d="M437,362l83,0l0,126C520,553 517,655 517,655l2,0C519,655 541,565 562,496l42,-134l79,0l41,134C746,563 768,655 768,655l3,0C771,655 768,553 768,488l0,-126l85,0l0,382l-131,0l-42,-139C664,553 645,461 645,461l-2,0C643,461 624,553 608,605l-42,139l-129,0M45,666l116,0l0,-304l94,0l0,304l115,0l0,78l-325,0z"
      />
      <glyph
        unicode="2"
        horiz-adv-x="604"
        d="M35,0l524,0l0,114l-352,0l0,5C250,191 353,240 427,297C505,349 565,399 565,504C565,631 472,727 316,727C221,727 148,695 105,642C68,596 49,542 49,466l129,0C178,517 189,555 214,582C236,607 267,620 309,620C385,620 431,572 431,501C431,436 392,402 326,360C256,314 171,264 111,197C66,146 38,87 35,0z"
      />
      <glyph
        unicode="&#x2082;"
        horiz-adv-x="416"
        d="M28,-160l356,0l0,84l-223,0C183,-42 244,-13 288,18C339,53 384,87 384,162C384,241 321,306 216,306C107,306 36,243 36,138l100,0C136,190 165,224 212,224C255,224 280,198 280,158C280,101 219,79 149,30C88,-12 30,-64 28,-160z"
      />
      <glyph
        unicode="&#xB2;"
        horiz-adv-x="416"
        d="M28,287l356,0l0,84l-223,0C183,405 244,434 288,465C339,500 384,534 384,609C384,688 321,753 216,753C107,753 36,691 36,585l100,0C136,637 165,671 212,671C255,671 280,645 280,605C280,548 219,526 149,478C88,435 30,383 28,287z"
      />
      <glyph
        unicode="&#x2154;"
        horiz-adv-x="1019"
        d="M31,287l356,0l0,84l-223,0C186,405 247,434 291,465C342,500 387,534 387,609C387,688 324,753 219,753C110,753 39,691 39,585l100,0C139,637 168,671 215,671C258,671 283,645 283,605C283,548 222,526 152,478C91,435 33,383 31,287M149,-130l95,0l638,874l-96,0M807,-121C906,-121 987,-74 987,22C987,83 954,119 890,135l0,1C941,153 971,181 971,229C971,309 902,357 805,357C694,357 629,293 626,211l99,0C727,249 751,282 802,282C842,282 870,260 870,224C870,186 841,166 791,166l-25,0l0,-73l28,0C850,93 881,73 881,28C881,-17 851,-41 801,-41C741,-41 715,-6 712,38l-100,0C613,-53 683,-121 807,-121z"
      />
      <glyph
        unicode="u"
        horiz-adv-x="583"
        d="M525,0l0,534l-126,0l0,-313C399,147 353,92 281,92C217,92 181,129 181,192l0,342l-127,0l0,-360C54,59 122,-14 239,-14C314,-14 361,18 400,76l2,0l0,-76z"
      />
      <glyph
        unicode="&#xFA;"
        horiz-adv-x="583"
        d="M525,0l0,534l-126,0l0,-313C399,147 353,92 281,92C217,92 181,129 181,192l0,342l-127,0l0,-360C54,59 122,-14 239,-14C314,-14 361,18 400,76l2,0l0,-76M236,618l98,0l105,154l-150,0z"
      />
      <glyph
        unicode="&#x16D;"
        horiz-adv-x="583"
        d="M525,0l0,534l-126,0l0,-313C399,147 353,92 281,92C217,92 181,129 181,192l0,342l-127,0l0,-360C54,59 122,-14 239,-14C314,-14 361,18 400,76l2,0l0,-76M290,613C377,613 435,672 438,755l-77,0C356,720 330,696 289,696C246,696 222,718 217,755l-79,0C142,667 206,613 290,613z"
      />
      <glyph
        unicode="&#xFB;"
        horiz-adv-x="583"
        d="M525,0l0,534l-126,0l0,-313C399,147 353,92 281,92C217,92 181,129 181,192l0,342l-127,0l0,-360C54,59 122,-14 239,-14C314,-14 361,18 400,76l2,0l0,-76M120,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0z"
      />
      <glyph
        unicode="&#xFC;"
        horiz-adv-x="583"
        d="M525,0l0,534l-126,0l0,-313C399,147 353,92 281,92C217,92 181,129 181,192l0,342l-127,0l0,-360C54,59 122,-14 239,-14C314,-14 361,18 400,76l2,0l0,-76M117,621l122,0l0,123l-122,0M339,621l122,0l0,123l-122,0z"
      />
      <glyph
        unicode="&#xF9;"
        horiz-adv-x="583"
        d="M525,0l0,534l-126,0l0,-313C399,147 353,92 281,92C217,92 181,129 181,192l0,342l-127,0l0,-360C54,59 122,-14 239,-14C314,-14 361,18 400,76l2,0l0,-76M246,618l98,0l-53,154l-151,0z"
      />
      <glyph
        unicode="&#x171;"
        horiz-adv-x="583"
        d="M525,0l0,534l-126,0l0,-313C399,147 353,92 281,92C217,92 181,129 181,192l0,342l-127,0l0,-360C54,59 122,-14 239,-14C314,-14 361,18 400,76l2,0l0,-76M158,618l85,0l83,154l-126,0M329,618l84,0l82,154l-125,0z"
      />
      <glyph
        unicode="&#x16B;"
        horiz-adv-x="583"
        d="M525,0l0,534l-126,0l0,-313C399,147 353,92 281,92C217,92 181,129 181,192l0,342l-127,0l0,-360C54,59 122,-14 239,-14C314,-14 361,18 400,76l2,0l0,-76M141,636l294,0l0,94l-294,0z"
      />
      <glyph unicode="_" horiz-adv-x="437" d="M-2,-175l441,0l0,85l-441,0z" />
      <glyph unicode="&#xA0;" horiz-adv-x="241" />
      <glyph unicode="&#xAD;" horiz-adv-x="368" d="M44,218l280,0l0,112l-280,0z" />
      <glyph
        unicode="&#x162;"
        horiz-adv-x="661"
        d="M29,625l232,0l0,-625l139,0l0,625l233,0l0,119l-604,0M397,-51l-131,0l0,-100l61,0C327,-183 303,-199 264,-206l0,-49C341,-250 397,-213 397,-131z"
      />
      <glyph
        unicode="&#x163;"
        horiz-adv-x="371"
        d="M26,447l82,0l0,-327C108,26 167,-5 253,-5C287,-5 316,-1 337,3l0,99l-6,0C321,100 303,98 290,98C254,98 233,109 233,148l0,299l107,0l0,87l-107,0l0,170l-125,0l0,-170l-82,0M279,-51l-131,0l0,-100l61,0C209,-183 185,-199 146,-206l0,-49C223,-250 279,-213 279,-131z"
      />
      <glyph
        unicode="&#x21A;"
        horiz-adv-x="661"
        d="M29,625l232,0l0,-625l139,0l0,625l233,0l0,119l-604,0M397,-51l-131,0l0,-100l61,0C327,-183 303,-199 264,-206l0,-49C341,-250 397,-213 397,-131z"
      />
      <glyph
        unicode="&#x21B;"
        horiz-adv-x="371"
        d="M26,447l82,0l0,-327C108,26 167,-5 253,-5C287,-5 316,-1 337,3l0,99l-6,0C321,100 303,98 290,98C254,98 233,109 233,148l0,299l107,0l0,87l-107,0l0,170l-125,0l0,-170l-82,0M279,-51l-131,0l0,-100l61,0C209,-183 185,-199 146,-206l0,-49C223,-250 279,-213 279,-131z"
      />
      <glyph
        unicode="&#x237;"
        horiz-adv-x="243"
        d="M42,-176C84,-176 120,-167 147,-143C170,-122 185,-89 185,-28l0,562l-127,0l0,-557C58,-61 41,-74 8,-76C-2,-77 -13,-76 -25,-76l-5,0l0,-93C-11,-173 17,-176 42,-176z"
      />
      <glyph unicode="&#x2C9;" horiz-adv-x="500" d="M104,636l294,0l0,94l-294,0z" />
      <glyph
        unicode="&#x394;"
        horiz-adv-x="716"
        d="M28,0l661,0l0,101l-253,643l-157,0l-251,-643M303,463C336,551 354,634 354,634l2,0C356,634 373,551 406,463l135,-354l-373,0z"
      />
      <glyph
        unicode="&#x3A9;"
        horiz-adv-x="775"
        d="M43,0l306,0l0,104C229,124 177,243 177,386C177,544 256,644 388,644C519,644 598,544 598,386C598,243 547,123 426,104l0,-104l306,0l0,109l-126,0l0,2C692,166 739,260 739,391C739,609 595,759 388,759C180,759 37,609 37,391C37,260 82,166 168,111l0,-2l-125,0z"
      />
      <glyph
        unicode="&#x3BC;"
        horiz-adv-x="587"
        d="M58,-179l127,0l0,93C185,-32 182,14 182,14l2,0C199,-2 221,-10 255,-10C325,-10 372,25 404,76l2,0l0,-76l123,0l0,534l-126,0l0,-313C403,147 357,92 285,92C221,92 185,129 185,192l0,342l-127,0z"
      />
      <glyph unicode="&#x2009;" horiz-adv-x="110" />
      <glyph unicode="&#x2032;" horiz-adv-x="220" d="M31,483l113,0l45,261l-112,0z" />
      <glyph unicode="&#x2033;" horiz-adv-x="404" d="M215,483l113,0l44,261l-112,0M31,483l113,0l45,261l-112,0z" />
      <glyph unicode="&#x2215;" horiz-adv-x="253" d="M-276,-130l95,0l638,874l-96,0z" />
      <glyph unicode="&#x2219;" horiz-adv-x="246" d="M53,258l140,0l0,140l-140,0z" />
      <glyph
        unicode="&#x24C5;"
        horiz-adv-x="865"
        d="M433,-18C647,-18 815,147 815,369C815,592 647,757 433,757C219,757 51,592 51,369C51,147 219,-18 433,-18M433,28C246,28 105,174 105,369C105,565 246,711 433,711C619,711 759,565 759,369C759,174 619,28 433,28M284,163l95,0l0,148l103,0C569,311 622,366 622,446C622,485 608,519 584,544C558,569 521,583 472,583l-188,0M379,505l83,0C504,505 528,483 528,444C528,407 504,385 461,385l-82,0z"
      />
      <glyph
        unicode="&#xF8FF;"
        horiz-adv-x="880"
        d="M68,0l745,0l0,745l-745,0M190,448l175,0l0,170l150,0l0,-170l175,0l0,-149l-175,0l0,-176l-150,0l0,176l-175,0z"
      />
      <glyph
        unicode="&#x173;"
        horiz-adv-x="583"
        d="M468,-187C501,-187 521,-183 534,-178l0,72l-3,0C522,-109 510,-111 497,-111C473,-111 459,-102 459,-80C459,-54 484,-21 525,1l0,533l-126,0l0,-313C399,147 353,92 281,92C217,92 181,129 181,192l0,342l-127,0l0,-360C54,59 122,-14 239,-14C314,-14 361,18 400,76l2,0l0,-76l26,0C392,-18 353,-51 353,-101C353,-153 392,-187 468,-187z"
      />
      <glyph
        unicode="&#x16F;"
        horiz-adv-x="583"
        d="M525,0l0,534l-126,0l0,-313C399,147 353,92 281,92C217,92 181,129 181,192l0,342l-127,0l0,-360C54,59 122,-14 239,-14C314,-14 361,18 400,76l2,0l0,-76M290,597C349,597 398,643 398,703C398,761 349,809 290,809C229,809 180,761 180,703C180,643 229,597 290,597M290,647C257,647 235,671 235,703C235,735 257,760 290,760C321,760 343,735 343,703C343,671 321,647 290,647z"
      />
      <glyph
        unicode="&#x169;"
        horiz-adv-x="583"
        d="M525,0l0,534l-126,0l0,-313C399,147 353,92 281,92C217,92 181,129 181,192l0,342l-127,0l0,-360C54,59 122,-14 239,-14C314,-14 361,18 400,76l2,0l0,-76M358,621C419,621 451,669 453,744l-75,0C375,722 367,706 347,706C311,706 281,746 221,746C166,746 125,704 124,621l74,0C200,646 214,661 231,661C260,661 301,621 358,621z"
      />
      <glyph
        unicode="v"
        horiz-adv-x="523"
        d="M190,0l139,0l189,534l-129,0l-81,-249C285,213 264,106 264,106l-3,0C261,106 239,213 216,285l-78,249l-133,0z"
      />
      <glyph
        unicode="w"
        horiz-adv-x="803"
        d="M175,0l122,0l69,254C380,310 400,411 400,411l2,0C402,411 421,310 435,254l67,-254l122,0l170,534l-128,0l-71,-249C576,220 561,123 561,123l-2,0C559,123 541,224 525,285l-68,249l-112,0l-66,-249C261,221 244,124 244,124l-2,0C242,124 226,223 209,285l-69,249l-132,0z"
      />
      <glyph
        unicode="&#x1E83;"
        horiz-adv-x="803"
        d="M348,618l98,0l105,154l-150,0M175,0l122,0l69,254C380,310 400,411 400,411l2,0C402,411 421,310 435,254l67,-254l122,0l170,534l-128,0l-71,-249C576,220 561,123 561,123l-2,0C559,123 541,224 525,285l-68,249l-112,0l-66,-249C261,221 244,124 244,124l-2,0C242,124 226,223 209,285l-69,249l-132,0z"
      />
      <glyph
        unicode="&#x175;"
        horiz-adv-x="803"
        d="M232,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0M175,0l122,0l69,254C380,310 400,411 400,411l2,0C402,411 421,310 435,254l67,-254l122,0l170,534l-128,0l-71,-249C576,220 561,123 561,123l-2,0C559,123 541,224 525,285l-68,249l-112,0l-66,-249C261,221 244,124 244,124l-2,0C242,124 226,223 209,285l-69,249l-132,0z"
      />
      <glyph
        unicode="&#x1E85;"
        horiz-adv-x="803"
        d="M451,621l122,0l0,123l-122,0M229,621l122,0l0,123l-122,0M175,0l122,0l69,254C380,310 400,411 400,411l2,0C402,411 421,310 435,254l67,-254l122,0l170,534l-128,0l-71,-249C576,220 561,123 561,123l-2,0C559,123 541,224 525,285l-68,249l-112,0l-66,-249C261,221 244,124 244,124l-2,0C242,124 226,223 209,285l-69,249l-132,0z"
      />
      <glyph
        unicode="&#x1E81;"
        horiz-adv-x="803"
        d="M175,0l122,0l69,254C380,310 400,411 400,411l2,0C402,411 421,310 435,254l67,-254l122,0l170,534l-128,0l-71,-249C576,220 561,123 561,123l-2,0C559,123 541,224 525,285l-68,249l-112,0l-66,-249C261,221 244,124 244,124l-2,0C242,124 226,223 209,285l-69,249l-132,0M358,618l98,0l-53,154l-151,0z"
      />
      <glyph
        unicode="x"
        horiz-adv-x="576"
        d="M18,0l140,0l71,107C252,145 284,208 284,208l2,0C286,208 317,145 340,107l70,-107l147,0l-196,286l169,248l-136,0l-54,-84C319,415 290,355 290,355l-2,0C288,355 260,414 238,450l-53,84l-146,0l173,-254z"
      />
      <glyph
        unicode="y"
        horiz-adv-x="539"
        d="M150,-182C237,-182 283,-148 327,-30l207,564l-128,0l-85,-248C299,221 275,120 275,120l-2,0C273,120 251,220 228,286l-88,248l-135,0l184,-464C200,41 195,-9 195,-19C184,-62 160,-78 117,-78C104,-78 69,-76 64,-73l-7,0l0,-103C66,-179 134,-182 150,-182z"
      />
      <glyph
        unicode="&#xFD;"
        horiz-adv-x="539"
        d="M150,-182C237,-182 283,-148 327,-30l207,564l-128,0l-85,-248C299,221 275,120 275,120l-2,0C273,120 251,220 228,286l-88,248l-135,0l184,-464C200,41 195,-9 195,-19C184,-62 160,-78 117,-78C104,-78 69,-76 64,-73l-7,0l0,-103C66,-179 134,-182 150,-182M220,618l98,0l105,154l-150,0z"
      />
      <glyph
        unicode="&#x177;"
        horiz-adv-x="539"
        d="M150,-182C237,-182 283,-148 327,-30l207,564l-128,0l-85,-248C299,221 275,120 275,120l-2,0C273,120 251,220 228,286l-88,248l-135,0l184,-464C200,41 195,-9 195,-19C184,-62 160,-78 117,-78C104,-78 69,-76 64,-73l-7,0l0,-103C66,-179 134,-182 150,-182M104,621l116,0l51,77l2,0l49,-77l116,0l-102,136l-129,0z"
      />
      <glyph
        unicode="&#xFF;"
        horiz-adv-x="539"
        d="M150,-182C237,-182 283,-148 327,-30l207,564l-128,0l-85,-248C299,221 275,120 275,120l-2,0C273,120 251,220 228,286l-88,248l-135,0l184,-464C200,41 195,-9 195,-19C184,-62 160,-78 117,-78C104,-78 69,-76 64,-73l-7,0l0,-103C66,-179 134,-182 150,-182M323,621l122,0l0,123l-122,0M101,621l122,0l0,123l-122,0z"
      />
      <glyph
        unicode="&#xA5;"
        horiz-adv-x="646"
        d="M79,274l181,0l0,-76l-181,0l0,-61l181,0l0,-137l129,0l0,137l181,0l0,61l-181,0l0,76l181,0l0,61l-160,0l221,378l-143,0l-79,-148C374,500 326,387 326,387l-2,0C324,387 278,500 242,565l-79,148l-147,0l221,-378l-158,0z"
      />
      <glyph
        unicode="&#x1EF3;"
        horiz-adv-x="539"
        d="M150,-182C237,-182 283,-148 327,-30l207,564l-128,0l-85,-248C299,221 275,120 275,120l-2,0C273,120 251,220 228,286l-88,248l-135,0l184,-464C200,41 195,-9 195,-19C184,-62 160,-78 117,-78C104,-78 69,-76 64,-73l-7,0l0,-103C66,-179 134,-182 150,-182M231,618l98,0l-53,154l-151,0z"
      />
      <glyph
        unicode="z"
        horiz-adv-x="526"
        d="M40,0l452,0l0,104l-194,0C254,104 182,99 182,99l0,3C182,102 237,153 267,187l212,260l0,87l-419,0l0,-102l162,0C263,432 330,438 330,438l0,-3C330,435 280,388 253,357l-213,-261z"
      />
      <glyph
        unicode="&#x17A;"
        horiz-adv-x="526"
        d="M219,618l98,0l105,154l-150,0M40,0l452,0l0,104l-194,0C254,104 182,99 182,99l0,3C182,102 237,153 267,187l212,260l0,87l-419,0l0,-102l162,0C263,432 330,438 330,438l0,-3C330,435 280,388 253,357l-213,-261z"
      />
      <glyph
        unicode="&#x17E;"
        horiz-adv-x="526"
        d="M439,755l-116,0l-51,-77l-2,0l-49,77l-116,0l102,-137l129,0M40,0l452,0l0,104l-194,0C254,104 182,99 182,99l0,3C182,102 237,153 267,187l212,260l0,87l-419,0l0,-102l162,0C263,432 330,438 330,438l0,-3C330,435 280,388 253,357l-213,-261z"
      />
      <glyph
        unicode="&#x17C;"
        horiz-adv-x="526"
        d="M40,0l452,0l0,104l-194,0C254,104 182,99 182,99l0,3C182,102 237,153 267,187l212,260l0,87l-419,0l0,-102l162,0C263,432 330,438 330,438l0,-3C330,435 280,388 253,357l-213,-261M207,621l130,0l0,123l-130,0z"
      />
      <glyph
        unicode="0"
        horiz-adv-x="680"
        d="M339,-17C523,-17 636,129 636,356C636,586 520,728 340,728C163,728 44,584 44,356C44,129 160,-17 339,-17M340,91C230,91 180,196 180,356C180,517 230,622 340,622C451,622 500,516 500,356C500,194 453,91 340,91z"
      />
      <glyph
        unicode="&#x2080;"
        horiz-adv-x="479"
        d="M240,-171C360,-171 445,-78 445,69C445,215 360,307 240,307C122,307 34,214 34,69C34,-78 121,-171 240,-171M240,-90C174,-90 144,-25 144,69C144,162 174,227 240,227C306,227 336,161 336,69C336,-26 307,-90 240,-90z"
      />
      <glyph
        unicode="&#x2070;"
        horiz-adv-x="479"
        d="M240,276C360,276 445,369 445,516C445,662 360,754 240,754C122,754 34,661 34,516C34,369 121,276 240,276M240,358C174,358 144,422 144,516C144,609 174,674 240,674C306,674 336,608 336,516C336,421 307,358 240,358z"
      />
    </font>

    <font horiz-adv-x="1000">
      <!-- Copyright &#xA9; 2011 - 2012 Linotype Corp., www.linotype.com. All rights reserved. This font software may not be reproduced, modified, disclosed or transferred without the express written approval of Linotype Corp. Neue Haas Grotesk is a trademark of Linotype Corp. registered in the U.S. Patent and Trademark Office and may be registered in certain other jurisdictions in the name of Linotype Corp. or its licensee Linotype GmbH. This typeface is original artwork of Christian Schwartz. The design may be protected in certain jurisdictions. -->
      <!-- Copyright: Copyright 2022 Adobe System Incorporated. All rights reserved. -->
      <font-face
        font-family="NHaasGroteskTXPro-75Bd"
        units-per-em="1000"
        underline-position="-100"
        underline-thickness="50"
      />
      <missing-glyph horiz-adv-x="500" />
      <glyph
        unicode="A"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="&#xC6;"
        horiz-adv-x="1045"
        d="M993,744l-728,0l-255,-744l169,0l48,145l231,0l0,-145l547,0l0,150l-375,0l0,168l311,0l0,134l-311,0l0,149l363,0M377,601l81,0l0,-335l-191,0z"
      />
      <glyph
        unicode="&#x1FC;"
        horiz-adv-x="1045"
        d="M993,744l-728,0l-255,-744l169,0l48,145l231,0l0,-145l547,0l0,150l-375,0l0,168l311,0l0,134l-311,0l0,149l363,0M377,601l81,0l0,-335l-191,0M543,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#xC1;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0M298,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x102;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M370,779C466,779 525,842 528,921l-89,0C432,900 410,876 369,876C325,876 304,897 297,921l-91,0C210,839 275,779 370,779M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="&#xC2;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M179,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="&#xC4;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0M169,785l153,0l0,134l-153,0M414,785l152,0l0,134l-152,0z"
      />
      <glyph
        unicode="&#xC0;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0M303,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="&#x100;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M211,789l312,0l0,111l-312,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="&#x104;"
        horiz-adv-x="736"
        d="M647,-184C683,-184 704,-180 717,-175l0,79l-2,0C707,-98 698,-100 686,-100C665,-100 653,-92 653,-73C653,-49 674,-18 722,5l-267,739l-177,0l-268,-744l171,0l46,142l270,0l47,-142l61,0C569,-16 526,-47 526,-100C526,-151 567,-184 647,-184M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="&#xC5;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M368,776C430,776 482,823 482,886C482,947 430,997 368,997C305,997 253,947 253,886C253,823 305,776 368,776M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0M368,833C336,833 315,856 315,886C315,916 336,941 368,941C399,941 420,916 420,886C420,856 399,833 368,833z"
      />
      <glyph
        unicode="&#xC3;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M440,786C506,786 545,841 545,924l-86,0C456,897 445,886 425,886C392,886 361,926 298,926C240,926 190,879 190,785l86,0C278,813 293,826 310,826C340,826 379,786 440,786M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="B"
        horiz-adv-x="710"
        d="M63,0l361,0C503,0 564,19 608,55C655,92 681,146 681,217C681,308 628,375 532,398l0,4C612,427 652,477 652,551C652,610 630,657 589,693C551,726 492,744 415,744l-352,0M232,445l0,167l161,0C451,612 488,582 488,528C488,473 449,445 391,445M232,134l0,192l171,0C465,326 512,294 512,229C512,201 502,177 485,161C467,144 440,134 406,134z"
      />
      <glyph
        unicode="C"
        horiz-adv-x="749"
        d="M397,-17C501,-17 577,12 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,147 180,-17 397,-17z"
      />
      <glyph
        unicode="&#x106;"
        horiz-adv-x="749"
        d="M397,-17C501,-17 577,12 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,147 180,-17 397,-17M316,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x10C;"
        horiz-adv-x="749"
        d="M397,-17C501,-17 577,12 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,147 180,-17 397,-17M572,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#xC7;"
        horiz-adv-x="749"
        d="M359,-210C435,-210 487,-178 487,-128C487,-72 433,-48 374,-57l0,2l43,39C511,-13 580,15 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,161 161,5 355,-15l-57,-61l18,-26C326,-98 336,-95 349,-95C369,-95 384,-104 384,-124C384,-148 362,-157 335,-157C310,-157 286,-150 266,-141l-20,-39C273,-197 312,-210 359,-210z"
      />
      <glyph
        unicode="&#x108;"
        horiz-adv-x="749"
        d="M397,-17C501,-17 577,12 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,147 180,-17 397,-17M197,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#x10A;"
        horiz-adv-x="749"
        d="M397,-17C501,-17 577,12 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,147 180,-17 397,-17M306,785l159,0l0,135l-159,0z"
      />
      <glyph
        unicode="D"
        horiz-adv-x="748"
        d="M65,0l300,0C593,0 718,146 718,367C718,595 584,744 375,744l-310,0M236,144l0,456l119,0C480,600 544,508 544,367C544,225 481,144 352,144z"
      />
      <glyph
        unicode="&#x10E;"
        horiz-adv-x="748"
        d="M65,0l300,0C593,0 718,146 718,367C718,595 584,744 375,744l-310,0M236,144l0,456l119,0C480,600 544,508 544,367C544,225 481,144 352,144M533,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x110;"
        horiz-adv-x="748"
        d="M5,311l60,0l0,-311l300,0C593,0 718,146 718,367C718,595 584,744 375,744l-310,0l0,-300l-60,0M234,144l0,167l171,0l0,133l-171,0l0,156l121,0C480,600 544,508 544,367C544,225 481,144 352,144z"
      />
      <glyph
        unicode="&#x2206;"
        horiz-adv-x="723"
        d="M27,0l669,0l0,112l-241,632l-188,0l-240,-632M203,132l102,283C339,509 356,605 356,605l2,0C358,605 374,509 408,415l103,-283z"
      />
      <glyph
        unicode="E"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0z"
      />
      <glyph
        unicode="&#xC9;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M283,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x114;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M355,779C451,779 510,842 513,921l-89,0C417,900 395,876 354,876C310,876 289,897 282,921l-91,0C195,839 260,779 355,779z"
      />
      <glyph
        unicode="&#x11A;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M539,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#xCA;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M164,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#xCB;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M154,785l153,0l0,134l-153,0M399,785l152,0l0,134l-152,0z"
      />
      <glyph
        unicode="&#x116;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M273,785l159,0l0,135l-159,0z"
      />
      <glyph
        unicode="&#xC8;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M288,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="&#x112;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M196,789l312,0l0,111l-312,0z"
      />
      <glyph
        unicode="&#x14A;"
        horiz-adv-x="753"
        d="M432,-44C415,-44 405,-43 388,-40l-7,0l0,-138C410,-183 433,-185 463,-185C557,-185 617,-154 652,-104C681,-63 691,-16 691,46l0,698l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0l0,-744l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l195,-321C503,-29 476,-44 432,-44z"
      />
      <glyph
        unicode="&#x118;"
        horiz-adv-x="670"
        d="M63,0l399,0C425,-19 393,-51 393,-100C393,-151 434,-184 514,-184C550,-184 571,-180 584,-175l0,79l-2,0C574,-98 565,-100 553,-100C532,-100 520,-92 520,-73C520,-50 540,-23 569,0l59,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0z"
      />
      <glyph
        unicode="&#xD0;"
        horiz-adv-x="752"
        d="M9,311l60,0l0,-311l300,0C597,0 722,146 722,367C722,595 588,744 379,744l-310,0l0,-300l-60,0M238,144l0,167l171,0l0,133l-171,0l0,156l121,0C484,600 548,508 548,367C548,225 485,144 356,144z"
      />
      <glyph
        unicode="&#x20AC;"
        horiz-adv-x="687"
        d="M27,388l45,0C71,377 71,366 71,355C71,341 71,328 72,315l-45,0l0,-65l54,0C115,87 229,-17 387,-17C558,-17 655,95 663,249l-156,0C500,173 462,115 387,115C313,115 267,163 248,250l165,0l0,65l-174,0C238,327 238,341 238,355C238,366 238,377 239,388l174,0l0,65l-167,0C263,542 307,599 381,599C449,599 486,552 497,480l160,0C645,624 551,727 385,727C226,727 112,616 80,453l-53,0z"
      />
      <glyph unicode="F" horiz-adv-x="640" d="M63,0l170,0l0,298l314,0l0,139l-314,0l0,162l376,0l0,145l-546,0z" />
      <glyph
        unicode="G"
        horiz-adv-x="784"
        d="M617,0l118,0l0,398l-311,0l0,-127l150,0l0,-3C573,193 510,122 405,122C283,122 207,226 207,369C207,509 271,616 403,616C488,616 535,570 553,515l170,0C696,657 586,757 398,757C300,757 223,728 163,677C80,607 34,496 34,369C34,255 68,157 133,87C194,22 280,-17 386,-17C477,-17 556,18 600,100l3,0z"
      />
      <glyph
        unicode="&#x11E;"
        horiz-adv-x="784"
        d="M617,0l118,0l0,398l-311,0l0,-127l150,0l0,-3C573,193 510,122 405,122C283,122 207,226 207,369C207,509 271,616 403,616C488,616 535,570 553,515l170,0C696,657 586,757 398,757C300,757 223,728 163,677C80,607 34,496 34,369C34,255 68,157 133,87C194,22 280,-17 386,-17C477,-17 556,18 600,100l3,0M390,779C486,779 545,842 548,921l-89,0C452,900 430,876 389,876C345,876 324,897 317,921l-91,0C230,839 295,779 390,779z"
      />
      <glyph
        unicode="&#x11C;"
        horiz-adv-x="784"
        d="M617,0l118,0l0,398l-311,0l0,-127l150,0l0,-3C573,193 510,122 405,122C283,122 207,226 207,369C207,509 271,616 403,616C488,616 535,570 553,515l170,0C696,657 586,757 398,757C300,757 223,728 163,677C80,607 34,496 34,369C34,255 68,157 133,87C194,22 280,-17 386,-17C477,-17 556,18 600,100l3,0M199,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#x122;"
        horiz-adv-x="784"
        d="M617,0l118,0l0,398l-311,0l0,-127l150,0l0,-3C573,193 510,122 405,122C283,122 207,226 207,369C207,509 271,616 403,616C488,616 535,570 553,515l170,0C696,657 586,757 398,757C300,757 223,728 163,677C80,607 34,496 34,369C34,255 68,157 133,87C194,22 280,-17 386,-17C477,-17 556,18 600,100l3,0M310,-263C397,-257 465,-217 465,-122l0,71l-151,0l0,-103l67,0C381,-184 352,-202 310,-210z"
      />
      <glyph
        unicode="&#x120;"
        horiz-adv-x="784"
        d="M617,0l118,0l0,398l-311,0l0,-127l150,0l0,-3C573,193 510,122 405,122C283,122 207,226 207,369C207,509 271,616 403,616C488,616 535,570 553,515l170,0C696,657 586,757 398,757C300,757 223,728 163,677C80,607 34,496 34,369C34,255 68,157 133,87C194,22 280,-17 386,-17C477,-17 556,18 600,100l3,0M308,785l159,0l0,135l-159,0z"
      />
      <glyph
        unicode="H"
        horiz-adv-x="747"
        d="M63,0l170,0l0,315l281,0l0,-315l170,0l0,744l-170,0l0,-287l-281,0l0,287l-170,0z"
      />
      <glyph
        unicode="&#x126;"
        horiz-adv-x="747"
        d="M2,538l61,0l0,-538l170,0l0,315l281,0l0,-315l170,0l0,538l60,0l0,91l-60,0l0,115l-170,0l0,-115l-281,0l0,115l-170,0l0,-115l-61,0M233,538l281,0l0,-87l-281,0z"
      />
      <glyph
        unicode="&#x124;"
        horiz-adv-x="747"
        d="M63,0l170,0l0,315l281,0l0,-315l170,0l0,744l-170,0l0,-287l-281,0l0,287l-170,0M185,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph unicode="I" horiz-adv-x="298" d="M63,0l171,0l0,744l-171,0z" />
      <glyph
        unicode="&#x132;"
        horiz-adv-x="860"
        d="M63,0l171,0l0,744l-171,0M561,-14C651,-14 718,17 758,67C790,105 805,155 805,217l0,527l-171,0l0,-523C634,161 608,127 551,127C495,127 463,155 463,229l0,53l-157,0l0,-57C306,78 391,-14 561,-14z"
      />
      <glyph unicode="&#xCD;" horiz-adv-x="298" d="M63,0l171,0l0,744l-171,0M79,785l123,0l109,131l-189,0z" />
      <glyph
        unicode="&#x12C;"
        horiz-adv-x="298"
        d="M63,0l171,0l0,744l-171,0M151,779C247,779 306,842 309,921l-89,0C213,900 191,876 150,876C106,876 85,897 78,921l-91,0C-9,839 56,779 151,779z"
      />
      <glyph
        unicode="&#xCE;"
        horiz-adv-x="298"
        d="M63,0l171,0l0,744l-171,0M-40,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#xCF;"
        horiz-adv-x="298"
        d="M63,0l171,0l0,744l-171,0M185,785l142,0l0,134l-142,0M-30,785l143,0l0,134l-143,0z"
      />
      <glyph unicode="&#x130;" horiz-adv-x="298" d="M63,0l171,0l0,744l-171,0M69,785l159,0l0,135l-159,0z" />
      <glyph unicode="&#xCC;" horiz-adv-x="298" d="M63,0l171,0l0,744l-171,0M84,785l122,0l-43,131l-189,0z" />
      <glyph unicode="&#x12A;" horiz-adv-x="298" d="M63,0l171,0l0,744l-171,0M4,789l288,0l0,111l-288,0z" />
      <glyph
        unicode="&#x12E;"
        horiz-adv-x="298"
        d="M63,0l56,0C84,-18 47,-52 47,-100C47,-151 88,-184 168,-184C204,-184 225,-180 238,-175l0,79l-2,0C228,-98 219,-100 207,-100C186,-100 174,-92 174,-73C174,-49 195,-17 234,2l0,742l-171,0z"
      />
      <glyph
        unicode="&#x128;"
        horiz-adv-x="298"
        d="M63,0l171,0l0,744l-171,0M221,786C287,786 326,841 326,924l-86,0C237,897 226,886 206,886C173,886 142,926 79,926C21,926 -29,879 -29,785l86,0C59,813 74,826 91,826C121,826 160,786 221,786z"
      />
      <glyph
        unicode="J"
        horiz-adv-x="579"
        d="M280,-14C370,-14 437,17 477,67C509,105 524,155 524,217l0,527l-171,0l0,-523C353,161 327,127 270,127C214,127 182,155 182,229l0,53l-157,0l0,-57C25,78 110,-14 280,-14z"
      />
      <glyph
        unicode="&#x134;"
        horiz-adv-x="579"
        d="M280,-14C370,-14 437,17 477,67C509,105 524,155 524,217l0,527l-171,0l0,-523C353,161 327,127 270,127C214,127 182,155 182,229l0,53l-157,0l0,-57C25,78 110,-14 280,-14M246,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="K"
        horiz-adv-x="736"
        d="M63,0l170,0l0,266l68,71l227,-337l199,0l-305,445l287,299l-204,0l-278,-298C229,486 233,554 233,622l0,122l-170,0z"
      />
      <glyph
        unicode="&#x136;"
        horiz-adv-x="736"
        d="M63,0l170,0l0,266l68,71l227,-337l199,0l-305,445l287,299l-204,0l-278,-298C229,486 233,554 233,622l0,122l-170,0M290,-263C377,-257 445,-217 445,-122l0,71l-151,0l0,-103l67,0C361,-184 332,-202 290,-210z"
      />
      <glyph unicode="L" horiz-adv-x="604" d="M63,0l513,0l0,146l-343,0l0,598l-170,0z" />
      <glyph
        unicode="&#x139;"
        horiz-adv-x="604"
        d="M63,0l513,0l0,146l-343,0l0,598l-170,0M78,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x13D;"
        horiz-adv-x="604"
        d="M359,513C453,517 508,559 508,661l0,83l-149,0l0,-118l68,0C426,595 404,571 359,567M63,0l513,0l0,146l-343,0l0,598l-170,0z"
      />
      <glyph
        unicode="&#x13B;"
        horiz-adv-x="604"
        d="M63,0l513,0l0,146l-343,0l0,598l-170,0M244,-263C331,-257 399,-217 399,-122l0,71l-151,0l0,-103l67,0C315,-184 286,-202 244,-210z"
      />
      <glyph
        unicode="&#x13F;"
        horiz-adv-x="604"
        d="M63,0l513,0l0,146l-343,0l0,598l-170,0M382,292l142,0l0,140l-142,0z"
      />
      <glyph
        unicode="&#x141;"
        horiz-adv-x="610"
        d="M5,195l64,33l0,-228l513,0l0,146l-343,0l0,172l209,110l0,116l-209,-110l0,310l-170,0l0,-400l-64,-33z"
      />
      <glyph
        unicode="M"
        horiz-adv-x="884"
        d="M63,0l153,0l0,333C216,427 204,601 204,601l2,0C206,601 238,442 264,354l107,-354l140,0l106,354C643,442 675,601 675,601l2,0C677,601 665,427 665,333l0,-333l157,0l0,744l-232,0l-91,-310C472,341 444,169 444,169l-3,0C441,169 412,341 385,434l-93,310l-229,0z"
      />
      <glyph
        unicode="N"
        horiz-adv-x="753"
        d="M63,0l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l194,-319l175,0l0,744l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0z"
      />
      <glyph
        unicode="&#x143;"
        horiz-adv-x="753"
        d="M63,0l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l194,-319l175,0l0,744l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0M315,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x147;"
        horiz-adv-x="753"
        d="M63,0l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l194,-319l175,0l0,744l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0M571,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x145;"
        horiz-adv-x="753"
        d="M63,0l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l194,-319l175,0l0,744l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0M291,-263C378,-257 446,-217 446,-122l0,71l-151,0l0,-103l67,0C362,-184 333,-202 291,-210z"
      />
      <glyph
        unicode="&#xD1;"
        horiz-adv-x="753"
        d="M63,0l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l194,-319l175,0l0,744l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0M457,786C523,786 562,841 562,924l-86,0C473,897 462,886 442,886C409,886 378,926 315,926C257,926 207,879 207,785l86,0C295,813 310,826 327,826C357,826 396,786 457,786z"
      />
      <glyph
        unicode="O"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122z"
      />
      <glyph
        unicode="&#x152;"
        horiz-adv-x="1137"
        d="M386,-18C470,-18 530,14 572,68l0,-68l523,0l0,150l-372,0l0,168l308,0l0,134l-308,0l0,149l361,0l0,143l-512,0l0,-76C530,723 470,757 386,757C176,757 32,592 32,369C32,147 176,-18 386,-18M395,122C272,122 206,229 206,369C206,510 272,619 395,619C503,619 561,531 561,393l0,-49C561,208 503,122 395,122z"
      />
      <glyph
        unicode="&#xD3;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M330,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x14E;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M402,779C498,779 557,842 560,921l-89,0C464,900 442,876 401,876C357,876 336,897 329,921l-91,0C242,839 307,779 402,779z"
      />
      <glyph
        unicode="&#xD4;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M211,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#xD6;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M446,785l152,0l0,134l-152,0M201,785l153,0l0,134l-153,0z"
      />
      <glyph
        unicode="&#xD2;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M335,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="&#x150;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M443,785l102,0l89,131l-156,0M246,785l102,0l90,131l-156,0z"
      />
      <glyph
        unicode="&#x14C;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M243,789l312,0l0,111l-312,0z"
      />
      <glyph
        unicode="&#x2126;"
        horiz-adv-x="780"
        d="M40,0l315,0l0,125C250,142 205,245 205,383C205,530 272,619 390,619C508,619 575,530 575,383C575,245 530,142 425,125l0,-125l315,0l0,130l-114,0l0,2C705,185 748,274 748,395C748,608 602,757 390,757C178,757 32,608 32,395C32,274 74,185 153,132l0,-2l-113,0z"
      />
      <glyph
        unicode="&#xD8;"
        horiz-adv-x="799"
        d="M84,-45l89,99C233,8 309,-18 400,-18C625,-18 767,147 767,369C767,470 737,560 684,627l90,102l-60,51l-86,-97C568,730 490,757 400,757C174,757 32,592 32,369C32,267 62,177 116,110l-92,-103M206,369C206,510 272,619 401,619C455,619 497,601 528,571l-300,-335C213,274 206,319 206,369M401,122C347,122 305,138 274,167l298,335C587,464 594,418 594,369C594,229 529,122 401,122z"
      />
      <glyph
        unicode="&#x1FE;"
        horiz-adv-x="799"
        d="M84,-45l89,99C233,8 309,-18 400,-18C625,-18 767,147 767,369C767,470 737,560 684,627l90,102l-60,51l-86,-97C568,730 490,757 400,757C174,757 32,592 32,369C32,267 62,177 116,110l-92,-103M206,369C206,510 272,619 401,619C455,619 497,601 528,571l-300,-335C213,274 206,319 206,369M401,122C347,122 305,138 274,167l298,335C587,464 594,418 594,369C594,229 529,122 401,122M330,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#xD5;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M472,786C538,786 577,841 577,924l-86,0C488,897 477,886 457,886C424,886 393,926 330,926C272,926 222,879 222,785l86,0C310,813 325,826 342,826C372,826 411,786 472,786z"
      />
      <glyph
        unicode="P"
        horiz-adv-x="686"
        d="M63,0l173,0l0,258l178,0C568,258 664,356 664,497C664,570 638,630 593,675C548,720 482,744 394,744l-331,0M236,599l146,0C452,599 492,562 492,497C492,434 453,393 381,393l-145,0z"
      />
      <glyph
        unicode="Q"
        horiz-adv-x="798"
        d="M692,-63l74,80l-92,84C733,170 765,262 765,369C765,592 625,757 399,757C173,757 32,592 32,369C32,147 173,-18 399,-18C472,-18 537,0 590,31M400,119C271,119 206,229 206,369C206,510 271,619 400,619C528,619 592,510 592,369C592,306 581,251 558,209l-95,88l-74,-80l90,-83C456,124 430,119 400,119z"
      />
      <glyph
        unicode="R"
        horiz-adv-x="715"
        d="M63,0l170,0l0,284l126,0C441,284 478,246 487,172C496,90 494,11 514,0l168,0l0,8C658,18 664,102 650,203C640,283 607,324 533,347l0,3C628,381 671,444 671,531C671,667 562,744 417,744l-354,0M233,609l156,0C464,609 501,569 501,508C501,449 460,410 384,410l-151,0z"
      />
      <glyph
        unicode="&#x154;"
        horiz-adv-x="715"
        d="M63,0l170,0l0,284l126,0C441,284 478,246 487,172C496,90 494,11 514,0l168,0l0,8C658,18 664,102 650,203C640,283 607,324 533,347l0,3C628,381 671,444 671,531C671,667 562,744 417,744l-354,0M233,609l156,0C464,609 501,569 501,508C501,449 460,410 384,410l-151,0M275,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x158;"
        horiz-adv-x="715"
        d="M63,0l170,0l0,284l126,0C441,284 478,246 487,172C496,90 494,11 514,0l168,0l0,8C658,18 664,102 650,203C640,283 607,324 533,347l0,3C628,381 671,444 671,531C671,667 562,744 417,744l-354,0M233,609l156,0C464,609 501,569 501,508C501,449 460,410 384,410l-151,0M531,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x156;"
        horiz-adv-x="715"
        d="M63,0l170,0l0,284l126,0C441,284 478,246 487,172C496,90 494,11 514,0l168,0l0,8C658,18 664,102 650,203C640,283 607,324 533,347l0,3C628,381 671,444 671,531C671,667 562,744 417,744l-354,0M233,609l156,0C464,609 501,569 501,508C501,449 460,410 384,410l-151,0M285,-263C372,-257 440,-217 440,-122l0,71l-151,0l0,-103l67,0C356,-184 327,-202 285,-210z"
      />
      <glyph
        unicode="S"
        horiz-adv-x="690"
        d="M355,-18C525,-18 655,68 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C30,74 156,-18 355,-18z"
      />
      <glyph
        unicode="&#x15A;"
        horiz-adv-x="690"
        d="M355,-18C525,-18 655,68 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C30,74 156,-18 355,-18M268,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x160;"
        horiz-adv-x="690"
        d="M355,-18C525,-18 655,68 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C30,74 156,-18 355,-18M520,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x15E;"
        horiz-adv-x="690"
        d="M309,-210C385,-210 437,-178 437,-128C437,-72 383,-48 324,-57l0,2l41,37C530,-14 655,71 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C29,88 134,-1 304,-16l-56,-60l18,-26C276,-98 286,-95 299,-95C319,-95 334,-104 334,-124C334,-148 312,-157 285,-157C260,-157 236,-150 216,-141l-20,-39C223,-197 262,-210 309,-210z"
      />
      <glyph
        unicode="&#x15C;"
        horiz-adv-x="690"
        d="M355,-18C525,-18 655,68 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C30,74 156,-18 355,-18M151,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#x218;"
        horiz-adv-x="690"
        d="M355,-18C525,-18 655,68 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C30,74 156,-18 355,-18M258,-263C345,-257 413,-217 413,-122l0,71l-151,0l0,-103l67,0C329,-184 300,-202 258,-210z"
      />
      <glyph unicode="T" horiz-adv-x="668" d="M27,599l221,0l0,-599l171,0l0,599l222,0l0,145l-614,0z" />
      <glyph
        unicode="&#x166;"
        horiz-adv-x="668"
        d="M27,599l221,0l0,-174l-146,0l0,-101l146,0l0,-324l171,0l0,324l147,0l0,101l-147,0l0,174l222,0l0,145l-614,0z"
      />
      <glyph
        unicode="&#x164;"
        horiz-adv-x="668"
        d="M27,599l221,0l0,-599l171,0l0,599l222,0l0,145l-614,0M521,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x162;"
        horiz-adv-x="668"
        d="M27,599l221,0l0,-599l171,0l0,599l222,0l0,145l-614,0M257,-263C344,-257 412,-217 412,-122l0,71l-151,0l0,-103l67,0C328,-184 299,-202 257,-210z"
      />
      <glyph
        unicode="&#xDE;"
        horiz-adv-x="686"
        d="M63,0l173,0l0,150l178,0C568,150 664,248 664,389C664,463 638,522 593,566C548,611 482,637 394,637l-158,0l0,107l-173,0M382,492C452,492 492,453 492,390C492,327 453,285 381,285l-145,0l0,207z"
      />
      <glyph
        unicode="U"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14z"
      />
      <glyph
        unicode="&#xDA;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M299,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x16C;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M365,779C461,779 520,842 523,921l-89,0C427,900 405,876 364,876C320,876 299,897 292,921l-91,0C205,839 270,779 365,779z"
      />
      <glyph
        unicode="&#xDB;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M174,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#xDC;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M409,785l152,0l0,134l-152,0M164,785l153,0l0,134l-153,0z"
      />
      <glyph
        unicode="&#xD9;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M305,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="&#x170;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M406,785l102,0l89,131l-156,0M209,785l102,0l90,131l-156,0z"
      />
      <glyph
        unicode="&#x16A;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M206,789l312,0l0,111l-312,0z"
      />
      <glyph
        unicode="&#x172;"
        horiz-adv-x="724"
        d="M403,-184C439,-184 460,-180 473,-175l0,79l-2,0C463,-98 454,-100 442,-100C421,-100 409,-92 409,-73C409,-34 452,-11 497,4C610,42 670,129 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,92 152,-4 335,-13C307,-31 282,-59 282,-100C282,-151 323,-184 403,-184z"
      />
      <glyph
        unicode="&#x16E;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M363,746C425,746 477,793 477,856C477,917 425,967 363,967C300,967 248,917 248,856C248,793 300,746 363,746M363,803C331,803 310,826 310,856C310,886 331,911 363,911C394,911 415,886 415,856C415,826 394,803 363,803z"
      />
      <glyph
        unicode="&#x168;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M435,786C501,786 540,841 540,924l-86,0C451,897 440,886 420,886C387,886 356,926 293,926C235,926 185,879 185,785l86,0C273,813 288,826 305,826C335,826 374,786 435,786z"
      />
      <glyph
        unicode="V"
        horiz-adv-x="690"
        d="M255,0l179,0l246,744l-172,0l-109,-358C375,304 349,167 349,167l-2,0C347,167 321,304 296,386l-110,358l-177,0z"
      />
      <glyph
        unicode="W"
        horiz-adv-x="999"
        d="M211,0l168,0l73,312C472,404 493,569 493,569l2,0C495,569 515,404 536,312l72,-312l167,0l204,744l-173,0l-69,-290C711,348 690,169 690,169l-2,0C688,169 663,356 640,460l-66,284l-161,0l-66,-285C325,355 298,169 298,169l-2,0C296,169 279,346 253,453l-71,291l-173,0z"
      />
      <glyph
        unicode="&#x1E82;"
        horiz-adv-x="999"
        d="M211,0l168,0l73,312C472,404 493,569 493,569l2,0C495,569 515,404 536,312l72,-312l167,0l204,744l-173,0l-69,-290C711,348 690,169 690,169l-2,0C688,169 663,356 640,460l-66,284l-161,0l-66,-285C325,355 298,169 298,169l-2,0C296,169 279,346 253,453l-71,291l-173,0M425,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x174;"
        horiz-adv-x="999"
        d="M211,0l168,0l73,312C472,404 493,569 493,569l2,0C495,569 515,404 536,312l72,-312l167,0l204,744l-173,0l-69,-290C711,348 690,169 690,169l-2,0C688,169 663,356 640,460l-66,284l-161,0l-66,-285C325,355 298,169 298,169l-2,0C296,169 279,346 253,453l-71,291l-173,0M306,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#x1E84;"
        horiz-adv-x="999"
        d="M211,0l168,0l73,312C472,404 493,569 493,569l2,0C495,569 515,404 536,312l72,-312l167,0l204,744l-173,0l-69,-290C711,348 690,169 690,169l-2,0C688,169 663,356 640,460l-66,284l-161,0l-66,-285C325,355 298,169 298,169l-2,0C296,169 279,346 253,453l-71,291l-173,0M541,785l152,0l0,134l-152,0M296,785l153,0l0,134l-153,0z"
      />
      <glyph
        unicode="&#x1E80;"
        horiz-adv-x="999"
        d="M211,0l168,0l73,312C472,404 493,569 493,569l2,0C495,569 515,404 536,312l72,-312l167,0l204,744l-173,0l-69,-290C711,348 690,169 690,169l-2,0C688,169 663,356 640,460l-66,284l-161,0l-66,-285C325,355 298,169 298,169l-2,0C296,169 279,346 253,453l-71,291l-173,0M430,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="X"
        horiz-adv-x="730"
        d="M15,0l194,0l77,131C321,190 362,283 362,283l2,0C364,283 404,189 437,131l75,-131l202,0l-248,390l225,354l-186,0l-61,-101C410,586 368,493 368,493l-2,0C366,493 325,586 291,643l-60,101l-195,0l229,-355z"
      />
      <glyph
        unicode="Y"
        horiz-adv-x="702"
        d="M266,0l170,0l0,280l255,464l-180,0l-81,-156C396,520 354,411 354,411l-2,0C352,411 312,521 278,588l-80,156l-187,0l255,-466z"
      />
      <glyph
        unicode="&#xDD;"
        horiz-adv-x="702"
        d="M266,0l170,0l0,280l255,464l-180,0l-81,-156C396,520 354,411 354,411l-2,0C352,411 312,521 278,588l-80,156l-187,0l255,-466M282,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x176;"
        horiz-adv-x="702"
        d="M266,0l170,0l0,280l255,464l-180,0l-81,-156C396,520 354,411 354,411l-2,0C352,411 312,521 278,588l-80,156l-187,0l255,-466M163,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#x178;"
        horiz-adv-x="702"
        d="M266,0l170,0l0,280l255,464l-180,0l-81,-156C396,520 354,411 354,411l-2,0C352,411 312,521 278,588l-80,156l-187,0l255,-466M153,785l153,0l0,134l-153,0M398,785l152,0l0,134l-152,0z"
      />
      <glyph
        unicode="&#x1EF2;"
        horiz-adv-x="702"
        d="M266,0l170,0l0,280l255,464l-180,0l-81,-156C396,520 354,411 354,411l-2,0C352,411 312,521 278,588l-80,156l-187,0l255,-466M287,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="Z"
        horiz-adv-x="688"
        d="M36,0l613,0l0,146l-248,0C339,146 235,140 235,140l0,3C235,143 321,227 370,286l265,324l0,134l-572,0l0,-144l214,0C335,600 434,605 434,605l0,-3C434,602 356,526 311,472l-275,-334z"
      />
      <glyph
        unicode="&#x179;"
        horiz-adv-x="688"
        d="M36,0l613,0l0,146l-248,0C339,146 235,140 235,140l0,3C235,143 321,227 370,286l265,324l0,134l-572,0l0,-144l214,0C335,600 434,605 434,605l0,-3C434,602 356,526 311,472l-275,-334M280,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x17D;"
        horiz-adv-x="688"
        d="M36,0l613,0l0,146l-248,0C339,146 235,140 235,140l0,3C235,143 321,227 370,286l265,324l0,134l-572,0l0,-144l214,0C335,600 434,605 434,605l0,-3C434,602 356,526 311,472l-275,-334M536,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x17B;"
        horiz-adv-x="688"
        d="M36,0l613,0l0,146l-248,0C339,146 235,140 235,140l0,3C235,143 321,227 370,286l265,324l0,134l-572,0l0,-144l214,0C335,600 434,605 434,605l0,-3C434,602 356,526 311,472l-275,-334M270,785l159,0l0,135l-159,0z"
      />
      <glyph
        unicode="a"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&#xE1;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M232,612l117,0l119,161l-183,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&#x103;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M296,606C390,606 453,669 456,761l-89,0C362,728 336,703 295,703C251,703 226,725 221,761l-91,0C134,666 203,606 296,606M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&#xE2;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M106,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph unicode="&#xB4;" horiz-adv-x="500" d="M177,612l117,0l119,161l-183,0z" />
      <glyph
        unicode="&#xE4;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89M98,607l149,0l0,137l-149,0M341,607l148,0l0,137l-148,0z"
      />
      <glyph
        unicode="&#xE6;"
        horiz-adv-x="933"
        d="M233,-15C333,-15 393,16 444,86C484,23 552,-16 651,-16C787,-16 870,56 897,150l-153,0C727,117 700,98 652,98C576,98 536,146 527,226l379,0C907,331 884,423 821,481C775,525 719,547 644,547C571,547 514,522 473,481C438,525 377,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 105,-15 233,-15M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89M642,436C702,436 741,391 746,317l-218,0C538,390 571,436 642,436z"
      />
      <glyph
        unicode="&#x1FD;"
        horiz-adv-x="933"
        d="M233,-15C333,-15 393,16 444,86C484,23 552,-16 651,-16C787,-16 870,56 897,150l-153,0C727,117 700,98 652,98C576,98 536,146 527,226l379,0C907,331 884,423 821,481C775,525 719,547 644,547C571,547 514,522 473,481C438,525 377,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 105,-15 233,-15M417,612l117,0l119,161l-183,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89M642,436C702,436 741,391 746,317l-218,0C538,390 571,436 642,436z"
      />
      <glyph
        unicode="&#x2113;"
        horiz-adv-x="435"
        d="M68,276C53,270 29,262 12,257l0,-91C28,170 52,178 68,183l0,-22C68,30 137,-6 242,-6C284,-6 323,1 350,10l0,120l-3,0C327,125 312,120 286,120C234,120 215,136 215,191l0,52C334,309 405,407 405,574C405,705 345,752 245,752C141,752 68,702 68,583M215,350l0,241C215,635 237,651 260,651C285,651 300,636 300,581C300,486 281,429 253,390C239,370 224,357 215,350z"
      />
      <glyph
        unicode="&#xE0;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M243,612l116,0l-53,161l-183,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&#x101;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M137,615l312,0l0,111l-312,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&amp;"
        horiz-adv-x="724"
        d="M295,-14C366,-14 432,13 485,52l24,-34l15,-18l192,0l-127,160C633,224 659,296 666,371l-137,0C524,333 516,297 501,263C482,290 450,334 431,358l-29,36C477,432 540,483 540,576C540,685 455,749 340,749C217,749 135,675 135,572C135,512 160,463 196,421C104,377 29,318 29,203C29,71 129,-14 295,-14M297,113C238,113 191,151 191,206C191,254 213,297 266,331l143,-184C377,125 339,113 297,113M280,576C280,614 304,639 341,639C376,639 401,612 401,575C401,537 385,504 337,476C303,508 280,538 280,576z"
      />
      <glyph
        unicode="&#x105;"
        horiz-adv-x="580"
        d="M480,-184C516,-184 537,-180 550,-175l0,79l-2,0C540,-98 531,-100 519,-100C498,-100 486,-92 486,-73C486,-48 506,-18 546,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0l41,0C398,-20 361,-49 361,-100C361,-151 400,-184 480,-184M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&#x2248;"
        horiz-adv-x="606"
        d="M422,356C511,356 551,413 551,522l-104,0C445,496 435,483 406,483C359,483 266,520 189,520C111,520 56,470 55,349l103,0C162,377 175,391 200,391C251,391 345,356 422,356M422,136C511,136 551,194 551,302l-104,0C445,276 435,264 406,264C359,264 266,300 189,300C111,300 56,251 55,129l103,0C162,158 175,171 200,171C251,171 345,136 422,136z"
      />
      <glyph
        unicode="&#xE5;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M294,596C356,596 408,643 408,706C408,767 356,817 294,817C231,817 179,767 179,706C179,643 231,596 294,596M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89M294,653C262,653 241,676 241,706C241,736 262,761 294,761C325,761 346,736 346,706C346,676 325,653 294,653z"
      />
      <glyph unicode="^" horiz-adv-x="503" d="M14,438l144,0l92,163l2,0l91,-163l146,0l-175,306l-125,0z" />
      <glyph
        unicode="~"
        horiz-adv-x="471"
        d="M323,257C401,257 441,322 441,427l-100,0C339,397 330,383 304,383C284,383 266,394 243,405C220,416 195,427 160,427C91,427 36,372 35,254l101,0C139,284 151,300 174,300C193,300 212,290 238,278C262,268 290,257 323,257z"
      />
      <glyph
        unicode="*"
        horiz-adv-x="474"
        d="M160,371l78,110l77,-110l73,53l-78,105l121,40l-31,82l-116,-40l0,133l-94,0l0,-133l-115,40l-34,-82l123,-40l-77,-105z"
      />
      <glyph
        unicode="@"
        horiz-adv-x="949"
        d="M418,98C480,98 525,126 554,182l2,0C573,121 629,92 706,92C818,92 911,177 911,350C911,605 718,744 489,744C258,744 40,583 40,294C40,47 220,-115 464,-115C543,-115 616,-101 682,-68l-31,77C595,-14 531,-29 465,-29C255,-29 141,108 141,295C141,523 297,656 490,656C686,656 816,554 816,351C816,214 759,178 714,178C679,178 667,199 673,250l33,268l-133,0l-8,-66l-1,0C545,502 509,529 452,529C335,529 258,430 258,290C258,173 320,98 418,98M461,200C417,200 398,239 398,293C398,370 429,427 485,427C531,427 552,390 552,335C552,255 518,200 461,200z"
      />
      <glyph
        unicode="&#xE3;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M366,613C432,613 471,668 471,751l-84,0C384,724 374,712 353,712C320,712 287,753 224,753C166,753 116,706 116,612l84,0C202,640 218,654 235,654C265,654 305,613 366,613M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="b"
        horiz-adv-x="621"
        d="M367,-17C505,-17 595,90 595,266C595,429 504,547 366,547C292,547 243,512 207,458l-3,0C206,490 209,536 209,581l0,163l-157,0l0,-744l151,0l0,78l2,0C236,13 296,-17 367,-17M325,109C249,109 204,173 204,266C204,358 242,428 324,428C398,428 435,362 435,267C435,171 398,109 325,109z"
      />
      <glyph unicode="\" horiz-adv-x="466" d="M485,-141l-333,925l-143,0l333,-925z" />
      <glyph unicode="|" horiz-adv-x="242" d="M71,-141l100,0l0,925l-100,0z" />
      <glyph
        unicode="{"
        horiz-adv-x="424"
        d="M355,-141l28,0l0,121l-22,0C302,-20 276,-5 276,55l0,153C276,275 249,305 192,322l0,3C249,342 276,373 276,440l0,149C276,651 302,662 361,662l22,0l0,122l-28,0C201,784 126,742 126,589l0,-130C126,409 101,385 30,382l0,-117C101,262 126,239 126,188l0,-132C126,-98 201,-141 355,-141z"
      />
      <glyph
        unicode="}"
        horiz-adv-x="424"
        d="M43,-141l27,0C224,-141 299,-98 299,56l0,132C299,238 324,262 394,265l0,116C324,384 299,409 299,458l0,130C299,742 224,784 70,784l-27,0l0,-122l21,0C123,662 149,650 149,589l0,-150C149,373 176,342 233,324l0,-3C176,304 149,274 149,207l0,-152C149,-6 123,-20 64,-20l-21,0z"
      />
      <glyph unicode="[" horiz-adv-x="427" d="M71,-141l312,0l0,121l-162,0l0,682l162,0l0,122l-312,0z" />
      <glyph unicode="]" horiz-adv-x="427" d="M43,-141l313,0l0,925l-313,0l0,-122l162,0l0,-682l-162,0z" />
      <glyph
        unicode="&#x2D8;"
        horiz-adv-x="500"
        d="M272,606C366,606 429,669 432,761l-89,0C338,728 312,703 271,703C227,703 202,725 197,761l-91,0C110,666 179,606 272,606z"
      />
      <glyph unicode="&#xA6;" horiz-adv-x="242" d="M71,395l100,0l0,389l-100,0M71,-141l100,0l0,404l-100,0z" />
      <glyph
        unicode="&#x2022;"
        horiz-adv-x="396"
        d="M198,183C281,183 349,250 349,333C349,416 281,485 198,485C115,485 47,416 47,333C47,250 115,183 198,183z"
      />
      <glyph
        unicode="c"
        horiz-adv-x="577"
        d="M307,-17C455,-17 551,77 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,106 137,-17 307,-17z"
      />
      <glyph
        unicode="&#x107;"
        horiz-adv-x="577"
        d="M307,-17C455,-17 551,77 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,106 137,-17 307,-17M235,612l117,0l119,161l-183,0z"
      />
      <glyph unicode="&#x2C7;" horiz-adv-x="500" d="M435,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z" />
      <glyph
        unicode="&#x10D;"
        horiz-adv-x="577"
        d="M307,-17C455,-17 551,77 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,106 137,-17 307,-17M483,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z"
      />
      <glyph
        unicode="&#xE7;"
        horiz-adv-x="577"
        d="M270,-210C346,-210 398,-178 398,-128C398,-72 344,-48 285,-57l0,2l43,39C464,-8 551,83 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,119 120,3 266,-14l-57,-62l18,-26C237,-98 247,-95 260,-95C280,-95 295,-104 295,-124C295,-148 273,-157 246,-157C221,-157 197,-150 177,-141l-20,-39C184,-197 223,-210 270,-210z"
      />
      <glyph
        unicode="&#x109;"
        horiz-adv-x="577"
        d="M307,-17C455,-17 551,77 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,106 137,-17 307,-17M109,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#x10B;"
        horiz-adv-x="577"
        d="M307,-17C455,-17 551,77 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,106 137,-17 307,-17M217,607l159,0l0,137l-159,0z"
      />
      <glyph
        unicode="&#xB8;"
        horiz-adv-x="500"
        d="M227,-210C303,-210 355,-178 355,-128C355,-72 301,-48 242,-57l0,2l62,55l-67,0l-71,-76l18,-26C194,-98 204,-95 217,-95C237,-95 252,-104 252,-124C252,-148 230,-157 203,-157C178,-157 154,-150 134,-141l-20,-39C141,-197 180,-210 227,-210z"
      />
      <glyph
        unicode="&#xA2;"
        horiz-adv-x="580"
        d="M273,-129l63,0l0,113C469,-5 554,83 559,200l-148,0C405,147 378,114 336,104l0,323C373,417 399,387 407,346l149,0C541,456 462,535 336,546l0,89l-63,0l0,-89C122,532 27,414 27,265C27,117 121,1 273,-15M184,265C184,351 214,410 273,426l0,-321C214,121 184,181 184,265z"
      />
      <glyph unicode="&#x2C6;" horiz-adv-x="500" d="M65,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z" />
      <glyph unicode=":" horiz-adv-x="261" d="M47,321l166,0l0,164l-166,0M47,0l166,0l0,164l-166,0z" />
      <glyph
        unicode=","
        horiz-adv-x="254"
        d="M45,-181C137,-173 210,-116 210,10l0,154l-166,0l0,-164l78,0l0,-2C122,-61 92,-99 45,-111z"
      />
      <glyph
        unicode="&#xF6C3;"
        horiz-adv-x="500"
        d="M173,-263C260,-257 328,-217 328,-122l0,71l-151,0l0,-103l67,0C244,-184 215,-202 173,-210z"
      />
      <glyph
        unicode="&#xA9;"
        horiz-adv-x="867"
        d="M434,-18C648,-18 816,147 816,369C816,592 648,757 434,757C220,757 51,592 51,369C51,147 220,-18 434,-18M434,32C249,32 110,176 110,369C110,563 249,706 434,706C618,706 756,563 756,369C756,176 618,32 434,32M439,146C502,146 546,164 581,196C613,225 631,267 634,314l-113,0C516,276 488,244 440,244C379,244 342,297 342,372C342,446 373,502 438,502C483,502 510,474 516,440l114,0C625,492 600,537 558,566C528,587 486,600 439,600C308,600 222,503 222,372C222,241 309,146 439,146z"
      />
      <glyph
        unicode="&#xA4;"
        horiz-adv-x="539"
        d="M98,91l70,71C197,146 230,138 269,138C307,138 342,146 371,162l70,-71l79,78l-69,69C469,270 479,309 479,350C479,392 470,430 450,462l70,70l-79,77l-70,-71C342,554 307,563 269,563C230,563 196,554 167,538l-69,71l-79,-77l68,-70C68,430 58,392 58,350C58,308 68,269 87,237l-68,-68M269,232C209,232 173,279 173,350C173,421 209,470 269,470C329,470 363,421 363,350C363,279 329,232 269,232z"
      />
      <glyph
        unicode="d"
        horiz-adv-x="622"
        d="M255,-17C327,-17 386,13 417,78l2,0l0,-78l151,0l0,744l-157,0l0,-163C413,536 415,491 417,458l-2,0C379,513 330,547 257,547C119,547 27,429 27,266C27,90 117,-17 255,-17M297,109C224,109 187,171 187,267C187,362 224,428 298,428C379,428 418,360 418,266C418,173 373,109 297,109z"
      />
      <glyph
        unicode="&#x2020;"
        horiz-adv-x="628"
        d="M44,407l186,0l0,-550l166,0l0,550l187,0l0,130l-187,0l0,207l-166,0l0,-207l-186,0z"
      />
      <glyph
        unicode="&#x2021;"
        horiz-adv-x="628"
        d="M44,407l186,0l0,-213l-186,0l0,-131l186,0l0,-206l166,0l0,206l187,0l0,131l-187,0l0,213l187,0l0,130l-187,0l0,207l-166,0l0,-207l-186,0z"
      />
      <glyph
        unicode="&#x10F;"
        horiz-adv-x="622"
        d="M255,-17C327,-17 386,13 417,78l2,0l0,-78l151,0l0,744l-157,0l0,-163C413,536 415,491 417,458l-2,0C379,513 330,547 257,547C119,547 27,429 27,266C27,90 117,-17 255,-17M297,109C224,109 187,171 187,267C187,362 224,428 298,428C379,428 418,360 418,266C418,173 373,109 297,109M607,550C689,554 738,588 738,680l0,64l-131,0l0,-96l59,0C665,622 647,604 607,600z"
      />
      <glyph
        unicode="&#x111;"
        horiz-adv-x="622"
        d="M276,596l137,0l0,-15C413,536 415,491 417,458l-2,0C379,513 330,547 257,547C119,547 27,429 27,266C27,90 117,-17 255,-17C327,-17 386,13 417,78l2,0l0,-78l151,0l0,596l58,0l0,80l-58,0l0,68l-157,0l0,-68l-137,0M297,109C224,109 187,171 187,267C187,362 224,428 298,428C379,428 418,360 418,266C418,173 373,109 297,109z"
      />
      <glyph
        unicode="&#xB0;"
        horiz-adv-x="438"
        d="M220,392C314,392 394,465 394,558C394,653 314,727 220,727C125,727 44,653 44,558C44,465 125,392 220,392M220,473C169,473 138,512 138,558C138,605 169,646 220,646C269,646 300,605 300,558C300,512 269,473 220,473z"
      />
      <glyph unicode="&#xA8;" horiz-adv-x="500" d="M297,607l148,0l0,137l-148,0M54,607l149,0l0,137l-149,0z" />
      <glyph
        unicode="&#xF7;"
        horiz-adv-x="603"
        d="M219,477l166,0l0,147l-166,0M59,279l486,0l0,127l-486,0M219,60l166,0l0,147l-166,0z"
      />
      <glyph
        unicode="$"
        horiz-adv-x="662"
        d="M304,-124l64,0l0,117C516,0 628,75 628,211C628,353 520,394 381,428l-13,3l0,168C420,589 451,558 458,512l154,0C604,636 514,709 368,720l0,80l-64,0l0,-79C159,715 48,645 48,517C48,369 159,338 300,302C301,301 302,301 304,301l0,-184C234,126 197,165 189,228l-163,0C31,92 128,4 304,-7M465,201C465,152 421,124 368,117l0,167C432,265 465,247 465,201M210,525C210,566 244,596 304,602l0,-155C238,466 210,484 210,525z"
      />
      <glyph unicode="&#x2D9;" horiz-adv-x="500" d="M170,607l159,0l0,137l-159,0z" />
      <glyph unicode="&#x131;" horiz-adv-x="261" d="M52,0l157,0l0,532l-157,0z" />
      <glyph
        unicode="&#xF6BE;"
        horiz-adv-x="261"
        d="M52,-181C100,-181 140,-170 169,-141C194,-117 209,-81 209,-13l0,545l-157,0l0,-539C52,-46 37,-58 6,-61C-5,-62 -17,-62 -31,-62l-6,0l0,-111C-13,-178 24,-181 52,-181z"
      />
      <glyph
        unicode="e"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#xE9;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M239,612l117,0l119,161l-183,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#x115;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M303,606C397,606 460,669 463,761l-89,0C369,728 343,703 302,703C258,703 233,725 228,761l-91,0C141,666 210,606 303,606M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#x11B;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M487,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#xEA;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M113,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#xEB;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436M105,607l149,0l0,137l-149,0M348,607l148,0l0,137l-148,0z"
      />
      <glyph
        unicode="&#x117;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M221,607l159,0l0,137l-159,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#xE8;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M250,612l116,0l-53,161l-183,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="8"
        horiz-adv-x="647"
        d="M325,-17C495,-17 618,65 618,207C618,291 570,360 476,386l0,3C550,415 586,469 586,533C586,653 485,727 324,727C164,727 60,648 60,530C60,464 95,412 171,385l0,-3C80,354 30,287 30,203C30,65 155,-17 325,-17M323,432C254,432 214,468 214,522C214,577 254,614 323,614C393,614 432,577 432,522C432,468 393,432 323,432M325,105C244,105 195,147 195,213C195,282 245,325 325,325C405,325 453,282 453,213C453,148 402,105 325,105z"
      />
      <glyph
        unicode="&#x2088;"
        horiz-adv-x="456"
        d="M228,-172C352,-172 430,-118 430,-29C430,22 398,66 334,84l0,3C384,106 409,140 409,180C409,256 339,306 228,306C119,306 48,252 48,177C48,135 72,103 124,84l0,-3C61,62 27,20 27,-32C27,-117 105,-172 228,-172M228,119C188,119 166,140 166,173C166,204 188,226 228,226C269,226 291,204 291,173C291,140 269,119 228,119M228,-85C184,-85 154,-61 154,-23C154,15 184,43 229,43C275,43 303,15 303,-23C303,-60 274,-85 228,-85z"
      />
      <glyph
        unicode="&#x2078;"
        horiz-adv-x="456"
        d="M228,276C352,276 430,330 430,419C430,470 398,514 334,532l0,3C384,554 409,588 409,628C409,704 339,754 228,754C119,754 48,700 48,625C48,583 72,551 124,532l0,-3C61,510 27,468 27,416C27,331 105,276 228,276M228,567C188,567 166,588 166,621C166,652 188,674 228,674C269,674 291,652 291,621C291,588 269,567 228,567M228,363C184,363 154,387 154,425C154,463 184,491 229,491C275,491 303,463 303,425C303,388 274,363 228,363z"
      />
      <glyph
        unicode="&#x2026;"
        horiz-adv-x="823"
        d="M44,0l166,0l0,164l-166,0M328,0l167,0l0,164l-167,0M613,0l166,0l0,164l-166,0z"
      />
      <glyph
        unicode="&#x113;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M144,615l312,0l0,111l-312,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph unicode="&#x2014;" horiz-adv-x="1000" d="M200,197l600,0l0,135l-600,0z" />
      <glyph unicode="&#x2013;" horiz-adv-x="500" d="M0,197l500,0l0,135l-500,0z" />
      <glyph
        unicode="&#x14B;"
        horiz-adv-x="604"
        d="M556,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0l0,81l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-327C400,-46 384,-58 353,-61C342,-62 330,-62 316,-62l-6,0l0,-111C334,-178 371,-181 399,-181C447,-181 487,-170 516,-141C541,-117 556,-81 556,-13z"
      />
      <glyph
        unicode="&#x119;"
        horiz-adv-x="596"
        d="M383,-184C419,-184 440,-180 453,-175l0,79l-2,0C443,-98 434,-100 422,-100C401,-100 389,-92 389,-73C389,-27 455,9 489,41C521,71 546,104 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16l12,0C292,-33 264,-60 264,-100C264,-151 303,-184 383,-184M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph unicode="=" horiz-adv-x="603" d="M61,368l486,0l0,123l-486,0M61,154l486,0l0,123l-486,0z" />
      <glyph
        unicode="&#x212E;"
        horiz-adv-x="751"
        d="M721,364C722,503 693,612 605,686C552,732 477,758 389,758C181,758 29,600 29,373C29,147 164,-14 395,-14C574,-14 684,87 717,225l-58,0C621,109 540,39 395,39C318,39 259,58 216,89l0,275M389,709C450,709 501,692 537,671l0,-258l-321,0l0,244C257,687 318,709 389,709z"
      />
      <glyph
        unicode="&#xF0;"
        horiz-adv-x="616"
        d="M167,557l149,47C350,568 380,528 399,483l-3,0C360,523 315,534 270,534C123,534 27,424 27,265C27,106 139,-17 309,-17C509,-17 589,124 589,305C589,450 528,566 458,648l76,24l-29,62l-101,-31C386,719 368,732 351,744l-199,0l0,-7C184,715 221,689 258,658l-120,-37M308,97C228,97 186,164 186,264C186,364 228,429 307,429C386,429 429,365 429,265C429,164 386,97 308,97z"
      />
      <glyph unicode="!" horiz-adv-x="289" d="M104,259l81,0l41,299l0,186l-163,0l0,-186M61,0l167,0l0,164l-167,0z" />
      <glyph
        unicode="&#xA1;"
        horiz-adv-x="289"
        d="M185,353l-81,0l-41,-299l0,-186l163,0l0,186M228,612l-167,0l0,-164l167,0z"
      />
      <glyph
        unicode="f"
        horiz-adv-x="395"
        d="M20,430l80,0l0,-430l157,0l0,430l111,0l0,102l-111,0l0,34C257,626 289,631 368,628l0,112C346,743 324,744 303,744C235,745 180,733 145,702C114,674 100,634 100,577l0,-45l-80,0z"
      />
      <glyph
        unicode="&#xFB00;"
        horiz-adv-x="730"
        d="M435,0l157,0l0,430l111,0l0,102l-111,0l0,34C592,626 624,631 703,628l0,112C615,750 536,742 493,712C453,684 435,642 435,577l0,-45l-178,0l0,28C257,620 287,625 365,623l0,112C278,744 204,737 162,710C118,682 100,636 100,571l0,-39l-80,0l0,-102l80,0l0,-430l157,0l0,430l178,0z"
      />
      <glyph
        unicode="&#xFB03;"
        horiz-adv-x="979"
        d="M770,0l156,0l0,532l-156,0M769,607l158,0l0,137l-158,0M435,0l157,0l0,430l111,0l0,102l-111,0l0,34C592,626 624,631 703,628l0,112C615,750 536,742 493,712C453,684 435,642 435,577l0,-45l-178,0l0,28C257,620 287,625 365,623l0,112C278,744 204,737 162,710C118,682 100,636 100,571l0,-39l-80,0l0,-102l80,0l0,-430l157,0l0,430l178,0z"
      />
      <glyph
        unicode="&#xFB04;"
        horiz-adv-x="982"
        d="M770,0l158,0l0,744l-158,0M435,0l157,0l0,430l111,0l0,102l-111,0l0,34C592,626 624,631 703,628l0,112C615,750 536,742 493,712C453,684 435,642 435,577l0,-45l-178,0l0,28C257,620 287,625 365,623l0,112C278,744 204,737 162,710C118,682 100,636 100,571l0,-39l-80,0l0,-102l80,0l0,-430l157,0l0,430l178,0z"
      />
      <glyph
        unicode="&#xF001;"
        horiz-adv-x="644"
        d="M20,430l80,0l0,-430l157,0l0,430l111,0l0,102l-111,0l0,34C257,626 289,631 368,628l0,112C280,750 204,742 162,707C120,682 100,642 100,577l0,-45l-80,0M435,0l156,0l0,532l-156,0M434,607l158,0l0,137l-158,0z"
      />
      <glyph
        unicode="&#xF002;"
        horiz-adv-x="647"
        d="M20,430l80,0l0,-430l157,0l0,430l111,0l0,102l-111,0l0,34C257,626 289,631 368,628l0,112C280,750 204,742 162,707C120,682 100,642 100,577l0,-45l-80,0M435,0l158,0l0,744l-158,0z"
      />
      <glyph
        unicode="&#xFB01;"
        horiz-adv-x="644"
        d="M20,430l80,0l0,-430l157,0l0,430l111,0l0,102l-111,0l0,34C257,626 289,631 368,628l0,112C280,750 204,742 162,707C120,682 100,642 100,577l0,-45l-80,0M435,0l156,0l0,532l-156,0M434,607l158,0l0,137l-158,0z"
      />
      <glyph
        unicode="5"
        horiz-adv-x="630"
        d="M313,-17C479,-17 597,83 597,238C597,388 484,482 354,482C284,482 240,460 207,424l-2,0l32,157l316,0l0,131l-433,0l-74,-397l153,0C220,341 259,362 310,362C379,362 433,316 433,239C433,165 381,114 307,114C239,114 194,149 183,202l-161,0C33,66 152,-17 313,-17z"
      />
      <glyph
        unicode="&#x2085;"
        horiz-adv-x="435"
        d="M213,-173C328,-173 405,-105 405,-3C405,92 336,146 247,146C201,146 171,133 152,112l-2,0l17,89l207,0l0,95l-292,0l-48,-260l109,0C156,52 181,63 211,63C252,63 281,40 281,-6C281,-49 252,-78 211,-78C170,-78 143,-59 136,-34l-116,0C31,-117 106,-173 213,-173z"
      />
      <glyph
        unicode="&#x2075;"
        horiz-adv-x="435"
        d="M213,275C328,275 405,343 405,445C405,540 336,594 247,594C201,594 171,581 152,560l-2,0l17,89l207,0l0,95l-292,0l-48,-260l109,0C156,500 181,511 211,511C252,511 281,488 281,442C281,399 252,370 211,370C170,370 143,389 136,414l-116,0C31,331 106,275 213,275z"
      />
      <glyph
        unicode="&#x215D;"
        horiz-adv-x="999"
        d="M216,275C331,275 408,343 408,445C408,540 339,594 250,594C204,594 174,581 155,560l-2,0l17,89l207,0l0,95l-292,0l-48,-260l109,0C159,500 184,511 214,511C255,511 284,488 284,442C284,399 255,370 214,370C173,370 146,389 139,414l-116,0C34,331 109,275 216,275M121,-130l103,0l628,874l-104,0M768,-122C892,-122 970,-68 970,21C970,72 938,116 874,134l0,3C924,156 949,190 949,230C949,306 879,356 768,356C659,356 588,302 588,227C588,185 612,153 664,134l0,-3C601,112 567,70 567,18C567,-67 645,-122 768,-122M768,169C728,169 706,190 706,223C706,254 728,276 768,276C809,276 831,254 831,223C831,190 809,169 768,169M768,-35C724,-35 694,-11 694,27C694,65 724,93 769,93C815,93 843,65 843,27C843,-10 814,-35 768,-35z"
      />
      <glyph
        unicode="&#xFB02;"
        horiz-adv-x="647"
        d="M20,430l80,0l0,-430l157,0l0,430l111,0l0,102l-111,0l0,34C257,626 289,631 368,628l0,112C280,750 204,742 162,707C120,682 100,642 100,577l0,-45l-80,0M435,0l158,0l0,744l-158,0z"
      />
      <glyph
        unicode="&#x192;"
        horiz-adv-x="466"
        d="M42,331l91,0l-48,-329C77,-53 51,-55 3,-53l0,-104C77,-166 149,-168 193,-128C222,-103 236,-65 244,-14l49,345l127,0l0,101l-112,0l18,123C334,610 371,613 439,609l0,112C355,731 273,730 224,690C192,664 176,625 168,573l-21,-141l-105,0z"
      />
      <glyph
        unicode="4"
        horiz-adv-x="654"
        d="M387,0l157,0l0,161l106,0l0,120l-106,0l0,439l-156,0l-335,-414l0,-145l334,0M162,275l0,2C162,277 215,332 244,368l76,95C350,500 390,562 390,562l2,0C392,562 387,495 387,453l0,-172l-123,0C224,281 162,275 162,275z"
      />
      <glyph
        unicode="&#x2084;"
        horiz-adv-x="466"
        d="M252,-160l128,0l0,96l67,0l0,89l-67,0l0,276l-128,0l-219,-262l0,-103l219,0M112,18l0,2C112,20 149,51 170,75l46,57C233,153 257,190 257,190l2,0C259,190 252,147 252,121l0,-96l-62,0C160,25 112,18 112,18z"
      />
      <glyph
        unicode="&#x2074;"
        horiz-adv-x="466"
        d="M252,288l128,0l0,96l67,0l0,89l-67,0l0,276l-128,0l-219,-262l0,-103l219,0M112,466l0,2C112,468 149,499 170,523l46,57C233,601 257,638 257,638l2,0C259,638 252,595 252,569l0,-96l-62,0C160,473 112,466 112,466z"
      />
      <glyph unicode="&#x2044;" horiz-adv-x="295" d="M-274,-130l103,0l628,874l-104,0z" />
      <glyph
        unicode="g"
        horiz-adv-x="613"
        d="M299,-185C377,-185 445,-167 491,-127C534,-89 560,-32 560,47l0,485l-150,0l0,-68l-2,0C378,523 327,547 255,547C124,547 31,440 31,285C31,124 140,33 263,33C329,33 376,59 407,111l4,0C409,93 408,68 408,41l0,-11C408,-35 371,-73 297,-73C236,-73 206,-51 196,-19l-153,0C59,-124 153,-185 299,-185M298,151C228,151 185,205 185,289C185,374 228,428 297,428C374,428 413,369 413,290C413,211 378,151 298,151z"
      />
      <glyph
        unicode="&#x11F;"
        horiz-adv-x="613"
        d="M299,-185C377,-185 445,-167 491,-127C534,-89 560,-32 560,47l0,485l-150,0l0,-68l-2,0C378,523 327,547 255,547C124,547 31,440 31,285C31,124 140,33 263,33C329,33 376,59 407,111l4,0C409,93 408,68 408,41l0,-11C408,-35 371,-73 297,-73C236,-73 206,-51 196,-19l-153,0C59,-124 153,-185 299,-185M298,151C228,151 185,205 185,289C185,374 228,428 297,428C374,428 413,369 413,290C413,211 378,151 298,151M308,606C402,606 465,669 468,761l-89,0C374,728 348,703 307,703C263,703 238,725 233,761l-91,0C146,666 215,606 308,606z"
      />
      <glyph
        unicode="&#x11D;"
        horiz-adv-x="613"
        d="M299,-185C377,-185 445,-167 491,-127C534,-89 560,-32 560,47l0,485l-150,0l0,-68l-2,0C378,523 327,547 255,547C124,547 31,440 31,285C31,124 140,33 263,33C329,33 376,59 407,111l4,0C409,93 408,68 408,41l0,-11C408,-35 371,-73 297,-73C236,-73 206,-51 196,-19l-153,0C59,-124 153,-185 299,-185M298,151C228,151 185,205 185,289C185,374 228,428 297,428C374,428 413,369 413,290C413,211 378,151 298,151M118,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#x123;"
        horiz-adv-x="613"
        d="M299,-185C377,-185 445,-167 491,-127C534,-89 560,-32 560,47l0,485l-150,0l0,-68l-2,0C378,523 327,547 255,547C124,547 31,440 31,285C31,124 140,33 263,33C329,33 376,59 407,111l4,0C409,93 408,68 408,41l0,-11C408,-35 371,-73 297,-73C236,-73 206,-51 196,-19l-153,0C59,-124 153,-185 299,-185M298,151C228,151 185,205 185,289C185,374 228,428 297,428C374,428 413,369 413,290C413,211 378,151 298,151M383,819C296,813 228,773 228,678l0,-71l151,0l0,103l-67,0C312,740 341,758 383,766z"
      />
      <glyph
        unicode="&#x121;"
        horiz-adv-x="613"
        d="M299,-185C377,-185 445,-167 491,-127C534,-89 560,-32 560,47l0,485l-150,0l0,-68l-2,0C378,523 327,547 255,547C124,547 31,440 31,285C31,124 140,33 263,33C329,33 376,59 407,111l4,0C409,93 408,68 408,41l0,-11C408,-35 371,-73 297,-73C236,-73 206,-51 196,-19l-153,0C59,-124 153,-185 299,-185M298,151C228,151 185,205 185,289C185,374 228,428 297,428C374,428 413,369 413,290C413,211 378,151 298,151M226,607l159,0l0,137l-159,0z"
      />
      <glyph
        unicode="&#xDF;"
        horiz-adv-x="606"
        d="M341,-9C476,-9 578,74 578,231C578,329 529,397 433,423l0,3C508,451 541,498 541,565C541,681 447,756 305,756C148,756 52,673 52,539l0,-539l157,0l0,534C209,601 247,637 307,637C363,637 394,603 394,552C394,493 351,463 286,463l-10,0l0,-92l21,0C382,371 423,323 423,244C423,148 370,104 278,114C277,114 275,114 273,115l0,-116C289,-6 313,-9 341,-9z"
      />
      <glyph unicode="`" horiz-adv-x="538" d="M225,612l116,0l-53,161l-183,0z" />
      <glyph
        unicode="&gt;"
        horiz-adv-x="541"
        d="M60,573l0,-143l171,-63C287,347 374,324 374,324l0,-2C374,322 288,297 231,275l-171,-67l0,-138l426,180l0,143z"
      />
      <glyph
        unicode="&#x2265;"
        horiz-adv-x="541"
        d="M486,120l-426,0l0,-120l426,0M60,587l0,-134l176,-50C292,387 384,369 384,369l0,-2C384,367 293,346 236,329l-176,-52l0,-131l426,144l0,153z"
      />
      <glyph
        unicode="&#xAB;"
        horiz-adv-x="511"
        d="M452,40l0,131l-96,91l0,2l96,91l0,135l-168,-152l0,-146M226,40l0,131l-96,91l0,2l96,91l0,135l-167,-152l0,-146z"
      />
      <glyph
        unicode="&#xBB;"
        horiz-adv-x="511"
        d="M59,490l0,-132l96,-91l0,-2l-96,-91l0,-134l167,151l0,146M284,490l0,-132l97,-91l0,-2l-97,-91l0,-134l168,151l0,146z"
      />
      <glyph unicode="&#x2039;" horiz-adv-x="301" d="M242,25l0,142l-104,95l0,2l104,97l0,142l-183,-163l0,-151z" />
      <glyph unicode="&#x203A;" horiz-adv-x="301" d="M59,503l0,-141l105,-96l0,-2l-105,-96l0,-143l183,164l0,150z" />
      <glyph
        unicode="h"
        horiz-adv-x="604"
        d="M209,539l0,205l-157,0l0,-744l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 243,513 211,456l-3,0C209,491 209,534 209,539z"
      />
      <glyph
        unicode="&#x127;"
        horiz-adv-x="604"
        d="M-6,596l58,0l0,-596l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 243,513 211,456l-3,0C209,491 209,534 209,539l0,57l137,0l0,80l-137,0l0,68l-157,0l0,-68l-58,0z"
      />
      <glyph
        unicode="&#x125;"
        horiz-adv-x="604"
        d="M209,539l0,205l-157,0l0,-744l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 243,513 211,456l-3,0C209,491 209,534 209,539M116,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph unicode="&#x2DD;" horiz-adv-x="500" d="M289,612l99,0l102,161l-153,0M95,612l99,0l103,161l-153,0z" />
      <glyph unicode="-" horiz-adv-x="363" d="M40,197l283,0l0,136l-283,0z" />
      <glyph unicode="i" horiz-adv-x="261" d="M51,607l159,0l0,137l-159,0M52,0l157,0l0,532l-157,0z" />
      <glyph unicode="&#xED;" horiz-adv-x="261" d="M52,0l157,0l0,532l-157,0M69,612l117,0l119,161l-183,0z" />
      <glyph
        unicode="&#x12D;"
        horiz-adv-x="261"
        d="M52,0l157,0l0,532l-157,0M133,606C227,606 290,669 293,761l-89,0C199,728 173,703 132,703C88,703 63,725 58,761l-91,0C-29,666 40,606 133,606z"
      />
      <glyph
        unicode="&#xEE;"
        horiz-adv-x="261"
        d="M52,0l157,0l0,532l-157,0M-57,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#xEF;"
        horiz-adv-x="261"
        d="M52,0l157,0l0,532l-157,0M158,607l128,0l0,137l-128,0M-25,607l129,0l0,137l-129,0z"
      />
      <glyph unicode="&#xEC;" horiz-adv-x="261" d="M52,0l157,0l0,532l-157,0M80,612l116,0l-53,161l-183,0z" />
      <glyph
        unicode="&#x133;"
        horiz-adv-x="522"
        d="M313,-181C361,-181 401,-170 430,-141C455,-117 470,-81 470,-13l0,545l-157,0l0,-539C313,-46 298,-58 267,-61C256,-62 244,-62 230,-62l-6,0l0,-111C248,-178 285,-181 313,-181M52,0l157,0l0,532l-157,0M312,607l159,0l0,137l-159,0M51,607l159,0l0,137l-159,0z"
      />
      <glyph unicode="&#x12B;" horiz-adv-x="261" d="M52,0l157,0l0,532l-157,0M4,615l252,0l0,111l-252,0z" />
      <glyph
        unicode="&#x221E;"
        horiz-adv-x="749"
        d="M194,127C280,127 332,176 378,241C422,176 472,127 555,127C653,127 722,201 722,317C722,433 653,507 555,507C472,507 422,457 378,394C332,457 280,507 194,507C87,507 27,421 27,317C27,213 86,127 194,127M205,395C246,395 276,358 300,316C276,276 246,238 205,238C162,238 144,274 144,317C144,360 162,395 205,395M544,238C506,238 478,276 453,316C478,358 506,395 544,395C587,395 607,360 607,317C607,274 587,238 544,238z"
      />
      <glyph
        unicode="&#x222B;"
        horiz-adv-x="387"
        d="M-1,-174C27,-180 63,-184 94,-184C212,-184 270,-129 270,-8l0,586C270,624 292,638 339,638C356,638 371,636 380,633l2,0l0,119C354,760 318,763 289,763C171,763 112,710 112,587l0,-585C112,-44 91,-58 47,-58C29,-58 15,-56 2,-54l-3,0z"
      />
      <glyph
        unicode="&#x12F;"
        horiz-adv-x="261"
        d="M52,0l45,0C63,-17 28,-50 28,-100C28,-151 69,-184 146,-184C182,-184 203,-180 216,-175l0,79l-2,0C206,-98 197,-100 185,-100C164,-100 152,-92 152,-73C152,-50 172,-19 209,2l0,530l-157,0M51,607l159,0l0,137l-159,0z"
      />
      <glyph
        unicode="&#x129;"
        horiz-adv-x="261"
        d="M52,0l157,0l0,532l-157,0M203,613C269,613 308,668 308,751l-84,0C221,724 211,712 190,712C157,712 124,753 61,753C3,753 -47,706 -47,612l84,0C39,640 55,654 72,654C102,654 142,613 203,613z"
      />
      <glyph
        unicode="j"
        horiz-adv-x="261"
        d="M52,-181C100,-181 140,-170 169,-141C194,-117 209,-81 209,-13l0,545l-157,0l0,-539C52,-46 37,-58 6,-61C-5,-62 -17,-62 -31,-62l-6,0l0,-111C-13,-178 24,-181 52,-181M51,607l159,0l0,137l-159,0z"
      />
      <glyph
        unicode="&#x135;"
        horiz-adv-x="261"
        d="M52,-181C100,-181 140,-170 169,-141C194,-117 209,-81 209,-13l0,545l-157,0l0,-539C52,-46 37,-58 6,-61C-5,-62 -17,-62 -31,-62l-6,0l0,-111C-13,-178 24,-181 52,-181M-59,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="k"
        horiz-adv-x="590"
        d="M399,0l182,0l-212,335l190,197l-182,0l-175,-191C204,375 207,434 207,512l0,232l-155,0l0,-744l155,0l0,177l53,55z"
      />
      <glyph
        unicode="&#x137;"
        horiz-adv-x="590"
        d="M399,0l182,0l-212,335l190,197l-182,0l-175,-191C204,375 207,434 207,512l0,232l-155,0l0,-744l155,0l0,177l53,55M217,-263C304,-257 372,-217 372,-122l0,71l-151,0l0,-103l67,0C288,-184 259,-202 217,-210z"
      />
      <glyph
        unicode="&#x138;"
        horiz-adv-x="590"
        d="M399,0l182,0l-212,335l190,197l-182,0l-175,-191C204,375 207,434 207,512l0,20l-155,0l0,-532l155,0l0,177l53,55z"
      />
      <glyph unicode="l" horiz-adv-x="265" d="M54,0l158,0l0,744l-158,0z" />
      <glyph unicode="&#x13A;" horiz-adv-x="265" d="M54,0l158,0l0,744l-158,0M64,785l123,0l109,131l-189,0z" />
      <glyph
        unicode="&#x13E;"
        horiz-adv-x="265"
        d="M54,0l158,0l0,744l-158,0M250,550C332,554 381,588 381,680l0,64l-131,0l0,-96l59,0C308,622 290,604 250,600z"
      />
      <glyph
        unicode="&#x13C;"
        horiz-adv-x="265"
        d="M54,0l158,0l0,744l-158,0M56,-263C143,-257 211,-217 211,-122l0,71l-151,0l0,-103l67,0C127,-184 98,-202 56,-210z"
      />
      <glyph unicode="&#x140;" horiz-adv-x="402" d="M54,0l158,0l0,744l-158,0M263,292l142,0l0,140l-142,0z" />
      <glyph
        unicode="&lt;"
        horiz-adv-x="541"
        d="M481,70l0,143l-170,63C255,296 167,319 167,319l0,2C167,321 254,346 311,368l170,66l0,139l-426,-180l0,-143z"
      />
      <glyph
        unicode="&#x2264;"
        horiz-adv-x="541"
        d="M55,0l426,0l0,120l-426,0M481,146l0,134l-176,50C249,346 157,364 157,364l0,2C157,366 248,387 305,404l176,52l0,131l-426,-144l0,-153z"
      />
      <glyph unicode="&#xAC;" horiz-adv-x="544" d="M53,316l290,0l0,-158l137,0l0,287l-427,0z" />
      <glyph
        unicode="&#x25CA;"
        horiz-adv-x="633"
        d="M400,744l-168,0l-209,-372l210,-372l168,0l209,371M185,374l81,152C300,591 313,630 313,630l2,0C315,630 330,591 363,526l82,-158l-80,-152C331,151 318,111 318,111l-2,0C316,111 302,151 268,216z"
      />
      <glyph
        unicode="&#x142;"
        horiz-adv-x="315"
        d="M10,201l68,50l0,-251l158,0l0,366l76,56l0,116l-76,-56l0,262l-158,0l0,-378l-68,-50z"
      />
      <glyph
        unicode="m"
        horiz-adv-x="910"
        d="M52,0l157,0l0,303C209,371 246,418 301,418C353,418 379,383 379,325l0,-325l157,0l0,303C536,371 571,418 627,418C678,418 706,383 706,325l0,-325l156,0l0,351C862,469 801,547 683,547C616,547 558,517 514,446l-2,0C485,512 436,547 366,547C291,547 240,512 206,449l-3,0l0,83l-151,0z"
      />
      <glyph unicode="&#xAF;" horiz-adv-x="500" d="M96,615l312,0l0,111l-312,0z" />
      <glyph unicode="&#x2212;" horiz-adv-x="606" d="M53,279l500,0l0,126l-500,0z" />
      <glyph
        unicode="&#xB5;"
        horiz-adv-x="603"
        d="M52,-177l157,0l0,84C209,-46 205,7 205,7l2,0C215,-1 231,-8 254,-8C325,-8 372,28 399,76l3,0l0,-76l149,0l0,532l-155,0l0,-301C396,165 357,117 294,117C238,117 209,151 209,210l0,322l-157,0z"
      />
      <glyph
        unicode="&#xD7;"
        horiz-adv-x="605"
        d="M142,91l160,160l160,-160l88,88l-160,160l160,160l-88,88l-160,-160l-159,160l-87,-88l159,-160l-160,-161z"
      />
      <glyph
        unicode="n"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0z"
      />
      <glyph
        unicode="&#x144;"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0M245,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x149;"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0M-35,550C47,554 96,588 96,680l0,64l-131,0l0,-96l59,0C23,622 5,604 -35,600z"
      />
      <glyph
        unicode="&#x148;"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0M493,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z"
      />
      <glyph
        unicode="&#x146;"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0M229,-263C316,-257 384,-217 384,-122l0,71l-151,0l0,-103l67,0C300,-184 271,-202 229,-210z"
      />
      <glyph
        unicode="9"
        horiz-adv-x="654"
        d="M320,-17C414,-17 483,13 532,68C592,133 615,238 615,360C615,498 583,597 515,658C466,702 397,727 315,727C152,727 33,624 33,475C33,324 148,224 290,224C365,224 419,256 460,308l2,0C458,241 446,192 425,159C400,119 364,101 316,101C253,101 220,137 211,170l-156,0C70,66 168,-17 320,-17M318,345C238,345 187,402 187,476C187,550 240,606 319,606C395,606 451,551 451,475C451,399 396,345 318,345z"
      />
      <glyph
        unicode="&#x2089;"
        horiz-adv-x="458"
        d="M223,-172C288,-172 337,-152 372,-113C409,-70 426,-3 426,67C426,158 406,222 357,261C322,289 276,306 222,306C109,306 29,241 29,142C29,42 106,-21 198,-21C244,-21 276,-1 299,25l2,0C299,-15 290,-42 276,-59C263,-76 243,-85 220,-85C183,-85 165,-64 158,-45l-120,0C53,-121 123,-172 223,-172M224,68C180,68 149,99 149,142C149,183 180,215 224,215C268,215 298,183 298,142C298,99 268,68 224,68z"
      />
      <glyph
        unicode="&#x2079;"
        horiz-adv-x="458"
        d="M223,276C288,276 337,296 372,335C409,378 426,445 426,515C426,606 406,670 357,709C322,737 276,754 222,754C109,754 29,689 29,590C29,490 106,427 198,427C244,427 276,447 299,473l2,0C299,433 290,406 276,389C263,372 243,363 220,363C183,363 165,384 158,403l-120,0C53,327 123,276 223,276M224,516C180,516 149,547 149,590C149,631 180,663 224,663C268,663 298,631 298,590C298,547 268,516 224,516z"
      />
      <glyph
        unicode="&#x2260;"
        horiz-adv-x="603"
        d="M61,372l217,0l-51,-99l-166,0l0,-119l106,0l-53,-104l103,0l53,104l277,0l0,119l-217,0l51,99l166,0l0,119l-106,0l53,104l-103,0l-53,-104l-277,0z"
      />
      <glyph
        unicode="&#xF1;"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0M379,613C445,613 484,668 484,751l-84,0C397,724 387,712 366,712C333,712 300,753 237,753C179,753 129,706 129,612l84,0C215,640 231,654 248,654C278,654 318,613 379,613z"
      />
      <glyph
        unicode="#"
        horiz-adv-x="639"
        d="M72,422l99,0l-19,-120l-97,0l-21,-117l99,0l-29,-185l100,0l29,185l115,0l-30,-185l100,0l29,185l98,0l20,117l-99,0l19,120l99,0l20,118l-100,0l28,173l-99,0l-28,-173l-115,0l28,173l-100,0l-28,-173l-97,0M271,422l115,0l-19,-120l-115,0z"
      />
      <glyph
        unicode="o"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97z"
      />
      <glyph
        unicode="&#xF3;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M247,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x14F;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M311,606C405,606 468,669 471,761l-89,0C377,728 351,703 310,703C266,703 241,725 236,761l-91,0C149,666 218,606 311,606z"
      />
      <glyph
        unicode="&#xF4;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M121,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#xF6;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M356,607l148,0l0,137l-148,0M113,607l149,0l0,137l-149,0z"
      />
      <glyph
        unicode="&#x153;"
        horiz-adv-x="978"
        d="M695,-16C829,-16 914,56 942,150l-153,0C772,117 745,98 696,98C626,98 582,146 571,226l381,0C953,331 926,422 865,480C820,523 762,547 687,547C605,547 541,512 499,454C458,511 392,547 302,547C133,547 27,425 27,265C27,106 133,-17 302,-17C392,-17 458,18 500,76C542,19 607,-16 695,-16M304,97C224,97 186,165 186,265C186,366 224,435 304,435C381,435 422,366 422,265C422,165 381,97 304,97M687,436C748,436 786,391 791,317l-219,0C582,390 616,436 687,436z"
      />
      <glyph
        unicode="&#x2DB;"
        horiz-adv-x="500"
        d="M225,-184C261,-184 282,-180 295,-175l0,79l-2,0C285,-98 276,-100 264,-100C243,-100 231,-92 231,-73C231,-50 251,-23 280,0l-97,0C147,-16 104,-47 104,-100C104,-151 145,-184 225,-184z"
      />
      <glyph
        unicode="&#xF2;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M258,612l116,0l-53,161l-183,0z"
      />
      <glyph
        unicode="&#x151;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M349,612l99,0l102,161l-153,0M155,612l99,0l103,161l-153,0z"
      />
      <glyph
        unicode="&#x14D;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M152,615l312,0l0,111l-312,0z"
      />
      <glyph unicode="1" horiz-adv-x="437" d="M197,0l163,0l0,719l-129,0C214,621 136,577 32,574l0,-109l165,0z" />
      <glyph
        unicode="&#x2081;"
        horiz-adv-x="342"
        d="M144,-160l129,0l0,461l-102,0C158,237 108,205 29,203l0,-87l115,0z"
      />
      <glyph
        unicode="&#x215B;"
        horiz-adv-x="943"
        d="M147,288l129,0l0,461l-102,0C161,685 111,653 32,651l0,-87l115,0M57,-130l103,0l628,874l-104,0M712,-122C836,-122 914,-68 914,21C914,72 882,116 818,134l0,3C868,156 893,190 893,230C893,306 823,356 712,356C603,356 532,302 532,227C532,185 556,153 608,134l0,-3C545,112 511,70 511,18C511,-67 589,-122 712,-122M712,169C672,169 650,190 650,223C650,254 672,276 712,276C753,276 775,254 775,223C775,190 753,169 712,169M712,-35C668,-35 638,-11 638,27C638,65 668,93 713,93C759,93 787,65 787,27C787,-10 758,-35 712,-35z"
      />
      <glyph
        unicode="&#xBD;"
        horiz-adv-x="944"
        d="M147,288l129,0l0,461l-102,0C161,685 111,653 32,651l0,-87l115,0M68,-130l103,0l628,874l-104,0M533,-110l377,0l0,99l-213,0C727,20 791,45 837,82C878,114 909,146 909,211C909,289 844,355 731,355C616,355 539,293 539,181l120,0C659,228 685,259 728,259C766,259 785,236 785,203C785,149 719,130 639,73C580,31 534,-21 533,-110z"
      />
      <glyph
        unicode="&#xBC;"
        horiz-adv-x="908"
        d="M691,-110l128,0l0,96l67,0l0,89l-67,0l0,276l-128,0l-219,-262l0,-103l219,0M77,-130l103,0l628,874l-104,0M147,288l129,0l0,461l-102,0C161,685 111,653 32,651l0,-87l115,0M551,68l0,2C551,70 588,101 609,125l46,57C672,203 696,240 696,240l2,0C698,240 691,197 691,171l0,-96l-62,0C599,75 551,68 551,68z"
      />
      <glyph unicode="&#xB9;" horiz-adv-x="342" d="M144,288l129,0l0,461l-102,0C158,685 108,653 29,651l0,-87l115,0z" />
      <glyph
        unicode="&#x2153;"
        horiz-adv-x="965"
        d="M147,288l129,0l0,461l-102,0C161,685 111,653 32,651l0,-87l115,0M67,-130l103,0l628,874l-104,0M745,-122C849,-122 933,-76 933,22C933,83 898,117 838,134l0,1C888,153 918,180 918,225C918,308 846,356 743,356C625,356 558,289 553,210l120,0C675,238 693,267 738,267C773,267 796,247 796,217C796,184 769,168 724,168l-22,0l0,-84l26,0C777,84 805,70 805,29C805,-7 781,-28 737,-28C684,-28 664,4 661,37l-121,0C541,-50 610,-122 745,-122z"
      />
      <glyph
        unicode="&#xAA;"
        horiz-adv-x="543"
        d="M359,245l139,0l0,8C487,260 481,282 481,318l0,241C481,623 457,671 408,699C374,718 331,727 271,727C131,727 63,651 58,572l133,0C197,607 219,633 273,633C322,633 342,610 342,578C342,543 312,534 224,523C118,510 37,480 37,375C37,285 109,234 200,234C271,234 315,264 341,304l1,0C345,277 352,256 359,245M243,326C206,326 176,341 176,381C176,419 200,435 263,447C295,453 325,462 344,473l0,-59C344,363 304,326 243,326z"
      />
      <glyph
        unicode="&#xBA;"
        horiz-adv-x="557"
        d="M279,232C430,232 527,339 527,479C527,620 430,727 279,727C129,727 30,620 30,479C30,339 129,232 279,232M278,333C210,333 176,393 176,479C176,565 210,626 278,626C346,626 381,565 381,479C381,393 346,333 278,333z"
      />
      <glyph
        unicode="&#xF8;"
        horiz-adv-x="616"
        d="M68,-57l78,87C190,0 245,-17 309,-17C478,-17 589,106 589,265C589,337 566,402 525,451l72,80l-49,44l-71,-79C432,528 375,547 309,547C139,547 27,425 27,265C27,190 52,123 96,73l-77,-86M183,265C183,366 228,436 308,436C344,436 372,423 392,402l-198,-220C186,206 183,234 183,265M308,96C275,96 249,105 229,123l195,216C429,317 432,292 432,265C432,165 386,96 308,96z"
      />
      <glyph
        unicode="&#x1FF;"
        horiz-adv-x="616"
        d="M68,-57l78,87C190,0 245,-17 309,-17C478,-17 589,106 589,265C589,337 566,402 525,451l72,80l-49,44l-71,-79C432,528 375,547 309,547C139,547 27,425 27,265C27,190 52,123 96,73l-77,-86M183,265C183,366 228,436 308,436C344,436 372,423 392,402l-198,-220C186,206 183,234 183,265M247,612l117,0l119,161l-183,0M308,96C275,96 249,105 229,123l195,216C429,317 432,292 432,265C432,165 386,96 308,96z"
      />
      <glyph
        unicode="&#xF5;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M381,613C447,613 486,668 486,751l-84,0C399,724 389,712 368,712C335,712 302,753 239,753C181,753 131,706 131,612l84,0C217,640 233,654 250,654C280,654 320,613 381,613z"
      />
      <glyph
        unicode="p"
        horiz-adv-x="624"
        d="M52,-177l157,0l0,127C209,7 206,46 204,66l2,0C237,14 293,-17 363,-17C505,-17 597,93 597,266C597,429 509,547 368,547C295,547 242,517 206,456l-3,0l0,76l-151,0M326,105C246,105 204,170 204,261C204,351 243,422 324,422C401,422 438,356 438,261C438,166 397,105 326,105z"
      />
      <glyph
        unicode="&#xB6;"
        horiz-adv-x="604"
        d="M279,351l0,-491l92,0l0,788l79,0l0,-788l92,0l0,884l-290,0C184,744 133,727 95,691C58,655 36,604 36,545C36,430 114,351 236,351z"
      />
      <glyph
        unicode="("
        horiz-adv-x="366"
        d="M223,-141l129,0C280,-22 222,125 222,313C222,512 277,663 353,784l-130,0C134,681 61,508 61,313C61,124 135,-34 223,-141z"
      />
      <glyph
        unicode=")"
        horiz-adv-x="366"
        d="M14,-141l130,0C232,-34 306,124 306,313C306,508 233,681 144,784l-131,0C90,663 145,512 145,313C145,125 86,-22 14,-141z"
      />
      <glyph
        unicode="&#x2202;"
        horiz-adv-x="616"
        d="M416,446C388,477 344,490 294,490C137,490 31,374 31,216C31,84 123,-16 285,-16C486,-16 580,125 580,322C580,581 458,729 173,751l0,-132C317,599 393,546 416,446M287,94C222,94 188,142 188,218C188,315 238,382 316,382C380,382 414,338 414,266C414,182 372,94 287,94z"
      />
      <glyph
        unicode="%"
        horiz-adv-x="931"
        d="M218,312C326,312 404,393 404,520C404,647 328,727 218,727C110,727 31,646 31,520C31,393 110,312 218,312M224,0l94,0l388,712l-94,0M712,-18C822,-18 900,64 900,191C900,317 823,399 712,399C605,399 525,317 525,191C525,64 604,-18 712,-18M218,398C172,398 152,449 152,520C152,592 172,643 218,643C264,643 285,590 285,520C285,448 264,398 218,398M713,69C666,69 646,120 646,191C646,262 666,315 713,315C757,315 779,262 779,191C779,120 759,69 713,69z"
      />
      <glyph unicode="." horiz-adv-x="254" d="M44,0l166,0l0,164l-166,0z" />
      <glyph unicode="&#xB7;" horiz-adv-x="257" d="M45,247l167,0l0,165l-167,0z" />
      <glyph
        unicode="&#x2030;"
        horiz-adv-x="1346"
        d="M221,0l94,0l388,712l-94,0M218,312C326,312 404,393 404,520C404,647 328,727 218,727C110,727 31,646 31,520C31,393 110,312 218,312M706,-18C816,-18 894,64 894,191C894,317 817,399 706,399C599,399 520,317 520,191C520,64 599,-18 706,-18M218,398C172,398 152,449 152,520C152,592 172,643 218,643C264,643 285,590 285,520C285,448 264,398 218,398M706,69C661,69 640,120 640,191C640,262 661,315 706,315C752,315 773,262 773,191C773,120 753,69 706,69M1128,-18C1237,-18 1315,64 1315,191C1315,317 1238,399 1128,399C1020,399 940,317 940,191C940,64 1019,-18 1128,-18M1128,69C1081,69 1062,120 1062,191C1062,262 1081,315 1128,315C1173,315 1195,262 1195,191C1195,120 1174,69 1128,69z"
      />
      <glyph
        unicode="&#x3C0;"
        horiz-adv-x="677"
        d="M96,0l157,0l0,432l157,0l0,-301C410,28 470,-7 560,-7C594,-7 617,-3 638,3l0,113l-3,0C626,115 617,113 607,113C578,113 566,123 566,155l0,277l81,0l0,100l-626,0l0,-100l75,0z"
      />
      <glyph
        unicode="+"
        horiz-adv-x="606"
        d="M53,279l183,0l0,-195l134,0l0,195l183,0l0,126l-183,0l0,193l-134,0l0,-193l-183,0z"
      />
      <glyph
        unicode="&#xB1;"
        horiz-adv-x="606"
        d="M53,350l183,0l0,-166l134,0l0,166l183,0l0,124l-183,0l0,164l-134,0l0,-164l-183,0M53,0l500,0l0,120l-500,0z"
      />
      <glyph unicode="&#x220F;" horiz-adv-x="764" d="M69,744l0,-848l171,0l0,703l283,0l0,-703l172,0l0,848z" />
      <glyph
        unicode="q"
        horiz-adv-x="624"
        d="M416,-177l156,0l0,709l-150,0l0,-75l-3,0C383,518 332,547 259,547C118,547 28,429 28,266C28,93 119,-17 262,-17C332,-17 386,14 418,66l2,0C418,44 416,1 416,-50M298,105C228,105 187,166 187,261C187,356 223,422 301,422C381,422 421,352 421,261C421,170 379,105 298,105z"
      />
      <glyph
        unicode="?"
        horiz-adv-x="580"
        d="M189,0l166,0l0,164l-166,0M204,259l140,0C345,288 349,303 365,321C386,344 423,362 477,404C516,435 551,481 551,556C551,677 453,757 297,757C124,757 25,652 21,518l156,0C178,580 223,627 291,627C346,627 386,598 386,552C386,494 325,468 280,429C225,383 204,343 204,259z"
      />
      <glyph
        unicode="&#xBF;"
        horiz-adv-x="580"
        d="M391,612l-166,0l0,-164l166,0M376,353l-140,0C236,324 231,309 215,291C194,268 158,250 104,208C65,177 29,131 29,56C29,-65 128,-145 284,-145C456,-145 555,-40 559,94l-155,0C402,32 358,-15 290,-15C234,-15 194,14 194,60C194,118 255,144 300,183C355,229 376,269 376,353z"
      />
      <glyph unicode='"' horiz-adv-x="451" d="M264,458l135,0l0,286l-135,0M52,458l137,0l0,286l-137,0z" />
      <glyph
        unicode="&#x201E;"
        horiz-adv-x="439"
        d="M187,147l-146,0l0,-147l67,0l0,-2C108,-56 77,-89 38,-100l0,-59C115,-151 187,-103 187,15M398,147l-146,0l0,-147l67,0l0,-2C319,-56 287,-89 249,-100l0,-59C326,-151 398,-103 398,15z"
      />
      <glyph
        unicode="&#x201C;"
        horiz-adv-x="439"
        d="M252,441l146,0l0,147l-67,0l0,2C331,643 362,677 400,687l0,60C324,738 252,691 252,573M41,441l146,0l0,147l-67,0l0,2C120,643 152,677 190,687l0,60C113,738 41,691 41,573z"
      />
      <glyph
        unicode="&#x201D;"
        horiz-adv-x="439"
        d="M187,744l-146,0l0,-147l67,0l0,-2C108,541 77,508 38,497l0,-59C115,446 187,494 187,612M398,744l-146,0l0,-147l67,0l0,-2C319,541 287,508 249,497l0,-59C326,446 398,494 398,612z"
      />
      <glyph
        unicode="&#x2018;"
        horiz-adv-x="228"
        d="M41,441l146,0l0,147l-67,0l0,2C120,643 152,677 190,687l0,60C113,738 41,691 41,573z"
      />
      <glyph
        unicode="&#x2019;"
        horiz-adv-x="228"
        d="M187,744l-146,0l0,-147l67,0l0,-2C108,541 77,508 38,497l0,-59C115,446 187,494 187,612z"
      />
      <glyph
        unicode="&#x201A;"
        horiz-adv-x="228"
        d="M187,147l-146,0l0,-147l67,0l0,-2C108,-56 77,-89 38,-100l0,-59C115,-151 187,-103 187,15z"
      />
      <glyph unicode="'" horiz-adv-x="241" d="M52,458l137,0l0,286l-137,0z" />
      <glyph
        unicode="r"
        horiz-adv-x="410"
        d="M203,532l-151,0l0,-532l157,0l0,247C209,351 265,404 350,403C361,403 372,402 383,400l4,0l0,137C380,540 369,541 354,541C288,541 240,511 206,437l-3,0z"
      />
      <glyph
        unicode="&#x155;"
        horiz-adv-x="410"
        d="M203,532l-151,0l0,-532l157,0l0,247C209,351 265,404 350,403C361,403 372,402 383,400l4,0l0,137C380,540 369,541 354,541C288,541 240,511 206,437l-3,0M161,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x221A;"
        horiz-adv-x="683"
        d="M18,211l76,0l134,-315l168,0l278,848l-160,0l-170,-542C323,134 307,64 307,64l-2,0C305,64 286,134 259,202l-56,143l-185,0z"
      />
      <glyph
        unicode="&#x159;"
        horiz-adv-x="410"
        d="M203,532l-151,0l0,-532l157,0l0,247C209,351 265,404 350,403C361,403 372,402 383,400l4,0l0,137C380,540 369,541 354,541C288,541 240,511 206,437l-3,0M416,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z"
      />
      <glyph
        unicode="&#x157;"
        horiz-adv-x="410"
        d="M203,532l-151,0l0,-532l157,0l0,247C209,351 265,404 350,403C361,403 372,402 383,400l4,0l0,137C380,540 369,541 354,541C288,541 240,511 206,437l-3,0M50,-263C137,-257 205,-217 205,-122l0,71l-151,0l0,-103l67,0C121,-184 92,-202 50,-210z"
      />
      <glyph
        unicode="&#xAE;"
        horiz-adv-x="658"
        d="M330,172C491,172 618,297 618,464C618,633 491,757 330,757C168,757 40,633 40,464C40,297 168,172 330,172M330,215C192,215 91,321 91,464C91,608 192,713 330,713C466,713 568,608 568,464C568,321 466,215 330,215M211,319l80,0l0,108l43,0C362,427 376,414 379,388C383,359 383,324 392,319l79,0l0,8C459,331 461,372 456,402C451,435 441,447 407,456l0,2C449,471 464,495 464,530C464,586 415,615 356,615l-145,0M291,554l52,0C372,554 384,541 384,520C384,497 370,486 342,486l-51,0z"
      />
      <glyph
        unicode="&#x2DA;"
        horiz-adv-x="500"
        d="M251,596C313,596 365,643 365,706C365,767 313,817 251,817C188,817 136,767 136,706C136,643 188,596 251,596M251,653C219,653 198,676 198,706C198,736 219,761 251,761C282,761 303,736 303,706C303,676 282,653 251,653z"
      />
      <glyph
        unicode="s"
        horiz-adv-x="539"
        d="M273,-17C411,-17 513,43 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C26,57 119,-17 273,-17z"
      />
      <glyph
        unicode="&#x15B;"
        horiz-adv-x="539"
        d="M273,-17C411,-17 513,43 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C26,57 119,-17 273,-17M201,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x161;"
        horiz-adv-x="539"
        d="M273,-17C411,-17 513,43 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C26,57 119,-17 273,-17M449,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z"
      />
      <glyph
        unicode="&#x15F;"
        horiz-adv-x="539"
        d="M236,-210C312,-210 364,-178 364,-128C364,-72 310,-48 251,-57l0,2l43,39C421,-10 513,49 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C25,67 102,-3 231,-15l-56,-61l18,-26C203,-98 213,-95 226,-95C246,-95 261,-104 261,-124C261,-148 239,-157 212,-157C187,-157 163,-150 143,-141l-20,-39C150,-197 189,-210 236,-210z"
      />
      <glyph
        unicode="&#x15D;"
        horiz-adv-x="539"
        d="M273,-17C411,-17 513,43 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C26,57 119,-17 273,-17M75,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#x219;"
        horiz-adv-x="539"
        d="M273,-17C411,-17 513,43 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C26,57 119,-17 273,-17M185,-263C272,-257 340,-217 340,-122l0,71l-151,0l0,-103l67,0C256,-184 227,-202 185,-210z"
      />
      <glyph
        unicode="&#xA7;"
        horiz-adv-x="661"
        d="M344,-189C479,-189 573,-123 573,-5C573,56 542,94 501,123C576,141 626,182 626,267C626,375 526,421 417,467C312,510 263,526 263,568C263,601 287,623 340,623C398,623 425,593 429,553l138,0C561,669 472,741 334,741C203,741 111,671 111,570C111,513 134,485 169,456C89,437 39,386 39,313C39,208 117,160 244,107C362,57 420,45 420,-5C420,-40 390,-68 338,-68C273,-68 237,-30 236,14l-145,0C95,-100 188,-189 344,-189M342,347C435,306 478,284 478,243C478,212 452,193 415,184l-97,45C214,277 182,294 182,332C182,363 205,382 243,390C270,377 306,362 342,347z"
      />
      <glyph
        unicode=";"
        horiz-adv-x="261"
        d="M47,321l166,0l0,164l-166,0M48,-181C140,-173 213,-116 213,10l0,154l-166,0l0,-164l78,0l0,-2C125,-61 95,-99 48,-111z"
      />
      <glyph
        unicode="7"
        horiz-adv-x="576"
        d="M125,0l172,0C305,250 422,460 551,587l0,125l-523,0l0,-138l192,0C301,574 392,580 392,580l0,-2C273,472 137,258 125,0z"
      />
      <glyph
        unicode="&#x2087;"
        horiz-adv-x="403"
        d="M232,-160C236,-4 299,123 380,202l0,94l-355,0l0,-104l128,0C224,192 263,197 263,197l0,-2C172,115 98,-4 94,-160z"
      />
      <glyph
        unicode="&#x2077;"
        horiz-adv-x="403"
        d="M232,288C236,444 299,571 380,650l0,94l-355,0l0,-104l128,0C224,640 263,645 263,645l0,-2C172,563 98,444 94,288z"
      />
      <glyph
        unicode="&#x215E;"
        horiz-adv-x="969"
        d="M738,-122C862,-122 940,-68 940,21C940,72 908,116 844,134l0,3C894,156 919,190 919,230C919,306 849,356 738,356C629,356 558,302 558,227C558,185 582,153 634,134l0,-3C571,112 537,70 537,18C537,-67 615,-122 738,-122M62,-130l103,0l628,874l-104,0M235,288C239,444 302,571 383,650l0,94l-355,0l0,-104l128,0C227,640 266,645 266,645l0,-2C175,563 101,444 97,288M738,-35C694,-35 664,-11 664,27C664,65 694,93 739,93C785,93 813,65 813,27C813,-10 784,-35 738,-35M738,169C698,169 676,190 676,223C676,254 698,276 738,276C779,276 801,254 801,223C801,190 779,169 738,169z"
      />
      <glyph
        unicode="6"
        horiz-adv-x="653"
        d="M344,-17C503,-17 621,86 621,236C620,385 504,487 368,487C289,487 234,454 194,398l-2,0C200,538 251,610 346,610C406,610 434,576 444,543l156,0C579,647 491,727 343,727C242,727 172,695 121,635C62,566 37,467 38,352C38,239 64,146 119,81C169,20 244,-17 344,-17M338,105C262,105 204,159 204,235C204,311 258,365 338,365C411,365 466,310 466,234C466,160 410,105 338,105z"
      />
      <glyph
        unicode="&#x2086;"
        horiz-adv-x="461"
        d="M241,-171C354,-171 432,-106 432,-8C432,91 355,154 264,154C217,154 180,137 156,107l-1,0C161,181 193,221 241,221C278,221 292,200 297,181l124,0C403,260 340,307 244,307C174,307 121,284 86,242C49,199 32,138 32,68C32,-12 53,-75 94,-116C129,-151 178,-171 241,-171M237,-79C193,-79 162,-49 162,-8C162,34 192,65 237,65C281,65 310,34 310,-8C310,-49 281,-79 237,-79z"
      />
      <glyph
        unicode="&#x2076;"
        horiz-adv-x="461"
        d="M241,277C354,277 432,342 432,440C432,539 355,602 264,602C217,602 180,585 156,555l-1,0C161,629 193,669 241,669C278,669 292,648 297,629l124,0C403,708 340,755 244,755C174,755 121,732 86,690C49,647 32,586 32,516C32,436 53,373 94,332C129,297 178,277 241,277M237,369C193,369 162,399 162,440C162,482 192,513 237,513C281,513 310,482 310,440C310,399 281,369 237,369z"
      />
      <glyph unicode="/" horiz-adv-x="466" d="M-19,-141l143,0l334,925l-143,0z" />
      <glyph unicode=" " horiz-adv-x="241" />
      <glyph
        unicode="&#xA3;"
        horiz-adv-x="627"
        d="M39,302l88,0C131,287 134,272 134,256C134,196 92,148 33,110l80,-119C146,15 180,36 222,36C304,36 338,-15 440,-15C503,-15 563,12 604,48l-69,126C513,152 478,130 438,130C382,130 366,157 295,157C260,157 232,149 193,129l-2,3C258,181 283,226 283,279C283,287 282,294 282,302l118,0l0,92l-144,0C241,429 225,462 225,503C225,561 259,600 327,600C393,600 439,562 439,476l158,0C595,569 566,627 514,670C466,709 402,727 325,727C149,727 54,631 54,507C54,461 68,426 85,394l-46,0z"
      />
      <glyph
        unicode="&#x2211;"
        horiz-adv-x="672"
        d="M630,-104l0,140l-393,0l0,2l235,260l0,67l-228,243l0,2l376,0l0,134l-571,0l0,-135l256,-279l0,-2l-256,-289l0,-143z"
      />
      <glyph
        unicode="t"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-298C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,264l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0z"
      />
      <glyph
        unicode="&#x167;"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-75l-80,0l0,-92l80,0l0,-131C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,97l104,0l0,92l-104,0l0,75l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0z"
      />
      <glyph
        unicode="&#x165;"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-298C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,264l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0M298,559C380,563 429,594 429,686l0,58l-131,0l0,-90l59,0C356,628 338,613 298,609z"
      />
      <glyph
        unicode="&#x163;"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-298C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,264l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0M143,-263C230,-257 298,-217 298,-122l0,71l-151,0l0,-103l67,0C214,-184 185,-202 143,-210z"
      />
      <glyph
        unicode="&#xFE;"
        horiz-adv-x="622"
        d="M367,-17C505,-17 597,93 597,266C597,429 509,547 368,547C295,547 243,512 207,458l-3,0C206,490 209,536 209,581l0,163l-157,0l0,-920l157,0l0,126C209,7 206,46 204,66l2,0C237,14 296,-17 367,-17M326,105C246,105 204,170 204,261C204,351 243,422 324,422C401,422 438,356 438,261C438,166 397,105 326,105z"
      />
      <glyph
        unicode="3"
        horiz-adv-x="635"
        d="M324,-17C473,-17 603,53 603,204C603,299 550,357 460,382l0,2C534,409 581,453 581,527C581,653 473,727 322,727C147,727 49,626 46,497l152,0C202,552 238,606 319,606C381,606 421,571 421,518C421,460 376,429 303,429l-39,0l0,-114l43,0C391,315 438,281 438,215C438,149 394,109 318,109C223,109 184,165 179,234l-154,0C26,159 54,90 111,45C163,5 231,-17 324,-17z"
      />
      <glyph
        unicode="&#x2083;"
        horiz-adv-x="444"
        d="M227,-172C331,-172 415,-126 415,-28C415,33 380,67 320,84l0,1C370,103 400,130 400,175C400,258 328,306 225,306C107,306 40,239 35,160l120,0C157,188 175,217 220,217C255,217 278,197 278,167C278,134 251,118 206,118l-22,0l0,-84l26,0C259,34 287,20 287,-21C287,-57 263,-78 219,-78C166,-78 146,-46 143,-13l-121,0C23,-100 92,-172 227,-172z"
      />
      <glyph
        unicode="&#x215C;"
        horiz-adv-x="1022"
        d="M230,276C334,276 418,322 418,420C418,481 383,515 323,532l0,1C373,551 403,578 403,623C403,706 331,754 228,754C110,754 43,687 38,608l120,0C160,636 178,665 223,665C258,665 281,645 281,615C281,582 254,566 209,566l-22,0l0,-84l26,0C262,482 290,468 290,427C290,391 266,370 222,370C169,370 149,402 146,435l-121,0C26,348 95,276 230,276M137,-130l103,0l628,874l-104,0M791,-122C915,-122 993,-68 993,21C993,72 961,116 897,134l0,3C947,156 972,190 972,230C972,306 902,356 791,356C682,356 611,302 611,227C611,185 635,153 687,134l0,-3C624,112 590,70 590,18C590,-67 668,-122 791,-122M791,169C751,169 729,190 729,223C729,254 751,276 791,276C832,276 854,254 854,223C854,190 832,169 791,169M791,-35C747,-35 717,-11 717,27C717,65 747,93 792,93C838,93 866,65 866,27C866,-10 837,-35 791,-35z"
      />
      <glyph
        unicode="&#xBE;"
        horiz-adv-x="996"
        d="M779,-110l128,0l0,96l67,0l0,89l-67,0l0,276l-128,0l-219,-262l0,-103l219,0M230,276C334,276 418,322 418,420C418,481 383,515 323,532l0,1C373,551 403,578 403,623C403,706 331,754 228,754C110,754 43,687 38,608l120,0C160,636 178,665 223,665C258,665 281,645 281,615C281,582 254,566 209,566l-22,0l0,-84l26,0C262,482 290,468 290,427C290,391 266,370 222,370C169,370 149,402 146,435l-121,0C26,348 95,276 230,276M156,-130l103,0l628,874l-104,0M639,68l0,2C639,70 676,101 697,125l46,57C760,203 784,240 784,240l2,0C786,240 779,197 779,171l0,-96l-62,0C687,75 639,68 639,68z"
      />
      <glyph
        unicode="&#xB3;"
        horiz-adv-x="444"
        d="M227,276C331,276 415,322 415,420C415,481 380,515 320,532l0,1C370,551 400,578 400,623C400,706 328,754 225,754C107,754 40,687 35,608l120,0C157,636 175,665 220,665C255,665 278,645 278,615C278,582 251,566 206,566l-22,0l0,-84l26,0C259,482 287,468 287,427C287,391 263,370 219,370C166,370 146,402 143,435l-121,0C23,348 92,276 227,276z"
      />
      <glyph
        unicode="&#x2DC;"
        horiz-adv-x="500"
        d="M326,613C392,613 431,668 431,751l-84,0C344,724 334,712 313,712C280,712 247,753 184,753C126,753 76,706 76,612l84,0C162,640 178,654 195,654C225,654 265,613 326,613z"
      />
      <glyph
        unicode="&#x2122;"
        horiz-adv-x="965"
        d="M450,362l98,0l0,113C548,538 545,637 545,637l2,0C547,637 568,546 587,485l38,-123l88,0l38,123C771,545 792,637 792,637l3,0C795,637 792,538 792,475l0,-113l100,0l0,382l-153,0l-35,-117C688,573 671,486 671,486l-2,0C669,486 652,573 636,627l-35,117l-151,0M51,652l111,0l0,-290l112,0l0,290l111,0l0,92l-334,0z"
      />
      <glyph
        unicode="2"
        horiz-adv-x="610"
        d="M31,0l544,0l0,136l-326,0l0,3C305,208 413,253 487,320C539,364 576,418 576,500C576,627 484,727 318,727C208,727 131,688 90,628C57,582 42,529 42,458l155,0C197,501 207,537 230,562C249,585 276,595 313,595C375,595 411,555 411,495C411,445 384,413 338,380C266,328 172,271 106,198C59,145 32,88 31,0z"
      />
      <glyph
        unicode="&#x2082;"
        horiz-adv-x="436"
        d="M28,-160l377,0l0,99l-213,0C222,-30 286,-5 332,32C373,64 404,96 404,161C404,239 339,305 226,305C111,305 34,243 34,131l120,0C154,178 180,209 223,209C261,209 280,186 280,153C280,99 214,80 134,23C75,-19 29,-71 28,-160z"
      />
      <glyph
        unicode="&#xB2;"
        horiz-adv-x="436"
        d="M28,288l377,0l0,99l-213,0C222,418 286,443 332,480C373,512 404,544 404,609C404,687 339,753 226,753C111,753 34,691 34,579l120,0C154,626 180,657 223,657C261,657 280,634 280,601C280,547 214,528 134,471C75,429 29,377 28,288z"
      />
      <glyph
        unicode="&#x2154;"
        horiz-adv-x="1053"
        d="M31,288l377,0l0,99l-213,0C225,418 289,443 335,480C376,512 407,544 407,609C407,687 342,753 229,753C114,753 37,691 37,579l120,0C157,626 183,657 226,657C264,657 283,634 283,601C283,547 217,528 137,471C78,429 32,377 31,288M171,-130l103,0l628,874l-104,0M833,-122C937,-122 1021,-76 1021,22C1021,83 986,117 926,134l0,1C976,153 1006,180 1006,225C1006,308 934,356 831,356C713,356 646,289 641,210l120,0C763,238 781,267 826,267C861,267 884,247 884,217C884,184 857,168 812,168l-22,0l0,-84l26,0C865,84 893,70 893,29C893,-7 869,-28 825,-28C772,-28 752,4 749,37l-121,0C629,-50 698,-122 833,-122z"
      />
      <glyph
        unicode="u"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76z"
      />
      <glyph
        unicode="&#xFA;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M235,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x16D;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M299,606C393,606 456,669 459,761l-89,0C365,728 339,703 298,703C254,703 229,725 224,761l-91,0C137,666 206,606 299,606z"
      />
      <glyph
        unicode="&#xFB;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M109,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#xFC;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M101,607l149,0l0,137l-149,0M344,607l148,0l0,137l-148,0z"
      />
      <glyph
        unicode="&#xF9;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M246,612l116,0l-53,161l-183,0z"
      />
      <glyph
        unicode="&#x171;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M143,612l99,0l103,161l-153,0M337,612l99,0l102,161l-153,0z"
      />
      <glyph
        unicode="&#x16B;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M140,615l312,0l0,111l-312,0z"
      />
      <glyph unicode="_" horiz-adv-x="445" d="M-2,-176l449,0l0,99l-449,0z" />
      <glyph unicode="&#xA0;" horiz-adv-x="237" />
      <glyph unicode="&#xAD;" horiz-adv-x="363" d="M40,197l283,0l0,136l-283,0z" />
      <glyph
        unicode="&#x162;"
        horiz-adv-x="668"
        d="M27,599l221,0l0,-599l171,0l0,599l222,0l0,145l-614,0M257,-263C344,-257 412,-217 412,-122l0,71l-151,0l0,-103l67,0C328,-184 299,-202 257,-210z"
      />
      <glyph
        unicode="&#x163;"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-298C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,264l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0M143,-263C230,-257 298,-217 298,-122l0,71l-151,0l0,-103l67,0C214,-184 185,-202 143,-210z"
      />
      <glyph
        unicode="&#x21A;"
        horiz-adv-x="668"
        d="M27,599l221,0l0,-599l171,0l0,599l222,0l0,145l-614,0M257,-263C344,-257 412,-217 412,-122l0,71l-151,0l0,-103l67,0C328,-184 299,-202 257,-210z"
      />
      <glyph
        unicode="&#x21B;"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-298C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,264l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0M143,-263C230,-257 298,-217 298,-122l0,71l-151,0l0,-103l67,0C214,-184 185,-202 143,-210z"
      />
      <glyph
        unicode="&#x237;"
        horiz-adv-x="261"
        d="M52,-181C100,-181 140,-170 169,-141C194,-117 209,-81 209,-13l0,545l-157,0l0,-539C52,-46 37,-58 6,-61C-5,-62 -17,-62 -31,-62l-6,0l0,-111C-13,-178 24,-181 52,-181z"
      />
      <glyph unicode="&#x2C9;" horiz-adv-x="500" d="M96,615l312,0l0,111l-312,0z" />
      <glyph
        unicode="&#x394;"
        horiz-adv-x="723"
        d="M27,0l669,0l0,112l-241,632l-188,0l-240,-632M203,132l102,283C339,509 356,605 356,605l2,0C358,605 374,509 408,415l103,-283z"
      />
      <glyph
        unicode="&#x3A9;"
        horiz-adv-x="780"
        d="M40,0l315,0l0,125C250,142 205,245 205,383C205,530 272,619 390,619C508,619 575,530 575,383C575,245 530,142 425,125l0,-125l315,0l0,130l-114,0l0,2C705,185 748,274 748,395C748,608 602,757 390,757C178,757 32,608 32,395C32,274 74,185 153,132l0,-2l-113,0z"
      />
      <glyph
        unicode="&#x3BC;"
        horiz-adv-x="603"
        d="M52,-177l157,0l0,84C209,-46 205,7 205,7l2,0C215,-1 231,-8 254,-8C325,-8 372,28 399,76l3,0l0,-76l149,0l0,532l-155,0l0,-301C396,165 357,117 294,117C238,117 209,151 209,210l0,322l-157,0z"
      />
      <glyph unicode="&#x2009;" horiz-adv-x="110" />
      <glyph unicode="&#x2032;" horiz-adv-x="241" d="M31,470l138,0l41,274l-137,0z" />
      <glyph unicode="&#x2033;" horiz-adv-x="443" d="M233,470l138,0l41,274l-137,0M31,470l138,0l41,274l-137,0z" />
      <glyph unicode="&#x2215;" horiz-adv-x="295" d="M-274,-130l103,0l628,874l-104,0z" />
      <glyph unicode="&#x2219;" horiz-adv-x="257" d="M45,247l167,0l0,165l-167,0z" />
      <glyph
        unicode="&#x24C5;"
        horiz-adv-x="867"
        d="M434,-18C648,-18 816,147 816,369C816,592 648,757 434,757C220,757 51,592 51,369C51,147 220,-18 434,-18M434,32C249,32 110,176 110,369C110,563 249,706 434,706C618,706 756,563 756,369C756,176 618,32 434,32M279,164l109,0l0,138l99,0C572,302 628,360 628,441C628,482 613,518 586,543C559,568 522,582 472,582l-193,0M388,493l72,0C498,493 520,473 520,439C520,406 498,387 461,387l-73,0z"
      />
      <glyph
        unicode="&#xF8FF;"
        horiz-adv-x="880"
        d="M68,0l745,0l0,745l-745,0M190,448l175,0l0,170l150,0l0,-170l175,0l0,-149l-175,0l0,-176l-150,0l0,176l-175,0z"
      />
      <glyph
        unicode="&#x173;"
        horiz-adv-x="599"
        d="M482,-184C518,-184 539,-180 552,-175l0,79l-2,0C542,-98 533,-100 521,-100C500,-100 488,-92 488,-73C488,-50 507,-21 547,2l0,530l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76l37,0C399,-18 364,-50 364,-100C364,-151 406,-184 482,-184z"
      />
      <glyph
        unicode="&#x16F;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M297,596C359,596 411,643 411,706C411,767 359,817 297,817C234,817 182,767 182,706C182,643 234,596 297,596M297,653C265,653 244,676 244,706C244,736 265,761 297,761C328,761 349,736 349,706C349,676 328,653 297,653z"
      />
      <glyph
        unicode="&#x169;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M369,613C435,613 474,668 474,751l-84,0C387,724 377,712 356,712C323,712 290,753 227,753C169,753 119,706 119,612l84,0C205,640 221,654 238,654C268,654 308,613 369,613z"
      />
      <glyph
        unicode="v"
        horiz-adv-x="549"
        d="M189,0l169,0l186,532l-158,0l-67,-214C297,247 277,132 277,132l-3,0C274,132 254,247 232,318l-65,214l-162,0z"
      />
      <glyph
        unicode="w"
        horiz-adv-x="822"
        d="M171,0l146,0l58,217C391,278 410,380 410,380l2,0C412,380 429,277 445,217l56,-217l148,0l165,532l-155,0l-57,-207C584,261 570,159 570,159l-2,0C568,159 551,263 533,327l-56,205l-133,0l-54,-205C273,263 256,160 256,160l-2,0C254,160 240,262 223,325l-56,207l-160,0z"
      />
      <glyph
        unicode="&#x1E83;"
        horiz-adv-x="822"
        d="M171,0l146,0l58,217C391,278 410,380 410,380l2,0C412,380 429,277 445,217l56,-217l148,0l165,532l-155,0l-57,-207C584,261 570,159 570,159l-2,0C568,159 551,263 533,327l-56,205l-133,0l-54,-205C273,263 256,160 256,160l-2,0C254,160 240,262 223,325l-56,207l-160,0M349,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x175;"
        horiz-adv-x="822"
        d="M171,0l146,0l58,217C391,278 410,380 410,380l2,0C412,380 429,277 445,217l56,-217l148,0l165,532l-155,0l-57,-207C584,261 570,159 570,159l-2,0C568,159 551,263 533,327l-56,205l-133,0l-54,-205C273,263 256,160 256,160l-2,0C254,160 240,262 223,325l-56,207l-160,0M223,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#x1E85;"
        horiz-adv-x="822"
        d="M171,0l146,0l58,217C391,278 410,380 410,380l2,0C412,380 429,277 445,217l56,-217l148,0l165,532l-155,0l-57,-207C584,261 570,159 570,159l-2,0C568,159 551,263 533,327l-56,205l-133,0l-54,-205C273,263 256,160 256,160l-2,0C254,160 240,262 223,325l-56,207l-160,0M458,607l148,0l0,137l-148,0M215,607l149,0l0,137l-149,0z"
      />
      <glyph
        unicode="&#x1E81;"
        horiz-adv-x="822"
        d="M171,0l146,0l58,217C391,278 410,380 410,380l2,0C412,380 429,277 445,217l56,-217l148,0l165,532l-155,0l-57,-207C584,261 570,159 570,159l-2,0C568,159 551,263 533,327l-56,205l-133,0l-54,-205C273,263 256,160 256,160l-2,0C254,160 240,262 223,325l-56,207l-160,0M360,612l116,0l-53,161l-183,0z"
      />
      <glyph
        unicode="x"
        horiz-adv-x="593"
        d="M17,0l171,0l43,68C259,113 293,186 293,186l2,0C295,186 328,113 353,68l41,-68l182,0l-187,282l163,250l-166,0l-29,-50C333,438 303,369 303,369l-2,0C301,369 271,438 245,482l-31,50l-179,0l169,-253z"
      />
      <glyph
        unicode="y"
        horiz-adv-x="557"
        d="M168,-178C270,-178 319,-136 362,-13l190,545l-156,0l-65,-204C308,257 284,143 284,143l-2,0C282,143 262,256 238,328l-68,204l-165,0l165,-425C189,59 198,18 198,0C195,-36 171,-54 122,-54C118,-54 73,-54 71,-53l-8,0l0,-123C66,-177 163,-178 168,-178z"
      />
      <glyph
        unicode="&#xFD;"
        horiz-adv-x="557"
        d="M168,-178C270,-178 319,-136 362,-13l190,545l-156,0l-65,-204C308,257 284,143 284,143l-2,0C282,143 262,256 238,328l-68,204l-165,0l165,-425C189,59 198,18 198,0C195,-36 171,-54 122,-54C118,-54 73,-54 71,-53l-8,0l0,-123C66,-177 163,-178 168,-178M221,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x177;"
        horiz-adv-x="557"
        d="M168,-178C270,-178 319,-136 362,-13l190,545l-156,0l-65,-204C308,257 284,143 284,143l-2,0C282,143 262,256 238,328l-68,204l-165,0l165,-425C189,59 198,18 198,0C195,-36 171,-54 122,-54C118,-54 73,-54 71,-53l-8,0l0,-123C66,-177 163,-178 168,-178M95,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#xFF;"
        horiz-adv-x="557"
        d="M168,-178C270,-178 319,-136 362,-13l190,545l-156,0l-65,-204C308,257 284,143 284,143l-2,0C282,143 262,256 238,328l-68,204l-165,0l165,-425C189,59 198,18 198,0C195,-36 171,-54 122,-54C118,-54 73,-54 71,-53l-8,0l0,-123C66,-177 163,-178 168,-178M330,607l148,0l0,137l-148,0M87,607l149,0l0,137l-149,0z"
      />
      <glyph
        unicode="&#xA5;"
        horiz-adv-x="677"
        d="M82,268l177,0l0,-72l-177,0l0,-65l177,0l0,-131l157,0l0,131l177,0l0,65l-177,0l0,72l177,0l0,64l-150,0l220,381l-177,0l-71,-137C381,510 340,401 340,401l-2,0C338,401 299,510 264,576l-72,137l-180,0l219,-381l-149,0z"
      />
      <glyph
        unicode="&#x1EF3;"
        horiz-adv-x="557"
        d="M168,-178C270,-178 319,-136 362,-13l190,545l-156,0l-65,-204C308,257 284,143 284,143l-2,0C282,143 262,256 238,328l-68,204l-165,0l165,-425C189,59 198,18 198,0C195,-36 171,-54 122,-54C118,-54 73,-54 71,-53l-8,0l0,-123C66,-177 163,-178 168,-178M232,612l116,0l-53,161l-183,0z"
      />
      <glyph
        unicode="z"
        horiz-adv-x="538"
        d="M36,0l469,0l0,123l-174,0C283,123 207,116 207,116l0,3C207,119 272,172 308,211l183,208l0,113l-432,0l0,-122l141,0C245,410 315,418 315,418l0,-3C315,415 258,367 226,332l-190,-215z"
      />
      <glyph
        unicode="&#x17A;"
        horiz-adv-x="538"
        d="M36,0l469,0l0,123l-174,0C283,123 207,116 207,116l0,3C207,119 272,172 308,211l183,208l0,113l-432,0l0,-122l141,0C245,410 315,418 315,418l0,-3C315,415 258,367 226,332l-190,-215M215,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x17E;"
        horiz-adv-x="538"
        d="M36,0l469,0l0,123l-174,0C283,123 207,116 207,116l0,3C207,119 272,172 308,211l183,208l0,113l-432,0l0,-122l141,0C245,410 315,418 315,418l0,-3C315,415 258,367 226,332l-190,-215M463,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z"
      />
      <glyph
        unicode="&#x17C;"
        horiz-adv-x="538"
        d="M36,0l469,0l0,123l-174,0C283,123 207,116 207,116l0,3C207,119 272,172 308,211l183,208l0,113l-432,0l0,-122l141,0C245,410 315,418 315,418l0,-3C315,415 258,367 226,332l-190,-215M197,607l159,0l0,137l-159,0z"
      />
      <glyph
        unicode="0"
        horiz-adv-x="692"
        d="M345,-17C533,-17 656,128 656,355C656,583 534,727 346,727C162,727 36,582 36,355C36,128 162,-17 345,-17M346,112C246,112 204,210 204,355C204,501 246,600 346,600C447,600 488,501 488,355C488,209 448,112 346,112z"
      />
      <glyph
        unicode="&#x2080;"
        horiz-adv-x="490"
        d="M245,-76C188,-76 165,-18 165,67C165,151 188,212 245,212C303,212 325,151 325,67C325,-18 303,-76 245,-76M245,-172C368,-172 458,-80 458,67C458,212 372,306 245,306C122,306 32,212 32,67C32,-80 124,-172 245,-172z"
      />
      <glyph
        unicode="&#x2070;"
        horiz-adv-x="490"
        d="M245,372C188,372 165,430 165,515C165,599 188,660 245,660C303,660 325,599 325,515C325,430 303,372 245,372M245,276C368,276 458,368 458,515C458,660 372,754 245,754C122,754 32,660 32,515C32,368 124,276 245,276z"
      />
    </font>

    <g>
      <path
        style="fill: #f9b60a"
        d="M349.6,76.4H203.4c-6.6,0-12-5.4-12-12V19.2c0-6.6,5.4-12,12-12h146.2c6.6,0,12,5.4,12,12v45.2
		C361.6,71.1,356.2,76.4,349.6,76.4z"
      />
    </g>
    <g>
      <path
        style="fill: #6bbdff"
        d="M167.6,333H25.4c-7.7,0-14-6.3-14-14V21.2c0-7.7,6.3-14,14-14h142.2c7.7,0,14,6.3,14,14V319
		C181.6,326.7,175.4,333,167.6,333z"
      />
    </g>
    <g>
      <path
        style="fill: #1895f9"
        d="M347.6,333H205.3c-7.7,0-14-6.3-14-14V168.9c0-7.7,6.3-14,14-14h142.2c7.7,0,14,6.3,14,14V319
		C361.6,326.7,355.3,333,347.6,333z"
      />
    </g>
    <text
      transform="matrix(1 0 0 1 41.754 170.1153)"
      style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 18px"
    >
      {{ $t('exampleCalcsGrossWageLabel') }}
    </text>
    <text
      transform="matrix(1 0 0 1 224.3353 246.6285)"
      style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 18px"
    >
      {{ $t('exampleCalcsNetWageLabel') }}
    </text>
    <g>
      <path
        style="fill: #f9b60a"
        d="M349.6,150.3H203.4c-6.6,0-12-5.4-12-12V93.1c0-6.6,5.4-12,12-12h146.2c6.6,0,12,5.4,12,12v45.2
		C361.6,144.9,356.2,150.3,349.6,150.3z"
      />
    </g>
    <g>
      <path
        style="fill: #b25b06"
        d="M282.7,67.9c0.8-1.3,1.7-2.6,2.6-3.9c0.4-0.6,0.4-1.1,0.1-1.7c-0.3-0.6-0.8-0.9-1.4-1
		c-0.1,0-0.1,0-0.2,0c-4.9,0-9.8,0-14.7,0c-1,0-1.7,0.6-1.8,1.5c-0.1,0.4,0.1,0.7,0.2,1c0.9,1.3,1.8,2.6,2.7,3.9h12.4L282.7,67.9z"
      />
      <path
        style="fill: #b25b06"
        d="M294.6,88.7c-0.1-2.2-0.7-4.3-1.5-6.3c-1-2.5-2.4-4.7-4.2-6.7c-1.7-2-3.8-3.7-5.9-5.2h-13.1
		c-1.7,1.1-3.2,2.3-4.6,3.8c-2.8,2.8-5,6-6.2,9.9c-0.4,1.4-0.7,2.8-0.9,4.3c0,0.4-0.1,0.7-0.1,1.1c0,0.1,0,0.1,0,0.2
		c0,0.3,0.1,0.6,0.1,0.9c0,1.5,0.5,2.8,1.4,4c1.3,1.7,3.1,2.7,5.2,3c0.4,0.1,0.7,0.1,1.2,0.1c7.1,0,14.3,0,21.4,0
		c3-0.2,5.4-1.5,6.8-4.3c0.4-0.8,0.6-1.7,0.7-2.6c0-0.1,0-0.2,0-0.2c0-0.3,0-0.7,0-1C294.7,89.4,294.7,89.1,294.6,88.7z"
      />
      <polygon style="fill: #f98504" points="282.7,67.9 282.6,67.8 270.3,67.8 270.2,67.8 269.9,70.6 283,70.6 	" />
      <path
        style="fill: #f98504"
        d="M281.1,89.2c-0.1-0.2-0.3-0.4-0.5-0.4c-0.4,0.1-1,0.2-1.7,0.2c-2,0-3.7-1.3-4.4-3.1h4.1
		c0.2,0,0.4-0.1,0.4-0.4l0.2-1c0.1-0.3-0.1-0.6-0.4-0.6H274c-0.1-0.5-0.1-1,0-1.5h5.3c0.2,0,0.4-0.1,0.4-0.4l0.2-1
		c0.1-0.3-0.1-0.6-0.4-0.6h-5c0.7-1.6,2.3-2.7,4.3-2.7c0.5,0,1,0.1,1.4,0.1c0.2,0.1,0.4-0.1,0.5-0.3l0.4-1.6
		c0.1-0.2-0.1-0.5-0.3-0.6c-0.4-0.1-1.2-0.2-1.9-0.2c-3.5,0-6.4,2.2-7.4,5.2h-1.2c-0.2,0-0.4,0.2-0.4,0.4v1c0,0.2,0.2,0.4,0.4,0.4
		h0.8c-0.1,0.5-0.1,1,0,1.5h-0.8c-0.2,0-0.4,0.2-0.4,0.4v1c0,0.2,0.2,0.4,0.4,0.4h1.1c0.9,3.3,3.8,5.7,7.5,5.7
		c0.9,0,1.8-0.2,2.2-0.3c0.2-0.1,0.4-0.3,0.3-0.5L281.1,89.2z"
      />
    </g>
    <g>
      <path
        style="fill: #2930ce"
        d="M349.6,221.1H203.3c-6.6,0-12-5.4-12-12v-42.1c0-6.6,5.4-12,12-12h146.2c6.6,0,12,5.4,12,12v42.1
		C361.6,215.8,356.2,221.1,349.6,221.1z"
      />
    </g>
    <text transform="matrix(1 0 0 1 258.1753 184.545)">
      <tspan x="0" y="0" style="fill: #6bbdff; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 12px">
        {{ $t('aboutBavGraphicsRetirementLabel1') }}
      </tspan>
      <tspan
        :x="currentLang === 'en' ? '-40.4' : '-25.4'"
        y="14.4"
        style="fill: #6bbdff; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 12px"
      >
        {{ $t('aboutBavGraphicsRetirementLabel2') }}
      </tspan>
    </text>
    <text
      :transform="currentLang === 'en' ? 'matrix(1 0 0 1 225.2508 44.4742)' : 'matrix(1 0 0 1 211.2508 44.4742)'"
      style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 14px"
    >
      {{ $t('aboutBavSocialSavingsLabel') }}
    </text>
    <text
      transform="matrix(1 0 0 1 253.4461 118.3282)"
      style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 14px"
    >
      {{ $t('exampleCalcsTaxLabel') }}
    </text>
  </svg>
</template>
<script>
export default {
  props: ['currentLang'],
  setup() {},
};
</script>
<style lang="scss">
svg.scenario-b {
  max-width: 350px;
  width: 100%;
  padding-top: 7px;
}
</style>
