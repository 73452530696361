<template>
  <div v-if="exampleCalculations && Object.keys(exampleCalculations).length > 0" class="pt-7">
    <div class="pb-2 pb-md-5">
      <p class="text-caption text-md-body-1" v-html="$t('messageExampleCalculations')"></p>
    </div>
    <v-card class="pt-md-8 pb-md-0 v-card--examples">
      <v-tabs
        v-model="currentTab"
        :vertical="$vuetify.breakpoint.xsOnly ? false : true"
        class="mb-md-10 v-tabs--calcs"
        :active-class="$vuetify.breakpoint.xsOnly ? 'bav-info__tab--active' : ''"
        :hide-slider="$vuetify.breakpoint.xsOnly"
      >
        <v-tab
          v-for="(item, index) in Object.keys(exampleCalculations).slice(0, 3)"
          :key="exampleCalculations[item].name + index + 'tab'"
          style="text-transform: none; height: auto; width: 100%"
          class="text-left flex-column justify-start; align-start py-2"
        >
          <v-simple-table dense style="width: 100%; background: transparent">
            <template v-slot:default>
              <thead class="tabs-table-head">
                <tr>
                  <th class="text-center text-sm-left font-weight-semibold mb-0">
                    {{ exampleCalculations[item].name }}
                  </th>
                  <th class="text-left d-none d-md-table-row"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="tabs-table-row d-none d-md-table-row">
                  <td>
                    <span class="mb-2 text-md-body-1 text-caption">
                      {{ $t('exampleCalcGrossWageLabel') }}
                    </span>
                  </td>
                  <td class="text-right">
                    <span class="mb-2 text-md-body-1 text-caption">
                      {{ formatCurrency(exampleCalculations[item].grossWage) }}
                    </span>
                  </td>
                </tr>

                <tr class="tabs-table-row">
                  <td>
                    <span class="mb-2 text-md-body-1 text-caption">
                      {{ $t('exampleCalcContributionLabel') }}
                    </span>
                  </td>

                  <td class="text-right">
                    <span class="mb-2 text-md-body-1 text-caption">
                      {{ formatCurrency(exampleCalculations[item].netContribution) }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab>

        <v-tabs-items
          v-for="(item, index) in Object.keys(exampleCalculations)"
          :key="exampleCalculations[item] + index + 'tab-item'"
          class="text"
        >
          <v-simple-table class="pr-sm-8">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="mb-2 text-md-body-1 text-caption">
                    <strong>{{ $t('formLabelDateOfBirth') }}:</strong>
                  </td>
                  <td class="text-md-body-1 text-caption">
                    {{ moment(exampleCalculations[currentTab].dateOfBirth).format('DD-MM-YYYY') }}
                  </td>
                </tr>
                <tr>
                  <td class="mb-2 text-md-body-1 text-caption">
                    <strong>{{ $t('exampleCalcGrossWageLabel') }}</strong>
                  </td>
                  <td class="text-md-body-1 text-caption">
                    {{ formatCurrency(exampleCalculations[currentTab].grossWage) }}
                  </td>
                </tr>
                <tr>
                  <td class="text-md-body-1 text-caption">
                    <strong>{{ $t('exampleCalcTaxClassLabel') }}</strong>
                  </td>
                  <td class="text-md-body-1 text-caption">
                    {{ $t(TAX_CLASS_TRANSLATION_MAP[exampleCalculations[currentTab].taxClass]) }}
                  </td>
                </tr>
                <tr>
                  <td class="mb-2 text-md-body-1 text-caption">
                    <strong>{{ $t('exampleCalcChurchTaxLabel') }}</strong>
                  </td>

                  <td class="text-md-body-1 text-caption">
                    {{ exampleCalculations[currentTab].churchTax ? $t('formLabelYes') : $t('formLabelNo') }}
                  </td>
                </tr>
                <tr>
                  <td class="mb-2 text-md-body-1 text-caption">
                    <strong>{{ $t('exampleCalcChildTaxAllowanceLabel') }}</strong>
                  </td>

                  <td class="mb-2 text-md-body-1 text-caption">
                    {{ exampleCalculations[currentTab].childAllowance }}
                  </td>
                </tr>
                <tr>
                  <td class="mb-2 text-md-body-1 text-caption">
                    <strong>{{ $t('exampleCalcContributionLabel') }}</strong>
                  </td>

                  <td class="mb-2 text-md-body-1 text-caption">
                    {{ formatCurrency(exampleCalculations[currentTab].netContribution) }}
                  </td>
                </tr>
                <!--We are taking contribution from company value and not from examples. Requested in TECH-1026-->
                <tr v-if="companyConfig.contribution.employerSubsidy">
                  <td class="mb-2 text-md-body-1 text-caption">
                    <strong>{{ $t('exampleCalcEmployersSubsidyLabel') }}</strong>
                  </td>

                  <td class="mb-2 text-md-body-1 text-caption">
                    {{ formatPercent(companyConfig.contribution.employerSubsidy) }}
                  </td>
                </tr>
                <tr v-if="companyConfig.contribution.employerContribution">
                  <td class="mb-2 text-md-body-1 text-caption">
                    <strong>{{ $t('exampleCalcEmployersContributionLabel') }}</strong>
                  </td>

                  <td class="mb-2 text-md-body-1 text-caption">
                    {{ formatCurrency(companyConfig.contribution.employerContribution) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tabs-items>
      </v-tabs>

      <v-row class="mb-1 mb-md-15">
        <v-col cols="12" sm="12" md="6" lg="6" class="order-sm-0 order-md-0 pb-0 pb-md-3">
          <v-card class="v-card--transparent pl-md-8 pr-md-5" color="transparent">
            <h3 class="mb-5 mt-5 mt-md-15 text-body-2 text-md-body-1 font-weight-bold">
              {{ $t('headlineExamplesTotalContribution') }}
            </h3>
            <p
              class="text-caption text-md-body-2"
              v-html="
                $t(
                  Boolean(exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerContribution)
                    ? 'messageTotalContributionWithEmployerContribution'
                    : 'messageTotalContributionWithoutEmployerContribution',
                  {
                    NAME: exampleCalculations[currentTab].name,
                    PERSONAL_CONTRIBUTION: formatCurrency(
                      exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployeeContribution,
                      { minimumFractionDigits: 0, maximumFractionDigits: 0 },
                    ),
                    SOCIAL_CONTRIBUTION: formatCurrency(
                      exampleCalculations[currentTab].withCompanyPension.payments.savingsInDues,
                    ),
                    EMPLOYER_CONTRIBUTION_LABEL: formatCurrency(
                      exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerContribution || 0,
                    ),
                    TOTAL_CONTRIBUTION: formatCurrency(
                      exampleCalculations[currentTab].withCompanyPension.payments.monthlyTotal,
                    ),
                    EMPLOYER_SUBSIDY_CURRENCY: formatCurrency(
                      exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerSubsidyCurrency,
                    ),
                  },
                )
              "
            ></p>
          </v-card>
        </v-col>
        <v-divider class="my-5 my-md-15"></v-divider>
        <v-col
          cols="12"
          sm="12"
          v-if="currentLang.startsWith('de')"
          md="6"
          lg="6"
          class="order-sm-1 order-md-1 pb-0 pb-md-3 pt-0 pt-md-3"
        >
          <total-contributions-graphic-de-v2
            v-if="exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerContribution"
            class="pl-md-4 pr-6"
            :currentLang="currentLang"
            :grossWage="exampleCalculations[currentTab].grossWage"
            :netWageBefore="exampleCalculations[currentTab].noCompanyPension.netWage"
            :netWageAfter="exampleCalculations[currentTab].withCompanyPension.netWage"
            :taxDeductionsBefore="exampleCalculations[currentTab].noCompanyPension.taxDues"
            :taxDeductionsAfter="exampleCalculations[currentTab].withCompanyPension.taxDues"
            :socialDeductionsBefore="exampleCalculations[currentTab].noCompanyPension.socialSecurityDues"
            :socialContribution="exampleCalculations[currentTab].withCompanyPension.payments.savingsInDues"
            :employerContribution="
              exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerContribution
            "
            :employerSubsidy="exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerSubsidy"
            :employerSubsidyCurrency="
              exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerSubsidyCurrency
            "
            :personalContribution="
              exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployeeContribution
            "
            :socialDeductionsAfter="exampleCalculations[currentTab].withCompanyPension.socialSecurityDues"
            :totalContribution="exampleCalculations[currentTab].withCompanyPension.payments.monthlyTotal"
          />
          <!-- for now we use 2 illustrations due to text alignment with lang but there is a better to do it by putting text in groups and using text-anchor -->
          <total-contributions-graphic-de
            v-else
            class="pl-md-4 pr-6"
            :currentLang="currentLang"
            :grossWage="exampleCalculations[currentTab].grossWage"
            :netWageBefore="exampleCalculations[currentTab].noCompanyPension.netWage"
            :netWageAfter="exampleCalculations[currentTab].withCompanyPension.netWage"
            :taxDeductionsBefore="exampleCalculations[currentTab].noCompanyPension.taxDues"
            :taxDeductionsAfter="exampleCalculations[currentTab].withCompanyPension.taxDues"
            :socialDeductionsBefore="exampleCalculations[currentTab].noCompanyPension.socialSecurityDues"
            :socialContribution="exampleCalculations[currentTab].withCompanyPension.payments.savingsInDues"
            :employerSubsidy="exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerSubsidy"
            :employerSubsidyCurrency="
              exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerSubsidyCurrency
            "
            :personalContribution="
              exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployeeContribution
            "
            :socialDeductionsAfter="exampleCalculations[currentTab].withCompanyPension.socialSecurityDues"
            :totalContribution="exampleCalculations[currentTab].withCompanyPension.payments.monthlyTotal"
          />
        </v-col>
        <v-col
          v-if="currentLang == 'en'"
          cols="12"
          sm="12"
          md="6"
          lg="6"
          class="order-sm-1 order-md-1 pb-0 pb-md-3 pt-0 pt-md-3"
        >
          <total-contributions-graphic-en-v2
            v-if="exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerContribution"
            class="pl-md-4 pr-6"
            :currentLang="currentLang"
            :grossWage="exampleCalculations[currentTab].grossWage"
            :netWageBefore="exampleCalculations[currentTab].noCompanyPension.netWage"
            :netWageAfter="exampleCalculations[currentTab].withCompanyPension.netWage"
            :taxDeductionsBefore="exampleCalculations[currentTab].noCompanyPension.taxDues"
            :taxDeductionsAfter="exampleCalculations[currentTab].withCompanyPension.taxDues"
            :socialDeductionsBefore="exampleCalculations[currentTab].noCompanyPension.socialSecurityDues"
            :socialContribution="exampleCalculations[currentTab].withCompanyPension.payments.savingsInDues"
            :employerContribution="
              exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerContribution
            "
            :employerSubsidy="exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerSubsidy"
            :employerSubsidyCurrency="
              exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerSubsidyCurrency
            "
            :personalContribution="
              exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployeeContribution
            "
            :socialDeductionsAfter="exampleCalculations[currentTab].withCompanyPension.socialSecurityDues"
            :totalContribution="exampleCalculations[currentTab].withCompanyPension.payments.monthlyTotal"
          />
          <total-contributions-graphic-en
            v-else
            class="pl-md-4 pr-6"
            :currentLang="currentLang"
            :grossWage="exampleCalculations[currentTab].grossWage"
            :netWageBefore="exampleCalculations[currentTab].noCompanyPension.netWage"
            :netWageAfter="exampleCalculations[currentTab].withCompanyPension.netWage"
            :taxDeductionsBefore="exampleCalculations[currentTab].noCompanyPension.taxDues"
            :taxDeductionsAfter="exampleCalculations[currentTab].withCompanyPension.taxDues"
            :socialDeductionsBefore="exampleCalculations[currentTab].noCompanyPension.socialSecurityDues"
            :socialContribution="exampleCalculations[currentTab].withCompanyPension.payments.savingsInDues"
            :employerSubsidy="exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerSubsidy"
            :employerSubsidyCurrency="
              exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployerSubsidyCurrency
            "
            :personalContribution="
              exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployeeContribution
            "
            :socialDeductionsAfter="exampleCalculations[currentTab].withCompanyPension.socialSecurityDues"
            :totalContribution="exampleCalculations[currentTab].withCompanyPension.payments.monthlyTotal"
          />
        </v-col>
      </v-row>
      <v-row class="mb-0 mb-sm-15">
        <v-col cols="12" sm="12" md="6" lg="6" class="order-1 order-md-0 pt-0 pt-md-3">
          <vue-apex-charts
            type="donut"
            height="350"
            :options="donutChart.chartOptions"
            :series="donutChart.series"
            class="chart--custom pr-md-8"
          />
          <!-- <vue-apex-charts
          type="donut"
          height="350"
          :options="donutChart.chartOptions"
          :series="donutChart.series"
        /> -->
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" class="pb-0 pb-md-3">
          <v-card class="v-card--transparent pr-md-8 pl-md-5">
            <h3 class="mb-5 text-body-2 text-md-body-1 font-weight-bold">
              {{ $t('headlineExamplesSubsidies') }}
            </h3>
            <p class="text-caption text-md-body-2">
              {{
                $t('messageExampleSubsidies', {
                  NAME: exampleCalculations[currentTab].name,
                  CONTRACT_DURATION: exampleCalculations[currentTab].withCompanyPension.contractLifetime.duration,
                  PERSONAL_CONTRIBUTION: formatCurrency(
                    exampleCalculations[currentTab].withCompanyPension.payments.paymentEmployeeContribution,
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 },
                  ),
                  TOTAL_PAID_OWN: formatCurrency(
                    exampleCalculations[currentTab].withCompanyPension.contractLifetime.totalPaidInOwn,
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 },
                  ),
                  GUARANTEED_CAPITAL_PAYMENT: formatCurrency(
                    Math.round(
                      exampleCalculations[currentTab].withCompanyPension.contractLifetime.capitalPaymentGuaranteed,
                    ),
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 },
                  ),
                  TOTAL_PAID_OTHER: formatCurrency(
                    exampleCalculations[currentTab].withCompanyPension.contractLifetime.totalPaidInOther,
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 },
                  ),
                })
              }}
            </p>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6" class="pb-0 pb-md-3 pt-0 pt-md-3">
          <v-card class="v-card--transparent pl-md-8 pr-md-5">
            <h3 class="mb-5 text-body-2 text-md-body-1 font-weight-bold">
              {{ $t('headlineCapitalPayment') }}
            </h3>
            <p class="text-caption text-md-body-2 mb-0 mb-md-5">
              {{
                $t('messageExampleValueDevelopment', {
                  NAME: exampleCalculations[currentTab].name,
                  CONTRACT_DURATION: exampleCalculations[currentTab].withCompanyPension.contractLifetime.duration,
                  ANNUAL_RETURN: formatPercent(
                    exampleCalculations[currentTab].withCompanyPension.contractLifetime.projectedAnnualReturn,
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 },
                  ),
                  CAPITAL_PAYMENT_PROJECTED: formatCurrency(
                    exampleCalculations[currentTab].withCompanyPension.contractLifetime.capitalPaymentProjected,
                    { minimumFractionDigits: 0, maximumFractionDigits: 0 },
                  ),
                })
              }}
            </p>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6" class="pb-0 pb-md-3 pt-3">
          <vue-apex-charts
            type="bar"
            height="350"
            :options="barChart.chartOptions"
            :series="barChart.series"
            class="mt-md-5 chart--custom pr-3 pr-md-8 pl-md-3"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-card class="mt-md-10 v-card--download-text" color="transparent">
          <h2 class="text-center mb-5 font-weight-bold">
            {{ $t('headlineExampleCalculationsDownload') }}
          </h2>
          <p class="text-center mb-0 mb-md-5 text-caption text-md-body-1">
            {{ $t('messageExampleCalculationsDownload') }}
          </p>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-card class="v-card--download">
          <v-card-text>
            <!-- User Avatar -->

            <!-- Title, Subtitle & Action Button -->
            <a
              :href="exampleCalculations[currentTab].exampleFile.de"
              target="_blank"
              class="d-flex flex-column justify-center align-center flex-wrap text-decoration-none"
            >
              <v-img :src="require('@/assets/images/icons/export_pdf.svg')" class="mb-3"></v-img>

              <v-card-subtitle class="text-caption text-md-body-2 pa-0"
                >{{ $t('buttonExampleDownload') }}
                {{ exampleCalculations[currentTab].name }}
              </v-card-subtitle>
            </a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, watch, computed, getCurrentInstance } from '@vue/composition-api';
import { mdiFilePdfBox, mdiDownload } from '@mdi/js';
import VueApexCharts from 'vue-apexcharts';
import moment from 'moment';
import themeConfig from '@themeConfig';
import totalContributionsGraphicDe from '../total-contributions-graphic-de';
import totalContributionsGraphicEn from '../total-contributions-graphic-en';
import totalContributionsGraphicDeV2 from '../total-contributions-graphic-de-v2.vue';
import totalContributionsGraphicEnV2 from '../total-contributions-graphic-en-v2.vue';
import { config } from '../../../../../appConfig';

const $themeColors = themeConfig.themes.light;
const chartColors = {
  donut: {
    series1: '#0404BC',
    series2: '#0395f9',
  },
};

const getFormatter = (opts) => {
  return new Intl.NumberFormat('DE', opts);
};

const formatNumber = (amount, opts = {}) => {
  const defaults = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return getFormatter({ ...defaults, ...opts }).format(amount);
};

const formatCurrency = (amount, opts = {}) => {
  const defaults = {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return getFormatter({ ...defaults, ...opts }).format(amount);
};

const formatPercent = (amount, opts = {}) => {
  const defaults = {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return getFormatter({ ...defaults, ...opts }).format(amount);
};

export default {
  components: {
    VueApexCharts,
    totalContributionsGraphicDe,
    totalContributionsGraphicEn,
    totalContributionsGraphicDeV2,
    totalContributionsGraphicEnV2,
  },
  setup() {
    const totalTabs = ref(3);
    const currentTab = ref(0);
    const assetsUrl = ref('');
    const vm = getCurrentInstance().proxy;
    const currentLang = computed(() => vm.$store.state.app.currentLang);

    watch(totalTabs, (newValue) => {
      currentTab.value = newValue - 1;
    });

    assetsUrl.value = config.variables.ASSETS_BASE_URL;

    return {
      TAX_CLASS_TRANSLATION_MAP: {
        TAX_CLASS_1: 'formLabelTaxClass1',
        TAX_CLASS_2: 'formLabelTaxClass2',
        TAX_CLASS_3: 'formLabelTaxClass3',
        TAX_CLASS_4: 'formLabelTaxClass4',
        TAX_CLASS_5: 'formLabelTaxClass5',
        TAX_CLASS_6: 'formLabelTaxClass6',
      },
      assetsUrl,
      formatNumber,
      formatPercent,
      getFormatter,
      formatCurrency,
      totalTabs,
      currentTab,
      moment,
      currentLang,
      icons: {
        mdiFilePdfBox,
        mdiDownload,
      },
    };
  },
  props: {
    companyConfig: { default: { contribution: { value: 0 } } },
    exampleCalculations: [],
  },

  mounted() {},
  computed: {
    barChart() {
      return {
        series: [
          {
            data: [
              this.exampleCalculations[this.currentTab].withCompanyPension.contractLifetime.capitalPaymentGuaranteed,
              this.exampleCalculations[this.currentTab].withCompanyPension.contractLifetime.capitalPaymentProjected,
            ],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: [chartColors.donut.series1, chartColors.donut.series2],
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            y: {
              title: {
                formatter: () => '', // title is missing a "show" property
              },
            },
          },
          xaxis: {
            categories: [this.$t('exampleCalcsGuaranteedPaymentLabel'), this.$t('exampleCalcsPredictedPaymentLabel')],
          },
          yaxis: {
            // opposite: isRtl,
            labels: {
              fontSize: '16px',
              formatter: (value) => formatCurrency(value),
            },
          },
        },
      };
    },
    donutChart() {
      return {
        series: [
          this.exampleCalculations[this.currentTab].withCompanyPension.contractLifetime.sharePaidInOwn * 100,
          this.exampleCalculations[this.currentTab].withCompanyPension.contractLifetime.sharePaidInOther * 100,
        ],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '16px',
            fontFamily: '"Neue Haas Grotesk Text Pro", sans-serif',
          },
          colors: [chartColors.donut.series1, chartColors.donut.series2],
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseFloat(val).toFixed(1)}%`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '16px',
                    fontFamily: '"Neue Haas Grotesk Text Pro", sans-serif',
                  },
                  value: {
                    fontSize: '16px',
                    fontFamily: '"Neue Haas Grotesk Text Pro", sans-serif',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseFloat(val).toFixed(0)}%`;
                    },
                  },
                },
              },
            },
          },
          tooltip: {
            y: {
              formatter: (value) => `${parseFloat(value).toFixed(2)}%`,
            },
          },
          labels: [this.$t('chartLabelContributionOwn'), this.$t('chartLabelContributionOther')],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '14px',
                        },
                        value: {
                          fontSize: '14px',
                        },
                        total: {
                          fontSize: '14px',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      };
    },
  },
};
</script>

<style lang="scss">
.exampleTabs .v-slide-group__content {
  align-items: flex-start;
}
.chart--custom .apexcharts-text {
  font-family: 'Neue Haas Grotesk Text Pro', sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.tabs-table-head {
  th {
    background: transparent !important;
    border: 0 !important;
  }
}
.v-tabs-bar {
  box-shadow: none !important;
}
.tabs-table-row {
  &:hover {
    background: transparent !important;
  }
  td {
    border: 0 !important;
  }
}
.v-card--download {
  background-color: transparent !important;
  transition: all 0.3s ease-in-out;
  border: 1px solid #2830ce !important;
  &:hover {
    background-color: rgba(138, 141, 147, 0.1) !important;
  }
  .v-card__text {
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%) !important;
  }
}
.v-tabs--calcs .v-slide-group__prev {
  display: none !important;
}
.v-tabs {
  td span,
  th {
    opacity: 0.5;
  }

  div[aria-selected='true'] {
    td span,
    th {
      opacity: 1;
      color: #2830ce !important;
      background-color: transparent !important;
    }
    th {
      color: #2830ce !important;
    }
  }
  th p {
    color: $primary-shade--light !important;
  }
}
.tabs-table-head {
  tr th {
    font-size: 12px !important;
  }
}

.v-tabs--calcs {
  border-bottom: 0 !important;
  .v-tabs-bar {
    border-bottom: 0;
  }
}
@media screen and (max-width: 960px) {
  .v-card--examples {
    border: 0 !important;
  }
  .v-card--download-text {
    h2 {
      font-size: 14px;
    }
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-tabs--calcs .v-tabs-bar {
    border-bottom: 1px solid rgba(16, 16, 17, 0.22) !important;
  }
  .tabs-table-head {
    tr th {
      font-size: 10px !important;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .tabs-table-head {
    tr th {
      font-size: 19px !important;
    }
  }
}
</style>
