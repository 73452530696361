<template>
  <svg
    id="Layer_2"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 372.3 340.9"
    style="enable-background: new 0 0 372.3 340.9"
    xml:space="preserve"
    class="scenario-a"
  >
    <font horiz-adv-x="1000">
      <font-face
        font-family="NHaasGroteskTXPro-75Bd"
        units-per-em="1000"
        underline-position="-100"
        underline-thickness="50"
      />
      <missing-glyph horiz-adv-x="500" />
      <glyph
        unicode="A"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="&#xC6;"
        horiz-adv-x="1045"
        d="M993,744l-728,0l-255,-744l169,0l48,145l231,0l0,-145l547,0l0,150l-375,0l0,168l311,0l0,134l-311,0l0,149l363,0M377,601l81,0l0,-335l-191,0z"
      />
      <glyph
        unicode="&#x1FC;"
        horiz-adv-x="1045"
        d="M993,744l-728,0l-255,-744l169,0l48,145l231,0l0,-145l547,0l0,150l-375,0l0,168l311,0l0,134l-311,0l0,149l363,0M377,601l81,0l0,-335l-191,0M543,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#xC1;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0M298,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x102;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M370,779C466,779 525,842 528,921l-89,0C432,900 410,876 369,876C325,876 304,897 297,921l-91,0C210,839 275,779 370,779M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="&#xC2;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M179,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="&#xC4;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0M169,785l153,0l0,134l-153,0M414,785l152,0l0,134l-152,0z"
      />
      <glyph
        unicode="&#xC0;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0M303,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="&#x100;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M211,789l312,0l0,111l-312,0M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="&#x104;"
        horiz-adv-x="736"
        d="M647,-184C683,-184 704,-180 717,-175l0,79l-2,0C707,-98 698,-100 686,-100C665,-100 653,-92 653,-73C653,-49 674,-18 722,5l-267,739l-177,0l-268,-744l171,0l46,142l270,0l47,-142l61,0C569,-16 526,-47 526,-100C526,-151 567,-184 647,-184M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="&#xC5;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M368,776C430,776 482,823 482,886C482,947 430,997 368,997C305,997 253,947 253,886C253,823 305,776 368,776M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0M368,833C336,833 315,856 315,886C315,916 336,941 368,941C399,941 420,916 420,886C420,856 399,833 368,833z"
      />
      <glyph
        unicode="&#xC3;"
        horiz-adv-x="736"
        d="M10,0l171,0l46,142l270,0l47,-142l180,0l-269,744l-177,0M440,786C506,786 545,841 545,924l-86,0C456,897 445,886 425,886C392,886 361,926 298,926C240,926 190,879 190,785l86,0C278,813 293,826 310,826C340,826 379,786 440,786M313,403C337,476 363,601 363,601l2,0C365,601 381,505 413,403l45,-139l-191,0z"
      />
      <glyph
        unicode="B"
        horiz-adv-x="710"
        d="M63,0l361,0C503,0 564,19 608,55C655,92 681,146 681,217C681,308 628,375 532,398l0,4C612,427 652,477 652,551C652,610 630,657 589,693C551,726 492,744 415,744l-352,0M232,445l0,167l161,0C451,612 488,582 488,528C488,473 449,445 391,445M232,134l0,192l171,0C465,326 512,294 512,229C512,201 502,177 485,161C467,144 440,134 406,134z"
      />
      <glyph
        unicode="C"
        horiz-adv-x="749"
        d="M397,-17C501,-17 577,12 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,147 180,-17 397,-17z"
      />
      <glyph
        unicode="&#x106;"
        horiz-adv-x="749"
        d="M397,-17C501,-17 577,12 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,147 180,-17 397,-17M316,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x10C;"
        horiz-adv-x="749"
        d="M397,-17C501,-17 577,12 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,147 180,-17 397,-17M572,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#xC7;"
        horiz-adv-x="749"
        d="M359,-210C435,-210 487,-178 487,-128C487,-72 433,-48 374,-57l0,2l43,39C511,-13 580,15 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,161 161,5 355,-15l-57,-61l18,-26C326,-98 336,-95 349,-95C369,-95 384,-104 384,-124C384,-148 362,-157 335,-157C310,-157 286,-150 266,-141l-20,-39C273,-197 312,-210 359,-210z"
      />
      <glyph
        unicode="&#x108;"
        horiz-adv-x="749"
        d="M397,-17C501,-17 577,12 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,147 180,-17 397,-17M197,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#x10A;"
        horiz-adv-x="749"
        d="M397,-17C501,-17 577,12 633,67C684,117 718,186 723,260l-171,0C544,183 488,124 397,124C280,124 206,227 206,369C206,510 269,615 396,615C480,615 536,563 546,493l170,0C707,577 668,650 601,699C550,737 479,757 397,757C175,757 32,591 32,369C32,147 180,-17 397,-17M306,785l159,0l0,135l-159,0z"
      />
      <glyph
        unicode="D"
        horiz-adv-x="748"
        d="M65,0l300,0C593,0 718,146 718,367C718,595 584,744 375,744l-310,0M236,144l0,456l119,0C480,600 544,508 544,367C544,225 481,144 352,144z"
      />
      <glyph
        unicode="&#x10E;"
        horiz-adv-x="748"
        d="M65,0l300,0C593,0 718,146 718,367C718,595 584,744 375,744l-310,0M236,144l0,456l119,0C480,600 544,508 544,367C544,225 481,144 352,144M533,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x110;"
        horiz-adv-x="748"
        d="M5,311l60,0l0,-311l300,0C593,0 718,146 718,367C718,595 584,744 375,744l-310,0l0,-300l-60,0M234,144l0,167l171,0l0,133l-171,0l0,156l121,0C480,600 544,508 544,367C544,225 481,144 352,144z"
      />
      <glyph
        unicode="&#x2206;"
        horiz-adv-x="723"
        d="M27,0l669,0l0,112l-241,632l-188,0l-240,-632M203,132l102,283C339,509 356,605 356,605l2,0C358,605 374,509 408,415l103,-283z"
      />
      <glyph
        unicode="E"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0z"
      />
      <glyph
        unicode="&#xC9;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M283,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x114;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M355,779C451,779 510,842 513,921l-89,0C417,900 395,876 354,876C310,876 289,897 282,921l-91,0C195,839 260,779 355,779z"
      />
      <glyph
        unicode="&#x11A;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M539,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#xCA;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M164,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#xCB;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M154,785l153,0l0,134l-153,0M399,785l152,0l0,134l-152,0z"
      />
      <glyph
        unicode="&#x116;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M273,785l159,0l0,135l-159,0z"
      />
      <glyph
        unicode="&#xC8;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M288,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="&#x112;"
        horiz-adv-x="670"
        d="M63,0l565,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0M196,789l312,0l0,111l-312,0z"
      />
      <glyph
        unicode="&#x14A;"
        horiz-adv-x="753"
        d="M432,-44C415,-44 405,-43 388,-40l-7,0l0,-138C410,-183 433,-185 463,-185C557,-185 617,-154 652,-104C681,-63 691,-16 691,46l0,698l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0l0,-744l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l195,-321C503,-29 476,-44 432,-44z"
      />
      <glyph
        unicode="&#x118;"
        horiz-adv-x="670"
        d="M63,0l399,0C425,-19 393,-51 393,-100C393,-151 434,-184 514,-184C550,-184 571,-180 584,-175l0,79l-2,0C574,-98 565,-100 553,-100C532,-100 520,-92 520,-73C520,-50 540,-23 569,0l59,0l0,146l-395,0l0,173l336,0l0,135l-336,0l0,148l388,0l0,142l-558,0z"
      />
      <glyph
        unicode="&#xD0;"
        horiz-adv-x="752"
        d="M9,311l60,0l0,-311l300,0C597,0 722,146 722,367C722,595 588,744 379,744l-310,0l0,-300l-60,0M238,144l0,167l171,0l0,133l-171,0l0,156l121,0C484,600 548,508 548,367C548,225 485,144 356,144z"
      />
      <glyph
        unicode="&#x20AC;"
        horiz-adv-x="687"
        d="M27,388l45,0C71,377 71,366 71,355C71,341 71,328 72,315l-45,0l0,-65l54,0C115,87 229,-17 387,-17C558,-17 655,95 663,249l-156,0C500,173 462,115 387,115C313,115 267,163 248,250l165,0l0,65l-174,0C238,327 238,341 238,355C238,366 238,377 239,388l174,0l0,65l-167,0C263,542 307,599 381,599C449,599 486,552 497,480l160,0C645,624 551,727 385,727C226,727 112,616 80,453l-53,0z"
      />
      <glyph unicode="F" horiz-adv-x="640" d="M63,0l170,0l0,298l314,0l0,139l-314,0l0,162l376,0l0,145l-546,0z" />
      <glyph
        unicode="G"
        horiz-adv-x="784"
        d="M617,0l118,0l0,398l-311,0l0,-127l150,0l0,-3C573,193 510,122 405,122C283,122 207,226 207,369C207,509 271,616 403,616C488,616 535,570 553,515l170,0C696,657 586,757 398,757C300,757 223,728 163,677C80,607 34,496 34,369C34,255 68,157 133,87C194,22 280,-17 386,-17C477,-17 556,18 600,100l3,0z"
      />
      <glyph
        unicode="&#x11E;"
        horiz-adv-x="784"
        d="M617,0l118,0l0,398l-311,0l0,-127l150,0l0,-3C573,193 510,122 405,122C283,122 207,226 207,369C207,509 271,616 403,616C488,616 535,570 553,515l170,0C696,657 586,757 398,757C300,757 223,728 163,677C80,607 34,496 34,369C34,255 68,157 133,87C194,22 280,-17 386,-17C477,-17 556,18 600,100l3,0M390,779C486,779 545,842 548,921l-89,0C452,900 430,876 389,876C345,876 324,897 317,921l-91,0C230,839 295,779 390,779z"
      />
      <glyph
        unicode="&#x11C;"
        horiz-adv-x="784"
        d="M617,0l118,0l0,398l-311,0l0,-127l150,0l0,-3C573,193 510,122 405,122C283,122 207,226 207,369C207,509 271,616 403,616C488,616 535,570 553,515l170,0C696,657 586,757 398,757C300,757 223,728 163,677C80,607 34,496 34,369C34,255 68,157 133,87C194,22 280,-17 386,-17C477,-17 556,18 600,100l3,0M199,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#x122;"
        horiz-adv-x="784"
        d="M617,0l118,0l0,398l-311,0l0,-127l150,0l0,-3C573,193 510,122 405,122C283,122 207,226 207,369C207,509 271,616 403,616C488,616 535,570 553,515l170,0C696,657 586,757 398,757C300,757 223,728 163,677C80,607 34,496 34,369C34,255 68,157 133,87C194,22 280,-17 386,-17C477,-17 556,18 600,100l3,0M310,-263C397,-257 465,-217 465,-122l0,71l-151,0l0,-103l67,0C381,-184 352,-202 310,-210z"
      />
      <glyph
        unicode="&#x120;"
        horiz-adv-x="784"
        d="M617,0l118,0l0,398l-311,0l0,-127l150,0l0,-3C573,193 510,122 405,122C283,122 207,226 207,369C207,509 271,616 403,616C488,616 535,570 553,515l170,0C696,657 586,757 398,757C300,757 223,728 163,677C80,607 34,496 34,369C34,255 68,157 133,87C194,22 280,-17 386,-17C477,-17 556,18 600,100l3,0M308,785l159,0l0,135l-159,0z"
      />
      <glyph
        unicode="H"
        horiz-adv-x="747"
        d="M63,0l170,0l0,315l281,0l0,-315l170,0l0,744l-170,0l0,-287l-281,0l0,287l-170,0z"
      />
      <glyph
        unicode="&#x126;"
        horiz-adv-x="747"
        d="M2,538l61,0l0,-538l170,0l0,315l281,0l0,-315l170,0l0,538l60,0l0,91l-60,0l0,115l-170,0l0,-115l-281,0l0,115l-170,0l0,-115l-61,0M233,538l281,0l0,-87l-281,0z"
      />
      <glyph
        unicode="&#x124;"
        horiz-adv-x="747"
        d="M63,0l170,0l0,315l281,0l0,-315l170,0l0,744l-170,0l0,-287l-281,0l0,287l-170,0M185,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph unicode="I" horiz-adv-x="298" d="M63,0l171,0l0,744l-171,0z" />
      <glyph
        unicode="&#x132;"
        horiz-adv-x="860"
        d="M63,0l171,0l0,744l-171,0M561,-14C651,-14 718,17 758,67C790,105 805,155 805,217l0,527l-171,0l0,-523C634,161 608,127 551,127C495,127 463,155 463,229l0,53l-157,0l0,-57C306,78 391,-14 561,-14z"
      />
      <glyph unicode="&#xCD;" horiz-adv-x="298" d="M63,0l171,0l0,744l-171,0M79,785l123,0l109,131l-189,0z" />
      <glyph
        unicode="&#x12C;"
        horiz-adv-x="298"
        d="M63,0l171,0l0,744l-171,0M151,779C247,779 306,842 309,921l-89,0C213,900 191,876 150,876C106,876 85,897 78,921l-91,0C-9,839 56,779 151,779z"
      />
      <glyph
        unicode="&#xCE;"
        horiz-adv-x="298"
        d="M63,0l171,0l0,744l-171,0M-40,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#xCF;"
        horiz-adv-x="298"
        d="M63,0l171,0l0,744l-171,0M185,785l142,0l0,134l-142,0M-30,785l143,0l0,134l-143,0z"
      />
      <glyph unicode="&#x130;" horiz-adv-x="298" d="M63,0l171,0l0,744l-171,0M69,785l159,0l0,135l-159,0z" />
      <glyph unicode="&#xCC;" horiz-adv-x="298" d="M63,0l171,0l0,744l-171,0M84,785l122,0l-43,131l-189,0z" />
      <glyph unicode="&#x12A;" horiz-adv-x="298" d="M63,0l171,0l0,744l-171,0M4,789l288,0l0,111l-288,0z" />
      <glyph
        unicode="&#x12E;"
        horiz-adv-x="298"
        d="M63,0l56,0C84,-18 47,-52 47,-100C47,-151 88,-184 168,-184C204,-184 225,-180 238,-175l0,79l-2,0C228,-98 219,-100 207,-100C186,-100 174,-92 174,-73C174,-49 195,-17 234,2l0,742l-171,0z"
      />
      <glyph
        unicode="&#x128;"
        horiz-adv-x="298"
        d="M63,0l171,0l0,744l-171,0M221,786C287,786 326,841 326,924l-86,0C237,897 226,886 206,886C173,886 142,926 79,926C21,926 -29,879 -29,785l86,0C59,813 74,826 91,826C121,826 160,786 221,786z"
      />
      <glyph
        unicode="J"
        horiz-adv-x="579"
        d="M280,-14C370,-14 437,17 477,67C509,105 524,155 524,217l0,527l-171,0l0,-523C353,161 327,127 270,127C214,127 182,155 182,229l0,53l-157,0l0,-57C25,78 110,-14 280,-14z"
      />
      <glyph
        unicode="&#x134;"
        horiz-adv-x="579"
        d="M280,-14C370,-14 437,17 477,67C509,105 524,155 524,217l0,527l-171,0l0,-523C353,161 327,127 270,127C214,127 182,155 182,229l0,53l-157,0l0,-57C25,78 110,-14 280,-14M246,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="K"
        horiz-adv-x="736"
        d="M63,0l170,0l0,266l68,71l227,-337l199,0l-305,445l287,299l-204,0l-278,-298C229,486 233,554 233,622l0,122l-170,0z"
      />
      <glyph
        unicode="&#x136;"
        horiz-adv-x="736"
        d="M63,0l170,0l0,266l68,71l227,-337l199,0l-305,445l287,299l-204,0l-278,-298C229,486 233,554 233,622l0,122l-170,0M290,-263C377,-257 445,-217 445,-122l0,71l-151,0l0,-103l67,0C361,-184 332,-202 290,-210z"
      />
      <glyph unicode="L" horiz-adv-x="604" d="M63,0l513,0l0,146l-343,0l0,598l-170,0z" />
      <glyph
        unicode="&#x139;"
        horiz-adv-x="604"
        d="M63,0l513,0l0,146l-343,0l0,598l-170,0M78,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x13D;"
        horiz-adv-x="604"
        d="M359,513C453,517 508,559 508,661l0,83l-149,0l0,-118l68,0C426,595 404,571 359,567M63,0l513,0l0,146l-343,0l0,598l-170,0z"
      />
      <glyph
        unicode="&#x13B;"
        horiz-adv-x="604"
        d="M63,0l513,0l0,146l-343,0l0,598l-170,0M244,-263C331,-257 399,-217 399,-122l0,71l-151,0l0,-103l67,0C315,-184 286,-202 244,-210z"
      />
      <glyph
        unicode="&#x13F;"
        horiz-adv-x="604"
        d="M63,0l513,0l0,146l-343,0l0,598l-170,0M382,292l142,0l0,140l-142,0z"
      />
      <glyph
        unicode="&#x141;"
        horiz-adv-x="610"
        d="M5,195l64,33l0,-228l513,0l0,146l-343,0l0,172l209,110l0,116l-209,-110l0,310l-170,0l0,-400l-64,-33z"
      />
      <glyph
        unicode="M"
        horiz-adv-x="884"
        d="M63,0l153,0l0,333C216,427 204,601 204,601l2,0C206,601 238,442 264,354l107,-354l140,0l106,354C643,442 675,601 675,601l2,0C677,601 665,427 665,333l0,-333l157,0l0,744l-232,0l-91,-310C472,341 444,169 444,169l-3,0C441,169 412,341 385,434l-93,310l-229,0z"
      />
      <glyph
        unicode="N"
        horiz-adv-x="753"
        d="M63,0l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l194,-319l175,0l0,744l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0z"
      />
      <glyph
        unicode="&#x143;"
        horiz-adv-x="753"
        d="M63,0l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l194,-319l175,0l0,744l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0M315,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x147;"
        horiz-adv-x="753"
        d="M63,0l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l194,-319l175,0l0,744l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0M571,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x145;"
        horiz-adv-x="753"
        d="M63,0l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l194,-319l175,0l0,744l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0M291,-263C378,-257 446,-217 446,-122l0,71l-151,0l0,-103l67,0C362,-184 333,-202 291,-210z"
      />
      <glyph
        unicode="&#xD1;"
        horiz-adv-x="753"
        d="M63,0l163,0l0,299C226,384 217,518 217,518l2,0C219,518 277,394 322,319l194,-319l175,0l0,744l-163,0l0,-301C528,358 537,225 537,225l-2,0C535,225 477,346 433,421l-196,323l-174,0M457,786C523,786 562,841 562,924l-86,0C473,897 462,886 442,886C409,886 378,926 315,926C257,926 207,879 207,785l86,0C295,813 310,826 327,826C357,826 396,786 457,786z"
      />
      <glyph
        unicode="O"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122z"
      />
      <glyph
        unicode="&#x152;"
        horiz-adv-x="1137"
        d="M386,-18C470,-18 530,14 572,68l0,-68l523,0l0,150l-372,0l0,168l308,0l0,134l-308,0l0,149l361,0l0,143l-512,0l0,-76C530,723 470,757 386,757C176,757 32,592 32,369C32,147 176,-18 386,-18M395,122C272,122 206,229 206,369C206,510 272,619 395,619C503,619 561,531 561,393l0,-49C561,208 503,122 395,122z"
      />
      <glyph
        unicode="&#xD3;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M330,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x14E;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M402,779C498,779 557,842 560,921l-89,0C464,900 442,876 401,876C357,876 336,897 329,921l-91,0C242,839 307,779 402,779z"
      />
      <glyph
        unicode="&#xD4;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M211,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#xD6;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M446,785l152,0l0,134l-152,0M201,785l153,0l0,134l-153,0z"
      />
      <glyph
        unicode="&#xD2;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M335,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="&#x150;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M443,785l102,0l89,131l-156,0M246,785l102,0l90,131l-156,0z"
      />
      <glyph
        unicode="&#x14C;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M243,789l312,0l0,111l-312,0z"
      />
      <glyph
        unicode="&#x2126;"
        horiz-adv-x="780"
        d="M40,0l315,0l0,125C250,142 205,245 205,383C205,530 272,619 390,619C508,619 575,530 575,383C575,245 530,142 425,125l0,-125l315,0l0,130l-114,0l0,2C705,185 748,274 748,395C748,608 602,757 390,757C178,757 32,608 32,395C32,274 74,185 153,132l0,-2l-113,0z"
      />
      <glyph
        unicode="&#xD8;"
        horiz-adv-x="799"
        d="M84,-45l89,99C233,8 309,-18 400,-18C625,-18 767,147 767,369C767,470 737,560 684,627l90,102l-60,51l-86,-97C568,730 490,757 400,757C174,757 32,592 32,369C32,267 62,177 116,110l-92,-103M206,369C206,510 272,619 401,619C455,619 497,601 528,571l-300,-335C213,274 206,319 206,369M401,122C347,122 305,138 274,167l298,335C587,464 594,418 594,369C594,229 529,122 401,122z"
      />
      <glyph
        unicode="&#x1FE;"
        horiz-adv-x="799"
        d="M84,-45l89,99C233,8 309,-18 400,-18C625,-18 767,147 767,369C767,470 737,560 684,627l90,102l-60,51l-86,-97C568,730 490,757 400,757C174,757 32,592 32,369C32,267 62,177 116,110l-92,-103M206,369C206,510 272,619 401,619C455,619 497,601 528,571l-300,-335C213,274 206,319 206,369M401,122C347,122 305,138 274,167l298,335C587,464 594,418 594,369C594,229 529,122 401,122M330,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#xD5;"
        horiz-adv-x="799"
        d="M400,-18C625,-18 767,147 767,369C767,592 625,757 400,757C174,757 32,592 32,369C32,147 174,-18 400,-18M401,122C272,122 206,229 206,369C206,510 272,619 401,619C529,619 593,510 593,369C593,229 529,122 401,122M472,786C538,786 577,841 577,924l-86,0C488,897 477,886 457,886C424,886 393,926 330,926C272,926 222,879 222,785l86,0C310,813 325,826 342,826C372,826 411,786 472,786z"
      />
      <glyph
        unicode="P"
        horiz-adv-x="686"
        d="M63,0l173,0l0,258l178,0C568,258 664,356 664,497C664,570 638,630 593,675C548,720 482,744 394,744l-331,0M236,599l146,0C452,599 492,562 492,497C492,434 453,393 381,393l-145,0z"
      />
      <glyph
        unicode="Q"
        horiz-adv-x="798"
        d="M692,-63l74,80l-92,84C733,170 765,262 765,369C765,592 625,757 399,757C173,757 32,592 32,369C32,147 173,-18 399,-18C472,-18 537,0 590,31M400,119C271,119 206,229 206,369C206,510 271,619 400,619C528,619 592,510 592,369C592,306 581,251 558,209l-95,88l-74,-80l90,-83C456,124 430,119 400,119z"
      />
      <glyph
        unicode="R"
        horiz-adv-x="715"
        d="M63,0l170,0l0,284l126,0C441,284 478,246 487,172C496,90 494,11 514,0l168,0l0,8C658,18 664,102 650,203C640,283 607,324 533,347l0,3C628,381 671,444 671,531C671,667 562,744 417,744l-354,0M233,609l156,0C464,609 501,569 501,508C501,449 460,410 384,410l-151,0z"
      />
      <glyph
        unicode="&#x154;"
        horiz-adv-x="715"
        d="M63,0l170,0l0,284l126,0C441,284 478,246 487,172C496,90 494,11 514,0l168,0l0,8C658,18 664,102 650,203C640,283 607,324 533,347l0,3C628,381 671,444 671,531C671,667 562,744 417,744l-354,0M233,609l156,0C464,609 501,569 501,508C501,449 460,410 384,410l-151,0M275,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x158;"
        horiz-adv-x="715"
        d="M63,0l170,0l0,284l126,0C441,284 478,246 487,172C496,90 494,11 514,0l168,0l0,8C658,18 664,102 650,203C640,283 607,324 533,347l0,3C628,381 671,444 671,531C671,667 562,744 417,744l-354,0M233,609l156,0C464,609 501,569 501,508C501,449 460,410 384,410l-151,0M531,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x156;"
        horiz-adv-x="715"
        d="M63,0l170,0l0,284l126,0C441,284 478,246 487,172C496,90 494,11 514,0l168,0l0,8C658,18 664,102 650,203C640,283 607,324 533,347l0,3C628,381 671,444 671,531C671,667 562,744 417,744l-354,0M233,609l156,0C464,609 501,569 501,508C501,449 460,410 384,410l-151,0M285,-263C372,-257 440,-217 440,-122l0,71l-151,0l0,-103l67,0C356,-184 327,-202 285,-210z"
      />
      <glyph
        unicode="S"
        horiz-adv-x="690"
        d="M355,-18C525,-18 655,68 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C30,74 156,-18 355,-18z"
      />
      <glyph
        unicode="&#x15A;"
        horiz-adv-x="690"
        d="M355,-18C525,-18 655,68 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C30,74 156,-18 355,-18M268,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x160;"
        horiz-adv-x="690"
        d="M355,-18C525,-18 655,68 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C30,74 156,-18 355,-18M520,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x15E;"
        horiz-adv-x="690"
        d="M309,-210C385,-210 437,-178 437,-128C437,-72 383,-48 324,-57l0,2l41,37C530,-14 655,71 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C29,88 134,-1 304,-16l-56,-60l18,-26C276,-98 286,-95 299,-95C319,-95 334,-104 334,-124C334,-148 312,-157 285,-157C260,-157 236,-150 216,-141l-20,-39C223,-197 262,-210 309,-210z"
      />
      <glyph
        unicode="&#x15C;"
        horiz-adv-x="690"
        d="M355,-18C525,-18 655,68 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C30,74 156,-18 355,-18M151,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#x218;"
        horiz-adv-x="690"
        d="M355,-18C525,-18 655,68 655,222C655,379 532,423 398,453C281,479 217,492 217,550C217,597 264,627 334,627C408,627 454,593 463,535l167,0C619,681 503,757 339,757C175,757 49,687 49,533C49,378 167,342 312,309C420,284 483,270 483,205C483,144 421,118 355,118C259,118 206,154 197,235l-172,0C30,74 156,-18 355,-18M258,-263C345,-257 413,-217 413,-122l0,71l-151,0l0,-103l67,0C329,-184 300,-202 258,-210z"
      />
      <glyph unicode="T" horiz-adv-x="668" d="M27,599l221,0l0,-599l171,0l0,599l222,0l0,145l-614,0z" />
      <glyph
        unicode="&#x166;"
        horiz-adv-x="668"
        d="M27,599l221,0l0,-174l-146,0l0,-101l146,0l0,-324l171,0l0,324l147,0l0,101l-147,0l0,174l222,0l0,145l-614,0z"
      />
      <glyph
        unicode="&#x164;"
        horiz-adv-x="668"
        d="M27,599l221,0l0,-599l171,0l0,599l222,0l0,145l-614,0M521,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x162;"
        horiz-adv-x="668"
        d="M27,599l221,0l0,-599l171,0l0,599l222,0l0,145l-614,0M257,-263C344,-257 412,-217 412,-122l0,71l-151,0l0,-103l67,0C328,-184 299,-202 257,-210z"
      />
      <glyph
        unicode="&#xDE;"
        horiz-adv-x="686"
        d="M63,0l173,0l0,150l178,0C568,150 664,248 664,389C664,463 638,522 593,566C548,611 482,637 394,637l-158,0l0,107l-173,0M382,492C452,492 492,453 492,390C492,327 453,285 381,285l-145,0l0,207z"
      />
      <glyph
        unicode="U"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14z"
      />
      <glyph
        unicode="&#xDA;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M299,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x16C;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M365,779C461,779 520,842 523,921l-89,0C427,900 405,876 364,876C320,876 299,897 292,921l-91,0C205,839 270,779 365,779z"
      />
      <glyph
        unicode="&#xDB;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M174,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#xDC;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M409,785l152,0l0,134l-152,0M164,785l153,0l0,134l-153,0z"
      />
      <glyph
        unicode="&#xD9;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M305,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="&#x170;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M406,785l102,0l89,131l-156,0M209,785l102,0l90,131l-156,0z"
      />
      <glyph
        unicode="&#x16A;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M206,789l312,0l0,111l-312,0z"
      />
      <glyph
        unicode="&#x172;"
        horiz-adv-x="724"
        d="M403,-184C439,-184 460,-180 473,-175l0,79l-2,0C463,-98 454,-100 442,-100C421,-100 409,-92 409,-73C409,-34 452,-11 497,4C610,42 670,129 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,92 152,-4 335,-13C307,-31 282,-59 282,-100C282,-151 323,-184 403,-184z"
      />
      <glyph
        unicode="&#x16E;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M363,746C425,746 477,793 477,856C477,917 425,967 363,967C300,967 248,917 248,856C248,793 300,746 363,746M363,803C331,803 310,826 310,856C310,886 331,911 363,911C394,911 415,886 415,856C415,826 394,803 363,803z"
      />
      <glyph
        unicode="&#x168;"
        horiz-adv-x="724"
        d="M364,-14C563,-14 670,86 670,259l0,485l-171,0l0,-486C499,177 453,124 364,124C268,124 225,174 225,255l0,489l-170,0l0,-485C55,84 162,-14 364,-14M435,786C501,786 540,841 540,924l-86,0C451,897 440,886 420,886C387,886 356,926 293,926C235,926 185,879 185,785l86,0C273,813 288,826 305,826C335,826 374,786 435,786z"
      />
      <glyph
        unicode="V"
        horiz-adv-x="690"
        d="M255,0l179,0l246,744l-172,0l-109,-358C375,304 349,167 349,167l-2,0C347,167 321,304 296,386l-110,358l-177,0z"
      />
      <glyph
        unicode="W"
        horiz-adv-x="999"
        d="M211,0l168,0l73,312C472,404 493,569 493,569l2,0C495,569 515,404 536,312l72,-312l167,0l204,744l-173,0l-69,-290C711,348 690,169 690,169l-2,0C688,169 663,356 640,460l-66,284l-161,0l-66,-285C325,355 298,169 298,169l-2,0C296,169 279,346 253,453l-71,291l-173,0z"
      />
      <glyph
        unicode="&#x1E82;"
        horiz-adv-x="999"
        d="M211,0l168,0l73,312C472,404 493,569 493,569l2,0C495,569 515,404 536,312l72,-312l167,0l204,744l-173,0l-69,-290C711,348 690,169 690,169l-2,0C688,169 663,356 640,460l-66,284l-161,0l-66,-285C325,355 298,169 298,169l-2,0C296,169 279,346 253,453l-71,291l-173,0M425,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x174;"
        horiz-adv-x="999"
        d="M211,0l168,0l73,312C472,404 493,569 493,569l2,0C495,569 515,404 536,312l72,-312l167,0l204,744l-173,0l-69,-290C711,348 690,169 690,169l-2,0C688,169 663,356 640,460l-66,284l-161,0l-66,-285C325,355 298,169 298,169l-2,0C296,169 279,346 253,453l-71,291l-173,0M306,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#x1E84;"
        horiz-adv-x="999"
        d="M211,0l168,0l73,312C472,404 493,569 493,569l2,0C495,569 515,404 536,312l72,-312l167,0l204,744l-173,0l-69,-290C711,348 690,169 690,169l-2,0C688,169 663,356 640,460l-66,284l-161,0l-66,-285C325,355 298,169 298,169l-2,0C296,169 279,346 253,453l-71,291l-173,0M541,785l152,0l0,134l-152,0M296,785l153,0l0,134l-153,0z"
      />
      <glyph
        unicode="&#x1E80;"
        horiz-adv-x="999"
        d="M211,0l168,0l73,312C472,404 493,569 493,569l2,0C495,569 515,404 536,312l72,-312l167,0l204,744l-173,0l-69,-290C711,348 690,169 690,169l-2,0C688,169 663,356 640,460l-66,284l-161,0l-66,-285C325,355 298,169 298,169l-2,0C296,169 279,346 253,453l-71,291l-173,0M430,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="X"
        horiz-adv-x="730"
        d="M15,0l194,0l77,131C321,190 362,283 362,283l2,0C364,283 404,189 437,131l75,-131l202,0l-248,390l225,354l-186,0l-61,-101C410,586 368,493 368,493l-2,0C366,493 325,586 291,643l-60,101l-195,0l229,-355z"
      />
      <glyph
        unicode="Y"
        horiz-adv-x="702"
        d="M266,0l170,0l0,280l255,464l-180,0l-81,-156C396,520 354,411 354,411l-2,0C352,411 312,521 278,588l-80,156l-187,0l255,-466z"
      />
      <glyph
        unicode="&#xDD;"
        horiz-adv-x="702"
        d="M266,0l170,0l0,280l255,464l-180,0l-81,-156C396,520 354,411 354,411l-2,0C352,411 312,521 278,588l-80,156l-187,0l255,-466M282,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x176;"
        horiz-adv-x="702"
        d="M266,0l170,0l0,280l255,464l-180,0l-81,-156C396,520 354,411 354,411l-2,0C352,411 312,521 278,588l-80,156l-187,0l255,-466M163,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph
        unicode="&#x178;"
        horiz-adv-x="702"
        d="M266,0l170,0l0,280l255,464l-180,0l-81,-156C396,520 354,411 354,411l-2,0C352,411 312,521 278,588l-80,156l-187,0l255,-466M153,785l153,0l0,134l-153,0M398,785l152,0l0,134l-152,0z"
      />
      <glyph
        unicode="&#x1EF2;"
        horiz-adv-x="702"
        d="M266,0l170,0l0,280l255,464l-180,0l-81,-156C396,520 354,411 354,411l-2,0C352,411 312,521 278,588l-80,156l-187,0l255,-466M287,785l122,0l-43,131l-189,0z"
      />
      <glyph
        unicode="Z"
        horiz-adv-x="688"
        d="M36,0l613,0l0,146l-248,0C339,146 235,140 235,140l0,3C235,143 321,227 370,286l265,324l0,134l-572,0l0,-144l214,0C335,600 434,605 434,605l0,-3C434,602 356,526 311,472l-275,-334z"
      />
      <glyph
        unicode="&#x179;"
        horiz-adv-x="688"
        d="M36,0l613,0l0,146l-248,0C339,146 235,140 235,140l0,3C235,143 321,227 370,286l265,324l0,134l-572,0l0,-144l214,0C335,600 434,605 434,605l0,-3C434,602 356,526 311,472l-275,-334M280,785l123,0l109,131l-189,0z"
      />
      <glyph
        unicode="&#x17D;"
        horiz-adv-x="688"
        d="M36,0l613,0l0,146l-248,0C339,146 235,140 235,140l0,3C235,143 321,227 370,286l265,324l0,134l-572,0l0,-144l214,0C335,600 434,605 434,605l0,-3C434,602 356,526 311,472l-275,-334M536,916l-138,0l-48,-57l-2,0l-46,57l-136,0l109,-131l153,0z"
      />
      <glyph
        unicode="&#x17B;"
        horiz-adv-x="688"
        d="M36,0l613,0l0,146l-248,0C339,146 235,140 235,140l0,3C235,143 321,227 370,286l265,324l0,134l-572,0l0,-144l214,0C335,600 434,605 434,605l0,-3C434,602 356,526 311,472l-275,-334M270,785l159,0l0,135l-159,0z"
      />
      <glyph
        unicode="a"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&#xE1;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M232,612l117,0l119,161l-183,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&#x103;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M296,606C390,606 453,669 456,761l-89,0C362,728 336,703 295,703C251,703 226,725 221,761l-91,0C134,666 203,606 296,606M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&#xE2;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M106,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph unicode="&#xB4;" horiz-adv-x="500" d="M177,612l117,0l119,161l-183,0z" />
      <glyph
        unicode="&#xE4;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89M98,607l149,0l0,137l-149,0M341,607l148,0l0,137l-148,0z"
      />
      <glyph
        unicode="&#xE6;"
        horiz-adv-x="933"
        d="M233,-15C333,-15 393,16 444,86C484,23 552,-16 651,-16C787,-16 870,56 897,150l-153,0C727,117 700,98 652,98C576,98 536,146 527,226l379,0C907,331 884,423 821,481C775,525 719,547 644,547C571,547 514,522 473,481C438,525 377,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 105,-15 233,-15M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89M642,436C702,436 741,391 746,317l-218,0C538,390 571,436 642,436z"
      />
      <glyph
        unicode="&#x1FD;"
        horiz-adv-x="933"
        d="M233,-15C333,-15 393,16 444,86C484,23 552,-16 651,-16C787,-16 870,56 897,150l-153,0C727,117 700,98 652,98C576,98 536,146 527,226l379,0C907,331 884,423 821,481C775,525 719,547 644,547C571,547 514,522 473,481C438,525 377,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 105,-15 233,-15M417,612l117,0l119,161l-183,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89M642,436C702,436 741,391 746,317l-218,0C538,390 571,436 642,436z"
      />
      <glyph
        unicode="&#x2113;"
        horiz-adv-x="435"
        d="M68,276C53,270 29,262 12,257l0,-91C28,170 52,178 68,183l0,-22C68,30 137,-6 242,-6C284,-6 323,1 350,10l0,120l-3,0C327,125 312,120 286,120C234,120 215,136 215,191l0,52C334,309 405,407 405,574C405,705 345,752 245,752C141,752 68,702 68,583M215,350l0,241C215,635 237,651 260,651C285,651 300,636 300,581C300,486 281,429 253,390C239,370 224,357 215,350z"
      />
      <glyph
        unicode="&#xE0;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M243,612l116,0l-53,161l-183,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&#x101;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M137,615l312,0l0,111l-312,0M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&amp;"
        horiz-adv-x="724"
        d="M295,-14C366,-14 432,13 485,52l24,-34l15,-18l192,0l-127,160C633,224 659,296 666,371l-137,0C524,333 516,297 501,263C482,290 450,334 431,358l-29,36C477,432 540,483 540,576C540,685 455,749 340,749C217,749 135,675 135,572C135,512 160,463 196,421C104,377 29,318 29,203C29,71 129,-14 295,-14M297,113C238,113 191,151 191,206C191,254 213,297 266,331l143,-184C377,125 339,113 297,113M280,576C280,614 304,639 341,639C376,639 401,612 401,575C401,537 385,504 337,476C303,508 280,538 280,576z"
      />
      <glyph
        unicode="&#x105;"
        horiz-adv-x="580"
        d="M480,-184C516,-184 537,-180 550,-175l0,79l-2,0C540,-98 531,-100 519,-100C498,-100 486,-92 486,-73C486,-48 506,-18 546,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0l41,0C398,-20 361,-49 361,-100C361,-151 400,-184 480,-184M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="&#x2248;"
        horiz-adv-x="606"
        d="M422,356C511,356 551,413 551,522l-104,0C445,496 435,483 406,483C359,483 266,520 189,520C111,520 56,470 55,349l103,0C162,377 175,391 200,391C251,391 345,356 422,356M422,136C511,136 551,194 551,302l-104,0C445,276 435,264 406,264C359,264 266,300 189,300C111,300 56,251 55,129l103,0C162,158 175,171 200,171C251,171 345,136 422,136z"
      />
      <glyph
        unicode="&#xE5;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M294,596C356,596 408,643 408,706C408,767 356,817 294,817C231,817 179,767 179,706C179,643 231,596 294,596M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89M294,653C262,653 241,676 241,706C241,736 262,761 294,761C325,761 346,736 346,706C346,676 325,653 294,653z"
      />
      <glyph unicode="^" horiz-adv-x="503" d="M14,438l144,0l92,163l2,0l91,-163l146,0l-175,306l-125,0z" />
      <glyph
        unicode="~"
        horiz-adv-x="471"
        d="M323,257C401,257 441,322 441,427l-100,0C339,397 330,383 304,383C284,383 266,394 243,405C220,416 195,427 160,427C91,427 36,372 35,254l101,0C139,284 151,300 174,300C193,300 212,290 238,278C262,268 290,257 323,257z"
      />
      <glyph
        unicode="*"
        horiz-adv-x="474"
        d="M160,371l78,110l77,-110l73,53l-78,105l121,40l-31,82l-116,-40l0,133l-94,0l0,-133l-115,40l-34,-82l123,-40l-77,-105z"
      />
      <glyph
        unicode="@"
        horiz-adv-x="949"
        d="M418,98C480,98 525,126 554,182l2,0C573,121 629,92 706,92C818,92 911,177 911,350C911,605 718,744 489,744C258,744 40,583 40,294C40,47 220,-115 464,-115C543,-115 616,-101 682,-68l-31,77C595,-14 531,-29 465,-29C255,-29 141,108 141,295C141,523 297,656 490,656C686,656 816,554 816,351C816,214 759,178 714,178C679,178 667,199 673,250l33,268l-133,0l-8,-66l-1,0C545,502 509,529 452,529C335,529 258,430 258,290C258,173 320,98 418,98M461,200C417,200 398,239 398,293C398,370 429,427 485,427C531,427 552,390 552,335C552,255 518,200 461,200z"
      />
      <glyph
        unicode="&#xE3;"
        horiz-adv-x="580"
        d="M393,0l153,0l0,9C532,18 526,41 526,83l0,273C526,428 500,483 445,515C408,537 360,547 292,547C134,547 60,462 56,371l145,0C205,413 234,441 294,441C348,441 373,415 373,377C373,337 334,322 239,309C123,295 30,265 30,147C30,42 107,-14 212,-14C294,-14 338,16 373,65l2,0C378,35 385,12 393,0M366,613C432,613 471,668 471,751l-84,0C384,724 374,712 353,712C320,712 287,753 224,753C166,753 116,706 116,612l84,0C202,640 218,654 235,654C265,654 305,613 366,613M261,89C218,89 183,106 183,152C183,198 216,215 285,230C320,238 354,246 377,260l0,-69C377,133 331,89 261,89z"
      />
      <glyph
        unicode="b"
        horiz-adv-x="621"
        d="M367,-17C505,-17 595,90 595,266C595,429 504,547 366,547C292,547 243,512 207,458l-3,0C206,490 209,536 209,581l0,163l-157,0l0,-744l151,0l0,78l2,0C236,13 296,-17 367,-17M325,109C249,109 204,173 204,266C204,358 242,428 324,428C398,428 435,362 435,267C435,171 398,109 325,109z"
      />
      <glyph unicode="\" horiz-adv-x="466" d="M485,-141l-333,925l-143,0l333,-925z" />
      <glyph unicode="|" horiz-adv-x="242" d="M71,-141l100,0l0,925l-100,0z" />
      <glyph
        unicode="{"
        horiz-adv-x="424"
        d="M355,-141l28,0l0,121l-22,0C302,-20 276,-5 276,55l0,153C276,275 249,305 192,322l0,3C249,342 276,373 276,440l0,149C276,651 302,662 361,662l22,0l0,122l-28,0C201,784 126,742 126,589l0,-130C126,409 101,385 30,382l0,-117C101,262 126,239 126,188l0,-132C126,-98 201,-141 355,-141z"
      />
      <glyph
        unicode="}"
        horiz-adv-x="424"
        d="M43,-141l27,0C224,-141 299,-98 299,56l0,132C299,238 324,262 394,265l0,116C324,384 299,409 299,458l0,130C299,742 224,784 70,784l-27,0l0,-122l21,0C123,662 149,650 149,589l0,-150C149,373 176,342 233,324l0,-3C176,304 149,274 149,207l0,-152C149,-6 123,-20 64,-20l-21,0z"
      />
      <glyph unicode="[" horiz-adv-x="427" d="M71,-141l312,0l0,121l-162,0l0,682l162,0l0,122l-312,0z" />
      <glyph unicode="]" horiz-adv-x="427" d="M43,-141l313,0l0,925l-313,0l0,-122l162,0l0,-682l-162,0z" />
      <glyph
        unicode="&#x2D8;"
        horiz-adv-x="500"
        d="M272,606C366,606 429,669 432,761l-89,0C338,728 312,703 271,703C227,703 202,725 197,761l-91,0C110,666 179,606 272,606z"
      />
      <glyph unicode="&#xA6;" horiz-adv-x="242" d="M71,395l100,0l0,389l-100,0M71,-141l100,0l0,404l-100,0z" />
      <glyph
        unicode="&#x2022;"
        horiz-adv-x="396"
        d="M198,183C281,183 349,250 349,333C349,416 281,485 198,485C115,485 47,416 47,333C47,250 115,183 198,183z"
      />
      <glyph
        unicode="c"
        horiz-adv-x="577"
        d="M307,-17C455,-17 551,77 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,106 137,-17 307,-17z"
      />
      <glyph
        unicode="&#x107;"
        horiz-adv-x="577"
        d="M307,-17C455,-17 551,77 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,106 137,-17 307,-17M235,612l117,0l119,161l-183,0z"
      />
      <glyph unicode="&#x2C7;" horiz-adv-x="500" d="M435,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z" />
      <glyph
        unicode="&#x10D;"
        horiz-adv-x="577"
        d="M307,-17C455,-17 551,77 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,106 137,-17 307,-17M483,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z"
      />
      <glyph
        unicode="&#xE7;"
        horiz-adv-x="577"
        d="M270,-210C346,-210 398,-178 398,-128C398,-72 344,-48 285,-57l0,2l43,39C464,-8 551,83 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,119 120,3 266,-14l-57,-62l18,-26C237,-98 247,-95 260,-95C280,-95 295,-104 295,-124C295,-148 273,-157 246,-157C221,-157 197,-150 177,-141l-20,-39C184,-197 223,-210 270,-210z"
      />
      <glyph
        unicode="&#x109;"
        horiz-adv-x="577"
        d="M307,-17C455,-17 551,77 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,106 137,-17 307,-17M109,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#x10B;"
        horiz-adv-x="577"
        d="M307,-17C455,-17 551,77 556,198l-151,0C398,137 363,102 305,102C226,102 186,168 186,265C186,365 226,430 305,430C357,430 389,399 400,350l152,0C533,466 445,547 303,547C136,547 27,425 27,265C27,106 137,-17 307,-17M217,607l159,0l0,137l-159,0z"
      />
      <glyph
        unicode="&#xB8;"
        horiz-adv-x="500"
        d="M227,-210C303,-210 355,-178 355,-128C355,-72 301,-48 242,-57l0,2l62,55l-67,0l-71,-76l18,-26C194,-98 204,-95 217,-95C237,-95 252,-104 252,-124C252,-148 230,-157 203,-157C178,-157 154,-150 134,-141l-20,-39C141,-197 180,-210 227,-210z"
      />
      <glyph
        unicode="&#xA2;"
        horiz-adv-x="580"
        d="M273,-129l63,0l0,113C469,-5 554,83 559,200l-148,0C405,147 378,114 336,104l0,323C373,417 399,387 407,346l149,0C541,456 462,535 336,546l0,89l-63,0l0,-89C122,532 27,414 27,265C27,117 121,1 273,-15M184,265C184,351 214,410 273,426l0,-321C214,121 184,181 184,265z"
      />
      <glyph unicode="&#x2C6;" horiz-adv-x="500" d="M65,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z" />
      <glyph unicode=":" horiz-adv-x="261" d="M47,321l166,0l0,164l-166,0M47,0l166,0l0,164l-166,0z" />
      <glyph
        unicode=","
        horiz-adv-x="254"
        d="M45,-181C137,-173 210,-116 210,10l0,154l-166,0l0,-164l78,0l0,-2C122,-61 92,-99 45,-111z"
      />
      <glyph
        unicode="&#xF6C3;"
        horiz-adv-x="500"
        d="M173,-263C260,-257 328,-217 328,-122l0,71l-151,0l0,-103l67,0C244,-184 215,-202 173,-210z"
      />
      <glyph
        unicode="&#xA9;"
        horiz-adv-x="867"
        d="M434,-18C648,-18 816,147 816,369C816,592 648,757 434,757C220,757 51,592 51,369C51,147 220,-18 434,-18M434,32C249,32 110,176 110,369C110,563 249,706 434,706C618,706 756,563 756,369C756,176 618,32 434,32M439,146C502,146 546,164 581,196C613,225 631,267 634,314l-113,0C516,276 488,244 440,244C379,244 342,297 342,372C342,446 373,502 438,502C483,502 510,474 516,440l114,0C625,492 600,537 558,566C528,587 486,600 439,600C308,600 222,503 222,372C222,241 309,146 439,146z"
      />
      <glyph
        unicode="&#xA4;"
        horiz-adv-x="539"
        d="M98,91l70,71C197,146 230,138 269,138C307,138 342,146 371,162l70,-71l79,78l-69,69C469,270 479,309 479,350C479,392 470,430 450,462l70,70l-79,77l-70,-71C342,554 307,563 269,563C230,563 196,554 167,538l-69,71l-79,-77l68,-70C68,430 58,392 58,350C58,308 68,269 87,237l-68,-68M269,232C209,232 173,279 173,350C173,421 209,470 269,470C329,470 363,421 363,350C363,279 329,232 269,232z"
      />
      <glyph
        unicode="d"
        horiz-adv-x="622"
        d="M255,-17C327,-17 386,13 417,78l2,0l0,-78l151,0l0,744l-157,0l0,-163C413,536 415,491 417,458l-2,0C379,513 330,547 257,547C119,547 27,429 27,266C27,90 117,-17 255,-17M297,109C224,109 187,171 187,267C187,362 224,428 298,428C379,428 418,360 418,266C418,173 373,109 297,109z"
      />
      <glyph
        unicode="&#x2020;"
        horiz-adv-x="628"
        d="M44,407l186,0l0,-550l166,0l0,550l187,0l0,130l-187,0l0,207l-166,0l0,-207l-186,0z"
      />
      <glyph
        unicode="&#x2021;"
        horiz-adv-x="628"
        d="M44,407l186,0l0,-213l-186,0l0,-131l186,0l0,-206l166,0l0,206l187,0l0,131l-187,0l0,213l187,0l0,130l-187,0l0,207l-166,0l0,-207l-186,0z"
      />
      <glyph
        unicode="&#x10F;"
        horiz-adv-x="622"
        d="M255,-17C327,-17 386,13 417,78l2,0l0,-78l151,0l0,744l-157,0l0,-163C413,536 415,491 417,458l-2,0C379,513 330,547 257,547C119,547 27,429 27,266C27,90 117,-17 255,-17M297,109C224,109 187,171 187,267C187,362 224,428 298,428C379,428 418,360 418,266C418,173 373,109 297,109M607,550C689,554 738,588 738,680l0,64l-131,0l0,-96l59,0C665,622 647,604 607,600z"
      />
      <glyph
        unicode="&#x111;"
        horiz-adv-x="622"
        d="M276,596l137,0l0,-15C413,536 415,491 417,458l-2,0C379,513 330,547 257,547C119,547 27,429 27,266C27,90 117,-17 255,-17C327,-17 386,13 417,78l2,0l0,-78l151,0l0,596l58,0l0,80l-58,0l0,68l-157,0l0,-68l-137,0M297,109C224,109 187,171 187,267C187,362 224,428 298,428C379,428 418,360 418,266C418,173 373,109 297,109z"
      />
      <glyph
        unicode="&#xB0;"
        horiz-adv-x="438"
        d="M220,392C314,392 394,465 394,558C394,653 314,727 220,727C125,727 44,653 44,558C44,465 125,392 220,392M220,473C169,473 138,512 138,558C138,605 169,646 220,646C269,646 300,605 300,558C300,512 269,473 220,473z"
      />
      <glyph unicode="&#xA8;" horiz-adv-x="500" d="M297,607l148,0l0,137l-148,0M54,607l149,0l0,137l-149,0z" />
      <glyph
        unicode="&#xF7;"
        horiz-adv-x="603"
        d="M219,477l166,0l0,147l-166,0M59,279l486,0l0,127l-486,0M219,60l166,0l0,147l-166,0z"
      />
      <glyph
        unicode="$"
        horiz-adv-x="662"
        d="M304,-124l64,0l0,117C516,0 628,75 628,211C628,353 520,394 381,428l-13,3l0,168C420,589 451,558 458,512l154,0C604,636 514,709 368,720l0,80l-64,0l0,-79C159,715 48,645 48,517C48,369 159,338 300,302C301,301 302,301 304,301l0,-184C234,126 197,165 189,228l-163,0C31,92 128,4 304,-7M465,201C465,152 421,124 368,117l0,167C432,265 465,247 465,201M210,525C210,566 244,596 304,602l0,-155C238,466 210,484 210,525z"
      />
      <glyph unicode="&#x2D9;" horiz-adv-x="500" d="M170,607l159,0l0,137l-159,0z" />
      <glyph unicode="&#x131;" horiz-adv-x="261" d="M52,0l157,0l0,532l-157,0z" />
      <glyph
        unicode="&#xF6BE;"
        horiz-adv-x="261"
        d="M52,-181C100,-181 140,-170 169,-141C194,-117 209,-81 209,-13l0,545l-157,0l0,-539C52,-46 37,-58 6,-61C-5,-62 -17,-62 -31,-62l-6,0l0,-111C-13,-178 24,-181 52,-181z"
      />
      <glyph
        unicode="e"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#xE9;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M239,612l117,0l119,161l-183,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#x115;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M303,606C397,606 460,669 463,761l-89,0C369,728 343,703 302,703C258,703 233,725 228,761l-91,0C141,666 210,606 303,606M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#x11B;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M487,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#xEA;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M113,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#xEB;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436M105,607l149,0l0,137l-149,0M348,607l148,0l0,137l-148,0z"
      />
      <glyph
        unicode="&#x117;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M221,607l159,0l0,137l-159,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="&#xE8;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M250,612l116,0l-53,161l-183,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph
        unicode="8"
        horiz-adv-x="647"
        d="M325,-17C495,-17 618,65 618,207C618,291 570,360 476,386l0,3C550,415 586,469 586,533C586,653 485,727 324,727C164,727 60,648 60,530C60,464 95,412 171,385l0,-3C80,354 30,287 30,203C30,65 155,-17 325,-17M323,432C254,432 214,468 214,522C214,577 254,614 323,614C393,614 432,577 432,522C432,468 393,432 323,432M325,105C244,105 195,147 195,213C195,282 245,325 325,325C405,325 453,282 453,213C453,148 402,105 325,105z"
      />
      <glyph
        unicode="&#x2088;"
        horiz-adv-x="456"
        d="M228,-172C352,-172 430,-118 430,-29C430,22 398,66 334,84l0,3C384,106 409,140 409,180C409,256 339,306 228,306C119,306 48,252 48,177C48,135 72,103 124,84l0,-3C61,62 27,20 27,-32C27,-117 105,-172 228,-172M228,119C188,119 166,140 166,173C166,204 188,226 228,226C269,226 291,204 291,173C291,140 269,119 228,119M228,-85C184,-85 154,-61 154,-23C154,15 184,43 229,43C275,43 303,15 303,-23C303,-60 274,-85 228,-85z"
      />
      <glyph
        unicode="&#x2078;"
        horiz-adv-x="456"
        d="M228,276C352,276 430,330 430,419C430,470 398,514 334,532l0,3C384,554 409,588 409,628C409,704 339,754 228,754C119,754 48,700 48,625C48,583 72,551 124,532l0,-3C61,510 27,468 27,416C27,331 105,276 228,276M228,567C188,567 166,588 166,621C166,652 188,674 228,674C269,674 291,652 291,621C291,588 269,567 228,567M228,363C184,363 154,387 154,425C154,463 184,491 229,491C275,491 303,463 303,425C303,388 274,363 228,363z"
      />
      <glyph
        unicode="&#x2026;"
        horiz-adv-x="823"
        d="M44,0l166,0l0,164l-166,0M328,0l167,0l0,164l-167,0M613,0l166,0l0,164l-166,0z"
      />
      <glyph
        unicode="&#x113;"
        horiz-adv-x="596"
        d="M308,-16C445,-16 532,56 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16M144,615l312,0l0,111l-312,0M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph unicode="&#x2014;" horiz-adv-x="1000" d="M200,197l600,0l0,135l-600,0z" />
      <glyph unicode="&#x2013;" horiz-adv-x="500" d="M0,197l500,0l0,135l-500,0z" />
      <glyph
        unicode="&#x14B;"
        horiz-adv-x="604"
        d="M556,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0l0,81l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-327C400,-46 384,-58 353,-61C342,-62 330,-62 316,-62l-6,0l0,-111C334,-178 371,-181 399,-181C447,-181 487,-170 516,-141C541,-117 556,-81 556,-13z"
      />
      <glyph
        unicode="&#x119;"
        horiz-adv-x="596"
        d="M383,-184C419,-184 440,-180 453,-175l0,79l-2,0C443,-98 434,-100 422,-100C401,-100 389,-92 389,-73C389,-27 455,9 489,41C521,71 546,104 559,150l-152,0C390,117 359,98 310,98C234,98 191,146 181,226l388,0C570,331 543,421 483,479C436,522 376,547 300,547C138,547 27,425 27,265C27,106 134,-16 308,-16l12,0C292,-33 264,-60 264,-100C264,-151 303,-184 383,-184M300,436C362,436 404,391 409,317l-227,0C192,390 228,436 300,436z"
      />
      <glyph unicode="=" horiz-adv-x="603" d="M61,368l486,0l0,123l-486,0M61,154l486,0l0,123l-486,0z" />
      <glyph
        unicode="&#x212E;"
        horiz-adv-x="751"
        d="M721,364C722,503 693,612 605,686C552,732 477,758 389,758C181,758 29,600 29,373C29,147 164,-14 395,-14C574,-14 684,87 717,225l-58,0C621,109 540,39 395,39C318,39 259,58 216,89l0,275M389,709C450,709 501,692 537,671l0,-258l-321,0l0,244C257,687 318,709 389,709z"
      />
      <glyph
        unicode="&#xF0;"
        horiz-adv-x="616"
        d="M167,557l149,47C350,568 380,528 399,483l-3,0C360,523 315,534 270,534C123,534 27,424 27,265C27,106 139,-17 309,-17C509,-17 589,124 589,305C589,450 528,566 458,648l76,24l-29,62l-101,-31C386,719 368,732 351,744l-199,0l0,-7C184,715 221,689 258,658l-120,-37M308,97C228,97 186,164 186,264C186,364 228,429 307,429C386,429 429,365 429,265C429,164 386,97 308,97z"
      />
      <glyph unicode="!" horiz-adv-x="289" d="M104,259l81,0l41,299l0,186l-163,0l0,-186M61,0l167,0l0,164l-167,0z" />
      <glyph
        unicode="&#xA1;"
        horiz-adv-x="289"
        d="M185,353l-81,0l-41,-299l0,-186l163,0l0,186M228,612l-167,0l0,-164l167,0z"
      />
      <glyph
        unicode="f"
        horiz-adv-x="395"
        d="M20,430l80,0l0,-430l157,0l0,430l111,0l0,102l-111,0l0,34C257,626 289,631 368,628l0,112C346,743 324,744 303,744C235,745 180,733 145,702C114,674 100,634 100,577l0,-45l-80,0z"
      />
      <glyph
        unicode="&#xFB00;"
        horiz-adv-x="730"
        d="M435,0l157,0l0,430l111,0l0,102l-111,0l0,34C592,626 624,631 703,628l0,112C615,750 536,742 493,712C453,684 435,642 435,577l0,-45l-178,0l0,28C257,620 287,625 365,623l0,112C278,744 204,737 162,710C118,682 100,636 100,571l0,-39l-80,0l0,-102l80,0l0,-430l157,0l0,430l178,0z"
      />
      <glyph
        unicode="&#xFB03;"
        horiz-adv-x="979"
        d="M770,0l156,0l0,532l-156,0M769,607l158,0l0,137l-158,0M435,0l157,0l0,430l111,0l0,102l-111,0l0,34C592,626 624,631 703,628l0,112C615,750 536,742 493,712C453,684 435,642 435,577l0,-45l-178,0l0,28C257,620 287,625 365,623l0,112C278,744 204,737 162,710C118,682 100,636 100,571l0,-39l-80,0l0,-102l80,0l0,-430l157,0l0,430l178,0z"
      />
      <glyph
        unicode="&#xFB04;"
        horiz-adv-x="982"
        d="M770,0l158,0l0,744l-158,0M435,0l157,0l0,430l111,0l0,102l-111,0l0,34C592,626 624,631 703,628l0,112C615,750 536,742 493,712C453,684 435,642 435,577l0,-45l-178,0l0,28C257,620 287,625 365,623l0,112C278,744 204,737 162,710C118,682 100,636 100,571l0,-39l-80,0l0,-102l80,0l0,-430l157,0l0,430l178,0z"
      />
      <glyph
        unicode="&#xF001;"
        horiz-adv-x="644"
        d="M20,430l80,0l0,-430l157,0l0,430l111,0l0,102l-111,0l0,34C257,626 289,631 368,628l0,112C280,750 204,742 162,707C120,682 100,642 100,577l0,-45l-80,0M435,0l156,0l0,532l-156,0M434,607l158,0l0,137l-158,0z"
      />
      <glyph
        unicode="&#xF002;"
        horiz-adv-x="647"
        d="M20,430l80,0l0,-430l157,0l0,430l111,0l0,102l-111,0l0,34C257,626 289,631 368,628l0,112C280,750 204,742 162,707C120,682 100,642 100,577l0,-45l-80,0M435,0l158,0l0,744l-158,0z"
      />
      <glyph
        unicode="&#xFB01;"
        horiz-adv-x="644"
        d="M20,430l80,0l0,-430l157,0l0,430l111,0l0,102l-111,0l0,34C257,626 289,631 368,628l0,112C280,750 204,742 162,707C120,682 100,642 100,577l0,-45l-80,0M435,0l156,0l0,532l-156,0M434,607l158,0l0,137l-158,0z"
      />
      <glyph
        unicode="5"
        horiz-adv-x="630"
        d="M313,-17C479,-17 597,83 597,238C597,388 484,482 354,482C284,482 240,460 207,424l-2,0l32,157l316,0l0,131l-433,0l-74,-397l153,0C220,341 259,362 310,362C379,362 433,316 433,239C433,165 381,114 307,114C239,114 194,149 183,202l-161,0C33,66 152,-17 313,-17z"
      />
      <glyph
        unicode="&#x2085;"
        horiz-adv-x="435"
        d="M213,-173C328,-173 405,-105 405,-3C405,92 336,146 247,146C201,146 171,133 152,112l-2,0l17,89l207,0l0,95l-292,0l-48,-260l109,0C156,52 181,63 211,63C252,63 281,40 281,-6C281,-49 252,-78 211,-78C170,-78 143,-59 136,-34l-116,0C31,-117 106,-173 213,-173z"
      />
      <glyph
        unicode="&#x2075;"
        horiz-adv-x="435"
        d="M213,275C328,275 405,343 405,445C405,540 336,594 247,594C201,594 171,581 152,560l-2,0l17,89l207,0l0,95l-292,0l-48,-260l109,0C156,500 181,511 211,511C252,511 281,488 281,442C281,399 252,370 211,370C170,370 143,389 136,414l-116,0C31,331 106,275 213,275z"
      />
      <glyph
        unicode="&#x215D;"
        horiz-adv-x="999"
        d="M216,275C331,275 408,343 408,445C408,540 339,594 250,594C204,594 174,581 155,560l-2,0l17,89l207,0l0,95l-292,0l-48,-260l109,0C159,500 184,511 214,511C255,511 284,488 284,442C284,399 255,370 214,370C173,370 146,389 139,414l-116,0C34,331 109,275 216,275M121,-130l103,0l628,874l-104,0M768,-122C892,-122 970,-68 970,21C970,72 938,116 874,134l0,3C924,156 949,190 949,230C949,306 879,356 768,356C659,356 588,302 588,227C588,185 612,153 664,134l0,-3C601,112 567,70 567,18C567,-67 645,-122 768,-122M768,169C728,169 706,190 706,223C706,254 728,276 768,276C809,276 831,254 831,223C831,190 809,169 768,169M768,-35C724,-35 694,-11 694,27C694,65 724,93 769,93C815,93 843,65 843,27C843,-10 814,-35 768,-35z"
      />
      <glyph
        unicode="&#xFB02;"
        horiz-adv-x="647"
        d="M20,430l80,0l0,-430l157,0l0,430l111,0l0,102l-111,0l0,34C257,626 289,631 368,628l0,112C280,750 204,742 162,707C120,682 100,642 100,577l0,-45l-80,0M435,0l158,0l0,744l-158,0z"
      />
      <glyph
        unicode="&#x192;"
        horiz-adv-x="466"
        d="M42,331l91,0l-48,-329C77,-53 51,-55 3,-53l0,-104C77,-166 149,-168 193,-128C222,-103 236,-65 244,-14l49,345l127,0l0,101l-112,0l18,123C334,610 371,613 439,609l0,112C355,731 273,730 224,690C192,664 176,625 168,573l-21,-141l-105,0z"
      />
      <glyph
        unicode="4"
        horiz-adv-x="654"
        d="M387,0l157,0l0,161l106,0l0,120l-106,0l0,439l-156,0l-335,-414l0,-145l334,0M162,275l0,2C162,277 215,332 244,368l76,95C350,500 390,562 390,562l2,0C392,562 387,495 387,453l0,-172l-123,0C224,281 162,275 162,275z"
      />
      <glyph
        unicode="&#x2084;"
        horiz-adv-x="466"
        d="M252,-160l128,0l0,96l67,0l0,89l-67,0l0,276l-128,0l-219,-262l0,-103l219,0M112,18l0,2C112,20 149,51 170,75l46,57C233,153 257,190 257,190l2,0C259,190 252,147 252,121l0,-96l-62,0C160,25 112,18 112,18z"
      />
      <glyph
        unicode="&#x2074;"
        horiz-adv-x="466"
        d="M252,288l128,0l0,96l67,0l0,89l-67,0l0,276l-128,0l-219,-262l0,-103l219,0M112,466l0,2C112,468 149,499 170,523l46,57C233,601 257,638 257,638l2,0C259,638 252,595 252,569l0,-96l-62,0C160,473 112,466 112,466z"
      />
      <glyph unicode="&#x2044;" horiz-adv-x="295" d="M-274,-130l103,0l628,874l-104,0z" />
      <glyph
        unicode="g"
        horiz-adv-x="613"
        d="M299,-185C377,-185 445,-167 491,-127C534,-89 560,-32 560,47l0,485l-150,0l0,-68l-2,0C378,523 327,547 255,547C124,547 31,440 31,285C31,124 140,33 263,33C329,33 376,59 407,111l4,0C409,93 408,68 408,41l0,-11C408,-35 371,-73 297,-73C236,-73 206,-51 196,-19l-153,0C59,-124 153,-185 299,-185M298,151C228,151 185,205 185,289C185,374 228,428 297,428C374,428 413,369 413,290C413,211 378,151 298,151z"
      />
      <glyph
        unicode="&#x11F;"
        horiz-adv-x="613"
        d="M299,-185C377,-185 445,-167 491,-127C534,-89 560,-32 560,47l0,485l-150,0l0,-68l-2,0C378,523 327,547 255,547C124,547 31,440 31,285C31,124 140,33 263,33C329,33 376,59 407,111l4,0C409,93 408,68 408,41l0,-11C408,-35 371,-73 297,-73C236,-73 206,-51 196,-19l-153,0C59,-124 153,-185 299,-185M298,151C228,151 185,205 185,289C185,374 228,428 297,428C374,428 413,369 413,290C413,211 378,151 298,151M308,606C402,606 465,669 468,761l-89,0C374,728 348,703 307,703C263,703 238,725 233,761l-91,0C146,666 215,606 308,606z"
      />
      <glyph
        unicode="&#x11D;"
        horiz-adv-x="613"
        d="M299,-185C377,-185 445,-167 491,-127C534,-89 560,-32 560,47l0,485l-150,0l0,-68l-2,0C378,523 327,547 255,547C124,547 31,440 31,285C31,124 140,33 263,33C329,33 376,59 407,111l4,0C409,93 408,68 408,41l0,-11C408,-35 371,-73 297,-73C236,-73 206,-51 196,-19l-153,0C59,-124 153,-185 299,-185M298,151C228,151 185,205 185,289C185,374 228,428 297,428C374,428 413,369 413,290C413,211 378,151 298,151M118,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#x123;"
        horiz-adv-x="613"
        d="M299,-185C377,-185 445,-167 491,-127C534,-89 560,-32 560,47l0,485l-150,0l0,-68l-2,0C378,523 327,547 255,547C124,547 31,440 31,285C31,124 140,33 263,33C329,33 376,59 407,111l4,0C409,93 408,68 408,41l0,-11C408,-35 371,-73 297,-73C236,-73 206,-51 196,-19l-153,0C59,-124 153,-185 299,-185M298,151C228,151 185,205 185,289C185,374 228,428 297,428C374,428 413,369 413,290C413,211 378,151 298,151M383,819C296,813 228,773 228,678l0,-71l151,0l0,103l-67,0C312,740 341,758 383,766z"
      />
      <glyph
        unicode="&#x121;"
        horiz-adv-x="613"
        d="M299,-185C377,-185 445,-167 491,-127C534,-89 560,-32 560,47l0,485l-150,0l0,-68l-2,0C378,523 327,547 255,547C124,547 31,440 31,285C31,124 140,33 263,33C329,33 376,59 407,111l4,0C409,93 408,68 408,41l0,-11C408,-35 371,-73 297,-73C236,-73 206,-51 196,-19l-153,0C59,-124 153,-185 299,-185M298,151C228,151 185,205 185,289C185,374 228,428 297,428C374,428 413,369 413,290C413,211 378,151 298,151M226,607l159,0l0,137l-159,0z"
      />
      <glyph
        unicode="&#xDF;"
        horiz-adv-x="606"
        d="M341,-9C476,-9 578,74 578,231C578,329 529,397 433,423l0,3C508,451 541,498 541,565C541,681 447,756 305,756C148,756 52,673 52,539l0,-539l157,0l0,534C209,601 247,637 307,637C363,637 394,603 394,552C394,493 351,463 286,463l-10,0l0,-92l21,0C382,371 423,323 423,244C423,148 370,104 278,114C277,114 275,114 273,115l0,-116C289,-6 313,-9 341,-9z"
      />
      <glyph unicode="`" horiz-adv-x="538" d="M225,612l116,0l-53,161l-183,0z" />
      <glyph
        unicode="&gt;"
        horiz-adv-x="541"
        d="M60,573l0,-143l171,-63C287,347 374,324 374,324l0,-2C374,322 288,297 231,275l-171,-67l0,-138l426,180l0,143z"
      />
      <glyph
        unicode="&#x2265;"
        horiz-adv-x="541"
        d="M486,120l-426,0l0,-120l426,0M60,587l0,-134l176,-50C292,387 384,369 384,369l0,-2C384,367 293,346 236,329l-176,-52l0,-131l426,144l0,153z"
      />
      <glyph
        unicode="&#xAB;"
        horiz-adv-x="511"
        d="M452,40l0,131l-96,91l0,2l96,91l0,135l-168,-152l0,-146M226,40l0,131l-96,91l0,2l96,91l0,135l-167,-152l0,-146z"
      />
      <glyph
        unicode="&#xBB;"
        horiz-adv-x="511"
        d="M59,490l0,-132l96,-91l0,-2l-96,-91l0,-134l167,151l0,146M284,490l0,-132l97,-91l0,-2l-97,-91l0,-134l168,151l0,146z"
      />
      <glyph unicode="&#x2039;" horiz-adv-x="301" d="M242,25l0,142l-104,95l0,2l104,97l0,142l-183,-163l0,-151z" />
      <glyph unicode="&#x203A;" horiz-adv-x="301" d="M59,503l0,-141l105,-96l0,-2l-105,-96l0,-143l183,164l0,150z" />
      <glyph
        unicode="h"
        horiz-adv-x="604"
        d="M209,539l0,205l-157,0l0,-744l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 243,513 211,456l-3,0C209,491 209,534 209,539z"
      />
      <glyph
        unicode="&#x127;"
        horiz-adv-x="604"
        d="M-6,596l58,0l0,-596l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 243,513 211,456l-3,0C209,491 209,534 209,539l0,57l137,0l0,80l-137,0l0,68l-157,0l0,-68l-58,0z"
      />
      <glyph
        unicode="&#x125;"
        horiz-adv-x="604"
        d="M209,539l0,205l-157,0l0,-744l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 243,513 211,456l-3,0C209,491 209,534 209,539M116,785l138,0l48,57l2,0l46,-57l136,0l-109,131l-153,0z"
      />
      <glyph unicode="&#x2DD;" horiz-adv-x="500" d="M289,612l99,0l102,161l-153,0M95,612l99,0l103,161l-153,0z" />
      <glyph unicode="-" horiz-adv-x="363" d="M40,197l283,0l0,136l-283,0z" />
      <glyph unicode="i" horiz-adv-x="261" d="M51,607l159,0l0,137l-159,0M52,0l157,0l0,532l-157,0z" />
      <glyph unicode="&#xED;" horiz-adv-x="261" d="M52,0l157,0l0,532l-157,0M69,612l117,0l119,161l-183,0z" />
      <glyph
        unicode="&#x12D;"
        horiz-adv-x="261"
        d="M52,0l157,0l0,532l-157,0M133,606C227,606 290,669 293,761l-89,0C199,728 173,703 132,703C88,703 63,725 58,761l-91,0C-29,666 40,606 133,606z"
      />
      <glyph
        unicode="&#xEE;"
        horiz-adv-x="261"
        d="M52,0l157,0l0,532l-157,0M-57,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#xEF;"
        horiz-adv-x="261"
        d="M52,0l157,0l0,532l-157,0M158,607l128,0l0,137l-128,0M-25,607l129,0l0,137l-129,0z"
      />
      <glyph unicode="&#xEC;" horiz-adv-x="261" d="M52,0l157,0l0,532l-157,0M80,612l116,0l-53,161l-183,0z" />
      <glyph
        unicode="&#x133;"
        horiz-adv-x="522"
        d="M313,-181C361,-181 401,-170 430,-141C455,-117 470,-81 470,-13l0,545l-157,0l0,-539C313,-46 298,-58 267,-61C256,-62 244,-62 230,-62l-6,0l0,-111C248,-178 285,-181 313,-181M52,0l157,0l0,532l-157,0M312,607l159,0l0,137l-159,0M51,607l159,0l0,137l-159,0z"
      />
      <glyph unicode="&#x12B;" horiz-adv-x="261" d="M52,0l157,0l0,532l-157,0M4,615l252,0l0,111l-252,0z" />
      <glyph
        unicode="&#x221E;"
        horiz-adv-x="749"
        d="M194,127C280,127 332,176 378,241C422,176 472,127 555,127C653,127 722,201 722,317C722,433 653,507 555,507C472,507 422,457 378,394C332,457 280,507 194,507C87,507 27,421 27,317C27,213 86,127 194,127M205,395C246,395 276,358 300,316C276,276 246,238 205,238C162,238 144,274 144,317C144,360 162,395 205,395M544,238C506,238 478,276 453,316C478,358 506,395 544,395C587,395 607,360 607,317C607,274 587,238 544,238z"
      />
      <glyph
        unicode="&#x222B;"
        horiz-adv-x="387"
        d="M-1,-174C27,-180 63,-184 94,-184C212,-184 270,-129 270,-8l0,586C270,624 292,638 339,638C356,638 371,636 380,633l2,0l0,119C354,760 318,763 289,763C171,763 112,710 112,587l0,-585C112,-44 91,-58 47,-58C29,-58 15,-56 2,-54l-3,0z"
      />
      <glyph
        unicode="&#x12F;"
        horiz-adv-x="261"
        d="M52,0l45,0C63,-17 28,-50 28,-100C28,-151 69,-184 146,-184C182,-184 203,-180 216,-175l0,79l-2,0C206,-98 197,-100 185,-100C164,-100 152,-92 152,-73C152,-50 172,-19 209,2l0,530l-157,0M51,607l159,0l0,137l-159,0z"
      />
      <glyph
        unicode="&#x129;"
        horiz-adv-x="261"
        d="M52,0l157,0l0,532l-157,0M203,613C269,613 308,668 308,751l-84,0C221,724 211,712 190,712C157,712 124,753 61,753C3,753 -47,706 -47,612l84,0C39,640 55,654 72,654C102,654 142,613 203,613z"
      />
      <glyph
        unicode="j"
        horiz-adv-x="261"
        d="M52,-181C100,-181 140,-170 169,-141C194,-117 209,-81 209,-13l0,545l-157,0l0,-539C52,-46 37,-58 6,-61C-5,-62 -17,-62 -31,-62l-6,0l0,-111C-13,-178 24,-181 52,-181M51,607l159,0l0,137l-159,0z"
      />
      <glyph
        unicode="&#x135;"
        horiz-adv-x="261"
        d="M52,-181C100,-181 140,-170 169,-141C194,-117 209,-81 209,-13l0,545l-157,0l0,-539C52,-46 37,-58 6,-61C-5,-62 -17,-62 -31,-62l-6,0l0,-111C-13,-178 24,-181 52,-181M-59,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="k"
        horiz-adv-x="590"
        d="M399,0l182,0l-212,335l190,197l-182,0l-175,-191C204,375 207,434 207,512l0,232l-155,0l0,-744l155,0l0,177l53,55z"
      />
      <glyph
        unicode="&#x137;"
        horiz-adv-x="590"
        d="M399,0l182,0l-212,335l190,197l-182,0l-175,-191C204,375 207,434 207,512l0,232l-155,0l0,-744l155,0l0,177l53,55M217,-263C304,-257 372,-217 372,-122l0,71l-151,0l0,-103l67,0C288,-184 259,-202 217,-210z"
      />
      <glyph
        unicode="&#x138;"
        horiz-adv-x="590"
        d="M399,0l182,0l-212,335l190,197l-182,0l-175,-191C204,375 207,434 207,512l0,20l-155,0l0,-532l155,0l0,177l53,55z"
      />
      <glyph unicode="l" horiz-adv-x="265" d="M54,0l158,0l0,744l-158,0z" />
      <glyph unicode="&#x13A;" horiz-adv-x="265" d="M54,0l158,0l0,744l-158,0M64,785l123,0l109,131l-189,0z" />
      <glyph
        unicode="&#x13E;"
        horiz-adv-x="265"
        d="M54,0l158,0l0,744l-158,0M250,550C332,554 381,588 381,680l0,64l-131,0l0,-96l59,0C308,622 290,604 250,600z"
      />
      <glyph
        unicode="&#x13C;"
        horiz-adv-x="265"
        d="M54,0l158,0l0,744l-158,0M56,-263C143,-257 211,-217 211,-122l0,71l-151,0l0,-103l67,0C127,-184 98,-202 56,-210z"
      />
      <glyph unicode="&#x140;" horiz-adv-x="402" d="M54,0l158,0l0,744l-158,0M263,292l142,0l0,140l-142,0z" />
      <glyph
        unicode="&lt;"
        horiz-adv-x="541"
        d="M481,70l0,143l-170,63C255,296 167,319 167,319l0,2C167,321 254,346 311,368l170,66l0,139l-426,-180l0,-143z"
      />
      <glyph
        unicode="&#x2264;"
        horiz-adv-x="541"
        d="M55,0l426,0l0,120l-426,0M481,146l0,134l-176,50C249,346 157,364 157,364l0,2C157,366 248,387 305,404l176,52l0,131l-426,-144l0,-153z"
      />
      <glyph unicode="&#xAC;" horiz-adv-x="544" d="M53,316l290,0l0,-158l137,0l0,287l-427,0z" />
      <glyph
        unicode="&#x25CA;"
        horiz-adv-x="633"
        d="M400,744l-168,0l-209,-372l210,-372l168,0l209,371M185,374l81,152C300,591 313,630 313,630l2,0C315,630 330,591 363,526l82,-158l-80,-152C331,151 318,111 318,111l-2,0C316,111 302,151 268,216z"
      />
      <glyph
        unicode="&#x142;"
        horiz-adv-x="315"
        d="M10,201l68,50l0,-251l158,0l0,366l76,56l0,116l-76,-56l0,262l-158,0l0,-378l-68,-50z"
      />
      <glyph
        unicode="m"
        horiz-adv-x="910"
        d="M52,0l157,0l0,303C209,371 246,418 301,418C353,418 379,383 379,325l0,-325l157,0l0,303C536,371 571,418 627,418C678,418 706,383 706,325l0,-325l156,0l0,351C862,469 801,547 683,547C616,547 558,517 514,446l-2,0C485,512 436,547 366,547C291,547 240,512 206,449l-3,0l0,83l-151,0z"
      />
      <glyph unicode="&#xAF;" horiz-adv-x="500" d="M96,615l312,0l0,111l-312,0z" />
      <glyph unicode="&#x2212;" horiz-adv-x="606" d="M53,279l500,0l0,126l-500,0z" />
      <glyph
        unicode="&#xB5;"
        horiz-adv-x="603"
        d="M52,-177l157,0l0,84C209,-46 205,7 205,7l2,0C215,-1 231,-8 254,-8C325,-8 372,28 399,76l3,0l0,-76l149,0l0,532l-155,0l0,-301C396,165 357,117 294,117C238,117 209,151 209,210l0,322l-157,0z"
      />
      <glyph
        unicode="&#xD7;"
        horiz-adv-x="605"
        d="M142,91l160,160l160,-160l88,88l-160,160l160,160l-88,88l-160,-160l-159,160l-87,-88l159,-160l-160,-161z"
      />
      <glyph
        unicode="n"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0z"
      />
      <glyph
        unicode="&#x144;"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0M245,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x149;"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0M-35,550C47,554 96,588 96,680l0,64l-131,0l0,-96l59,0C23,622 5,604 -35,600z"
      />
      <glyph
        unicode="&#x148;"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0M493,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z"
      />
      <glyph
        unicode="&#x146;"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0M229,-263C316,-257 384,-217 384,-122l0,71l-151,0l0,-103l67,0C300,-184 271,-202 229,-210z"
      />
      <glyph
        unicode="9"
        horiz-adv-x="654"
        d="M320,-17C414,-17 483,13 532,68C592,133 615,238 615,360C615,498 583,597 515,658C466,702 397,727 315,727C152,727 33,624 33,475C33,324 148,224 290,224C365,224 419,256 460,308l2,0C458,241 446,192 425,159C400,119 364,101 316,101C253,101 220,137 211,170l-156,0C70,66 168,-17 320,-17M318,345C238,345 187,402 187,476C187,550 240,606 319,606C395,606 451,551 451,475C451,399 396,345 318,345z"
      />
      <glyph
        unicode="&#x2089;"
        horiz-adv-x="458"
        d="M223,-172C288,-172 337,-152 372,-113C409,-70 426,-3 426,67C426,158 406,222 357,261C322,289 276,306 222,306C109,306 29,241 29,142C29,42 106,-21 198,-21C244,-21 276,-1 299,25l2,0C299,-15 290,-42 276,-59C263,-76 243,-85 220,-85C183,-85 165,-64 158,-45l-120,0C53,-121 123,-172 223,-172M224,68C180,68 149,99 149,142C149,183 180,215 224,215C268,215 298,183 298,142C298,99 268,68 224,68z"
      />
      <glyph
        unicode="&#x2079;"
        horiz-adv-x="458"
        d="M223,276C288,276 337,296 372,335C409,378 426,445 426,515C426,606 406,670 357,709C322,737 276,754 222,754C109,754 29,689 29,590C29,490 106,427 198,427C244,427 276,447 299,473l2,0C299,433 290,406 276,389C263,372 243,363 220,363C183,363 165,384 158,403l-120,0C53,327 123,276 223,276M224,516C180,516 149,547 149,590C149,631 180,663 224,663C268,663 298,631 298,590C298,547 268,516 224,516z"
      />
      <glyph
        unicode="&#x2260;"
        horiz-adv-x="603"
        d="M61,372l217,0l-51,-99l-166,0l0,-119l106,0l-53,-104l103,0l53,104l277,0l0,119l-217,0l51,99l166,0l0,119l-106,0l53,104l-103,0l-53,-104l-277,0z"
      />
      <glyph
        unicode="&#xF1;"
        horiz-adv-x="604"
        d="M204,532l-152,0l0,-532l157,0l0,300C209,368 250,417 311,417C369,417 400,378 400,320l0,-320l156,0l0,350C556,469 488,547 371,547C297,547 240,515 207,451l-3,0M379,613C445,613 484,668 484,751l-84,0C397,724 387,712 366,712C333,712 300,753 237,753C179,753 129,706 129,612l84,0C215,640 231,654 248,654C278,654 318,613 379,613z"
      />
      <glyph
        unicode="#"
        horiz-adv-x="639"
        d="M72,422l99,0l-19,-120l-97,0l-21,-117l99,0l-29,-185l100,0l29,185l115,0l-30,-185l100,0l29,185l98,0l20,117l-99,0l19,120l99,0l20,118l-100,0l28,173l-99,0l-28,-173l-115,0l28,173l-100,0l-28,-173l-97,0M271,422l115,0l-19,-120l-115,0z"
      />
      <glyph
        unicode="o"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97z"
      />
      <glyph
        unicode="&#xF3;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M247,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x14F;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M311,606C405,606 468,669 471,761l-89,0C377,728 351,703 310,703C266,703 241,725 236,761l-91,0C149,666 218,606 311,606z"
      />
      <glyph
        unicode="&#xF4;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M121,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#xF6;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M356,607l148,0l0,137l-148,0M113,607l149,0l0,137l-149,0z"
      />
      <glyph
        unicode="&#x153;"
        horiz-adv-x="978"
        d="M695,-16C829,-16 914,56 942,150l-153,0C772,117 745,98 696,98C626,98 582,146 571,226l381,0C953,331 926,422 865,480C820,523 762,547 687,547C605,547 541,512 499,454C458,511 392,547 302,547C133,547 27,425 27,265C27,106 133,-17 302,-17C392,-17 458,18 500,76C542,19 607,-16 695,-16M304,97C224,97 186,165 186,265C186,366 224,435 304,435C381,435 422,366 422,265C422,165 381,97 304,97M687,436C748,436 786,391 791,317l-219,0C582,390 616,436 687,436z"
      />
      <glyph
        unicode="&#x2DB;"
        horiz-adv-x="500"
        d="M225,-184C261,-184 282,-180 295,-175l0,79l-2,0C285,-98 276,-100 264,-100C243,-100 231,-92 231,-73C231,-50 251,-23 280,0l-97,0C147,-16 104,-47 104,-100C104,-151 145,-184 225,-184z"
      />
      <glyph
        unicode="&#xF2;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M258,612l116,0l-53,161l-183,0z"
      />
      <glyph
        unicode="&#x151;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M349,612l99,0l102,161l-153,0M155,612l99,0l103,161l-153,0z"
      />
      <glyph
        unicode="&#x14D;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M152,615l312,0l0,111l-312,0z"
      />
      <glyph unicode="1" horiz-adv-x="437" d="M197,0l163,0l0,719l-129,0C214,621 136,577 32,574l0,-109l165,0z" />
      <glyph
        unicode="&#x2081;"
        horiz-adv-x="342"
        d="M144,-160l129,0l0,461l-102,0C158,237 108,205 29,203l0,-87l115,0z"
      />
      <glyph
        unicode="&#x215B;"
        horiz-adv-x="943"
        d="M147,288l129,0l0,461l-102,0C161,685 111,653 32,651l0,-87l115,0M57,-130l103,0l628,874l-104,0M712,-122C836,-122 914,-68 914,21C914,72 882,116 818,134l0,3C868,156 893,190 893,230C893,306 823,356 712,356C603,356 532,302 532,227C532,185 556,153 608,134l0,-3C545,112 511,70 511,18C511,-67 589,-122 712,-122M712,169C672,169 650,190 650,223C650,254 672,276 712,276C753,276 775,254 775,223C775,190 753,169 712,169M712,-35C668,-35 638,-11 638,27C638,65 668,93 713,93C759,93 787,65 787,27C787,-10 758,-35 712,-35z"
      />
      <glyph
        unicode="&#xBD;"
        horiz-adv-x="944"
        d="M147,288l129,0l0,461l-102,0C161,685 111,653 32,651l0,-87l115,0M68,-130l103,0l628,874l-104,0M533,-110l377,0l0,99l-213,0C727,20 791,45 837,82C878,114 909,146 909,211C909,289 844,355 731,355C616,355 539,293 539,181l120,0C659,228 685,259 728,259C766,259 785,236 785,203C785,149 719,130 639,73C580,31 534,-21 533,-110z"
      />
      <glyph
        unicode="&#xBC;"
        horiz-adv-x="908"
        d="M691,-110l128,0l0,96l67,0l0,89l-67,0l0,276l-128,0l-219,-262l0,-103l219,0M77,-130l103,0l628,874l-104,0M147,288l129,0l0,461l-102,0C161,685 111,653 32,651l0,-87l115,0M551,68l0,2C551,70 588,101 609,125l46,57C672,203 696,240 696,240l2,0C698,240 691,197 691,171l0,-96l-62,0C599,75 551,68 551,68z"
      />
      <glyph unicode="&#xB9;" horiz-adv-x="342" d="M144,288l129,0l0,461l-102,0C158,685 108,653 29,651l0,-87l115,0z" />
      <glyph
        unicode="&#x2153;"
        horiz-adv-x="965"
        d="M147,288l129,0l0,461l-102,0C161,685 111,653 32,651l0,-87l115,0M67,-130l103,0l628,874l-104,0M745,-122C849,-122 933,-76 933,22C933,83 898,117 838,134l0,1C888,153 918,180 918,225C918,308 846,356 743,356C625,356 558,289 553,210l120,0C675,238 693,267 738,267C773,267 796,247 796,217C796,184 769,168 724,168l-22,0l0,-84l26,0C777,84 805,70 805,29C805,-7 781,-28 737,-28C684,-28 664,4 661,37l-121,0C541,-50 610,-122 745,-122z"
      />
      <glyph
        unicode="&#xAA;"
        horiz-adv-x="543"
        d="M359,245l139,0l0,8C487,260 481,282 481,318l0,241C481,623 457,671 408,699C374,718 331,727 271,727C131,727 63,651 58,572l133,0C197,607 219,633 273,633C322,633 342,610 342,578C342,543 312,534 224,523C118,510 37,480 37,375C37,285 109,234 200,234C271,234 315,264 341,304l1,0C345,277 352,256 359,245M243,326C206,326 176,341 176,381C176,419 200,435 263,447C295,453 325,462 344,473l0,-59C344,363 304,326 243,326z"
      />
      <glyph
        unicode="&#xBA;"
        horiz-adv-x="557"
        d="M279,232C430,232 527,339 527,479C527,620 430,727 279,727C129,727 30,620 30,479C30,339 129,232 279,232M278,333C210,333 176,393 176,479C176,565 210,626 278,626C346,626 381,565 381,479C381,393 346,333 278,333z"
      />
      <glyph
        unicode="&#xF8;"
        horiz-adv-x="616"
        d="M68,-57l78,87C190,0 245,-17 309,-17C478,-17 589,106 589,265C589,337 566,402 525,451l72,80l-49,44l-71,-79C432,528 375,547 309,547C139,547 27,425 27,265C27,190 52,123 96,73l-77,-86M183,265C183,366 228,436 308,436C344,436 372,423 392,402l-198,-220C186,206 183,234 183,265M308,96C275,96 249,105 229,123l195,216C429,317 432,292 432,265C432,165 386,96 308,96z"
      />
      <glyph
        unicode="&#x1FF;"
        horiz-adv-x="616"
        d="M68,-57l78,87C190,0 245,-17 309,-17C478,-17 589,106 589,265C589,337 566,402 525,451l72,80l-49,44l-71,-79C432,528 375,547 309,547C139,547 27,425 27,265C27,190 52,123 96,73l-77,-86M183,265C183,366 228,436 308,436C344,436 372,423 392,402l-198,-220C186,206 183,234 183,265M247,612l117,0l119,161l-183,0M308,96C275,96 249,105 229,123l195,216C429,317 432,292 432,265C432,165 386,96 308,96z"
      />
      <glyph
        unicode="&#xF5;"
        horiz-adv-x="616"
        d="M309,-17C478,-17 589,106 589,265C589,425 478,547 309,547C139,547 27,425 27,265C27,106 139,-17 309,-17M308,97C228,97 186,165 186,265C186,366 228,435 308,435C386,435 430,366 430,265C430,165 386,97 308,97M381,613C447,613 486,668 486,751l-84,0C399,724 389,712 368,712C335,712 302,753 239,753C181,753 131,706 131,612l84,0C217,640 233,654 250,654C280,654 320,613 381,613z"
      />
      <glyph
        unicode="p"
        horiz-adv-x="624"
        d="M52,-177l157,0l0,127C209,7 206,46 204,66l2,0C237,14 293,-17 363,-17C505,-17 597,93 597,266C597,429 509,547 368,547C295,547 242,517 206,456l-3,0l0,76l-151,0M326,105C246,105 204,170 204,261C204,351 243,422 324,422C401,422 438,356 438,261C438,166 397,105 326,105z"
      />
      <glyph
        unicode="&#xB6;"
        horiz-adv-x="604"
        d="M279,351l0,-491l92,0l0,788l79,0l0,-788l92,0l0,884l-290,0C184,744 133,727 95,691C58,655 36,604 36,545C36,430 114,351 236,351z"
      />
      <glyph
        unicode="("
        horiz-adv-x="366"
        d="M223,-141l129,0C280,-22 222,125 222,313C222,512 277,663 353,784l-130,0C134,681 61,508 61,313C61,124 135,-34 223,-141z"
      />
      <glyph
        unicode=")"
        horiz-adv-x="366"
        d="M14,-141l130,0C232,-34 306,124 306,313C306,508 233,681 144,784l-131,0C90,663 145,512 145,313C145,125 86,-22 14,-141z"
      />
      <glyph
        unicode="&#x2202;"
        horiz-adv-x="616"
        d="M416,446C388,477 344,490 294,490C137,490 31,374 31,216C31,84 123,-16 285,-16C486,-16 580,125 580,322C580,581 458,729 173,751l0,-132C317,599 393,546 416,446M287,94C222,94 188,142 188,218C188,315 238,382 316,382C380,382 414,338 414,266C414,182 372,94 287,94z"
      />
      <glyph
        unicode="%"
        horiz-adv-x="931"
        d="M218,312C326,312 404,393 404,520C404,647 328,727 218,727C110,727 31,646 31,520C31,393 110,312 218,312M224,0l94,0l388,712l-94,0M712,-18C822,-18 900,64 900,191C900,317 823,399 712,399C605,399 525,317 525,191C525,64 604,-18 712,-18M218,398C172,398 152,449 152,520C152,592 172,643 218,643C264,643 285,590 285,520C285,448 264,398 218,398M713,69C666,69 646,120 646,191C646,262 666,315 713,315C757,315 779,262 779,191C779,120 759,69 713,69z"
      />
      <glyph unicode="." horiz-adv-x="254" d="M44,0l166,0l0,164l-166,0z" />
      <glyph unicode="&#xB7;" horiz-adv-x="257" d="M45,247l167,0l0,165l-167,0z" />
      <glyph
        unicode="&#x2030;"
        horiz-adv-x="1346"
        d="M221,0l94,0l388,712l-94,0M218,312C326,312 404,393 404,520C404,647 328,727 218,727C110,727 31,646 31,520C31,393 110,312 218,312M706,-18C816,-18 894,64 894,191C894,317 817,399 706,399C599,399 520,317 520,191C520,64 599,-18 706,-18M218,398C172,398 152,449 152,520C152,592 172,643 218,643C264,643 285,590 285,520C285,448 264,398 218,398M706,69C661,69 640,120 640,191C640,262 661,315 706,315C752,315 773,262 773,191C773,120 753,69 706,69M1128,-18C1237,-18 1315,64 1315,191C1315,317 1238,399 1128,399C1020,399 940,317 940,191C940,64 1019,-18 1128,-18M1128,69C1081,69 1062,120 1062,191C1062,262 1081,315 1128,315C1173,315 1195,262 1195,191C1195,120 1174,69 1128,69z"
      />
      <glyph
        unicode="&#x3C0;"
        horiz-adv-x="677"
        d="M96,0l157,0l0,432l157,0l0,-301C410,28 470,-7 560,-7C594,-7 617,-3 638,3l0,113l-3,0C626,115 617,113 607,113C578,113 566,123 566,155l0,277l81,0l0,100l-626,0l0,-100l75,0z"
      />
      <glyph
        unicode="+"
        horiz-adv-x="606"
        d="M53,279l183,0l0,-195l134,0l0,195l183,0l0,126l-183,0l0,193l-134,0l0,-193l-183,0z"
      />
      <glyph
        unicode="&#xB1;"
        horiz-adv-x="606"
        d="M53,350l183,0l0,-166l134,0l0,166l183,0l0,124l-183,0l0,164l-134,0l0,-164l-183,0M53,0l500,0l0,120l-500,0z"
      />
      <glyph unicode="&#x220F;" horiz-adv-x="764" d="M69,744l0,-848l171,0l0,703l283,0l0,-703l172,0l0,848z" />
      <glyph
        unicode="q"
        horiz-adv-x="624"
        d="M416,-177l156,0l0,709l-150,0l0,-75l-3,0C383,518 332,547 259,547C118,547 28,429 28,266C28,93 119,-17 262,-17C332,-17 386,14 418,66l2,0C418,44 416,1 416,-50M298,105C228,105 187,166 187,261C187,356 223,422 301,422C381,422 421,352 421,261C421,170 379,105 298,105z"
      />
      <glyph
        unicode="?"
        horiz-adv-x="580"
        d="M189,0l166,0l0,164l-166,0M204,259l140,0C345,288 349,303 365,321C386,344 423,362 477,404C516,435 551,481 551,556C551,677 453,757 297,757C124,757 25,652 21,518l156,0C178,580 223,627 291,627C346,627 386,598 386,552C386,494 325,468 280,429C225,383 204,343 204,259z"
      />
      <glyph
        unicode="&#xBF;"
        horiz-adv-x="580"
        d="M391,612l-166,0l0,-164l166,0M376,353l-140,0C236,324 231,309 215,291C194,268 158,250 104,208C65,177 29,131 29,56C29,-65 128,-145 284,-145C456,-145 555,-40 559,94l-155,0C402,32 358,-15 290,-15C234,-15 194,14 194,60C194,118 255,144 300,183C355,229 376,269 376,353z"
      />
      <glyph unicode='"' horiz-adv-x="451" d="M264,458l135,0l0,286l-135,0M52,458l137,0l0,286l-137,0z" />
      <glyph
        unicode="&#x201E;"
        horiz-adv-x="439"
        d="M187,147l-146,0l0,-147l67,0l0,-2C108,-56 77,-89 38,-100l0,-59C115,-151 187,-103 187,15M398,147l-146,0l0,-147l67,0l0,-2C319,-56 287,-89 249,-100l0,-59C326,-151 398,-103 398,15z"
      />
      <glyph
        unicode="&#x201C;"
        horiz-adv-x="439"
        d="M252,441l146,0l0,147l-67,0l0,2C331,643 362,677 400,687l0,60C324,738 252,691 252,573M41,441l146,0l0,147l-67,0l0,2C120,643 152,677 190,687l0,60C113,738 41,691 41,573z"
      />
      <glyph
        unicode="&#x201D;"
        horiz-adv-x="439"
        d="M187,744l-146,0l0,-147l67,0l0,-2C108,541 77,508 38,497l0,-59C115,446 187,494 187,612M398,744l-146,0l0,-147l67,0l0,-2C319,541 287,508 249,497l0,-59C326,446 398,494 398,612z"
      />
      <glyph
        unicode="&#x2018;"
        horiz-adv-x="228"
        d="M41,441l146,0l0,147l-67,0l0,2C120,643 152,677 190,687l0,60C113,738 41,691 41,573z"
      />
      <glyph
        unicode="&#x2019;"
        horiz-adv-x="228"
        d="M187,744l-146,0l0,-147l67,0l0,-2C108,541 77,508 38,497l0,-59C115,446 187,494 187,612z"
      />
      <glyph
        unicode="&#x201A;"
        horiz-adv-x="228"
        d="M187,147l-146,0l0,-147l67,0l0,-2C108,-56 77,-89 38,-100l0,-59C115,-151 187,-103 187,15z"
      />
      <glyph unicode="'" horiz-adv-x="241" d="M52,458l137,0l0,286l-137,0z" />
      <glyph
        unicode="r"
        horiz-adv-x="410"
        d="M203,532l-151,0l0,-532l157,0l0,247C209,351 265,404 350,403C361,403 372,402 383,400l4,0l0,137C380,540 369,541 354,541C288,541 240,511 206,437l-3,0z"
      />
      <glyph
        unicode="&#x155;"
        horiz-adv-x="410"
        d="M203,532l-151,0l0,-532l157,0l0,247C209,351 265,404 350,403C361,403 372,402 383,400l4,0l0,137C380,540 369,541 354,541C288,541 240,511 206,437l-3,0M161,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x221A;"
        horiz-adv-x="683"
        d="M18,211l76,0l134,-315l168,0l278,848l-160,0l-170,-542C323,134 307,64 307,64l-2,0C305,64 286,134 259,202l-56,143l-185,0z"
      />
      <glyph
        unicode="&#x159;"
        horiz-adv-x="410"
        d="M203,532l-151,0l0,-532l157,0l0,247C209,351 265,404 350,403C361,403 372,402 383,400l4,0l0,137C380,540 369,541 354,541C288,541 240,511 206,437l-3,0M416,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z"
      />
      <glyph
        unicode="&#x157;"
        horiz-adv-x="410"
        d="M203,532l-151,0l0,-532l157,0l0,247C209,351 265,404 350,403C361,403 372,402 383,400l4,0l0,137C380,540 369,541 354,541C288,541 240,511 206,437l-3,0M50,-263C137,-257 205,-217 205,-122l0,71l-151,0l0,-103l67,0C121,-184 92,-202 50,-210z"
      />
      <glyph
        unicode="&#xAE;"
        horiz-adv-x="658"
        d="M330,172C491,172 618,297 618,464C618,633 491,757 330,757C168,757 40,633 40,464C40,297 168,172 330,172M330,215C192,215 91,321 91,464C91,608 192,713 330,713C466,713 568,608 568,464C568,321 466,215 330,215M211,319l80,0l0,108l43,0C362,427 376,414 379,388C383,359 383,324 392,319l79,0l0,8C459,331 461,372 456,402C451,435 441,447 407,456l0,2C449,471 464,495 464,530C464,586 415,615 356,615l-145,0M291,554l52,0C372,554 384,541 384,520C384,497 370,486 342,486l-51,0z"
      />
      <glyph
        unicode="&#x2DA;"
        horiz-adv-x="500"
        d="M251,596C313,596 365,643 365,706C365,767 313,817 251,817C188,817 136,767 136,706C136,643 188,596 251,596M251,653C219,653 198,676 198,706C198,736 219,761 251,761C282,761 303,736 303,706C303,676 282,653 251,653z"
      />
      <glyph
        unicode="s"
        horiz-adv-x="539"
        d="M273,-17C411,-17 513,43 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C26,57 119,-17 273,-17z"
      />
      <glyph
        unicode="&#x15B;"
        horiz-adv-x="539"
        d="M273,-17C411,-17 513,43 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C26,57 119,-17 273,-17M201,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x161;"
        horiz-adv-x="539"
        d="M273,-17C411,-17 513,43 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C26,57 119,-17 273,-17M449,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z"
      />
      <glyph
        unicode="&#x15F;"
        horiz-adv-x="539"
        d="M236,-210C312,-210 364,-178 364,-128C364,-72 310,-48 251,-57l0,2l43,39C421,-10 513,49 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C25,67 102,-3 231,-15l-56,-61l18,-26C203,-98 213,-95 226,-95C246,-95 261,-104 261,-124C261,-148 239,-157 212,-157C187,-157 163,-150 143,-141l-20,-39C150,-197 189,-210 236,-210z"
      />
      <glyph
        unicode="&#x15D;"
        horiz-adv-x="539"
        d="M273,-17C411,-17 513,43 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C26,57 119,-17 273,-17M75,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#x219;"
        horiz-adv-x="539"
        d="M273,-17C411,-17 513,43 513,158C513,292 405,317 310,334C237,347 179,353 179,393C179,427 210,447 258,447C308,447 338,428 348,390l146,0C480,483 406,547 258,547C133,547 34,490 34,381C34,257 130,233 225,215C299,201 362,195 362,147C362,109 330,86 274,86C213,86 175,114 166,164l-148,0C26,57 119,-17 273,-17M185,-263C272,-257 340,-217 340,-122l0,71l-151,0l0,-103l67,0C256,-184 227,-202 185,-210z"
      />
      <glyph
        unicode="&#xA7;"
        horiz-adv-x="661"
        d="M344,-189C479,-189 573,-123 573,-5C573,56 542,94 501,123C576,141 626,182 626,267C626,375 526,421 417,467C312,510 263,526 263,568C263,601 287,623 340,623C398,623 425,593 429,553l138,0C561,669 472,741 334,741C203,741 111,671 111,570C111,513 134,485 169,456C89,437 39,386 39,313C39,208 117,160 244,107C362,57 420,45 420,-5C420,-40 390,-68 338,-68C273,-68 237,-30 236,14l-145,0C95,-100 188,-189 344,-189M342,347C435,306 478,284 478,243C478,212 452,193 415,184l-97,45C214,277 182,294 182,332C182,363 205,382 243,390C270,377 306,362 342,347z"
      />
      <glyph
        unicode=";"
        horiz-adv-x="261"
        d="M47,321l166,0l0,164l-166,0M48,-181C140,-173 213,-116 213,10l0,154l-166,0l0,-164l78,0l0,-2C125,-61 95,-99 48,-111z"
      />
      <glyph
        unicode="7"
        horiz-adv-x="576"
        d="M125,0l172,0C305,250 422,460 551,587l0,125l-523,0l0,-138l192,0C301,574 392,580 392,580l0,-2C273,472 137,258 125,0z"
      />
      <glyph
        unicode="&#x2087;"
        horiz-adv-x="403"
        d="M232,-160C236,-4 299,123 380,202l0,94l-355,0l0,-104l128,0C224,192 263,197 263,197l0,-2C172,115 98,-4 94,-160z"
      />
      <glyph
        unicode="&#x2077;"
        horiz-adv-x="403"
        d="M232,288C236,444 299,571 380,650l0,94l-355,0l0,-104l128,0C224,640 263,645 263,645l0,-2C172,563 98,444 94,288z"
      />
      <glyph
        unicode="&#x215E;"
        horiz-adv-x="969"
        d="M738,-122C862,-122 940,-68 940,21C940,72 908,116 844,134l0,3C894,156 919,190 919,230C919,306 849,356 738,356C629,356 558,302 558,227C558,185 582,153 634,134l0,-3C571,112 537,70 537,18C537,-67 615,-122 738,-122M62,-130l103,0l628,874l-104,0M235,288C239,444 302,571 383,650l0,94l-355,0l0,-104l128,0C227,640 266,645 266,645l0,-2C175,563 101,444 97,288M738,-35C694,-35 664,-11 664,27C664,65 694,93 739,93C785,93 813,65 813,27C813,-10 784,-35 738,-35M738,169C698,169 676,190 676,223C676,254 698,276 738,276C779,276 801,254 801,223C801,190 779,169 738,169z"
      />
      <glyph
        unicode="6"
        horiz-adv-x="653"
        d="M344,-17C503,-17 621,86 621,236C620,385 504,487 368,487C289,487 234,454 194,398l-2,0C200,538 251,610 346,610C406,610 434,576 444,543l156,0C579,647 491,727 343,727C242,727 172,695 121,635C62,566 37,467 38,352C38,239 64,146 119,81C169,20 244,-17 344,-17M338,105C262,105 204,159 204,235C204,311 258,365 338,365C411,365 466,310 466,234C466,160 410,105 338,105z"
      />
      <glyph
        unicode="&#x2086;"
        horiz-adv-x="461"
        d="M241,-171C354,-171 432,-106 432,-8C432,91 355,154 264,154C217,154 180,137 156,107l-1,0C161,181 193,221 241,221C278,221 292,200 297,181l124,0C403,260 340,307 244,307C174,307 121,284 86,242C49,199 32,138 32,68C32,-12 53,-75 94,-116C129,-151 178,-171 241,-171M237,-79C193,-79 162,-49 162,-8C162,34 192,65 237,65C281,65 310,34 310,-8C310,-49 281,-79 237,-79z"
      />
      <glyph
        unicode="&#x2076;"
        horiz-adv-x="461"
        d="M241,277C354,277 432,342 432,440C432,539 355,602 264,602C217,602 180,585 156,555l-1,0C161,629 193,669 241,669C278,669 292,648 297,629l124,0C403,708 340,755 244,755C174,755 121,732 86,690C49,647 32,586 32,516C32,436 53,373 94,332C129,297 178,277 241,277M237,369C193,369 162,399 162,440C162,482 192,513 237,513C281,513 310,482 310,440C310,399 281,369 237,369z"
      />
      <glyph unicode="/" horiz-adv-x="466" d="M-19,-141l143,0l334,925l-143,0z" />
      <glyph unicode=" " horiz-adv-x="241" />
      <glyph
        unicode="&#xA3;"
        horiz-adv-x="627"
        d="M39,302l88,0C131,287 134,272 134,256C134,196 92,148 33,110l80,-119C146,15 180,36 222,36C304,36 338,-15 440,-15C503,-15 563,12 604,48l-69,126C513,152 478,130 438,130C382,130 366,157 295,157C260,157 232,149 193,129l-2,3C258,181 283,226 283,279C283,287 282,294 282,302l118,0l0,92l-144,0C241,429 225,462 225,503C225,561 259,600 327,600C393,600 439,562 439,476l158,0C595,569 566,627 514,670C466,709 402,727 325,727C149,727 54,631 54,507C54,461 68,426 85,394l-46,0z"
      />
      <glyph
        unicode="&#x2211;"
        horiz-adv-x="672"
        d="M630,-104l0,140l-393,0l0,2l235,260l0,67l-228,243l0,2l376,0l0,134l-571,0l0,-135l256,-279l0,-2l-256,-289l0,-143z"
      />
      <glyph
        unicode="t"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-298C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,264l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0z"
      />
      <glyph
        unicode="&#x167;"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-75l-80,0l0,-92l80,0l0,-131C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,97l104,0l0,92l-104,0l0,75l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0z"
      />
      <glyph
        unicode="&#x165;"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-298C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,264l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0M298,559C380,563 429,594 429,686l0,58l-131,0l0,-90l59,0C356,628 338,613 298,609z"
      />
      <glyph
        unicode="&#x163;"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-298C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,264l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0M143,-263C230,-257 298,-217 298,-122l0,71l-151,0l0,-103l67,0C214,-184 185,-202 143,-210z"
      />
      <glyph
        unicode="&#xFE;"
        horiz-adv-x="622"
        d="M367,-17C505,-17 597,93 597,266C597,429 509,547 368,547C295,547 243,512 207,458l-3,0C206,490 209,536 209,581l0,163l-157,0l0,-920l157,0l0,126C209,7 206,46 204,66l2,0C237,14 296,-17 367,-17M326,105C246,105 204,170 204,261C204,351 243,422 324,422C401,422 438,356 438,261C438,166 397,105 326,105z"
      />
      <glyph
        unicode="3"
        horiz-adv-x="635"
        d="M324,-17C473,-17 603,53 603,204C603,299 550,357 460,382l0,2C534,409 581,453 581,527C581,653 473,727 322,727C147,727 49,626 46,497l152,0C202,552 238,606 319,606C381,606 421,571 421,518C421,460 376,429 303,429l-39,0l0,-114l43,0C391,315 438,281 438,215C438,149 394,109 318,109C223,109 184,165 179,234l-154,0C26,159 54,90 111,45C163,5 231,-17 324,-17z"
      />
      <glyph
        unicode="&#x2083;"
        horiz-adv-x="444"
        d="M227,-172C331,-172 415,-126 415,-28C415,33 380,67 320,84l0,1C370,103 400,130 400,175C400,258 328,306 225,306C107,306 40,239 35,160l120,0C157,188 175,217 220,217C255,217 278,197 278,167C278,134 251,118 206,118l-22,0l0,-84l26,0C259,34 287,20 287,-21C287,-57 263,-78 219,-78C166,-78 146,-46 143,-13l-121,0C23,-100 92,-172 227,-172z"
      />
      <glyph
        unicode="&#x215C;"
        horiz-adv-x="1022"
        d="M230,276C334,276 418,322 418,420C418,481 383,515 323,532l0,1C373,551 403,578 403,623C403,706 331,754 228,754C110,754 43,687 38,608l120,0C160,636 178,665 223,665C258,665 281,645 281,615C281,582 254,566 209,566l-22,0l0,-84l26,0C262,482 290,468 290,427C290,391 266,370 222,370C169,370 149,402 146,435l-121,0C26,348 95,276 230,276M137,-130l103,0l628,874l-104,0M791,-122C915,-122 993,-68 993,21C993,72 961,116 897,134l0,3C947,156 972,190 972,230C972,306 902,356 791,356C682,356 611,302 611,227C611,185 635,153 687,134l0,-3C624,112 590,70 590,18C590,-67 668,-122 791,-122M791,169C751,169 729,190 729,223C729,254 751,276 791,276C832,276 854,254 854,223C854,190 832,169 791,169M791,-35C747,-35 717,-11 717,27C717,65 747,93 792,93C838,93 866,65 866,27C866,-10 837,-35 791,-35z"
      />
      <glyph
        unicode="&#xBE;"
        horiz-adv-x="996"
        d="M779,-110l128,0l0,96l67,0l0,89l-67,0l0,276l-128,0l-219,-262l0,-103l219,0M230,276C334,276 418,322 418,420C418,481 383,515 323,532l0,1C373,551 403,578 403,623C403,706 331,754 228,754C110,754 43,687 38,608l120,0C160,636 178,665 223,665C258,665 281,645 281,615C281,582 254,566 209,566l-22,0l0,-84l26,0C262,482 290,468 290,427C290,391 266,370 222,370C169,370 149,402 146,435l-121,0C26,348 95,276 230,276M156,-130l103,0l628,874l-104,0M639,68l0,2C639,70 676,101 697,125l46,57C760,203 784,240 784,240l2,0C786,240 779,197 779,171l0,-96l-62,0C687,75 639,68 639,68z"
      />
      <glyph
        unicode="&#xB3;"
        horiz-adv-x="444"
        d="M227,276C331,276 415,322 415,420C415,481 380,515 320,532l0,1C370,551 400,578 400,623C400,706 328,754 225,754C107,754 40,687 35,608l120,0C157,636 175,665 220,665C255,665 278,645 278,615C278,582 251,566 206,566l-22,0l0,-84l26,0C259,482 287,468 287,427C287,391 263,370 219,370C166,370 146,402 143,435l-121,0C23,348 92,276 227,276z"
      />
      <glyph
        unicode="&#x2DC;"
        horiz-adv-x="500"
        d="M326,613C392,613 431,668 431,751l-84,0C344,724 334,712 313,712C280,712 247,753 184,753C126,753 76,706 76,612l84,0C162,640 178,654 195,654C225,654 265,613 326,613z"
      />
      <glyph
        unicode="&#x2122;"
        horiz-adv-x="965"
        d="M450,362l98,0l0,113C548,538 545,637 545,637l2,0C547,637 568,546 587,485l38,-123l88,0l38,123C771,545 792,637 792,637l3,0C795,637 792,538 792,475l0,-113l100,0l0,382l-153,0l-35,-117C688,573 671,486 671,486l-2,0C669,486 652,573 636,627l-35,117l-151,0M51,652l111,0l0,-290l112,0l0,290l111,0l0,92l-334,0z"
      />
      <glyph
        unicode="2"
        horiz-adv-x="610"
        d="M31,0l544,0l0,136l-326,0l0,3C305,208 413,253 487,320C539,364 576,418 576,500C576,627 484,727 318,727C208,727 131,688 90,628C57,582 42,529 42,458l155,0C197,501 207,537 230,562C249,585 276,595 313,595C375,595 411,555 411,495C411,445 384,413 338,380C266,328 172,271 106,198C59,145 32,88 31,0z"
      />
      <glyph
        unicode="&#x2082;"
        horiz-adv-x="436"
        d="M28,-160l377,0l0,99l-213,0C222,-30 286,-5 332,32C373,64 404,96 404,161C404,239 339,305 226,305C111,305 34,243 34,131l120,0C154,178 180,209 223,209C261,209 280,186 280,153C280,99 214,80 134,23C75,-19 29,-71 28,-160z"
      />
      <glyph
        unicode="&#xB2;"
        horiz-adv-x="436"
        d="M28,288l377,0l0,99l-213,0C222,418 286,443 332,480C373,512 404,544 404,609C404,687 339,753 226,753C111,753 34,691 34,579l120,0C154,626 180,657 223,657C261,657 280,634 280,601C280,547 214,528 134,471C75,429 29,377 28,288z"
      />
      <glyph
        unicode="&#x2154;"
        horiz-adv-x="1053"
        d="M31,288l377,0l0,99l-213,0C225,418 289,443 335,480C376,512 407,544 407,609C407,687 342,753 229,753C114,753 37,691 37,579l120,0C157,626 183,657 226,657C264,657 283,634 283,601C283,547 217,528 137,471C78,429 32,377 31,288M171,-130l103,0l628,874l-104,0M833,-122C937,-122 1021,-76 1021,22C1021,83 986,117 926,134l0,1C976,153 1006,180 1006,225C1006,308 934,356 831,356C713,356 646,289 641,210l120,0C763,238 781,267 826,267C861,267 884,247 884,217C884,184 857,168 812,168l-22,0l0,-84l26,0C865,84 893,70 893,29C893,-7 869,-28 825,-28C772,-28 752,4 749,37l-121,0C629,-50 698,-122 833,-122z"
      />
      <glyph
        unicode="u"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76z"
      />
      <glyph
        unicode="&#xFA;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M235,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x16D;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M299,606C393,606 456,669 459,761l-89,0C365,728 339,703 298,703C254,703 229,725 224,761l-91,0C137,666 206,606 299,606z"
      />
      <glyph
        unicode="&#xFB;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M109,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#xFC;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M101,607l149,0l0,137l-149,0M344,607l148,0l0,137l-148,0z"
      />
      <glyph
        unicode="&#xF9;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M246,612l116,0l-53,161l-183,0z"
      />
      <glyph
        unicode="&#x171;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M143,612l99,0l103,161l-153,0M337,612l99,0l102,161l-153,0z"
      />
      <glyph
        unicode="&#x16B;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M140,615l312,0l0,111l-312,0z"
      />
      <glyph unicode="_" horiz-adv-x="445" d="M-2,-176l449,0l0,99l-449,0z" />
      <glyph unicode="&#xA0;" horiz-adv-x="237" />
      <glyph unicode="&#xAD;" horiz-adv-x="363" d="M40,197l283,0l0,136l-283,0z" />
      <glyph
        unicode="&#x162;"
        horiz-adv-x="668"
        d="M27,599l221,0l0,-599l171,0l0,599l222,0l0,145l-614,0M257,-263C344,-257 412,-217 412,-122l0,71l-151,0l0,-103l67,0C328,-184 299,-202 257,-210z"
      />
      <glyph
        unicode="&#x163;"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-298C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,264l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0M143,-263C230,-257 298,-217 298,-122l0,71l-151,0l0,-103l67,0C214,-184 185,-202 143,-210z"
      />
      <glyph
        unicode="&#x21A;"
        horiz-adv-x="668"
        d="M27,599l221,0l0,-599l171,0l0,599l222,0l0,145l-614,0M257,-263C344,-257 412,-217 412,-122l0,71l-151,0l0,-103l67,0C328,-184 299,-202 257,-210z"
      />
      <glyph
        unicode="&#x21B;"
        horiz-adv-x="389"
        d="M21,432l80,0l0,-298C101,26 168,-6 267,-6C309,-6 341,-1 358,2l0,117l-8,0C340,118 324,117 314,117C277,117 255,128 255,168l0,264l104,0l0,100l-104,0l0,170l-154,0l0,-170l-80,0M143,-263C230,-257 298,-217 298,-122l0,71l-151,0l0,-103l67,0C214,-184 185,-202 143,-210z"
      />
      <glyph
        unicode="&#x237;"
        horiz-adv-x="261"
        d="M52,-181C100,-181 140,-170 169,-141C194,-117 209,-81 209,-13l0,545l-157,0l0,-539C52,-46 37,-58 6,-61C-5,-62 -17,-62 -31,-62l-6,0l0,-111C-13,-178 24,-181 52,-181z"
      />
      <glyph unicode="&#x2C9;" horiz-adv-x="500" d="M96,615l312,0l0,111l-312,0z" />
      <glyph
        unicode="&#x394;"
        horiz-adv-x="723"
        d="M27,0l669,0l0,112l-241,632l-188,0l-240,-632M203,132l102,283C339,509 356,605 356,605l2,0C358,605 374,509 408,415l103,-283z"
      />
      <glyph
        unicode="&#x3A9;"
        horiz-adv-x="780"
        d="M40,0l315,0l0,125C250,142 205,245 205,383C205,530 272,619 390,619C508,619 575,530 575,383C575,245 530,142 425,125l0,-125l315,0l0,130l-114,0l0,2C705,185 748,274 748,395C748,608 602,757 390,757C178,757 32,608 32,395C32,274 74,185 153,132l0,-2l-113,0z"
      />
      <glyph
        unicode="&#x3BC;"
        horiz-adv-x="603"
        d="M52,-177l157,0l0,84C209,-46 205,7 205,7l2,0C215,-1 231,-8 254,-8C325,-8 372,28 399,76l3,0l0,-76l149,0l0,532l-155,0l0,-301C396,165 357,117 294,117C238,117 209,151 209,210l0,322l-157,0z"
      />
      <glyph unicode="&#x2009;" horiz-adv-x="110" />
      <glyph unicode="&#x2032;" horiz-adv-x="241" d="M31,470l138,0l41,274l-137,0z" />
      <glyph unicode="&#x2033;" horiz-adv-x="443" d="M233,470l138,0l41,274l-137,0M31,470l138,0l41,274l-137,0z" />
      <glyph unicode="&#x2215;" horiz-adv-x="295" d="M-274,-130l103,0l628,874l-104,0z" />
      <glyph unicode="&#x2219;" horiz-adv-x="257" d="M45,247l167,0l0,165l-167,0z" />
      <glyph
        unicode="&#x24C5;"
        horiz-adv-x="867"
        d="M434,-18C648,-18 816,147 816,369C816,592 648,757 434,757C220,757 51,592 51,369C51,147 220,-18 434,-18M434,32C249,32 110,176 110,369C110,563 249,706 434,706C618,706 756,563 756,369C756,176 618,32 434,32M279,164l109,0l0,138l99,0C572,302 628,360 628,441C628,482 613,518 586,543C559,568 522,582 472,582l-193,0M388,493l72,0C498,493 520,473 520,439C520,406 498,387 461,387l-73,0z"
      />
      <glyph
        unicode="&#xF8FF;"
        horiz-adv-x="880"
        d="M68,0l745,0l0,745l-745,0M190,448l175,0l0,170l150,0l0,-170l175,0l0,-149l-175,0l0,-176l-150,0l0,176l-175,0z"
      />
      <glyph
        unicode="&#x173;"
        horiz-adv-x="599"
        d="M482,-184C518,-184 539,-180 552,-175l0,79l-2,0C542,-98 533,-100 521,-100C500,-100 488,-92 488,-73C488,-50 507,-21 547,2l0,530l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76l37,0C399,-18 364,-50 364,-100C364,-151 406,-184 482,-184z"
      />
      <glyph
        unicode="&#x16F;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M297,596C359,596 411,643 411,706C411,767 359,817 297,817C234,817 182,767 182,706C182,643 234,596 297,596M297,653C265,653 244,676 244,706C244,736 265,761 297,761C328,761 349,736 349,706C349,676 328,653 297,653z"
      />
      <glyph
        unicode="&#x169;"
        horiz-adv-x="599"
        d="M547,0l0,532l-155,0l0,-301C392,165 353,117 290,117C234,117 205,151 205,210l0,322l-157,0l0,-352C48,63 114,-15 235,-15C308,-15 356,14 395,76l3,0l0,-76M369,613C435,613 474,668 474,751l-84,0C387,724 377,712 356,712C323,712 290,753 227,753C169,753 119,706 119,612l84,0C205,640 221,654 238,654C268,654 308,613 369,613z"
      />
      <glyph
        unicode="v"
        horiz-adv-x="549"
        d="M189,0l169,0l186,532l-158,0l-67,-214C297,247 277,132 277,132l-3,0C274,132 254,247 232,318l-65,214l-162,0z"
      />
      <glyph
        unicode="w"
        horiz-adv-x="822"
        d="M171,0l146,0l58,217C391,278 410,380 410,380l2,0C412,380 429,277 445,217l56,-217l148,0l165,532l-155,0l-57,-207C584,261 570,159 570,159l-2,0C568,159 551,263 533,327l-56,205l-133,0l-54,-205C273,263 256,160 256,160l-2,0C254,160 240,262 223,325l-56,207l-160,0z"
      />
      <glyph
        unicode="&#x1E83;"
        horiz-adv-x="822"
        d="M171,0l146,0l58,217C391,278 410,380 410,380l2,0C412,380 429,277 445,217l56,-217l148,0l165,532l-155,0l-57,-207C584,261 570,159 570,159l-2,0C568,159 551,263 533,327l-56,205l-133,0l-54,-205C273,263 256,160 256,160l-2,0C254,160 240,262 223,325l-56,207l-160,0M349,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x175;"
        horiz-adv-x="822"
        d="M171,0l146,0l58,217C391,278 410,380 410,380l2,0C412,380 429,277 445,217l56,-217l148,0l165,532l-155,0l-57,-207C584,261 570,159 570,159l-2,0C568,159 551,263 533,327l-56,205l-133,0l-54,-205C273,263 256,160 256,160l-2,0C254,160 240,262 223,325l-56,207l-160,0M223,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#x1E85;"
        horiz-adv-x="822"
        d="M171,0l146,0l58,217C391,278 410,380 410,380l2,0C412,380 429,277 445,217l56,-217l148,0l165,532l-155,0l-57,-207C584,261 570,159 570,159l-2,0C568,159 551,263 533,327l-56,205l-133,0l-54,-205C273,263 256,160 256,160l-2,0C254,160 240,262 223,325l-56,207l-160,0M458,607l148,0l0,137l-148,0M215,607l149,0l0,137l-149,0z"
      />
      <glyph
        unicode="&#x1E81;"
        horiz-adv-x="822"
        d="M171,0l146,0l58,217C391,278 410,380 410,380l2,0C412,380 429,277 445,217l56,-217l148,0l165,532l-155,0l-57,-207C584,261 570,159 570,159l-2,0C568,159 551,263 533,327l-56,205l-133,0l-54,-205C273,263 256,160 256,160l-2,0C254,160 240,262 223,325l-56,207l-160,0M360,612l116,0l-53,161l-183,0z"
      />
      <glyph
        unicode="x"
        horiz-adv-x="593"
        d="M17,0l171,0l43,68C259,113 293,186 293,186l2,0C295,186 328,113 353,68l41,-68l182,0l-187,282l163,250l-166,0l-29,-50C333,438 303,369 303,369l-2,0C301,369 271,438 245,482l-31,50l-179,0l169,-253z"
      />
      <glyph
        unicode="y"
        horiz-adv-x="557"
        d="M168,-178C270,-178 319,-136 362,-13l190,545l-156,0l-65,-204C308,257 284,143 284,143l-2,0C282,143 262,256 238,328l-68,204l-165,0l165,-425C189,59 198,18 198,0C195,-36 171,-54 122,-54C118,-54 73,-54 71,-53l-8,0l0,-123C66,-177 163,-178 168,-178z"
      />
      <glyph
        unicode="&#xFD;"
        horiz-adv-x="557"
        d="M168,-178C270,-178 319,-136 362,-13l190,545l-156,0l-65,-204C308,257 284,143 284,143l-2,0C282,143 262,256 238,328l-68,204l-165,0l165,-425C189,59 198,18 198,0C195,-36 171,-54 122,-54C118,-54 73,-54 71,-53l-8,0l0,-123C66,-177 163,-178 168,-178M221,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x177;"
        horiz-adv-x="557"
        d="M168,-178C270,-178 319,-136 362,-13l190,545l-156,0l-65,-204C308,257 284,143 284,143l-2,0C282,143 262,256 238,328l-68,204l-165,0l165,-425C189,59 198,18 198,0C195,-36 171,-54 122,-54C118,-54 73,-54 71,-53l-8,0l0,-123C66,-177 163,-178 168,-178M95,614l137,0l49,78l2,0l47,-78l135,0l-109,148l-153,0z"
      />
      <glyph
        unicode="&#xFF;"
        horiz-adv-x="557"
        d="M168,-178C270,-178 319,-136 362,-13l190,545l-156,0l-65,-204C308,257 284,143 284,143l-2,0C282,143 262,256 238,328l-68,204l-165,0l165,-425C189,59 198,18 198,0C195,-36 171,-54 122,-54C118,-54 73,-54 71,-53l-8,0l0,-123C66,-177 163,-178 168,-178M330,607l148,0l0,137l-148,0M87,607l149,0l0,137l-149,0z"
      />
      <glyph
        unicode="&#xA5;"
        horiz-adv-x="677"
        d="M82,268l177,0l0,-72l-177,0l0,-65l177,0l0,-131l157,0l0,131l177,0l0,65l-177,0l0,72l177,0l0,64l-150,0l220,381l-177,0l-71,-137C381,510 340,401 340,401l-2,0C338,401 299,510 264,576l-72,137l-180,0l219,-381l-149,0z"
      />
      <glyph
        unicode="&#x1EF3;"
        horiz-adv-x="557"
        d="M168,-178C270,-178 319,-136 362,-13l190,545l-156,0l-65,-204C308,257 284,143 284,143l-2,0C282,143 262,256 238,328l-68,204l-165,0l165,-425C189,59 198,18 198,0C195,-36 171,-54 122,-54C118,-54 73,-54 71,-53l-8,0l0,-123C66,-177 163,-178 168,-178M232,612l116,0l-53,161l-183,0z"
      />
      <glyph
        unicode="z"
        horiz-adv-x="538"
        d="M36,0l469,0l0,123l-174,0C283,123 207,116 207,116l0,3C207,119 272,172 308,211l183,208l0,113l-432,0l0,-122l141,0C245,410 315,418 315,418l0,-3C315,415 258,367 226,332l-190,-215z"
      />
      <glyph
        unicode="&#x17A;"
        horiz-adv-x="538"
        d="M36,0l469,0l0,123l-174,0C283,123 207,116 207,116l0,3C207,119 272,172 308,211l183,208l0,113l-432,0l0,-122l141,0C245,410 315,418 315,418l0,-3C315,415 258,367 226,332l-190,-215M215,612l117,0l119,161l-183,0z"
      />
      <glyph
        unicode="&#x17E;"
        horiz-adv-x="538"
        d="M36,0l469,0l0,123l-174,0C283,123 207,116 207,116l0,3C207,119 272,172 308,211l183,208l0,113l-432,0l0,-122l141,0C245,410 315,418 315,418l0,-3C315,415 258,367 226,332l-190,-215M463,760l-137,0l-49,-78l-2,0l-47,78l-135,0l109,-148l153,0z"
      />
      <glyph
        unicode="&#x17C;"
        horiz-adv-x="538"
        d="M36,0l469,0l0,123l-174,0C283,123 207,116 207,116l0,3C207,119 272,172 308,211l183,208l0,113l-432,0l0,-122l141,0C245,410 315,418 315,418l0,-3C315,415 258,367 226,332l-190,-215M197,607l159,0l0,137l-159,0z"
      />
      <glyph
        unicode="0"
        horiz-adv-x="692"
        d="M345,-17C533,-17 656,128 656,355C656,583 534,727 346,727C162,727 36,582 36,355C36,128 162,-17 345,-17M346,112C246,112 204,210 204,355C204,501 246,600 346,600C447,600 488,501 488,355C488,209 448,112 346,112z"
      />
      <glyph
        unicode="&#x2080;"
        horiz-adv-x="490"
        d="M245,-76C188,-76 165,-18 165,67C165,151 188,212 245,212C303,212 325,151 325,67C325,-18 303,-76 245,-76M245,-172C368,-172 458,-80 458,67C458,212 372,306 245,306C122,306 32,212 32,67C32,-80 124,-172 245,-172z"
      />
      <glyph
        unicode="&#x2070;"
        horiz-adv-x="490"
        d="M245,372C188,372 165,430 165,515C165,599 188,660 245,660C303,660 325,599 325,515C325,430 303,372 245,372M245,276C368,276 458,368 458,515C458,660 372,754 245,754C122,754 32,660 32,515C32,368 124,276 245,276z"
      />
    </font>

    <g>
      <path
        style="fill: #f9b60a"
        d="M349.6,76.4H203.4c-6.6,0-12-5.4-12-12V19.2c0-6.6,5.4-12,12-12h146.2c6.6,0,12,5.4,12,12v45.2
		C361.6,71.1,356.2,76.4,349.6,76.4z"
      />
    </g>
    <g>
      <path
        style="fill: #6bbdff"
        d="M167.6,333H25.4c-7.7,0-14-6.3-14-14V21.2c0-7.7,6.3-14,14-14h142.2c7.7,0,14,6.3,14,14V319
		C181.6,326.7,175.4,333,167.6,333z"
      />
    </g>
    <g>
      <path
        style="fill: #1895f9"
        d="M347.6,333H205.3c-7.7,0-14-6.3-14-14V168.9c0-7.7,6.3-14,14-14h142.2c7.7,0,14,6.3,14,14V319
		C361.6,326.7,355.3,333,347.6,333z"
      />
    </g>
    <text
      transform="matrix(1 0 0 1 41.754 170.1153)"
      style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 18px"
    >
      {{ $t('exampleCalcsGrossWageLabel') }}
    </text>
    <text
      transform="matrix(1 0 0 1 224.3353 246.6285)"
      style="fill: #2930ce; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 18px"
    >
      {{ $t('exampleCalcsNetWageLabel') }}
    </text>
    <text
      :transform="currentLang === 'en' ? 'matrix(1 0 0 1 225.2508 44.4742)' : 'matrix(1 0 0 1 211.2508 44.4742)'"
      style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 14px"
    >
      {{ $t('aboutBavSocialSavingsLabel') }}
    </text>
    <g>
      <path
        style="fill: #f9b60a"
        d="M349.6,150.3H203.4c-6.6,0-12-5.4-12-12V93.1c0-6.6,5.4-12,12-12h146.2c6.6,0,12,5.4,12,12v45.2
		C361.6,144.9,356.2,150.3,349.6,150.3z"
      />
    </g>
    <text
      transform="matrix(1 0 0 1 253.4753 118.3282)"
      style="fill: #b25b06; font-family: 'NHaasGroteskTXPro-75Bd'; font-size: 14px"
    >
      {{ $t('exampleCalcsTaxLabel') }}
    </text>
    <g>
      <path
        style="fill: #b25b06"
        d="M282.7,67.9c0.8-1.3,1.7-2.6,2.6-3.9c0.4-0.6,0.4-1.1,0.1-1.7c-0.3-0.6-0.8-0.9-1.4-1
		c-0.1,0-0.1,0-0.2,0c-4.9,0-9.8,0-14.7,0c-1,0-1.7,0.6-1.8,1.5c-0.1,0.4,0.1,0.7,0.2,1c0.9,1.3,1.8,2.6,2.7,3.9h12.4L282.7,67.9z"
      />
      <path
        style="fill: #b25b06"
        d="M294.6,88.7c-0.1-2.2-0.7-4.3-1.5-6.3c-1-2.5-2.4-4.7-4.2-6.7c-1.7-2-3.8-3.7-5.9-5.2h-13.1
		c-1.7,1.1-3.2,2.3-4.6,3.8c-2.8,2.8-5,6-6.2,9.9c-0.4,1.4-0.7,2.8-0.9,4.3c0,0.4-0.1,0.7-0.1,1.1c0,0.1,0,0.1,0,0.2
		c0,0.3,0.1,0.6,0.1,0.9c0,1.5,0.5,2.8,1.4,4c1.3,1.7,3.1,2.7,5.2,3c0.4,0.1,0.7,0.1,1.2,0.1c7.1,0,14.3,0,21.4,0
		c3-0.2,5.4-1.5,6.8-4.3c0.4-0.8,0.6-1.7,0.7-2.6c0-0.1,0-0.2,0-0.2c0-0.3,0-0.7,0-1C294.7,89.4,294.7,89.1,294.6,88.7z"
      />
      <polygon style="fill: #f98504" points="282.7,67.9 282.6,67.8 270.3,67.8 270.2,67.8 269.9,70.6 283,70.6 	" />
      <path
        style="fill: #f98504"
        d="M281.1,89.2c-0.1-0.2-0.3-0.4-0.5-0.4c-0.4,0.1-1,0.2-1.7,0.2c-2,0-3.7-1.3-4.4-3.1h4.1
		c0.2,0,0.4-0.1,0.4-0.4l0.2-1c0.1-0.3-0.1-0.6-0.4-0.6H274c-0.1-0.5-0.1-1,0-1.5h5.3c0.2,0,0.4-0.1,0.4-0.4l0.2-1
		c0.1-0.3-0.1-0.6-0.4-0.6h-5c0.7-1.6,2.3-2.7,4.3-2.7c0.5,0,1,0.1,1.4,0.1c0.2,0.1,0.4-0.1,0.5-0.3l0.4-1.6
		c0.1-0.2-0.1-0.5-0.3-0.6c-0.4-0.1-1.2-0.2-1.9-0.2c-3.5,0-6.4,2.2-7.4,5.2h-1.2c-0.2,0-0.4,0.2-0.4,0.4v1c0,0.2,0.2,0.4,0.4,0.4
		h0.8c-0.1,0.5-0.1,1,0,1.5h-0.8c-0.2,0-0.4,0.2-0.4,0.4v1c0,0.2,0.2,0.4,0.4,0.4h1.1c0.9,3.3,3.8,5.7,7.5,5.7
		c0.9,0,1.8-0.2,2.2-0.3c0.2-0.1,0.4-0.3,0.3-0.5L281.1,89.2z"
      />
    </g>
  </svg>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default {
  props: ['currentLang'],
  setup() {},
};
</script>

<style lang="scss">
svg.scenario-a {
  max-width: 336px;
  width: 100%;
  padding-top: 7px;
}
</style>
