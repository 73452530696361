<template>
  <div class="py-sm-10 py-5 bav-info">
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10" lg="10">
        <v-card class="v-card--no-border" color="transparent">
          <h1 class="font-weight-bold mb-6 text-center">
            {{ $t('bavInfoHeading1') }}
          </h1>
          <p class="mb-5 text-center">
            {{ $t('bavInfoIntroText') }}
            <br />
          </p>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" class="mb-md-10">
      <v-col cols="12" sm="12" md="10" lg="10" class="bav-info-tabs">
        <v-tabs
          ref="tabs"
          v-model="currentTab"
          active-class="bav-info__tab--active"
          grow
          :icons-and-text="!$vuetify.breakpoint.smAndDown"
          :hide-slider="$vuetify.breakpoint.smAndDown"
        >
          <v-tab>
            <span class="bav-info__tab-text">
              {{ $t('bavTab1Text') }}
            </span>
            <v-icon v-if="!$vuetify.breakpoint.smAndDown" size="16">{{ icons.mdiShield }}</v-icon>
          </v-tab>
          <v-tab>
            <span class="bav-info__tab-text">
              {{ $t('bavTab2Text') }}
            </span>
            <v-icon v-if="!$vuetify.breakpoint.smAndDown" size="16">{{ icons.mdiFile }}</v-icon>
          </v-tab>
          <v-tab>
            <span class="bav-info__tab-text">
              {{ $t('bavTab3Text') }}
            </span>
            <v-icon v-if="!$vuetify.breakpoint.smAndDown" size="16">{{ icons.mdiCompass }}</v-icon>
          </v-tab>
        </v-tabs>

        <div class="v-tabs-overlay" v-bind:class="{ scrolling: isScrolling }">
          <v-tabs-items v-model="currentTab">
            <v-tab-item>
              <d-general-bav-info :companyName="companyConfig.employeeFacingName || companyData.employeeFacingName" />
              <v-divider class="my-5 my-md-15"></v-divider>
              <d-footer></d-footer>
            </v-tab-item>

            <v-tab-item>
              <d-example-calcs
                :companyConfig="companyConfig"
                :exampleCalculations="loadedExampleCalculations"
              ></d-example-calcs>

              <v-divider class="my-5 my-md-15"></v-divider>
              <d-footer></d-footer>
            </v-tab-item>
            <v-tab-item>
              <d-insurance-providers :currentProviders="loadedProviders"></d-insurance-providers>
              <v-divider class="my-5 my-md-15"></v-divider>
              <d-footer></d-footer>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="12" md="10" lg="10">
        <v-row :justify="currentTab === 0 ? 'end' : 'space-between'">
          <v-col v-if="currentTab === 2" cols="6" sm="6" md="6" lg="6" align="left">
            <a href="#" class="bav-info-next d-flex align-items-center justify-start" @click.prevent="preTab">
              <v-icon color="primary">{{ icons.mdiChevronDoubleLeft }}</v-icon>
              <span v-if="currentTab !== 2 && !$vuetify.breakpoint.smAndDown" class="ml-2">{{
                $t('bavInfoBackToCalculations')
              }}</span>
              <span v-if="currentTab == 2 && !$vuetify.breakpoint.smAndDown" class="ml-2">{{
                $t('bavInfoBackToCalculations')
              }}</span>
              <span v-else class="ml-2 text-no-wrap">{{ $t('bavInfoBackToCalculationsMobile') }}</span>
            </a>
          </v-col>
          <v-col v-if="currentTab == 1" cols="6" sm="6" md="6" lg="6" align="left">
            <a href="#" class="bav-info-next d-flex align-center justify-start" @click.prevent="preTab">
              <v-icon color="primary">{{ icons.mdiChevronDoubleLeft }}</v-icon>
              <span v-if="!$vuetify.breakpoint.smAndDown" class="ml-2">{{ $t('bavInfoBackToGeneral') }}</span>
              <span v-else class="ml-2">{{ $t('bavInfoBackToGeneralMobile') }}</span>
            </a>
          </v-col>
          <v-col v-if="currentTab === 0" cols="6" sm="6" md="6" lg="6" align="right">
            <a href="#" class="mr-5 bav-info-next d-flex align-center justify-end" @click.prevent="nextTab">
              <span v-if="!$vuetify.breakpoint.smAndDown" class="mr-2">{{ $t('bavInfoNextToCalculations') }}</span>
              <span v-else class="mr-2">{{ $t('bavInfoNextToCalculationsMobile') }}</span>
              <v-icon color="primary">{{ icons.mdiChevronDoubleRight }}</v-icon>
            </a>
          </v-col>
          <v-col v-if="currentTab === 1" cols="6" sm="6" md="6" lg="6" align="right">
            <a href="#" class="mr-5 bav-info-next d-flex align-center justify-end" @click.prevent="nextTab">
              <span v-if="!$vuetify.breakpoint.smAndDown" class="mr-2">{{ $t('bavInfoNextToProviders') }}</span>
              <span v-else class="mr-2">{{ $t('bavInfoNextToProvidersMobile') }}</span>
              <v-icon color="primary">{{ icons.mdiChevronDoubleRight }}</v-icon>
            </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { ref, getCurrentInstance, onMounted, watch } from '@vue/composition-api';
import { mdiShield, mdiFile, mdiCompass, mdiChevronDoubleRight, mdiChevronDoubleLeft } from '@mdi/js';
import { getCompanyExampleCalculations, getCompanyProviderPlan, getProviderDetails } from '../../api/companies';
import { getPlanDocuments } from '../../api/plans';
import DExampleCalcs from './bav-info/tabs/ExampleCalcs.vue';
import DFooter from '@/components/tiles/LargeTiles.vue';
import DInsuranceProviders from './bav-info/tabs/InsuranceProviders.vue';
import DGeneralBavInfo from './bav-info/tabs/GeneralBavInfo.vue';
import ExampleCalculations from '@/assets/democontent/Adjust/3f09c2c8-27fd-4dee-8e38-fcd8cf167a6b/examples/examples.json';
import appConfig from '../../../appConfig';

export default {
  components: {
    DExampleCalcs,
    DInsuranceProviders,
    DFooter,
    DGeneralBavInfo,
  },
  setup() {
    const vm = getCurrentInstance().proxy;

    const currentTab = ref(0);
    const totalTabs = ref(3);
    const loadedExampleCalculations = ref([]);
    const loadedProviders = ref([]);
    const tabItemContent = '';
    const { companyConfig } = vm.$store.state.auth;
    const { companyData } = vm.$store.state.auth;
    const totalLengthOfTab = totalTabs;
    const exampleCalculations = ExampleCalculations;
    const isScrolling = ref(false);
    const tabs = ref(null);

    const preTab = () => {
      if (currentTab.value !== 0) {
        isScrolling.value = true;
        setTimeout(() => {
          currentTab.value -= 1;
          window.scrollTo(0, 0);
          setTimeout(() => {
            isScrolling.value = false;
          }, 250);
        }, 250);
      }
    };

    const nextTab = () => {
      if (currentTab.value !== totalLengthOfTab.value - 1) {
        isScrolling.value = true;
        setTimeout(() => {
          currentTab.value += 1;
          window.scrollTo(0, 0);
          setTimeout(() => {
            isScrolling.value = false;
          }, 250);
        }, 250);
      }
    };

    const fetchProviders = async (id) => {
      const providersListResponse = await getCompanyProviderPlan('api', id);
      const providersInUse = providersListResponse.data.list;

      if (!providersInUse || providersInUse.length === 0) return [];

      const plansInUse = providersInUse
        .map((p) => {
          return p.plans.map((i) => {
            return {
              ...i,
              providerId: p.provider,
            };
          });
        })
        .reduce((arr, e) => arr.concat(e));

      Promise.all(
        plansInUse.map(async (p) => {
          const planDocumentRes = await getPlanDocuments(p.planId);
          const providerDetailsRes = await getProviderDetails(p.providerId);
          const planBenefits = providerDetailsRes.data.plans.find(
            (providerPlan) => p.planId === providerPlan.planId,
          )?.benefits;

          return {
            ...p,
            planDocument: planDocumentRes.data,
            providerDetails: providerDetailsRes.data,
            planBenefits,
          };
        }),
      ).then((res) => {
        loadedProviders.value = res;
      });
    };

    onMounted(() => {
      getCompanyExampleCalculations('api', companyData.id).then((res) => {
        loadedExampleCalculations.value = res.data;
      });
      fetchProviders(companyData.id);
      setTimeout(() => {
        tabs.value.callSlider();
      }, 0);
    });

    watch(currentTab, () => {
      setTimeout(() => {
        tabs.value.callSlider();
      }, 0);
    });

    watch(
      () => vm.$store.state.app.currentLang,
      () => {
        setTimeout(() => {
          tabs.value.callSlider();
        }, 0);
      },
    );

    return {
      loadedExampleCalculations,
      loadedProviders,
      currentProviders: {},
      currentTab,
      tabItemContent,
      companyConfig,
      companyData,
      preTab,
      nextTab,
      tabs,
      exampleCalculations,
      isScrolling,

      icons: {
        mdiShield,
        mdiFile,
        mdiCompass,
        mdiChevronDoubleRight,
        mdiChevronDoubleLeft,
      },
    };
  },
};
</script>

<style lang="scss">
.bav-info {
  padding-top: 33px !important;
  .v-tabs-bar.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows)
    > .v-slide-group__wrapper
    > .v-tabs-bar__content
    > .v-tab:first-of-type {
    margin-left: unset !important;
  }

  .v-tabs-bar {
    background-color: transparent !important;
  }

  .v-tabs-items {
    background-color: transparent !important;
  }
  .v-tab:not(.v-tabs--vertical .v-tab) {
    font-size: 14px !important;
    text-transform: none !important;
    padding-top: 13px;
    padding-bottom: 8px;
    letter-spacing: normal !important;
  }
  &-next {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
  }
  &__tab {
    &-text {
      color: #898989;
      margin-top: 9px;
    }
    &--active {
      background-color: rgba(108, 189, 255, 0.5);
      font-weight: bold;
      .bav-info__tab-text {
        color: #2830ce;
      }
    }
  }
}
.v-tabs-overlay {
  position: relative;
}
.v-tabs-overlay::after {
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  z-index: 4;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  pointer-events: none; /* Pass events through the "fade" layer */
}
.scrolling::after {
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .bav-info {
    .v-tabs-bar {
      //    height: 50px !important;

      border-radius: 5px;
    }
    .v-tab {
      //   max-width: 50px;
      line-height: 12px !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
    }
    .v-slide-group__wrapper {
      tr td {
        display: none;
      }
    }
    &__tab-text {
      max-width: 95px;
      font-size: 10px !important;
      margin: 0 !important;
    }
    &-tabs {
      // padding: 0;
    }
  }
}
</style>
